import React, { FC } from 'react';
import { Divider, DividerProps } from 'antd';

interface Props extends DividerProps {}

const BaseDivider: FC<Props> = props => {
  return <Divider {...props} />;
};

const UcDivider = Object.assign(BaseDivider, Divider);

export default UcDivider;
