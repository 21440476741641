import { BulkActionProcessCollection } from 'services/bulkActions/types';

import { Action, ActionTypes } from './types';

const reducer = (
  state: BulkActionProcessCollection,
  action: Action
): BulkActionProcessCollection => {
  switch (action.type) {
    case ActionTypes.ADD_NEW_PROCESS:
      return {
        ...state,
        [action.payload.processId]: action.payload
      };
    case ActionTypes.REMOVE_PROCESS:
      const stateClone = { ...state };

      delete stateClone[action.payload];

      return stateClone;
    default:
      return state;
  }
};

export default reducer;
