import { ImportableEntity } from '../types';

const getImportSteps = (entityType: ImportableEntity) => {
  const STEPS = ['fileUpload', 'mapping', 'importing', 'finish'];

  if (entityType === 'Product') {
    STEPS.unshift('productFamily');
  }

  return STEPS;
};

export default getImportSteps;
