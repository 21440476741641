import { FileExtension } from '../types';

const getFileExampleLink = (
  importBluePrintUrl: string,
  extension: FileExtension,
  attributeFamilyId?: string
) => {
  const url = importBluePrintUrl.replace(':extension', extension);

  return attributeFamilyId
    ? url.replace(':attributeFamilyId', attributeFamilyId)
    : url;
};

export default getFileExampleLink;
