import { PageContainer } from 'components/wrapper';
import styles from './styles.module.less';
import { SettingsListSkeleton } from '../SettingsListSkeleton';

export const OtherPaymentsLazySkeleton = () => {
  return (
    <PageContainer>
      <div className={styles.other_payments}>
        <SettingsListSkeleton />
      </div>
    </PageContainer>
  );
};
