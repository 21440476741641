import { ImportableField } from 'generatedHooks/commerce/generated';

import { ImportableFieldExtended } from '../types';

const importableFieldsAdapter = (
  data: ImportableField[]
): ImportableFieldExtended[] => {
  return data?.map(({ key, meta, type, ...rest }: ImportableField) => {
    const modifiedKey = `${key}_${meta?.localeId || meta?.id || ''}`;

    return {
      ...rest,
      type,
      key: `${type}:${modifiedKey}`,
      originKey: key,
      meta
    };
  });
};

export default importableFieldsAdapter;
