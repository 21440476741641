import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserSnap } from 'hooks';

import { Dropdown } from 'components/basic';
import {
  getHelpMenuItemLabel,
  helpMenuItems
} from 'components/global/Help/helpers';
import {
  ApplicationQR,
  HeaderButton,
  KeyboardShortcutsGuide
} from 'components/shared';

const Help = () => {
  const [shortcutsGuideModalVisible, setShortcutsGuideModalVisible] =
    useState(false);

  const [qrsModalVisible, setQrsModalVisible] = useState(false);

  const { t } = useTranslation('helpMenu');

  useUserSnap();

  const openKeyboardShortcutsGuide = () => {
    setShortcutsGuideModalVisible(true);
  };

  const closeShortcutsGuideModal = () => {
    setShortcutsGuideModalVisible(false);
  };

  const openQrsModal = () => {
    setQrsModalVisible(true);
  };

  const closeQrsModal = () => {
    setQrsModalVisible(false);
  };

  const items = useMemo(
    () =>
      helpMenuItems.map(item => {
        const label = getHelpMenuItemLabel(item, t);

        return {
          key: item.key,
          onClick:
            item.onClick?.({
              openKeyboardShortcutsGuide,
              openQrsModal
            }) || (() => {}),
          label,
          icon: item.icon,
          type: item.type
        };
      }),
    [helpMenuItems, openKeyboardShortcutsGuide]
  );

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        placement="bottomRight"
        overlayStyle={{ minWidth: 320 }}
      >
        <HeaderButton icon={<QuestionCircleOutlined />} dataTestPrefix="help" />
      </Dropdown>
      <KeyboardShortcutsGuide
        visible={shortcutsGuideModalVisible}
        closeModal={closeShortcutsGuideModal}
      />
      <ApplicationQR visible={qrsModalVisible} closeModal={closeQrsModal} />
    </>
  );
};

export default Help;
