import Api from 'axiosApi';
import SessionStorageManager from 'services/SessionStorageManager';
import { deleteOneSignalTags } from 'services/OneSignalService';

const { VITE_APP_ACCOUNTS_URL } = import.meta.env;

export const redirectToLogin = () => {
  const redirectPath = window.median ? '/sign-in' : '';

  window.open(`${VITE_APP_ACCOUNTS_URL}${redirectPath}`, '_top');
};

export const redirectToAccounts = () => {
  const target = window.median ? '_top' : '';

  window.open(VITE_APP_ACCOUNTS_URL as string, target);
};

export const logout = async () => {
  try {
    await Api.post('/api/logout');
    SessionStorageManager.clearDateRange();
    await deleteOneSignalTags();
  } catch (e) {
    console.error(e);
  } finally {
    await redirectToLogin();
  }
};
