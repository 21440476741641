import { ApolloClient } from '@apollo/client';

export const customerRegistered = (apolloClient: ApolloClient<any>) => {
  apolloClient.cache.modify({
    fields: {
      customers() {}
    }
  });
};

export const orderPlaced = (apolloClient: ApolloClient<any>) => {
  apolloClient.cache.modify({
    fields: {
      orders() {},
      reservations() {},
      rentals() {}
    }
  });
};
