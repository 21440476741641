import { ImportableEntity } from './types';

type Keys = 'products' | 'categories' | 'customers' | 'attributes' | 'families';

export const IMPORTABLE_ENTITY_KEYS: Record<ImportableEntity, Keys> = {
  Product: 'products',
  Category: 'categories',
  Customer: 'customers',
  Attribute: 'attributes',
  Family: 'families'
};

export const translatableFamilies = ['physical', 'digital', 'subscription'];

export const VALID_MIME_TYPES = {
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const VALID_MIME_TYPES_ARRAY = Object.values(VALID_MIME_TYPES);
export const MAX_FILE_SIZE_MB = 64;
