export class FetchingGraphqlService {
  private static defaultHeaders: object = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json'
  };

  static fetchGraphql(
    url: string,
    headers: any | undefined = FetchingGraphqlService.defaultHeaders,
    query: string,
    variables?: any
  ) {
    return fetch(`${url}/graphql`, {
      method: 'POST',
      headers: {
        ...FetchingGraphqlService.defaultHeaders,
        ...headers
      },
      body: JSON.stringify({
        query,
        variables
      })
    })
      .then(res => {
        return res.json();
      })
      .catch(() => {
        console.error('Something went wrong');
      });
  }
}
