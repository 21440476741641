import { useEffect, UIEvent, useRef } from 'react';

type Params = {
  trigger: Function;
  loading: boolean;
  hasMore?: boolean;
  global?: boolean;
};

function useInfiniteScroll(params: Params) {
  const page = useRef(1);
  const { trigger, loading, global = true, hasMore } = params;

  function handleScroll(e: UIEvent<HTMLElement> | Event) {
    const { scrollTop, scrollHeight, offsetHeight } = global
      ? document.documentElement
      : (e.currentTarget as HTMLElement);

    if (
      hasMore &&
      !loading &&
      Math.ceil(scrollTop + offsetHeight) + 20 > scrollHeight
    ) {
      page.current++;
      trigger();
    }
  }

  function resetPage() {
    page.current = 1;
  }

  function getCurrentPage() {
    return page.current;
  }

  useEffect(() => {
    if (!global) {
      return;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trigger, loading, global, hasMore]);

  return { onScroll: handleScroll, resetPage, getCurrentPage };
}

export default useInfiniteScroll;
