import { FC, useMemo } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import styles from './styles.module.less';

interface Props extends ButtonProps {
  forceRenderChildren?: boolean;
}

const BaseButton: FC<Props> = ({
  forceRenderChildren = false,
  className = '',
  children,
  icon,
  ...props
}) => {
  const isMobile = useReactiveVar(isMobileDevice);
  const child = useMemo(
    () =>
      forceRenderChildren
        ? children
        : !isMobile
          ? children
          : icon
            ? null
            : children,
    [icon, isMobile, children]
  );

  return (
    <Button
      size="large"
      className={`${className} ${styles.button}`}
      icon={icon}
      {...props}
    >
      {child}
    </Button>
  );
};

const UcButton = Object.assign(BaseButton, Button);

export default UcButton;
