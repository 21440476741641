import { ReactNode } from 'react';

import { ActionTypes, Action } from './types';

type State = {
  element: ReactNode;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_ELEMENT:
      return {
        ...state,
        element: action.element
      };
    default:
      return state;
  }
};

export default reducer;
