import { Input } from 'antd';
import { GroupProps } from 'antd/es/input';

const { Group } = Input;

const BaseGroup = (props: GroupProps) => {
  return <Group {...props} />;
};

const UcGroupInput = Object.assign(Group, BaseGroup);

export default UcGroupInput;
