import { TFunction } from 'react-i18next';
import { Actions } from 'providers/PermissionAndLimitationProvider';

import { MenuProps } from './menuTypes';
import { SettingsTablesLazySkeleton } from './components/SettingsTablesLazySkeleton';
import { BillingsLazySkeleton } from './components/BillingsLazySkeleton';
import { SettingsListSkeleton } from './components/SettingsListSkeleton';
import CodeInjectionLazySkeleton from './components/CodeInjectionLazySkeleton';

export const data = (t: TFunction<'settings'>): MenuProps[] => [
  {
    key: 'project-setup',
    tab: t('project-setup'),
    url: 'project-setup',
    fallback: SettingsListSkeleton
  },
  {
    key: 'locations',
    tab: t('locations'),
    url: 'locations',
    fallback: SettingsListSkeleton
  },
  {
    key: 'payments',
    tab: t('payments'),
    url: 'payments',
    fallback: SettingsListSkeleton
  },
  {
    key: 'notifications',
    tab: t('notifications'),
    url: 'notifications',
    fallback: SettingsListSkeleton
  },
  {
    key: 'checkouts',
    tab: t('checkouts'),
    url: 'checkouts',
    fallback: SettingsListSkeleton,
    permission: {
      I: Actions.See.CustomCheckout
    }
  },
  {
    key: 'shipping-delivery',
    tab: t('shippingDelivery'),
    url: 'shipping-delivery',
    fallback: SettingsListSkeleton
  },
  {
    key: 'taxes',
    tab: t('taxes'),
    url: 'taxes',
    fallback: SettingsTablesLazySkeleton
  },
  {
    key: 'team',
    tab: t('team'),
    url: 'team',
    fallback: SettingsTablesLazySkeleton
  },
  {
    key: 'domains',
    tab: t('domains'),
    url: 'domains',
    fallback: SettingsTablesLazySkeleton
  },
  {
    key: 'languages',
    tab: t('languages'),
    url: 'languages',
    fallback: SettingsListSkeleton
  },
  {
    key: 'billing',
    tab: t('billing'),
    url: 'billing',
    fallback: BillingsLazySkeleton
  },
  {
    key: 'code-injection',
    tab: t('codeInjection'),
    url: 'code-injection',
    fallback: CodeInjectionLazySkeleton
  },
  {
    key: 'business-hours',
    tab: t('timeSlots'),
    url: 'time-slots',
    fallback: BillingsLazySkeleton
  },
  {
    key: 'legal-docs',
    tab: t('legalDocs'),
    url: 'legal-docs'
  }
];

export const generateSettingsTabsByPermissions = (
  t: TFunction<'settings'>,
  Can: any
) => {
  return data(t).reduce((acc: MenuProps[], curr) => {
    if (curr.permission) {
      const permissionProtectedTab = Can({
        I: curr.permission.I,
        RequestAccess: curr.permission.RequestAccess,
        children: curr
      });

      if (permissionProtectedTab) {
        acc.push(permissionProtectedTab);
      }
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
};
