import { FC, ReactNode } from 'react';
import { IntermediateWrapperProps } from 'providers/PermissionAndLimitationProvider/core/globalTypes';

interface Props extends Omit<IntermediateWrapperProps, 'icon'> {
  children: ReactNode;
}

const IntermediateWrapper: FC<Props> = ({
  children,
  disabled,
  onMouseEnter,
  onMouseLeave,
  onClick
}) => {
  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 100,
          cursor: disabled ? 'not-allowed' : 'inherit'
        }}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      />
      {children}
    </div>
  );
};

export default IntermediateWrapper;
