import React, { FC, useMemo } from 'react';
import { Popup } from 'antd-mobile';

import { PopupSheetProps } from './types';
import styles from './styles.module.less';
import { ShowElement } from 'components/shared';

const PopupSheet: FC<PopupSheetProps> = ({
  visible,
  onClose,
  children,
  withSeparator = false,
  outSeparator = false,
  fullScreen = false,
  fullBody = false,
  radius = false,
  layout = false,
  mask = true,
  position = 'bottom',
  zIndex = '5',
  padding,
  overflow,
  bodyExtraStyles = ''
}) => {
  const bodyStyles = useMemo(() => {
    const stylesArray = [''];

    if (padding) {
      stylesArray.push(styles.popupBody);
    }

    if (overflow) {
      stylesArray.push(styles.overflowBody);
    }

    if (bodyExtraStyles) {
      stylesArray.push(bodyExtraStyles);
    }

    return stylesArray.join(' ');
  }, [padding, overflow, bodyExtraStyles]);

  return (
    <Popup
      style={{ '--z-index': zIndex }}
      visible={visible}
      position={position}
      maskClassName={styles.popup_sheet_mask}
      bodyClassName={`${styles.popup} ${
        fullScreen ? styles.fullscreen : fullBody ? styles.fullBody : ''
      } ${layout ? styles.full_layout : ''} ${radius ? styles.radius : ''}`}
      onMaskClick={onClose}
      mask={mask}
    >
      <ShowElement isShow={Boolean(withSeparator)}>
        <div onClick={onClose} className={styles.separatorContainer}>
          <div className={styles.separator} />
        </div>
      </ShowElement>

      <ShowElement isShow={Boolean(outSeparator && !withSeparator)}>
        <div className={styles.outSeparatorContainer}>
          <div onClick={onClose} className={styles.outSeparator} />
        </div>
      </ShowElement>

      <div className={bodyStyles}>{children}</div>
    </Popup>
  );
};

export default PopupSheet;
