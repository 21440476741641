import { Table, TablePaginationConfig } from 'antd';
import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { BaseTableProps } from './types';
import { BulkPanel, Locale } from './components';
import { defaultPagination, rowSelectionPropsFallback } from './constants';
import { UniversalEmptyStateIcon } from '../SVG';
import ShowElement from '../ShowElement';
import { flatDataSourceKeys, getRowSelectionDefaultConfigs } from './utils';
import useRowSelection from './hooks/useRowSelection';
import ListSkeleton from '../Skeleton/components/List';

/**
 *
 * @param props{
 *   height:  optional | height is coming from outside if we want to set custom height (default value is auto)
 *   filtersLeftElements: required | array of ReactNodes (pass key from outside)
 *   filtersRightElements: required | array of ReactNodes (pass key from outside)
 *   emptyText: required {
 *     primary: required | string
 *     secondary: optional | string
 *   }
 * }
 * @extends TableProps
 * @return Table component
 * @interface MyTableProps
 *
 */

const CustomTable = <T extends object>(props: BaseTableProps<T>) => {
  const {
    mobileView,
    height,
    pagination = false,
    filtersLeftElements,
    filtersLeftElementsStyles = {},
    filtersRightElements,
    filtersRightElementsStyles = {},
    bulkPanelElements,
    dataSource = [],
    rowKey = 'id',
    rowSelection,
    rowSelectionProps,
    scroll = {},
    filterPanelStyles,
    className = '',
    emptyText: { primary, secondary, icon, footer } = {
      primary: '',
      secondary: '',
      footer: null
    },
    loading = false,
    ...rest
  } = props;

  const isMobile = useReactiveVar(isMobileDevice);

  const rowSelectionPropsWithFallback =
    rowSelectionProps || rowSelectionPropsFallback;

  const {
    allRowsSelected,
    excludedRowKeys,
    setAllRowsSelected,
    setExcludedRowKeys,
    selectedRowKeys,
    setSelectedRowKeys,
    resetSelections,
    entity,
    totalEntitiesCount,
    totalEntitiesSelected
  } = rowSelectionPropsWithFallback;

  const flattenDataSourceKeys = useMemo(() => {
    return flatDataSourceKeys<T & { children?: T[] }>(
      dataSource as T[],
      rowKey
    );
  }, [dataSource, rowKey]);

  const { onSelectionChange, showBulkPanel } = useRowSelection<T>({
    ...rowSelectionPropsWithFallback,
    dataSourceKeys: flattenDataSourceKeys,
    loading
  });

  const hasDataSource = Boolean(dataSource.length);

  const combinedPagination =
    typeof pagination === 'object'
      ? {
          ...defaultPagination,
          ...pagination,
          hideOnSinglePage: Number(pagination.total) <= 10,
          style: { justifyContent: isMobile ? 'center' : 'flex-start' },
          size: 'large',
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Component,
            prefix: 'pagination'
          })
        }
      : false;

  const EmptyIcon = icon || <UniversalEmptyStateIcon />;

  const shouldUseRowSelection = Boolean(rowSelection || rowSelectionProps);

  return (
    <div
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Component,
        prefix: 'table'
      })}
      style={{ height }}
      className={styles.tableWrapper}
    >
      <ShowElement isShow={showBulkPanel}>
        <BulkPanel
          actions={bulkPanelElements}
          onCancel={resetSelections}
          totalSelected={totalEntitiesSelected}
          totalDataCount={totalEntitiesCount}
          entity={entity}
        />
      </ShowElement>
      <div style={filterPanelStyles} className={styles.filterPanel}>
        <div
          style={filtersLeftElementsStyles}
          className={styles.filtersLeftElements}
        >
          {filtersLeftElements}
        </div>
        <div
          style={filtersRightElementsStyles}
          className={styles.filtersRightElements}
        >
          {filtersRightElements}
        </div>
      </div>
      <Table<T>
        {...rest}
        tableLayout="fixed"
        size="large"
        className={`${className} ${loading ? '' : styles.centeredEmpty}`}
        pagination={combinedPagination as TablePaginationConfig}
        rowSelection={
          shouldUseRowSelection
            ? {
                ...getRowSelectionDefaultConfigs<T>({
                  allRowsSelected,
                  excludedRowKeys,
                  onChange: onSelectionChange,
                  selectedRowKeys,
                  setAllRowsSelected,
                  setExcludedRowKeys,
                  setSelectedRowKeys,
                  resetSelections,
                  totalEntitiesCount,
                  totalEntitiesSelected
                }),
                ...rowSelection
              }
            : undefined
        }
        rowClassName={rest.onRow ? styles.row_pointer : ''}
        rowKey={rowKey}
        dataSource={loading ? [] : dataSource}
        locale={{
          emptyText: (
            <Locale
              loading={loading}
              icon={EmptyIcon}
              primary={primary}
              secondary={secondary}
              footer={footer}
            />
          )
        }}
        loading={false}
        scroll={{ y: '100%', x: hasDataSource ? 1069 : undefined, ...scroll }}
        showHeader={hasDataSource}
        components={
          isMobile && mobileView
            ? {
                header: {
                  wrapper: () => <></>
                },
                table: () => (
                  <ListSkeleton count={20} loading={loading}>
                    {dataSource.length ? (
                      mobileView
                    ) : (
                      <Locale
                        loading={loading}
                        icon={EmptyIcon}
                        primary={primary}
                        secondary={secondary}
                        footer={footer}
                      />
                    )}
                  </ListSkeleton>
                )
              }
            : undefined
        }
      />
    </div>
  );
};

CustomTable.defaultProps = {
  size: 'large',
  height: 'auto',
  emptyText: {
    primary: 'No Data'
  }
} as BaseTableProps<any>;

CustomTable.ColumnGroup = Table.ColumnGroup;

export default CustomTable;
