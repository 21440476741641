import React, { useEffect } from 'react';
import { Checkbox, Radio } from 'antd';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { VisiblePosition } from './OverlayContext';
import useOverlay from './useOverlay';

type Props = {
  type?: string;
  indeterminate?: boolean;
  onChange: () => void;
  position?: VisiblePosition;
  isChecked?: boolean;
};

function BulkSelect({
  type = 'checkbox',
  indeterminate = false,
  onChange,
  position = 'left',
  isChecked
}: Props) {
  const selectContext = useOverlay();

  useEffect(() => {
    selectContext.setVisiblePosition(isChecked ? position : 'all');
  }, [isChecked]);

  const Component = type === 'checkbox' ? Checkbox : Radio;

  return (
    <Component
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Checkbox,
        prefix: 'image'
      })}
      onChange={onChange}
      checked={isChecked}
      indeterminate={indeterminate}
    />
  );
}

export default BulkSelect;
