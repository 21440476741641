import { FC, ReactElement, ReactNode } from 'react';

interface EmptyWrapperProps {
  isEmpty: boolean;
  indicator: ReactElement;
  children?: ReactNode;
}

const EmptyWrapper: FC<EmptyWrapperProps> = ({
  isEmpty,
  children,
  indicator
}) => {
  return <> {isEmpty ? indicator : children}</>;
};

export default EmptyWrapper;
