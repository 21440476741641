import { ProductTypeGroup } from 'generatedHooks/commerce/generated';

import { IImportReportData } from 'components/global/ImportModal/types';
import { NotificationType } from 'components/global/Notifications/types';

export enum NotificationSocketEvents {
  Read = 'read',
  ReadAll = 'readAll',
  Delete = 'delete',
  DeleteAll = 'deleteAll',
  Seen = 'seen',
  Unread = 'unRead',
  UnreadAll = 'unReadAll'
}

export interface INotificationMeta {
  scope: string;
  payload: INotificationPayload;
}

export interface INotificationPayload {
  id?: string;
  report?: IImportReportData;
  projectId?: number | string;
  filename?: string;
  itemsType?: Exclude<ProductTypeGroup, ProductTypeGroup.Collection>;
}

export interface INotification {
  type: NotificationType;
  _id: string;
  read: boolean;
  notification: {
    meta: INotificationMeta;
  };
  createdAt: Date;
}

export interface NotificationConfigs {
  seen: boolean;
}

export interface NotificationsResponse {
  notifications: INotification[];
  settings: NotificationConfigs;
}
