import { KeyValuePair } from 'core/globalTypes';
import dateTimeConverter, {
  IDateTimeConverterOptions
} from 'helpers/commonHelpers/dateTimeConverter';

/**
 * `createTypePolicyForDates` creates a type policy object for date fields to use for InMemoryCache configs.
 * @param typeName - The typename which date field needs to be converted
 * @param dateFields - The name of the field which holds the date
 * @param options - Optional options object
 */

const createTypePolicyForDates = (
  typeName: string,
  dateFields: string | string[] = 'createdAt',
  options?: IDateTimeConverterOptions
) => {
  const typePolicy: KeyValuePair<KeyValuePair<any>> = {
    [typeName]: {
      fields: {}
    }
  };

  if (typeof dateFields === 'string') {
    typePolicy[typeName].fields[dateFields] = {
      read(dateField: string) {
        return dateTimeConverter(dateField, options);
      }
    };
  } else {
    dateFields.forEach(fieldName => {
      typePolicy[typeName].fields[fieldName] = {
        read(dateField: string) {
          return dateTimeConverter(dateField, options);
        }
      };
    });
  }

  return typePolicy;
};

export default createTypePolicyForDates;
