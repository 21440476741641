import { FC } from 'react';
import { Avatar, AvatarProps } from 'antd';

interface Props extends AvatarProps {}

const BaseAvatar: FC<Props> = props => {
  return <Avatar {...props} />;
};

const UcAvatar = Object.assign(Avatar, BaseAvatar);

export default UcAvatar;
