import React, {
  useState,
  useMemo,
  useEffect,
  SetStateAction,
  Dispatch,
  useCallback
} from 'react';
import { Form, App } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/lib/upload/interface';
import { linkTypes } from 'apollo/link';
import {
  useImportCategoriesMutation,
  useImportProductsMutation
} from 'generatedHooks/commerce/generated';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSocket } from 'services/socketService';
import { FieldData } from 'rc-field-form/lib/interface';
import { KeyValuePair } from 'core/globalTypes';
import { useNavigationContext } from 'providers/NavigationProvider';
import { useProjectContext } from 'providers/ProjectProvider';
import { SystemMessage } from 'helpers';
import {
  createDataTestAttribute,
  modalConfirmRenderWithDataTest
} from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Modal } from 'components/basic';
import {
  mapImportableFields,
  getContinueText,
  getStepNumbers,
  getInitialStep
} from './utils';
import ImportingStep from './pages/Importing';
import ProductFamilyStep from './pages/ProductFamily';
import MappingStep from './pages/Mapping';
import FinishStep from './pages/Finish';
import FileUploadStep from './pages/FileUpload';
import styles from './styles.module.less';
import ModalHeader from './components/ModalHeader';
import {
  IImportReportData,
  ImportableFieldExtended,
  ImportModalProps
} from './types';
import { IMPORTABLE_ENTITY_KEYS, translatableFamilies } from './constants';
import ImportProgress from './components/ImportProgress';

const { useApp } = App;

const ImportModal: React.FC<ImportModalProps> = ({
  setModalVisible,
  modalVisible,
  entityType,
  productAttributes,
  isOpenFromNotifications,
  importReportFromNotification,
  refetchEntities,
  attributeFamiliesLoading
}) => {
  const { modal } = useApp();
  const { t } = useTranslation('importModal');
  const [currentStep, setCurrentStep] = useState<number>(
    getInitialStep(entityType, isOpenFromNotifications)
  );

  const [headerList, setHeaderList] = useState<string[] | undefined>([]);
  const [file, setFile] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMappingValid, setIsMappingValid] = useState<boolean>(false);
  const [importableFields, setImportableFields] = useState<
    ImportableFieldExtended[] | null
  >(null);

  const [selectedFamily, setSelectedFamily] = useState<string | null>(null);
  const [isShowingUploadedList, setIsShowingUploadedList] =
    useState<boolean>(true);

  const [passedSteps, setPassedSteps] = useState([0]);

  const [selectedFamilyId, setSelectedFamilyId] = useState<string>('');
  const [importReport, setImportReport] = useState<IImportReportData | null>(
    null
  );

  const [newFieldsDefaultValues, setNewFieldsDefaultValues] = useState<
    KeyValuePair<string>
  >({});

  const { setShouldShowConfirm } = useNavigationContext();

  const { on } = useSocket();

  const [form] = Form.useForm();

  const [uploadCategories] = useImportCategoriesMutation({
    context: {
      urlType: linkTypes.file,
      hideMessages: false
    },
    onCompleted: () => {
      /**
       * This condition is to prevent issues in case when modal is closed during
       * 'uploadCategories' execution
       */
      modalVisible && currentStep === STEPS.MAPPING && toNextStep();
    }
  });

  const [uploadProducts] = useImportProductsMutation({
    context: {
      urlType: linkTypes.file,
      hideMessages: false
    },
    onCompleted: () => {
      /**
       * This condition is to prevent issues in case when modal is closed during
       * 'uploadProducts' execution
       */
      modalVisible && currentStep === STEPS.MAPPING && toNextStep();
    }
  });

  const navigate = useNavigate();
  const { name: projectName } = useProjectContext();

  const productFamily = useCallback(
    (withTranslation = true) => {
      // If the selected product family is custom one, it will not be translated in Mapping Table
      if (
        selectedFamily &&
        translatableFamilies.includes(selectedFamily) &&
        withTranslation
      ) {
        return t(`productFamilies.${selectedFamily}`);
      }

      return productAttributes?.find(item => item.code === selectedFamily)
        ?.name;
    },
    [selectedFamily, productAttributes]
  );

  const STEPS = useMemo(() => getStepNumbers(entityType), [entityType]);

  const fieldsKeys = useMemo(() => {
    if (importableFields) {
      return importableFields.map(
        (field: ImportableFieldExtended) => field.key
      );
    }

    return [];
  }, [importableFields]);

  useEffect(() => {
    if (on && !isOpenFromNotifications) {
      on('notification', (data: any) => {
        setImportReport(
          data.notification.meta.payload.report as IImportReportData
        );

        refetchEntities && refetchEntities();
      });
    }
  }, [on]);

  useEffect(() => {
    // In case if the user goes back to product family step and changes the family
    setPassedSteps([0]);
    setIsShowingUploadedList(false);
    setFile([]);
  }, [selectedFamily]);

  const resetStates = () => {
    if (!isOpenFromNotifications) {
      // Reseting all states if modal was not opened from notifications
      setPassedSteps([0]);
      form.resetFields();
      setIsLoading(false);
      setCurrentStep(0);
      setSelectedFamily(null);
      setSelectedFamilyId('');
      setFile([]);
      setImportReport(null);
    }
  };

  const resetFieldsOnFileChange = () => {
    // Reseting the fields in mapping, in case if the file has been changed or removed
    form.resetFields(fieldsKeys);
  };

  const onCloseHandler = () => {
    resetStates();
    setModalVisible(false);
  };

  const onBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const STEP_CONTENTS = () => {
    const components = [
      <ProductFamilyStep
        key={'productFamily'}
        productAttributes={productAttributes || []}
        setSelectedFamily={setSelectedFamily}
        setSelectedFamilyId={setSelectedFamilyId}
        selectedFamily={selectedFamily || ''}
        loading={!!attributeFamiliesLoading}
      />,
      <FileUploadStep
        key={'fileUpload'}
        setHeader={list => setHeaderList(list)}
        setFile={file => setFile(file)}
        file={file}
        showUploadList={isShowingUploadedList}
        setShowUploadList={setIsShowingUploadedList}
        resetFields={resetFieldsOnFileChange}
        entityType={entityType}
        setIsLoading={setIsLoading}
        attributeFamilyId={selectedFamilyId}
        productFamily={productFamily(false)}
      />,
      <MappingStep
        key={'mapping'}
        fileHeader={headerList}
        setFields={
          setImportableFields as Dispatch<
            SetStateAction<ImportableFieldExtended[]>
          >
        }
        form={form}
        setIsMappingValid={setIsMappingValid}
        productFamily={productFamily()}
        entityType={entityType}
        selectedFamilyId={selectedFamilyId}
        setNewFieldsDefaultValues={setNewFieldsDefaultValues}
      />,
      <ImportingStep
        key={'importing'}
        entityType={entityType}
        onClose={onCloseHandler}
      />,
      <FinishStep
        key={'finish'}
        importReport={
          isOpenFromNotifications ? importReportFromNotification : importReport
        }
        entityType={entityType}
      />
    ];

    return entityType === 'Product' ? components : components.slice(1);
  };

  const isContinueDisabled = useMemo(() => {
    const states: boolean[] = [!file.length, !isMappingValid];

    if (entityType === 'Product') {
      states.unshift(!selectedFamily);
    }

    return states[currentStep];
  }, [selectedFamily, file.length, isMappingValid, currentStep]);

  const toNextStep = () => {
    if (currentStep === STEPS.UPLOAD) {
      if (!headerList?.length) {
        SystemMessage.warning(
          t('emptyFirstRow', "The first row of the file shouldn't be empty")
        );

        return;
      }
    }

    if (currentStep <= STEPS.MAPPING) {
      setPassedSteps(passedSteps => [...passedSteps, currentStep + 1]);
    }

    setCurrentStep(currentStep => currentStep + 1);
  };

  const onContinueHandler = () => {
    if (
      (currentStep === STEPS.FAMILY && selectedFamily) ||
      currentStep === STEPS.UPLOAD
    ) {
      toNextStep();
    } else if (currentStep === STEPS.MAPPING) {
      uploadHandler();
    } else if (currentStep >= STEPS.IMPORTING) {
      setShouldShowConfirm(false);
      onCloseHandler();
      navigate(`/${projectName}`, { state: { blockNavigate: false } });
    }
  };

  const uploadHandler = async () => {
    setIsLoading(true);

    try {
      switch (entityType) {
        case 'Category':
          await uploadCategories({
            variables: {
              input: {
                file: file[0],
                mapping: mapImportableFields(
                  form.getFieldsValue(fieldsKeys),
                  newFieldsDefaultValues,
                  importableFields as ImportableFieldExtended[]
                )
              }
            }
          });
          break;
        case 'Product':
          await uploadProducts({
            variables: {
              input: {
                file: file[0],
                mapping: mapImportableFields(
                  form.getFieldsValue(fieldsKeys),
                  newFieldsDefaultValues,
                  importableFields as ImportableFieldExtended[]
                ),
                attributeFamilyId: selectedFamilyId
              }
            }
          });
      }
    } catch (e) {
      console.log(e, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // In case if the user goes back from mapping step and removes uploaded file
    if (headerList?.length || passedSteps.includes(STEPS.MAPPING)) {
      setPassedSteps(passedSteps =>
        passedSteps.filter(step => step !== STEPS.MAPPING)
      );
    }
  }, [headerList?.length]);

  useEffect(() => {
    if (
      importReport &&
      !isOpenFromNotifications &&
      currentStep === STEPS.IMPORTING
    ) {
      // Redirecting to the last step only if modal was not opened from notifications
      setCurrentStep(STEPS.FINISH);
    }
  }, [importReport, isOpenFromNotifications, currentStep]);

  const confirmClose = () => {
    modal.confirm({
      title: t('exit'),
      icon: <ExclamationCircleOutlined />,
      content: t('closeConfirmation'),
      okText: t('yes'),
      cancelText: t('no'),
      okType: 'primary',
      centered: true,
      cancelButtonProps: {
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'cancel'
        })
      },
      okButtonProps: {
        danger: true,
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'confirm'
        })
      },
      modalRender: modalConfirmRenderWithDataTest,
      async onOk() {
        onCloseHandler();
      }
    });
  };

  const isStepDisabled = (stepIndex: number) => {
    return (
      isLoading ||
      !passedSteps.includes(stepIndex) ||
      currentStep > STEPS.MAPPING
    );
  };

  const onFieldsChange = (changedField: FieldData[]) => {
    const [changedFieldName] = changedField[0].name as string[];

    if (changedFieldName in newFieldsDefaultValues) {
      setNewFieldsDefaultValues(prevValues => {
        delete prevValues[changedFieldName];

        return prevValues;
      });
    }
  };

  return (
    <Form name="importForm" form={form} onFieldsChange={onFieldsChange}>
      <Modal
        title={
          <ModalHeader
            back={currentStep > 0 && currentStep <= STEPS.MAPPING}
            continueButtonText={getContinueText(entityType)[currentStep]}
            onBack={onBack}
            onContinue={onContinueHandler}
            disabled={isContinueDisabled}
            loading={isLoading}
            title={t(`import_${IMPORTABLE_ENTITY_KEYS[entityType]}`)}
          />
        }
        open={modalVisible}
        onCancel={currentStep <= STEPS.MAPPING ? confirmClose : onCloseHandler} // Checking if mapping passed or not
        className={styles.fullSize__modal}
        footer={null}
      >
        <ImportProgress
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          entityType={entityType}
          isStepDisabled={isStepDisabled}
        />
        <div className={styles.content__modal}>
          {STEP_CONTENTS()[currentStep]}
        </div>
      </Modal>
    </Form>
  );
};

export default ImportModal;
