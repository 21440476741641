import {
  AttributeSwatchType,
  AttributeType
} from 'generatedHooks/commerce/generated';

import { ATTRIBUTE_TYPES_WITH_CHECKBOX_SWATCH } from 'components/global/AttributeSection/AttributeModal/constants';

const BASE_SWATCH_TYPE_OPTIONS = [
  {
    name: 'attributes:swatch.image',
    dataTest: 'image-swatch-type',
    defaultName: 'Image Swatch',
    value: AttributeSwatchType.Image
  },
  {
    name: 'attributes:swatch.color',
    dataTest: 'color-swatch-type',
    defaultName: 'Color Swatch',
    value: AttributeSwatchType.Color
  },
  {
    name: 'attributes:swatch.dropdown',
    dataTest: 'dropdown-swatch-type',
    defaultName: 'Dropdown',
    value: AttributeSwatchType.Dropdown
  },
  {
    name: 'attributes:swatch.text',
    dataTest: 'text-swatch-type',
    defaultName: 'Text',
    value: AttributeSwatchType.Text
  }
];

const CHECKBOX_SWATCH_TYPE_OPTION = {
  name: 'attributes:swatch.checkbox',
  dataTest: 'checkbox-swatch-type',
  defaultName: 'Checkbox',
  value: AttributeSwatchType.Checkbox
};

const getSwatchTypeOptions = (selectedAttributeType: AttributeType) => {
  if (ATTRIBUTE_TYPES_WITH_CHECKBOX_SWATCH.includes(selectedAttributeType)) {
    return BASE_SWATCH_TYPE_OPTIONS.concat(CHECKBOX_SWATCH_TYPE_OPTION);
  }

  return BASE_SWATCH_TYPE_OPTIONS;
};

export default getSwatchTypeOptions;
