import React, { FC, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeyValuePair } from 'core/globalTypes';
import Loading from 'packages/Media/Loading';
import Overlay from 'packages/Media/UIComponents/Overlay';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';
import { IUnsplashImagesData } from 'packages/Media/StockImages/types';

import InlineText from 'components/mobile/titles/InlineText';
import { UCCheckboxSingle } from 'components/mobile/UCCheckbox';
import MobileSearch from 'components/mobile/Search';
import styles from './styles.module.less';
import UCSecondaryButton from 'components/mobile/buttons/UCSecondaryButton';

interface Props {
  images: IUnsplashImagesData[];
  loading: boolean;
  selectCategories: React.ReactNode;
  selectedImages: KeyValuePair;
  onImportImages: () => void | Promise<any>;
  getCurrentPage: () => number;
  toggleSelect: (image: IUnsplashImagesData) => void;
  onScroll: (e: Event | React.UIEvent<HTMLElement, UIEvent>) => void;
  setCategory: (arg: string | null) => void;
  setSearch: (arg: string | null) => void;
  setSelectedImages: (arg: {} | KeyValuePair) => void;
}

const StockImagesMobile: FC<Props> = ({
  images,
  loading,
  selectCategories: categorySelect,
  selectedImages,
  onImportImages,
  getCurrentPage,
  toggleSelect,
  onScroll,
  setCategory,
  setSearch
}) => {
  const { t } = useTranslation(['common']);
  const [reqLoading, setReqLoading] = useState<boolean>(false);

  const handleSearch = (value: string) => {
    setCategory(null);
    setSearch(value);
  };

  const handleAddSelections = () => {
    setReqLoading(true);

    onImportImages()?.then(() => {
      setReqLoading(false);
    });
  };

  return (
    <>
      <MobileSearch placeholder={t('common:search')} onChange={handleSearch} />
      <Row className={styles.selectSection}>
        <Col span={8}>
          <InlineText font={16} title={t('common:images')} />
        </Col>
        <Col span={16} className={styles.categoryStyles}>
          {categorySelect}
        </Col>
      </Row>
      <div className={styles.imagesGrid} onScroll={onScroll}>
        <Loading visible={loading} />
        <Row gutter={[16, 16]}>
          {(!loading || getCurrentPage() > 1) &&
            images.map((image: IUnsplashImagesData) => {
              return (
                <Col key={image.id} span={8} className={styles.imageColWrapper}>
                  <Card
                    className={styles.styledCard}
                    cover={
                      <div className={styles.coverWrapper}>
                        <div className={styles.image}>
                          <LazyImage
                            width={104}
                            height={104}
                            minWidth={104}
                            minHeight={104}
                            src={image.thumbUrl}
                            alt={image.altDescription || ''}
                          />
                        </div>
                        <Overlay
                          bottomRightStyles={styles.checkboxPosition}
                          bottomRightContent={
                            <UCCheckboxSingle
                              onChange={() => {
                                toggleSelect(image);
                              }}
                              checked={Boolean(selectedImages[image.id])}
                            />
                          }
                        />
                      </div>
                    }
                  />
                </Col>
              );
            })}
        </Row>
      </div>
      <Row className={styles.mediasAddBlock}>
        <div className={styles.mediasAddBlockBtnWrapper}>
          <Col span={9} className={styles.mediasAddCol}>
            <span>
              {Object.keys(selectedImages).length}&nbsp;
              {t('common:itemSelected')}
            </span>
          </Col>
          <Col span={2} className={styles.mediasAddColBtn}>
            <UCSecondaryButton
              disabled={!Object.keys(selectedImages).length}
              handleClick={handleAddSelections}
              loading={reqLoading}
              className={styles.addBtn}
            >
              {t('common:addSelection')}
              &nbsp;&nbsp;
              {`${Object.keys(selectedImages).length}`}
            </UCSecondaryButton>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default StockImagesMobile;
