import { FC, ReactNode, useLayoutEffect } from 'react';
import { ConfigProvider, Modal, App, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { setupIcons } from 'helpers';
import { DEFAULT_LANGUAGE } from 'core/constants/common';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { theme } from './constants';
import AppInit from 'components/shared/AppInit';

// const locales: Record<string, Locale> = { en, hy };

Modal.defaultProps = {
  ...Modal.defaultProps,
  maskClosable: false
};

interface Props {
  children?: ReactNode;
}

const AntConfigProvider: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();

  const isMobile = useReactiveVar(isMobileDevice);

  useLayoutEffect(() => {
    setupIcons();
  }, []);

  useLayoutEffect(() => {
    notification.config({
      placement: isMobile ? 'top' : 'bottomRight',
      maxCount: 5
    });
  }, [isMobile]);

  return (
    <ConfigProvider
      locale={{
        locale: i18n.language || DEFAULT_LANGUAGE
      }}
      theme={theme}
    >
      <App>
        <AppInit />
        {children}
      </App>
    </ConfigProvider>
  );
};

export default AntConfigProvider;
