import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.less';
import { Button, Typography } from 'components/basic';
import UcraftLogo from 'components/shared/SVG/UcraftLogo';

const { UcHeading, UcBodyText } = Typography;

const NotFound = () => {
  const { t } = useTranslation('notFound');
  const navigate = useNavigate();
  const { state } = useLocation();

  const isProjectRoute = Boolean(state?.projectName);

  const navigateHandler = () => {
    if (isProjectRoute) {
      navigate(`${state?.projectName}`, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo_wrapper}>
        <UcraftLogo />
      </div>
      <div className={styles.content}>
        <div className={styles.text_info}>
          <UcHeading className={styles.title} level={2}>
            {t('title', '404 Not Found')}
          </UcHeading>
          <UcBodyText size="small" type="secondary">
            {t('subtitle', "This page doesn't exist")}
          </UcBodyText>
        </div>
        <div className={styles.actions}>
          <Button type="primary" onClick={navigateHandler}>
            {t(isProjectRoute ? 'goToDashboard' : 'goToMyProjects')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
