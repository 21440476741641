import { Project } from 'generatedHooks/accounts/generated';
import { TFunction } from 'react-i18next';

export const renderProjectStatus = (project: Project, t: TFunction) => {
  if (project.isExpired) {
    return t('projects:expired', 'Expired');
  }

  if (project.isOffline) {
    return t('projects:offline', 'Offline');
  }

  return t('projects:active', 'Active');
};
