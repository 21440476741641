import { HTMLAttributes } from 'react';

const VectorRight = (props: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="12"
        fill="none"
        viewBox="0 0 6 12"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M.984 1l4 5-4 5"
        />
      </svg>
    </span>
  );
};

export default VectorRight;
