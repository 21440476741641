import {
  AttributeFamily,
  ProductTypeGroup
} from 'generatedHooks/commerce/generated';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { LimitationsTypeEnums } from 'providers/PermissionAndLimitationProvider';
import { PureNullable } from 'core/globalTypes';

import { ProductType } from 'pages/products/productFamily/types';

export interface ProductTypeChooserListProps {
  dataSource: AttributeFamily[] | IDefaultProductTypes[];
  setSelectedType?: Dispatch<SetStateAction<PureNullable<string>>>;
  setSelectedFamilyId?: Dispatch<SetStateAction<string>>;
  setSelectedGroupType?: Dispatch<
    SetStateAction<PureNullable<ProductTypeGroup>>
  >;
  navigatingPage?: NavigatingPage;
  withHeading?: boolean;
  selectedType?: string;
  loading?: boolean;
  checkLimitations?: boolean;
  checkPermissions?: boolean;
  heading?: string;
  hideArrows?: boolean;
}

export interface ProductTypeChooserProps {
  familyCode: string;
  icon: ProductType;
  title: string;
  familyId: string;
  groupType: Exclude<
    ProductTypeGroup,
    ProductTypeGroup.Collection | ProductTypeGroup.Subscription
  >;
  productsCount?: Maybe<number> | undefined;
  isSelected: boolean;
  checkLimitations?: boolean;
  checkPermissions?: boolean;
  hideArrow?: boolean;
}

export interface LimitationWrapperProps {
  entityType: LimitationsTypeEnums;
  children?: ReactNode;
}

export interface IDefaultProductTypes {
  id: string;
  code: ProductType;
  name: string;
  icon: ProductType;
  groupType: ProductTypeGroup;
}

export enum NavigatingPage {
  PRODUCT = 'PRODUCT',
  FAMILY = 'FAMILY',
  EVENT = 'EVENT',
  BOOKING = 'BOOKING',
  RENTAL = 'RENTAL'
}

export enum ProductsGroupTypes {
  /** Type of physical group. */
  Physical = 'PHYSICAL',
  /** Type of digital group. */
  Digital = 'DIGITAL',
  /** Type of subscription group. */
  Subscription = 'SUBSCRIPTION',
  /** Type of appointment booking group. */
  BookingAppointment = 'BOOKING_APPOINTMENT',
  /** Type of event booking group. */
  BookingEvent = 'EVENT',
  /** Type of table booking group. */
  BookingTable = 'BOOKING_TABLE',
  /** Type of rental booking group. */
  BookingRental = 'BOOKING_RENTAL',
  /** Type of collection group. */
  Collection = 'COLLECTION'
}
