import { Skeleton } from 'antd';
import { FC } from 'react';

import styles from './styles.module.less';
import { TypeChooserSkeletonProps } from 'components/shared/TypeChooserRadioButton/types';

const TypeChooserSkeleton: FC<TypeChooserSkeletonProps> = ({ className }) => {
  return (
    <Skeleton
      paragraph={{ rows: 3, style: { width: '100%' } }}
      title={false}
      className={`${styles.skeleton} ${className || ''}`}
      active
    />
  );
};

export default TypeChooserSkeleton;
