import { Project } from 'generatedHooks/accounts/generated';
import {
  BADGE_COLOR_ACTIVE,
  BADGE_COLOR_EXPIRED,
  BADGE_COLOR_OFFLINE
} from 'core/constants/common';

const renderProjectStatusBadgeColor = (
  project: Partial<Project> | undefined | null
) => {
  if (!project) {
    return;
  }

  const { isExpired, isOffline } = project;

  if (isExpired) {
    return BADGE_COLOR_EXPIRED;
  }

  if (isOffline) {
    return BADGE_COLOR_OFFLINE;
  }

  return BADGE_COLOR_ACTIVE;
};

export default renderProjectStatusBadgeColor;
