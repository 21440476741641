import { Project } from 'generatedHooks/accounts/generated';
import React, { FC } from 'react';
import { mockImageSrc } from 'core/mocks/projects';
import { Badge } from 'antd';
import { capitalize } from 'lodash';
import { renderProjectStatusBadgeColor } from 'helpers';

import styles from './styles.module.less';
import { Typography } from 'components/basic';

interface IProjectProps {
  project: Project | undefined;
}

export const ProjectItem: FC<IProjectProps> = ({ project }) => {
  return (
    <div className={styles.project_container}>
      <div
        onClick={() => {
          window.open(`${window.origin}/${project?.name}`, '_top');
        }}
        style={{
          backgroundImage: `url(${project?.screenshotUrl}), url(${mockImageSrc})`,
          backgroundSize: 'contain'
        }}
        className={styles.project_image}
      />
      <div className={styles.project_info}>
        <div className={styles.project_info_name}>
          <Typography.UcBodyText ellipsis={true} size="small">
            {project?.clientName}
          </Typography.UcBodyText>
        </div>
        <div className={styles.project_info_status}>
          <Badge
            color={renderProjectStatusBadgeColor(project)}
            text={
              <span className={styles.status_text}>
                {capitalize(project?.status)}
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};
