import { useLayoutEffect } from 'react';

const useSetupVH = () => {
  useLayoutEffect(() => {
    const listener = () => {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    listener();
    addEventListener('resize', listener);

    return () => {
      removeEventListener('resize', listener);
    };
  }, []);
};

export default useSetupVH;
