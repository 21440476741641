import { Checkbox, CheckboxProps } from 'antd';

import UcCheckboxGroup from '../CheckboxGroup';
import styles from './styles.module.less';

const BaseCheckbox = ({ className = '', ...props }: CheckboxProps) => {
  return <Checkbox className={`${className} ${styles.checkbox}`} {...props} />;
};

const UcCheckbox = Object.assign(BaseCheckbox, Checkbox, {
  Group: UcCheckboxGroup
});

export default UcCheckbox;
