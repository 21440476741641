import { EntityImportMappingInput } from 'generatedHooks/commerce/generated';
import { KeyValuePair } from 'core/globalTypes';

import { ImportableFieldExtended } from '../types';

const mapImportableFields = (
  fieldsValue: KeyValuePair<string>,
  newFieldsDefaultValues: KeyValuePair<string>,
  fields: ImportableFieldExtended[]
) => {
  const mapped: EntityImportMappingInput[] = [];

  for (const { type, originKey, key, meta } of fields) {
    const sheetColumn = fieldsValue[key];

    if (sheetColumn) {
      const mappedField: EntityImportMappingInput = {
        type,
        sheetColumn,
        key: originKey
      };

      mappedField.meta = meta || {};

      if (key in newFieldsDefaultValues) {
        mappedField.meta.defaultValue = newFieldsDefaultValues[key];
      }

      mapped.push(mappedField);
    }
  }

  return mapped;
};

export default mapImportableFields;
