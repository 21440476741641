import { FC } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { icons } from './utils';
import styles from './styles.module.less';
import { ImgIconProps } from './types';

const ImgIcon: FC<ImgIconProps> = ({
  icon,
  size = 20,
  className = '',
  onClick,
  paddingType = 'default',
  backgroundOnHover = icon === 'ellipsis'
}) => {
  return (
    <span
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Icon,
        prefix: 'more'
      })}
      onClick={onClick}
      className={`${className} ${styles[paddingType]} ${
        styles.img_icon_wrapper
      } ${!backgroundOnHover ? styles.img_icon_wrapper_no_background : ''}`}
    >
      <img src={icons[icon]} alt={icon} style={{ width: size, height: size }} />
    </span>
  );
};

export default ImgIcon;
