import { ReactNode } from 'react';

export const ActionTypes = {
  SET_ELEMENT: 'SET_ELEMENT'
};

interface SetElementAction {
  type: typeof ActionTypes.SET_ELEMENT;
  element: ReactNode;
}

export type Action = SetElementAction;
