import { Tooltip, TooltipProps } from 'antd';
import { FC } from 'react';

type Props = TooltipProps & {};

const BaseTooltip: FC<Props> = props => {
  return <Tooltip {...props} />;
};

const UcTooltip = Object.assign(BaseTooltip, Tooltip);

export default UcTooltip;
