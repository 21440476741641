import { Helmet } from 'react-helmet';
import { FC } from 'react';
import { useProjectContext } from 'providers/ProjectProvider';

interface ISeoHelmetProps {
  title: string;
  description?: string;
  name?: string;
  type?: string;
  excludeProjectName?: boolean;
}

const SeoHelmet: FC<ISeoHelmetProps> = ({
  title,
  description,
  name,
  type,
  excludeProjectName
}) => {
  const { clientName } = useProjectContext();

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>
        {excludeProjectName
          ? `${title} - Ucraft`
          : `${title} - ${clientName} - Ucraft`}
      </title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SeoHelmet;
