import { useEffect } from 'react';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import {
  RUDDERSTUCK_ANONYMOUS_ID,
  RUDDERSTUCK_SESSION_ID
} from 'core/constants/common';

const writeKey = import.meta.env.VITE_APP_ANALYTICS_WRITE_KEY || '';
const analyticsUrl = import.meta.env.VITE_APP_ANALYTICS_URL || '';

const useInitRudderstackAnalytics = () => {
  useEffect(() => {
    if (writeKey && analyticsUrl) {
      const analytics = new RudderAnalytics();
      analytics.load(writeKey, analyticsUrl);
      window.rudderanalytics = analytics;
      analytics.ready(() => {
        const anonymousId = analytics?.getAnonymousId() || '';
        const sessionId = analytics.getSessionId() || '';

        localStorage.setItem(RUDDERSTUCK_ANONYMOUS_ID, anonymousId);
        localStorage.setItem(RUDDERSTUCK_SESSION_ID, String(sessionId));
      });
    }
  }, []);
};

export default useInitRudderstackAnalytics;
