import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { NavBar, ImgIcon } from 'components/mobile';
import Avatar from 'components/mobile/Header/Avatar';
import styles from './styles.module.less';

interface Props {
  children: ReactNode;
  avatarSrc: string;
  firstNameAndLastName: string;
}

const Mobile: FC<Props> = ({ avatarSrc, firstNameAndLastName, children }) => {
  const { t } = useTranslation('projects');

  const newProjectHandler = () => {
    window.location.href = `${
      import.meta.env.VITE_APP_SSO_URL
    }/callback?target=onboarding-start`;
  };

  return (
    <div className={styles.mobile_project_wrapper}>
      <NavBar
        leftValue={
          <Avatar avatar={avatarSrc} userName={firstNameAndLastName} />
        }
        rightValue={<ImgIcon icon="plusIcon" onClick={newProjectHandler} />}
        title={t('myProject', 'My Projects')}
      />
      {children}
    </div>
  );
};

export default Mobile;
