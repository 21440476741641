import { FC } from 'react';
import { Typography } from 'antd';

import styles from './style.module.less';
import { TitleProps } from './types';

const { Text } = Typography;

const Title: FC<TitleProps> = ({ text }) => {
  return <Text className={styles.title}>{text}</Text>;
};

export default Title;
