import React, { FC } from 'react';
import { Radio, RadioProps } from 'antd';

import UcRadioGroup from './RadioGroups';

interface BaseRadioProps extends RadioProps {}

const BaseRadio: FC<BaseRadioProps> = props => {
  return <Radio {...props} />;
};

const UcRadio = Object.assign(Radio, BaseRadio, {
  group: UcRadioGroup
});

export default UcRadio;
