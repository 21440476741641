import { FC } from 'react';

import { Typography } from 'components/basic';
import { ActionProps } from './types';

const { UcBodyText } = Typography;

const Action: FC<ActionProps> = ({ text, onClick }) => {
  return (
    <UcBodyText size="small" onClick={onClick}>
      {text}
    </UcBodyText>
  );
};

export default Action;
