import { Project } from 'generatedHooks/accounts/generated';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { NiladicVoid } from 'core/globalTypes';

export interface SiderProps {
  project: Project;
}

export interface SiderMenuProps {
  projectName: string;
  collapsed?: boolean;
  onClosePopup?: NiladicVoid;
}

export interface ProjectsListDrawerProps {
  currentProjectId: string | undefined;
  fullHeight?: boolean;
  visible?: boolean;
}

export interface ManageWebsiteProps {
  projectId: string | number;
  websiteUrl: string;
  collapsed: boolean;
}

export interface ProjectItemProps {
  project: Project | undefined;
}

export enum SiderMenuItemType {
  SingleItem = 'SINGLE_ITEM',
  Submenu = 'SUBMENU'
}

export interface CreateMenuItemsArgs {
  t: TFunction;
  Can: ReturnType<typeof useCanFunction>;
  navigate: NavigateFunction;
  onClick: (url: string, type: SiderMenuItemType) => void;
  label: (title: string) => ReactNode;
  isMobile: boolean;
}
