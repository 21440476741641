import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Undefinable } from 'core/globalTypes';

const useTimer = (startPoint = 60, endPoint = 0, intervalTime = 1000) => {
  const intervalRef =
    useRef<Undefinable<number | ReturnType<typeof setInterval> | string>>();

  const [timeout, setTimeout] = useState(startPoint);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setTimeout(startPoint);
  }, [startPoint]);

  const timer = () => {
    setTimeout(prev => prev - 1);
  };

  useLayoutEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
      setIsPlaying(false);
      setTimeout(startPoint);
    };
  }, []);

  useLayoutEffect(() => {
    if (timeout === endPoint) {
      clearInterval(intervalRef.current);
      setIsPlaying(false);
      setTimeout(startPoint);
    }
  }, [timeout, endPoint, startPoint]);

  const startTimer = useCallback(() => {
    setIsPlaying(true);
    intervalRef.current = setInterval(() => {
      timer();
    }, intervalTime);
  }, [intervalRef.current, intervalTime]);

  const clearThisInterval = () => {
    clearInterval(intervalRef.current);
  };

  return { startTimer, clearInterval: clearThisInterval, timeout, isPlaying };
};

export default useTimer;
