import { FC, useState, useMemo } from 'react';
import { Card, Col, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  IImportReportData,
  ImportableEntity
} from 'components/global/ImportModal/types';
import styles from './styles.module.less';
import ImportReportModal from '../ImportReportModal';

const { Text, Link } = Typography;

interface Props {
  importReport: IImportReportData | undefined | null;
  entityType: ImportableEntity;
}

const ImportReport: FC<Props> = ({ importReport, entityType }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation('importModal');

  const total = useMemo(() => {
    if (importReport) {
      return (
        importReport.inserted + importReport.updated + importReport.skipped
      );
    }
  }, [importReport]);

  return (
    <>
      <Card className={styles.cardWrapper}>
        <Row justify="space-between">
          <Col>
            <Text className={styles.cardContentText}>{t('updatedItems')}</Text>
          </Col>
          <Col>{importReport?.updated}</Col>
        </Row>
        <Row justify="space-between" className={styles.newItems}>
          <Col>
            <Text className={styles.cardContentText}>{t('newItems')}</Text>
          </Col>
          <Col>{importReport?.inserted}</Col>
        </Row>
        <Row
          justify="space-between"
          onClick={() => setModalVisible(true)}
          className={styles.skippedItems}
        >
          <Col>
            <Text className={styles.skippedText}>{t('skippedItems')}</Text>
          </Col>
          <Col>
            <Link>{importReport?.skipped}</Link>
          </Col>
        </Row>
        <Divider className={styles.divider} />
        <Row justify="space-between" className={styles.totalText}>
          <Col>{t('total')}</Col>
          <Col>{total}</Col>
        </Row>
      </Card>
      <ImportReportModal
        modalVisible={modalVisible}
        entityType={entityType}
        importReport={importReport}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default ImportReport;
