import { Row, Col, Skeleton } from 'antd';
import { useScreenBreakpoints } from 'hooks';

import { ShowElement } from 'components/shared';
import styles from 'components/shared/Skeleton/styles.module.less';
import { PageProduct } from 'components/shared/Skeleton/constants';

const ProductSkeleton = ({ type = PageProduct }) => {
  const screens = useScreenBreakpoints();

  return (
    <Row
      className={styles.product_skeleton_container}
      data-testid="page-skeleton"
    >
      <ShowElement isShow={Boolean(screens.lg && type === PageProduct)}>
        <Col style={{ paddingLeft: 24, paddingRight: 12 }} span={5}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Col>
      </ShowElement>
      <Col
        flex={1}
        style={{ height: '100%', paddingLeft: 12, paddingRight: 12 }}
      >
        <Row gutter={24}>
          <Col flex={1}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Col>
          <ShowElement isShow={Boolean(screens.lg)}>
            <Col span={8}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          </ShowElement>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductSkeleton;
