import { FC, useMemo } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { detectOS } from 'helpers';

import { KeyboardShortcutsGuideProps } from 'components/shared/KeyboardShortcutsGuide/types';
import { Divider, Modal, Typography } from 'components/basic';
import { ShortcutsList } from './components';
import {
  comboShortcutList,
  navigationShortcuts
} from 'components/shared/KeyboardShortcutsGuide/listSource';
import styles from './styles.module.less';

const { UcHeading } = Typography;

const KeyboardShortcutsGuide: FC<KeyboardShortcutsGuideProps> = ({
  visible,
  closeModal
}) => {
  const { t } = useTranslation('keyboardShortcuts');

  const os = useMemo(() => {
    return detectOS();
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={closeModal}
      footer={null}
      title={
        <UcHeading level={4} defaultMargin={false}>
          {t('dashboard_keyboard_shortcuts')}
        </UcHeading>
      }
      width={960}
      centered
    >
      <Row wrap={false}>
        <Col flex={'1 1 50%'}>
          <ShortcutsList dataSource={navigationShortcuts} />
        </Col>
        <Col className={styles.divider_column}>
          <Divider type="vertical" className={styles.divider} />
        </Col>
        <Col flex={'1 1 50%'}>
          <ShortcutsList dataSource={comboShortcutList(os)} />
        </Col>
      </Row>
    </Modal>
  );
};

export default KeyboardShortcutsGuide;
