import { ScriptPosition } from 'generatedHooks/builder/generated';

export const FIELDS_LABELS_TRANSLATIONS: Record<
  ScriptPosition,
  Record<'key', ScriptPosition> & Record<'defaultValue', string>
> = {
  [ScriptPosition.TopHead]: {
    key: ScriptPosition.TopHead,
    defaultValue: 'After <head> tag'
  },
  [ScriptPosition.BottomHead]: {
    key: ScriptPosition.BottomHead,
    defaultValue: 'Before </head> tag'
  },
  [ScriptPosition.TopBody]: {
    key: ScriptPosition.TopBody,
    defaultValue: 'After <body> tag'
  },
  [ScriptPosition.BottomBody]: {
    key: ScriptPosition.BottomBody,
    defaultValue: 'Before </body> tag'
  }
};
