import React, { FC } from 'react';

import styles from './styles.module.less';

interface Props {
  title: string;
  font?: number;
  bold?: boolean;
  color?: string;
  fontWidth?: number;
}

const InlineText: FC<Props> = ({
  title,
  font = 14,
  bold = false,
  color = '#262626',
  fontWidth = 500
}) => {
  return (
    <span
      className={`${styles[`text-${font}`]} ${bold ? styles.bold : ''}`}
      style={{ color: color, fontWeight: fontWidth }}
    >
      {title}
    </span>
  );
};

export default InlineText;
