import { FC } from 'react';
import { Typography } from 'antd';

import styles from './styles.module.less';
import { BodyTextTypes, BodyTextProps, Cursor } from './types';
import { FontWightTypes } from 'components/basic/Typography/types.d';
import { customGenericClassNames } from '../helpers';

const { Text } = Typography;

const BodyText: FC<BodyTextProps> = ({
  text = '',
  className = '',
  display = '',
  size = BodyTextTypes.extraSmall,
  ellipsis = false,
  fontWeight = FontWightTypes.regular,
  children,
  type,
  cursor = Cursor.Inherit,
  onClick,
  ...restProps
}) => {
  return (
    <Text
      title={text}
      className={`${className} ${customGenericClassNames(type, ellipsis)} ${
        styles.bodyTextWrapper
      } ${styles[size]} ${styles[fontWeight]} ${styles[display]} ${
        styles[cursor]
      } ${onClick ? styles.pointer : ''}`}
      onClick={onClick}
      {...restProps}
    >
      {text || children}
    </Text>
  );
};

export default BodyText;
