import { coreConfigs } from 'apollo/cache';
import dayjs from 'dayjs';
import i18n from 'i18next';
// import 'dayjs/min/locales';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  DATE_FORMAT,
  FULL_DATE_FORMAT,
  FULL_DATE_FORMAT_WITHOUT_SECONDS
} from 'core/constants/date-constants';

import {
  DateFormats,
  TimeFormats
} from 'pages/settings/pages/ProjectSetup/components/Formats/types';

dayjs.extend(customParseFormat);

export interface IDateTimeConverterOptions {
  dateOnly?: boolean;
  timeOnly?: boolean;
  convertByTimezone?: boolean;
  customFormat?: string | null;
}

const defaultOptions = {
  dateOnly: false,
  timeOnly: false,
  convertByTimezone: true
};

const isValidInitialDateFormat = (date: string) => {
  return (
    dayjs(date, DATE_FORMAT, true).isValid() ||
    dayjs(date, FULL_DATE_FORMAT, true).isValid() ||
    dayjs(date, FULL_DATE_FORMAT_WITHOUT_SECONDS, true).isValid()
  );
};

/**
 * `dateTimeConverter` is created to use for InMemoryCache configs.
 * It will detect the date format configured by the user and will convert the incoming date to required format.
 * @param date - The incoming date
 * @param options - Optional object for options.
 * @returns Converted date string
 */

const dateTimeConverter = (
  date: string,
  {
    dateOnly = defaultOptions.dateOnly,
    convertByTimezone = defaultOptions.convertByTimezone,
    timeOnly = defaultOptions.timeOnly,
    customFormat
  }: IDateTimeConverterOptions = defaultOptions
) => {
  if (!date) {
    return '-';
  }

  if (!isValidInitialDateFormat(date)) {
    return date;
  }

  const keepLocalTime = !convertByTimezone;

  const { date_format, time_format, timezone, locale } = coreConfigs();

  if (customFormat) {
    const utc = dayjs.utc(date);

    return convertByTimezone
      ? utc.tz(timezone).format(customFormat)
      : utc.format(customFormat);
  }

  let hFormat = '';

  if (time_format === TimeFormats.format1) {
    hFormat = 'HH:mm';
  } else if (time_format === TimeFormats.format2) {
    hFormat = 'hh:mm a';
  } else {
    throw new Error('Incorrect hour format');
  }

  const today = dayjs().tz(timezone, keepLocalTime).format(DateFormats.format2);

  const tomorrow = dayjs()
    .tz(timezone, keepLocalTime)
    .add(1, 'days')
    .format(DateFormats.format2);

  const yesterday = dayjs()
    .tz(timezone, keepLocalTime)
    .add(-1, 'days')
    .format(DateFormats.format2);

  const formattedDate = dayjs
    .utc(date)
    .tz(timezone, keepLocalTime)
    .format(DateFormats.format2);

  const formattedTime = dayjs
    .utc(date)
    .tz(timezone, keepLocalTime)
    .format(hFormat);

  if (!timeOnly) {
    if (today === formattedDate) {
      return `${i18n.t('common:today', 'Today')}${
        dateOnly ? '' : ', ' + formattedTime
      }`;
    }

    if (yesterday === formattedDate) {
      return `${i18n.t('common:yesterday', 'Yesterday')}${
        dateOnly ? '' : ', ' + formattedTime
      }`;
    }

    if (tomorrow === formattedDate) {
      return `${i18n.t('common:tomorrow', 'Tomorrow')}${
        dateOnly ? '' : ', ' + formattedTime
      }`;
    }
  }

  dayjs.locale(locale);

  let format = `${date_format}, ${hFormat}`;

  if (dateOnly) {
    format = date_format;
  } else if (timeOnly) {
    format = hFormat;
  }

  return dayjs.utc(date).tz(timezone, keepLocalTime).format(format);
};

export default dateTimeConverter;
