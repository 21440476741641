import { FC, isValidElement } from 'react';

import styles from './styles.module.less';
import { Typography } from 'components/basic';
import { ShowElement } from 'components/shared';
import { FilterItemProps } from './types';

const { UcBodyText } = Typography;

const FilterItem: FC<FilterItemProps> = ({
  title,
  children,
  showTopBorder = true
}) => {
  const titleContent = isValidElement(title) ? (
    title
  ) : (
    <UcBodyText size="small" fontWeight="medium">
      {title}
    </UcBodyText>
  );

  return (
    <div
      className={`${styles.filter_item} ${
        showTopBorder ? styles.show_top_border : ''
      }`}
    >
      <ShowElement isShow={Boolean(title)}>{titleContent}</ShowElement>
      <div className={styles.children_container}>{children}</div>
    </div>
  );
};

export default FilterItem;
