import { makeVar, useReactiveVar } from '@apollo/client';
import axios from 'axios';
import { FC, PropsWithChildren, useEffect } from 'react';

const { VITE_APP_STATIC_URL } = import.meta.env;

export type ICurrency = {
  code: string;
  emoji: string;
  is_crypto: boolean;
  iso_code: string;
  name: string;
  precision: number;
  symbol: string;
  symbol_native: string;
};

export const currenciesVar = makeVar<ICurrency[]>([]);

const CurrenciseProvider: FC<PropsWithChildren> = ({ children }) => {
  const currencies = useReactiveVar(currenciesVar);

  useEffect(() => {
    if (!currencies.length) {
      try {
        axios
          .get(`${VITE_APP_STATIC_URL}/currencies.json`)
          .then(response => currenciesVar(response.data));
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  return <>{children}</>;
};

export default CurrenciseProvider;
