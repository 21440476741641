import usePermissionAndLimitationContext from './usePermissionAndLimitationContext';
import LimitationsTypeEnums from '../core/globalTypes';
import { LimitationsService } from '../services';
import { initEntitiesStateForLimitations } from '../context/actions';

const useQuicklyCheckPermissionAndLimitation = () => {
  const {
    state: {
      project,
      environment,
      permissions: { availablePermissions },
      limitations: { entities }
    },
    dispatch
  } = usePermissionAndLimitationContext();

  const fetchLimitations = async (entities: LimitationsTypeEnums[]) => {
    try {
      if (project.name) {
        const resEntitiesLimitation = await LimitationsService.getLimitation(
          environment.COMMERCE_URL,
          environment.REMOTE_HOST,
          project.name,
          entities
        );

        dispatch(initEntitiesStateForLimitations(resEntitiesLimitation));
      }
    } catch (err: any) {
      throw Error(err.message);
    }
  };

  const checkPermission = (I: string) => !I || availablePermissions.includes(I);

  const checkLimitation = ({
    disabledAt,
    entity
  }: {
    entity?: LimitationsTypeEnums | LimitationsTypeEnums[];
    disabledAt?: boolean;
  }) => {
    let disabled = false;

    if (!entity) {
      disabled = Boolean(disabledAt);
    } else if (entity && Object.values(entities).length) {
      if (Array.isArray(entity)) {
        disabled = entity.some(e => entities[e]?.limitReached);
      } else {
        disabled = entities[entity]?.limitReached;
      }
    }

    return disabled;
  };

  return { checkPermission, checkLimitation, fetchLimitations };
};

export default useQuicklyCheckPermissionAndLimitation;
