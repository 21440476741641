export const commonTranslations = {
  save: 'Save',
  sortBy: 'Sort by',
  next: 'Next',
  type: 'Type',
  checkbox: 'Checkbox',
  text: 'Text',
  select: 'Select',
  multiselect: 'Multi-select',
  image: 'Image',
  gallery: 'Gallery',
  richText: 'Rich Text',
  toggle: 'Toggle',
  dateAndTime: 'Date & Time',
  date: 'Date',
  file: 'File',
  all: 'All',
  noActive: 'Inactive',
  active: 'Active',
  filters: 'Filters',
  status: 'Status',
  price: 'Price',
  addon: 'AddOn'
};

export const productsTranslations = {
  primary: 'No Products Available Yet',
  secondary: 'Click the New Product button to add products to your dashboard.',
  chooseProductFamilyType: 'Choose Product Type',
  nameRequired: 'Name is required',
  sku: 'SKU (Stock Keeping Unit)',
  skuRequired: 'SKU is required',
  descriptionRequired: 'Description is required',
  descriptionPlaceholder: 'Describe your product here',
  localeError: 'Fill in all the required fields before switching the language',
  multipleOptions: 'This product has variants, like size or color',
  weightRequired: 'Weight is required',
  crossSelling: 'Cross-selling Products',
  crossSellingPlaceholder: 'Cross-selling Products',
  upSelling: 'Upselling Products',
  upSellingPlaceholder: 'Upselling Products',
  linkBackText: 'Show selected products as related',
  addOrDisableError: 'Add variants or disable the options',
  urlRequired: 'URL is required',
  productAlias: 'Product alias'
};

export const categoriesTranslations = {
  noData: 'No Categories Available Yet',
  noDataSecondary:
    'Click the New Category button to add categories to your dashboard',
  categoryPreview: 'View',
  nameRequired: 'Name is required',
  descriptionPlaceholder: 'Describe your category here',
  categoryImageDesc:
    'This image will be displayed on the category pages. Upload images up to 300KB for better performance and storage efficiency.',
  categoryIconDesc:
    'This image will be displayed in the category listing panels. Upload images up to 300KB for better performance and storage efficiency.',
  categoryUrlPlaceholder: 'Category alias',
  urlRequired: 'Category URL is required'
};

export const attributesTranslations = {
  attributeType: 'Type',
  attributeName: 'Name',
  isRequired: 'Required',
  isUnique: 'Unique',
  valuePerLocale: 'Translatable',
  valuePerChannel: 'Price for Channels',
  isComparable: 'Comparable Attribute',
  isConfigurable: 'Configurable Attribute',
  isVisibleOnFront: 'Visible on Website',
  empty: {
    primary: 'There are no any Attributes',
    secondary: 'You can create Attribute by clicking "Add Attribute" button',
    title: 'No Option Available Yet',
    text: 'The <1>{{type}}</1> attribute type has no options.'
  },
  inputValidation: 'Field Validation',
  attributeOptions: 'Attribute Options'
};

export const productFamilyTranslations = {
  details: 'Details',
  productFamilyDefault: 'Default Attributes',
  noAttributesSelected: 'No Groups Available Yet',
  createAttributeGroup: 'New Attribute Group'
};

export const customersTranslations = {
  empty: {
    primary: 'No Customers Available Yet',
    secondary:
      'Click the New Customer button to add customers to your dashboard',
    customerPreview: {
      primary: 'No Orders Available Yet'
    }
  },
  customerGroup: 'Customer Group',
  customerGroupPlaceholder: 'Select a customer group',
  customerGroupRequired: 'Select a customer group',
  editModalTitle: 'Edit Customer',
  email: 'Email',
  emailPlaceholder: 'Email',
  emailRequired: 'Email is required',
  firstName: 'First Name',
  firstNameRequired: 'First name is required',
  lastName: 'Last Name',
  lastNamePlaceholder: 'Last Name',
  lastNameRequired: 'Last name is required',
  joined: 'Joined',
  orderCount: 'Number of Orders',
  groups: {
    nameRequired: 'Name is required',
    codeName: 'Code Name',
    codeNamePlaceholder: 'Code Name',
    codeNameRequired: 'Code name is required'
  }
};

export const notesTranslations = {
  empty: 'No Notes Yet',
  newNote: 'New Note',
  notesInfo: 'Only you and staff members can see notes.'
};
