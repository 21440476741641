import {
  Button,
  Dashboard,
  Product,
  DiscountForm,
  Input,
  Table,
  Tag,
  PageContainer,
  Grid,
  List,
  Timeline
} from './components';

const Skeleton = () => null;

export default Object.assign(Skeleton, {
  Table,
  Grid,
  Tag,
  Button,
  Product,
  Input,
  PageContainer,
  Dashboard,
  DiscountForm,
  List,
  Timeline
});
