import React, { FC } from 'react';
import { Typography } from 'antd';

import styles from './styles.module.less';

const { Title } = Typography;

interface Props {
  title: string;
  font?: number;
  addClass?: string;
  fontWidth?: number;
}

const MainTitle: FC<Props> = ({
  title,
  font = 40,
  addClass = '',
  fontWidth = 600
}) => {
  const mainStyles = styles[`title-${font}`];

  return (
    <Title
      className={`${mainStyles} ${addClass}`}
      style={{ fontWeight: fontWidth }}
    >
      {title}
    </Title>
  );
};

export default MainTitle;
