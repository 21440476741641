import React, {
  useReducer,
  createContext,
  useCallback,
  useContext
} from 'react';

import reducer from './reducer';
import initialState from './initialState';
import { Action, ActionTypes } from './types';

type Props = {
  children: React.ReactNode;
};

type State = {
  element: React.ReactNode;
};

type Dispatch = (action: Action) => void;

const SettingsStateContext = createContext<State | undefined>(undefined);
const SettingsDispatchContext = createContext<Dispatch | undefined>(undefined);

function SettingsPageProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
}

function useSettingsStateContext() {
  const context = useContext(SettingsStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useSettingsStateContext must be used within a SettingsPageProvider'
    );
  }

  return context;
}

function useSettingsDispatchContext() {
  const dispatch = useContext(SettingsDispatchContext);

  const setSettingsPageContent = useCallback(
    (element: React.ReactNode) => {
      if (dispatch) {
        dispatch({ type: ActionTypes.SET_ELEMENT, element });
      }
    },
    [dispatch]
  );

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useSettingsDispatchContext must be used within a SettingsPageProvider'
    );
  }

  return {
    setSettingsPageContent
  };
}

export default SettingsPageProvider;
export { useSettingsDispatchContext, useSettingsStateContext };
