import { KeyValuePair } from 'core/globalTypes';

export const filterSelectedTags = (
  data: Array<KeyValuePair>,
  values: Array<string | number> = [],
  key: string
) => {
  return data.filter(item => {
    return values.includes(item[key]);
  });
};
