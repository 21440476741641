import { Maybe, PureNullable } from 'core/globalTypes';
import {
  Order,
  OrderItem,
  Product,
  ShipmentAddressInput
} from 'generatedHooks/commerce/generated';
import { ReactNode } from 'react';

type OrderActionButtonType = 'primary' | 'default';

export interface Props {
  order?: Order;
  loading: boolean;
  isPhysicalOrder: boolean;
}

export enum DownloadableLinkType {
  URL = 'url',
  FILE = 'file'
}

export enum OrderActions {
  FULFILL = 'FULFILL',
  MAKE_PAID = 'MAKE_PAID',
  CANCEL = 'CANCEL',
  RETURN = 'RETURN',
  DISPATCH = 'DISPATCH',
  PRINT = 'PRINT',
  REFUND = 'REFUND',
  MAKE_UNPAID = 'MAKE_UNPAID'
}

export enum OrderActionsDataTests {
  FULFILL = 'fulfill',
  MAKE_PAID = 'make-paid',
  CANCEL = 'cancel',
  RETURN = 'return',
  DISPATCH = 'dispatch',
  PRINT = 'print',
  REFUND = 'refund',
  MAKE_UNPAID = 'make-unpaid'
}

export enum OrderCalculateAction {
  FULFILL = 'FULFILL',
  REFUND = 'REFUND',
  RETURN = 'RETURN'
}

export enum OrderChildActions {
  ManualFulfill = 'MANUAL_FULFILL',
  CarrierFulfill = 'CARRIER_FULFILL'
}

export enum ActionsPositions {
  Bottom = 'bottom',
  ActionsMenu = 'actions-menu'
}

export interface IOrderAction {
  key: OrderActions;
  type: OrderActionButtonType;
  icon?: ReactNode;
  childActions?: IOrderChildAction[];
  position?: ActionsPositions;
  dataTest?: OrderActionsDataTests;
}

export interface IOrderChildAction {
  key: OrderChildActions;
}

export type OrderItemWithChildAttributeOptions = OrderItem & {
  childAttributeOptions?: Product['attributeOptions'];
};

export type ConfirmationFormData = {
  email: Maybe<string>;
  phone: Maybe<string>;
};

export enum OrderListLocation {
  FulfillModal = 'FulfillModal',
  RefundModal = 'RefundModal',
  ShipmentModal = 'ShipmentModal',
  ReturnModal = 'ReturnModal'
}

export enum ShipmentAddressTypeEnum {
  Recipient = 'Recipient',
  Shipper = 'Shipper'
}

export type ShipmentAddressType = {
  data: PureNullable<ShipmentAddressInput>;
  type: PureNullable<ShipmentAddressTypeEnum>;
};
