import { KeyValuePair, StateDispatcher } from 'core/globalTypes';
import { useUpdateCoreConfigsMutation } from 'generatedHooks/commerce/generated';

export enum UnitSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial'
}

export enum DateFormats {
  format1 = 'DD/MM/YYYY',
  format2 = 'MM/DD/YYYY',
  format3 = 'DD.MM.YYYY',
  format4 = 'MM.DD.YYYY '
}

export enum TimeFormats {
  format1 = '24H',
  format2 = '12H'
}

export interface FormatsProps {
  updateFormats: ReturnType<typeof useUpdateCoreConfigsMutation>[0];
  formatsLoading: boolean;
  updateIsFieldDisabled: (value: string) => void;
  isFieldDisabled: KeyValuePair<boolean>;
  setShouldShowConfirm: StateDispatcher<boolean>;
}

export type InitialState = {
  unitSystem?: UnitSystem;
  weightValue?: string;
  weightUnit?: string;
  dimensionUnit?: string;
};
