import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Access } from './components';
import { LimitedAccessAlertProps } from './types';
import { UpgradePackage } from '../UpgradePackage';

const { Title } = Typography;

export const LimitedAccessAlert: FC<LimitedAccessAlertProps> = ({
  onChangeNote,
  loadingSentNote,
  isExistNoteForThisPermission,
  requestForPermissionHandler,
  redirectHandler,
  isView,
  checkCanUpdatePlan
}) => {
  const { t } = useTranslation('permissions-and-limitations');

  const UpgradeOrAccess = checkCanUpdatePlan ? (
    <UpgradePackage
      title={t(
        'upgradePackageTitle',
        'To enable the following functionality you need to upgrade your package.'
      )}
      upgradeHandler={redirectHandler}
    />
  ) : (
    <Access
      onChangeNote={onChangeNote}
      loadingSentNote={loadingSentNote}
      requestForPermissionHandler={requestForPermissionHandler}
      isExistNoteForThisPermission={isExistNoteForThisPermission}
      pageViewOrAction={isView}
    />
  );

  return (
    <div
      style={{
        padding: '16px',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box'
      }}
    >
      <Title level={4}>No Access</Title>
      {UpgradeOrAccess}
    </div>
  );
};
