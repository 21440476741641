export interface unsplashImages_unsplashImages_data {
  __typename: 'UnsplashPhoto';
  id: string;
  regularUrl: any | null;
  thumbUrl: any | null;
  twitterUsername: string | null;
  profileImageSmall: string | null;
  userProfileLink: any | null;
  altDescription: string | null;
  width: number | null;
  height: number | null;
  username: string | null;
}

export interface unsplashImages_unsplashImages_paginatorInfo {
  __typename: 'PaginatorInfo';
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
}

export interface unsplashImages_unsplashImages {
  __typename: 'UnsplashPhotoPaginator';
  data: unsplashImages_unsplashImages_data[];
  paginatorInfo: unsplashImages_unsplashImages_paginatorInfo;
}

export interface unsplashImages {
  unsplashImages: unsplashImages_unsplashImages | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

export enum MediaType {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  VECTOR = 'VECTOR'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum MediaWithFoldersOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  SIZE = 'SIZE'
}

export interface MediaWithFoldersOrderByOrderByClause {
  field: MediaWithFoldersOrderByColumn;
  order: SortOrder;
}

// ====================================================
// GraphQL query operation: mediaWithFolders
// ====================================================

export interface mediaWithFolders_mediaWithFolders_data_Image_tags {
  __typename: 'Tag';
  id: string;
  name: string;
}

export interface mediaWithFolders_mediaWithFolders_data_Image {
  __typename: 'Image';
  id: string;
  name: string;
  type: MediaType;
  size: number;
  description: string | null;
  thumbnail: string | null;
  createdAt: any;
  filename: string;
  extension: string | null;
  tags: mediaWithFolders_mediaWithFolders_data_Image_tags[];
  width: number | null;
  height: number | null;
  title: string | null;
}

export interface mediaWithFolders_mediaWithFolders_data_Video_tags {
  __typename: 'Tag';
  id: string;
  name: string;
}

export interface mediaWithFolders_mediaWithFolders_data_Video {
  __typename: 'Video';
  id: string;
  name: string;
  type: MediaType;
  size: number;
  description: string | null;
  thumbnail: string | null;
  createdAt: any;
  filename: string;
  extension: string | null;
  tags: mediaWithFolders_mediaWithFolders_data_Video_tags[];
  title: string | null;
  duration: number;
}

export interface mediaWithFolders_mediaWithFolders_data_Audio_tags {
  __typename: 'Tag';
  id: string;
  name: string;
}

export interface mediaWithFolders_mediaWithFolders_data_Audio {
  __typename: 'Audio';
  id: string;
  name: string;
  type: MediaType;
  size: number;
  description: string | null;
  thumbnail: string | null;
  createdAt: any;
  filename: string;
  extension: string | null;
  tags: mediaWithFolders_mediaWithFolders_data_Audio_tags[];
  title: string | null;
  duration: number;
}

export interface mediaWithFolders_mediaWithFolders_data_Document_tags {
  __typename: 'Tag';
  id: string;
  name: string;
}

export interface mediaWithFolders_mediaWithFolders_data_Document {
  __typename: 'Document';
  id: string;
  name: string;
  type: MediaType;
  size: number;
  description: string | null;
  thumbnail: string | null;
  createdAt: any;
  filename: string;
  extension: string | null;
  tags: mediaWithFolders_mediaWithFolders_data_Document_tags[];
  title: string | null;
}

export interface mediaWithFolders_mediaWithFolders_data_Folder_tags {
  __typename: 'Tag';
  id: string;
  name: string;
}

export interface mediaWithFolders_mediaWithFolders_data_Folder_children {
  __typename: 'Image' | 'Folder' | 'Audio' | 'Video' | 'Document' | 'Vectors';
  id: string;
}

export interface mediaWithFolders_mediaWithFolders_data_Folder {
  __typename: 'Folder';
  id: string;
  name: string;
  type: MediaType;
  size: number;
  description: string | null;
  thumbnail: string | null;
  createdAt: any;
  filename: string;
  extension: string | null;
  tags: mediaWithFolders_mediaWithFolders_data_Folder_tags[];
  children:
    | (mediaWithFolders_mediaWithFolders_data_Folder_children | null)[]
    | null;
}

export type mediaWithFolders_mediaWithFolders_data =
  | mediaWithFolders_mediaWithFolders_data_Image
  | mediaWithFolders_mediaWithFolders_data_Video
  | mediaWithFolders_mediaWithFolders_data_Audio
  | mediaWithFolders_mediaWithFolders_data_Document
  | mediaWithFolders_mediaWithFolders_data_Folder;

export interface mediaWithFolders_mediaWithFolders_paginatorInfo {
  __typename: 'PaginatorInfo';
  /**
   * Total items available in the collection.
   */
  total: number;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
}

export interface mediaWithFolders_mediaWithFolders {
  __typename: 'MediaUnionPaginator';
  /**
   * A List of MediaUnion items.
   */
  data: mediaWithFolders_mediaWithFolders_data[];
  /**
   * Pagination information about the List of items.
   */
  paginatorInfo: mediaWithFolders_mediaWithFolders_paginatorInfo;
}

export interface mediaWithFolders {
  mediaWithFolders: mediaWithFolders_mediaWithFolders | null;
}

export interface mediaWithFoldersVariables {
  type: MediaType;
  search?: string | null;
  orderBy?: MediaWithFoldersOrderByOrderByClause[] | null;
  first: number;
  page?: number | null;
  parentId?: string | null;
}
