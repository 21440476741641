import { Skeleton } from 'antd';
import { FC } from 'react';

import styles from './styles.module.less';
import { ListSkeletonProps } from './types';

const ListSkeleton: FC<ListSkeletonProps> = ({
  className = '',
  count = 3,
  loading,
  children
}) => {
  return loading ? (
    <Skeleton
      active
      className={`${styles.list_skeleton} ${className}`}
      title={false}
      paragraph={{ rows: count }}
    />
  ) : (
    <>{children}</>
  );
};

export default ListSkeleton;
