import { FC } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Empty as AntEmpty, Typography } from 'components/basic';
import { ShowElement } from 'components/shared';
import { EmptyProps } from './types';

const { UcHeading, UcBodyText } = Typography;

const Empty: FC<EmptyProps> = ({ title, description, icon, footer }) => {
  return (
    <AntEmpty
      image={icon}
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Component,
        prefix: 'empty'
      })}
      imageStyle={{
        height: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 'var(--ant-margin-md)'
      }}
      description={
        <>
          <ShowElement isShow={Boolean(title)}>
            <UcHeading level={4} fontWeight="regular">
              {title}
            </UcHeading>
          </ShowElement>

          <ShowElement isShow={Boolean(description)}>
            <UcBodyText type="secondary" size="small">
              {description}
            </UcBodyText>
          </ShowElement>
        </>
      }
    >
      {footer}
    </AntEmpty>
  );
};

export default Empty;
