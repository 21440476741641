import {
  ReactNode,
  cloneElement,
  Children,
  isValidElement,
  ReactElement
} from 'react';

export const ChildrenWithProps = (children: ReactNode, properties: {}) => {
  if (!isValidElement(children)) {
    return children;
  }

  const element = Children.only(children);

  return cloneElement(element as ReactElement, {
    ...properties
  });
};
