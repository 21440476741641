import { FC } from 'react';

import { Typography } from 'components/basic';
import styles from './styles.module.less';

interface Props {
  title: string;
  description: string;
  secondaryText?: React.ReactNode;
}

const { UcBodyText, UcHeading } = Typography;

const StepContentHeading: FC<Props> = ({
  title,
  description,
  secondaryText
}) => {
  return (
    <div className={styles.text_wrapper}>
      <div className={styles.title}>
        <UcHeading level={4} fontWeight="regular">
          {title}
        </UcHeading>
      </div>
      <div className={styles.description}>
        <UcBodyText size="base">{description}</UcBodyText>
      </div>
      {!!secondaryText && (
        <div className={styles.description}>
          <UcBodyText size="base">{secondaryText}</UcBodyText>
        </div>
      )}
    </div>
  );
};

export default StepContentHeading;
