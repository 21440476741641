/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as ArrowDown } from 'assets/icons/directionDown.svg';
import { FC, SVGProps } from 'react';

const ArrowDownIcon: FC<SVGProps<any>> = ({
  width,
  height,
  viewBox,
  fill,
  ...props
}) => <ArrowDown {...props} />;

export default ArrowDownIcon;
