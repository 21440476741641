import { Component } from 'react';
import { Result } from 'antd';
import { CHUNKS_ERROR_MESSAGE } from 'core/constants/common';
import isEqual from 'lodash/isEqual';

import { ErrorBoundaryProps, State } from './types';
import { Button } from 'components/basic';
import styles from './styles.module.less';

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  State
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  handleReload = () => {
    localStorage.setItem('hasReloaded', '');
    window.location.reload();
  };

  render() {
    const { error, hasError } = this.state;
    const hasReloaded = localStorage.getItem('hasReloaded');

    if (hasError) {
      if (
        error?.message?.includes(CHUNKS_ERROR_MESSAGE) &&
        error?.message?.includes(location.host) &&
        error?.message?.endsWith('.js') &&
        !isEqual(hasReloaded, 'reloaded')
      ) {
        localStorage.setItem('hasReloaded', 'reloaded');
        location.reload();

        return null;
      }

      return (
        <Result
          className={styles.result}
          status="warning"
          title="Oops, something went wrong"
          subTitle={
            error?.name === 'ChunkLoadError'
              ? 'This application has been updated. Please refresh your browser to see the latest content.'
              : 'An error has occurred. Please try again.'
          }
          extra={[
            <Button type="primary" key="reload" onClick={this.handleReload}>
              Reload
            </Button>
          ]}
        />
      );
    }

    return this.props.children;
  }
}
