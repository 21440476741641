import React, { useEffect } from 'react';
import { ModalProps } from 'antd/lib/modal';

import { Modal as AntdModal } from 'components/basic';

type Props = {
  children: React.ReactNode;
};

function Modal({
  children,
  visible,
  onOk,
  okButtonProps,
  ...props
}: ModalProps & Props) {
  function handleEnter(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!okButtonProps?.disabled && !okButtonProps?.loading) {
        onOk && onOk(event);
      }
    }
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('keydown', handleEnter);
    }

    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [visible, okButtonProps]);

  return (
    <AntdModal
      okButtonProps={okButtonProps}
      onOk={onOk}
      open={visible}
      {...props}
    >
      {children}
    </AntdModal>
  );
}

Modal.confirm = AntdModal.confirm;

export default Modal;
