import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { Spin } from 'antd';
import {
  Project,
  useProjectByAliasQuery
} from 'generatedHooks/accounts/generated';
import { useLazyQuery } from '@apollo/client';
import { getMediaByIdsQuery } from 'packages/Media/queries/mediaQuery';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import SystemMessage from 'helpers/utils/SystemMessage';
import { linkTypes } from 'apollo/link';
import { showJoinMessageQueryParam } from 'core/constants/common';

import { useSocket } from '../services/socketService';

export const ProjectContext = createContext<Project>({} as Project);

export const useProjectContext = () => useContext(ProjectContext);

const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const projectName = location.pathname.split('/')[1];
  const { t } = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();

  const [getMediaByIds] = useLazyQuery(getMediaByIdsQuery, {
    context: { urlType: linkTypes.builder }
  });

  const { data: project } = useProjectByAliasQuery({
    variables: {
      name: projectName
    },
    context: {
      urlType: linkTypes.accounts
    },
    async onCompleted(data) {
      const logoId = data.projectByAlias?.logo;

      if (logoId) {
        await getMediaByIds({
          variables: {
            ids: [logoId]
          }
        });
      }
    }
  });

  useSocket(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (
      params.has(showJoinMessageQueryParam) &&
      project?.projectByAlias?.clientName
    ) {
      SystemMessage.success(
        <div style={{ whiteSpace: 'pre-line' }}>
          <Trans
            shouldUnescape
            t={t}
            i18nKey="joinCongratulations"
            values={{ projectName: project?.projectByAlias?.clientName }}
          />
        </div>,
        {
          icon: <></>
        }
      );
      searchParams.delete(showJoinMessageQueryParam);
      setSearchParams(searchParams);
    }
  }, [project?.projectByAlias?.clientName]);

  if (project && !project.projectByAlias) {
    return <Navigate to="~" replace />;
  }

  return (
    <ProjectContext.Provider value={(project?.projectByAlias || {}) as Project}>
      {project ? children : <Spin />}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
