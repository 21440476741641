import { IDateTimeConverterOptions } from 'helpers/commonHelpers/dateTimeConverter';
import {
  OrderFulfillStatus,
  OrderItem as OrderItemType,
  OrderPaymentStatus,
  ProductType,
  ProductTypeGroup
} from 'generatedHooks/commerce/generated';
import { PureNullable } from 'core/globalTypes';

import { IOrderAction, OrderCalculateAction } from 'pages/orderDetails/types';
import physicalOrderLifecycleActions from 'pages/orderDetails/utilities/physicalOrderLifecycleActions';
import nonPhysicalOrderLifecycleActions from 'pages/orderDetails/utilities/nonPhysicalOrderLifecycleActions';
import giftCardOrderLifecycleActions from 'pages/orderDetails/utilities/giftCardOrderLifecycleActions';

export const defaultStepValue = 1;
export const remainingQuantityCalculatorByActionType = {
  [OrderCalculateAction.FULFILL]: (
    item: OrderItemType,
    physicalProduct: { [key in ProductType]?: PureNullable<number> }
  ) => {
    if (physicalProduct[item.type] && item.measurementPrice?.step) {
      return (
        (physicalProduct[item.type] as number) -
        (item.qtyShipped as number) * item.measurementPrice?.step
      );
    }

    return (item?.qtyOrdered as number) - (item?.qtyShipped as number) || 0;
  },
  [OrderCalculateAction.REFUND]: (item: OrderItemType) => {
    if (item.measurementPrice?.step) {
      return (item.qtyToRefund as number) * item.measurementPrice?.step;
    }

    return (item?.qtyOrdered as number) - (item?.qtyRefunded as number) || 0;
  },

  [OrderCalculateAction.RETURN]: (item: OrderItemType) => {
    return (
      (item.qtyShipped as number) * (item?.measurementPrice?.step as number) ||
      defaultStepValue
    );
  }
};

export enum OrderTypes {
  Order = 'order',
  Attendee = 'attendee',
  Reservation = 'reservation'
}

export const OrderTitles = {
  order: 'order',
  attendee: 'attendee',
  reservation: 'reservation'
};

export const DATE_TIME_CONVERTER_DEFAULT_CONFIGS: IDateTimeConverterOptions = {
  convertByTimezone: false
};

export const SHIPPING_ADDRESS_FORM_FIELDS = [
  'personName',
  'address1',
  'city',
  'postcode',
  'countryCode'
];

export const WEIGHT_KEY_SUFFIX = 'weight';

export const orderActionsMappingByGroupType: Record<
  Exclude<ProductTypeGroup, ProductTypeGroup.Collection>,
  {
    paymentStatus: Record<OrderPaymentStatus, IOrderAction[]>;
    fulfillStatus: Record<OrderFulfillStatus, IOrderAction[]>;
  }
> = {
  [ProductTypeGroup.Physical]: physicalOrderLifecycleActions,
  [ProductTypeGroup.Digital]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.Subscription]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.BookingAppointment]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.BookingTable]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.BookingRental]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.BookingReservation]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.BookingEvent]: nonPhysicalOrderLifecycleActions,
  [ProductTypeGroup.GiftCard]: giftCardOrderLifecycleActions
};
