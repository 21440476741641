import { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  icon: ReactNode;
  className?: string;
  onClick?: () => void;
  size?: 'large' | 'small' | 'default';
}

const MobileIconWrapper: FC<Props> = ({
  icon,
  size = 'default',
  className,
  onClick
}) => {
  return (
    <span
      onClick={onClick}
      className={`${styles[size]} ${styles.mobile_icon_wrapper} ${className}`}
    >
      {icon}
    </span>
  );
};

export default MobileIconWrapper;
