import { CoreConfig, KeyValuePair } from 'core/globalTypes';

const coreConfigsDataToObj = (configs: CoreConfig[]) => {
  const configsObj: KeyValuePair = {};

  for (let index = 0; index < configs.length; index++) {
    const element = configs[index];
    configsObj[element.code] = element.value;
  }

  return configsObj;
};

export default coreConfigsDataToObj;
