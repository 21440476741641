import { ReactNode } from 'react';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from 'core/constants/common';
import buildIdJson from 'build-id.json';

const hasBuildId = Boolean(buildIdJson.buildId);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    ns: [
      'common',
      'errors',
      'mutation',
      'pageTitles',
      'media',
      'shipping',
      'payments',
      'bulkActions'
    ],
    defaultNS: 'common',
    fallbackLng: DEFAULT_LANGUAGE,
    backend: {
      queryStringParams: hasBuildId ? { id: buildIdJson.buildId } : {}
    }

    // backend: {
    //   loadPath: PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json'
    // }
  });

type Props = {
  children: ReactNode;
};

function MyI18nextProvider({ children }: Props) {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default MyI18nextProvider;
