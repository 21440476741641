import { TimePicker, TimePickerProps } from 'antd';
import dayjs from 'dayjs';

import UcRangeTimePicker from '../RangeTimePicker';
import styles from './styles.module.less';

const BaseTimePicker = ({
  changeOnBlur = true,
  popupClassName = '',
  ...props
}: TimePickerProps) => {
  return (
    <TimePicker
      size="large"
      defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
      popupClassName={`${popupClassName} ${
        changeOnBlur ? styles.hideFooter : ''
      }`}
      changeOnBlur={changeOnBlur}
      {...props}
    />
  );
};

const UcTimePicker = Object.assign(BaseTimePicker, TimePicker, {
  RangePicker: UcRangeTimePicker
});

export default UcTimePicker;
