export interface DataTestAttributeCreatorArgs {
  dataTestAttribute: DataTestAttributes;
  prefix?: string;
  suffix?: string | number;
}

export enum DataTestAttributes {
  Button = 'btn',
  Link = 'link',
  Checkbox = 'checkbox',
  RadioButton = 'radio-btn',
  Input = 'input',
  Dropdown = 'dropdown',
  Option = 'option',
  Text = 'text',
  TextArea = 'textarea',
  Component = 'component',
  Tooltip = 'tooltip',
  Notification = 'notification',
  Toast = 'toast',
  Icon = 'icon',
  Switcher = 'switcher',
  Tab = 'tab',
  ColorPicker = 'color-picker',
  Menu = 'menu',
  Modal = 'modal',
  Popup = 'popup',
  Item = 'item',
  //seperate component
  LeftSidebarMenuItem = 'left-menu-item'
}
