import { VALID_MIME_TYPES } from '../constants';

const readFileByType = (
  file: Blob,
  onFileLoad: (fileResult: FileReader['result'], isCsvFile: boolean) => void
) => {
  const reader = new FileReader();

  const isCsvFile = file.type === VALID_MIME_TYPES.CSV;

  reader.onload = e => {
    if (e.target) {
      onFileLoad(e.target.result, isCsvFile);
    }
  };

  const readExcel = () => {
    reader.readAsArrayBuffer(file);
  };

  const readCsv = () => {
    reader.readAsText(file, 'UTF-8');
  };

  const readFileByTypeMapping = {
    [VALID_MIME_TYPES.CSV]: readCsv,
    [VALID_MIME_TYPES.XLS]: readExcel,
    [VALID_MIME_TYPES.XLSX]: readExcel
  };

  readFileByTypeMapping[file.type]();
};

export default readFileByType;
