import {
  BulkActionEntity,
  BulkActionOperation
} from 'generatedHooks/commerce/generated';

import { OperationMetaData } from './types';

const defaultDeleteData: OperationMetaData = {
  key: 'delete',
  buttonLabelKey: 'bulkActions:delete',
  danger: true,
  mutationSuccessMessageKey: 'bulkActions:deleteStartedMessage',
  confirmModalTitleKey: 'bulkActions:deleteConfirmContent',
  successMessageKey: 'bulkActions:deleteSuccessMessage',
  partialSuccessMessageKey: 'bulkActions:partialDeleteSuccessMessage',
  failureMessageKey: 'bulkActions:deleteFailureMessage',
  modalConfirmButtonTextKey: 'common:delete',
  mutationErrorKey: 'bulkActions:bulkDeleteError'
};

const commonVisibilityData: Omit<OperationMetaData, 'key' | 'buttonLabelKey'> =
  {
    danger: false,
    mutationSuccessMessageKey: 'bulkActions:changeVisibilityStartedMessage',
    confirmModalTitleKey: 'bulkActions:changeVisibilityConfirmContent',
    successMessageKey: 'bulkActions:changeVisibilitySuccessMessage',
    partialSuccessMessageKey:
      'bulkActions:partialChangeVisibilitySuccessMessage',
    failureMessageKey: 'bulkActions:changeVisibilityFailureMessage',
    modalConfirmButtonTextKey: 'common:confirm',
    mutationErrorKey: 'bulkActions:visibilityChangeError'
  };

const defaultMakeVisibleData = {
  ...commonVisibilityData,
  key: 'make-visible',
  buttonLabelKey: 'bulkActions:makeVisible'
};

const defaultMakeInvisibleData = {
  ...commonVisibilityData,
  key: 'make-invisible',
  buttonLabelKey: 'bulkActions:makeInvisible'
};

export const BULK_ACTIONS_META_DATA: Record<
  BulkActionEntity,
  {
    [BulkActionOperation.Delete]: OperationMetaData;
    [BulkActionOperation.MakeVisible]?: OperationMetaData;
    [BulkActionOperation.MakeInvisible]?: OperationMetaData;
  } & Record<'entityTranslationKey', string>
> = {
  [BulkActionEntity.Product]: {
    entityTranslationKey: 'product',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.Booking]: {
    entityTranslationKey: 'booking',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.Event]: {
    entityTranslationKey: 'event',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.Attribute]: {
    entityTranslationKey: 'field',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.Field]: {
    entityTranslationKey: 'field',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.CartRule]: {
    entityTranslationKey: 'discount',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.Category]: {
    entityTranslationKey: 'category',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.BookingCategory]: {
    entityTranslationKey: 'category',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.EventCategory]: {
    entityTranslationKey: 'category',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.CollectionCategory]: {
    entityTranslationKey: 'category',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.AttributeFamily]: {
    entityTranslationKey: 'productFamily',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.Collection]: {
    entityTranslationKey: 'collection',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.CollectionItem]: {
    entityTranslationKey: 'collectionItem',
    [BulkActionOperation.Delete]: defaultDeleteData,
    [BulkActionOperation.MakeVisible]: defaultMakeVisibleData,
    [BulkActionOperation.MakeInvisible]: defaultMakeInvisibleData
  },
  [BulkActionEntity.Customer]: {
    entityTranslationKey: 'customer',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.CustomerGroup]: {
    entityTranslationKey: 'customerGroup',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.ProductReview]: {
    entityTranslationKey: 'productReview',
    [BulkActionOperation.Delete]: defaultDeleteData
  },
  [BulkActionEntity.TeamMember]: {
    entityTranslationKey: 'teamMember',
    [BulkActionOperation.Delete]: defaultDeleteData
  }
};
