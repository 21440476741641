import { Actions } from 'providers/PermissionAndLimitationProvider';

const { See, Manage } = Actions;

export const NAVIGATION_SHORTCUTS_PERMISSIONS = {
  Orders: {
    I: See.Orders,
    Or: See.OwnOrders
  },
  Products: {
    I: See.Products,
    Or: Manage.OwnProducts
  },
  Analytics: { I: See.Analytics },
  Customers: { I: See.Customers },
  Discounts: { I: See.Discounts },
  Settings: { I: See.Settings },
  ActivityLog: { I: See.ActivityLog }
};
