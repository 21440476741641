import {
  ExportAttendeesDocument,
  ExportCategoriesDocument,
  ExportCustomersDocument,
  ExportProductsDocument,
  ExportableEntityType,
  ExportRentalOrdersDocument
} from 'generatedHooks/commerce/generated';

export const EXPORT_MUTATIONS_MAPPING = {
  [ExportableEntityType.Attendee]: ExportAttendeesDocument,
  [ExportableEntityType.Category]: ExportCategoriesDocument,
  [ExportableEntityType.Customer]: ExportCustomersDocument,
  [ExportableEntityType.Product]: ExportProductsDocument,
  [ExportableEntityType.RentalOrder]: ExportRentalOrdersDocument
};
