import React from 'react';
import { Button } from 'antd';

import styles from './styles.module.less';

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset'
}

export type bgColor = 'black' | undefined;

interface ButtonProps {
  loading?: boolean;
  htmlType?: ButtonTypes;
  block?: boolean;
  handleClick?: Function;
  className?: string;
  childClassName?: string;
  bgColor?: bgColor;
  disabled?: boolean;
  ghost?: boolean;
  danger?: boolean;
  icon?: React.ReactNode | undefined;
  rightIcon?: React.ReactNode | undefined;
  children?: React.ReactNode;
}

const UCSecondaryButton: React.FC<ButtonProps> = ({
  htmlType = ButtonTypes.button,
  block,
  handleClick,
  className = '',
  childClassName = '',
  disabled = false,
  ghost = false,
  loading,
  danger,
  icon,
  rightIcon,
  children,
  ...rest
}) => {
  const handleButtonClick = (event: React.MouseEvent): void => {
    if (!disabled && handleClick) {
      handleClick(event);
    }
  };

  return (
    <Button
      loading={loading}
      type={'default'}
      htmlType={htmlType}
      block={block}
      onClick={handleButtonClick}
      className={`${styles.ucSecondaryButton} ${className}`}
      disabled={disabled}
      ghost={ghost}
      danger={danger}
      icon={icon}
      {...rest}
    >
      {childClassName ? (
        <span className={childClassName}>{children}</span>
      ) : (
        children
      )}
      {rightIcon}
    </Button>
  );
};

export default UCSecondaryButton;
