import React, { FC } from 'react';
import { Badge, BadgeProps } from 'antd';

interface BaseBadgeProps extends BadgeProps {}

const BaseBadge: FC<BaseBadgeProps> = props => {
  return <Badge {...props} />;
};

const UcBadge = Object.assign(Badge, BaseBadge);

export default UcBadge;
