import { FC, memo } from 'react';
import { KeyValuePair } from 'core/globalTypes';
import { EDITOR_EMPTY_CONTENT } from 'core/constants/common';

import RichText from '../RichText';

interface Props {
  value?: KeyValuePair<string>;
  onChange?: (v: object) => void;
  placeholder?: string;
  locale?: string;
  disabled?: boolean;
  enableDefaultControls?: boolean;
  showAiButton?: boolean;
}

const Editor: FC<Props> = ({
  value = {},
  onChange,
  placeholder,
  locale,
  disabled,
  showAiButton = false
}) => {
  const isHtmlEmpty = (html: string) => html === EDITOR_EMPTY_CONTENT;

  const handleChange = (editorState: string) => {
    if (
      editorState !== value[locale as string] &&
      (!isHtmlEmpty(editorState) || value[locale as string])
    ) {
      onChange?.({ ...value, [locale as string]: editorState });
    }
  };

  return (
    <RichText
      showAiButton={showAiButton}
      value={value[locale as string]}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default memo(Editor);
