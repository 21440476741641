import {
  ReactNode,
  useLayoutEffect,
  SetStateAction,
  createContext,
  useState,
  useRef,
  useMemo
} from 'react';
import {
  useCoreConfigsQuery,
  useGlobalConfigurationsQuery
} from 'generatedHooks/commerce/generated';
import { coreConfigs, globalConfigs } from 'apollo/cache';
import { coreConfigsToObj, coreConfigsDataToObj } from 'helpers';
import { useReactiveVar } from '@apollo/client';
import { Spin } from 'antd';
import { useSidebarAvailableRoutes } from 'hooks';
import { useBulkActionsProcess } from 'services/bulkActions/hooks';

import { State as DashboardAvailableRoutesState } from 'hooks/useSiderAvailableRoutes/types';
import { useProjectContext } from './ProjectProvider';
import { ExpiredAlert } from 'components/shared';

interface Props {
  children: ReactNode;
}

type GlobalDataType = {
  collapsed: boolean;
  setCollapsed: (collapsed: SetStateAction<boolean>) => void;
  dashboardAvailableRoutesState: DashboardAvailableRoutesState;
  bulkActionProcess: ReturnType<typeof useBulkActionsProcess>;
};

export const GlobalContext = createContext<GlobalDataType>({
  collapsed: false,
  setCollapsed: () => {},
  dashboardAvailableRoutesState: {
    dashboardSiderAvailableRoutes: {},
    noAnyAccess: true
  },
  bulkActionProcess: {
    addNewProcess: () => {},
    bulkActionsProcesses: {},
    removeProcess: () => {},
    completedProcessesReport: [],
    excludeEntityFromCompletedProcesses: () => {},
    getCompletedProcessesByEntity: () => []
  }
});

const GlobalConfigProvider = ({ children }: Props) => {
  const project = useProjectContext();

  const { data: configsData } = useGlobalConfigurationsQuery();
  const { data: coreConfigsData } = useCoreConfigsQuery({
    variables: {
      input: {}
    }
  });

  const configsInitialized = useRef(false);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const gConf = useReactiveVar(globalConfigs);
  const cConf = useReactiveVar(coreConfigs);

  const dashboardAvailableRoutesState = useSidebarAvailableRoutes();
  const bulkActionProcess = useBulkActionsProcess();

  const canRendChildren = useMemo(() => {
    return Object.keys(gConf).length && Object.keys(cConf);
  }, [gConf, cConf]);

  useLayoutEffect(() => {
    if (configsData && coreConfigsData && !configsInitialized.current) {
      globalConfigs({
        ...globalConfigs(),
        defaultChannelCode:
          configsData.globalConfigurations.company.defaultChannel.code,
        defaultChannelId:
          configsData.globalConfigurations.company.defaultChannel.id,
        defaultAttributeFamilyId:
          configsData.globalConfigurations.company.defaultAttributeFamily.id,
        baseCurrency:
          configsData.globalConfigurations.company.defaultChannel.baseCurrency,
        defaultInventorySourceId:
          configsData.globalConfigurations.company.defaultInventorySource.id,
        defaultLocale:
          configsData.globalConfigurations.company.defaultChannel.defaultLocale
            .code,
        defaultInternationalCode:
          configsData.globalConfigurations.company.defaultChannel.defaultLocale
            .internationalCode,
        rootCategoryId:
          configsData.globalConfigurations.company.defaultChannel
            .rootCategoryId,
        importBlueprintUrls:
          configsData.globalConfigurations.importBlueprintUrls
        // defaultInternationalCode:
        //   configsData.globalConfigurations.company.defaultChannel.defaultLocale
        //     ?.internationalCode
      });

      coreConfigs({
        date_format: 'MMM DD, YYYY',
        time_format: '24H',
        locale: 'en-EN', //TODO: check locale with data
        ...coreConfigsToObj(configsData.globalConfigurations.coreConfigs || []),
        ...coreConfigs(),
        ...coreConfigsDataToObj(coreConfigsData?.coreConfigs || [])
      });

      configsInitialized.current = true;
    }
  }, [configsData, coreConfigsData]);

  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      '--global-header-height',
      project.isExpired ? `${88 + 36}px` : '88px'
    );
  }, [project.isExpired]);

  return (
    <GlobalContext.Provider
      value={{
        collapsed,
        setCollapsed,
        dashboardAvailableRoutesState,
        bulkActionProcess
      }}
    >
      {canRendChildren ? (
        <>
          {project?.isExpired && <ExpiredAlert />}
          {children}
        </>
      ) : (
        <Spin />
      )}
    </GlobalContext.Provider>
  );
};

export default GlobalConfigProvider;
