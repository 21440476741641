import { useMemo, FC } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { useProjectContext } from 'providers/ProjectProvider';

import { NavBarProps } from './types';
import { SiderMenu } from 'components/global/Sider/components';
import styles from './styles.module.less';

const NavBar: FC<NavBarProps> = ({
  children,
  visible,
  visibleHandlerMenus
}) => {
  const isSmall = useMemo(() => window.innerWidth < 379, [window.innerWidth]);
  const project = useProjectContext();

  return (
    <nav className={styles.navbar}>
      {!visible ? (
        <MenuOutlined
          className={styles.headerIcon}
          onClick={() => visibleHandlerMenus(true)}
        />
      ) : (
        <CloseOutlined
          className={styles.headerIcon}
          onClick={() => visibleHandlerMenus(false)}
        />
      )}
      <Drawer
        width={isSmall ? 320 : 378}
        title={children}
        rootClassName={styles.navbarBurgerMenu}
        placement="left"
        styles={{
          header: {
            padding: '16px 8px',
            display: 'inline-flex',
            justifyContent: 'flex-end',
            height: 88
          },
          body: {
            padding: `11px 1px 24px 0`
          }
        }}
        onClose={() => visibleHandlerMenus(false)}
        open={visible}
      >
        {project?.name && <SiderMenu projectName={project.name} />}
      </Drawer>
    </nav>
  );
};

export default NavBar;
