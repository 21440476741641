import { Card, CardProps } from 'antd';
import { FC } from 'react';

interface Props extends CardProps {
  headerBorder?: boolean;
}

const BaseCard: FC<Props> = ({ headerBorder = false, ...props }) => {
  return (
    <Card
      {...props}
      headStyle={{
        ...props.headStyle,
        borderBottom: !headerBorder ? 'unset' : 'inherit'
      }}
    />
  );
};

const UcCard = Object.assign(BaseCard, Card);

export default UcCard;
