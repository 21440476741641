import { FC } from 'react';
import { Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Button } from 'components/basic';
import styles from './styles.module.less';

interface Props {
  onContinue: () => void;
  continueButtonText: string;
  back: boolean;
  onBack: () => void;
  disabled: boolean;
  title: string;
  loading: boolean;
}

const ModalHeader: FC<Props> = ({
  onContinue,
  continueButtonText,
  back = false,
  onBack,
  disabled = false,
  title,
  loading = false
}) => {
  const { t } = useTranslation('importModal');

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.modalTitle}>
        <Divider type="vertical" />
        <Typography.Text className="textEllipsis">{title}</Typography.Text>
      </div>
      <div className={styles.actions}>
        {back && (
          <Button
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Button,
              prefix: 'back'
            })}
            onClick={onBack}
            type="default"
            disabled={loading}
            className={styles.backButton}
          >
            {t('back')}
          </Button>
        )}
        <Button
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Button,
            prefix: 'continue'
          })}
          onClick={onContinue}
          type="primary"
          disabled={disabled}
          loading={loading}
        >
          {t(continueButtonText)}
        </Button>
      </div>
    </div>
  );
};

export default ModalHeader;
