import React, { Dispatch, SetStateAction } from 'react';
import { Modal, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { IMPORTABLE_ENTITY_KEYS } from 'components/global/ImportModal/constants';
import styles from './styles.module.less';
import {
  IImportReportData,
  ImportableEntity
} from 'components/global/ImportModal/types';
import { columns } from './columns';

interface Props {
  entityType: ImportableEntity;
  importReport: IImportReportData | undefined | null;
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

const { Text, Paragraph } = Typography;

const ImportReportModal: React.FC<Props> = ({
  entityType,
  importReport,
  modalVisible,
  setModalVisible
}) => {
  const { t } = useTranslation('importModal');

  return (
    <Modal
      open={modalVisible}
      title={<Text style={{ fontWeight: 600 }}>{t('skippedItems')}</Text>}
      width={600}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('downloadPdf')}
      onCancel={() => setModalVisible(false)}
      centered
      footer={null}
      className={styles.skippedItemsModal}
    >
      <div>
        <Paragraph className={styles.errors}>
          {t('countError', {
            data: t(IMPORTABLE_ENTITY_KEYS[entityType]),
            count: importReport?.reasons.length
          })}
        </Paragraph>
        <Paragraph className={styles.hint}>
          {t('skippedItemsModalDescription')}
        </Paragraph>
        <Table
          columns={columns(t)}
          dataSource={importReport?.reasons}
          pagination={false}
          className={styles.table}
          showHeader={!!importReport?.reasons?.length}
        />
      </div>
    </Modal>
  );
};

export default ImportReportModal;
