import { Editor } from 'tinymce';

tinymce.PluginManager.add(
  'wrapListItemContentInParagraphPlugin',
  function (editor: Editor) {
    const wrapListItemsInP = (content: string): string => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      const listItems = tempDiv.querySelectorAll('li');
      listItems.forEach(li => {
        if (
          !li.firstElementChild ||
          li.firstElementChild.tagName.toLowerCase() !== 'p'
        ) {
          const p = document.createElement('p');
          p.innerHTML = li.innerHTML;
          li.innerHTML = '';
          li.appendChild(p);
        }
      });

      return tempDiv.innerHTML;
    };

    const wrapCurrentListItemsInP = () => {
      const body = editor.getBody();
      const listItems = body.querySelectorAll('li');
      listItems.forEach(li => {
        if (
          !li.firstElementChild ||
          li.firstElementChild.tagName.toLowerCase() !== 'p'
        ) {
          const p = document.createElement('p');
          p.innerHTML = li.innerHTML;
          li.innerHTML = '';
          li.appendChild(p);
        }
      });
    };

    editor.on('ExecCommand', e => {
      if (
        e.command === 'InsertOrderedList' ||
        e.command === 'InsertUnorderedList'
      ) {
        setTimeout(() => {
          editor.setContent(wrapListItemsInP(editor.getContent()));
        }, 0);
      }
    });

    editor.on('SetContent', e => {
      e.content = wrapListItemsInP(e.content);
    });

    editor.on('GetContent', e => {
      e.content = wrapListItemsInP(e.content);
    });

    editor.on('NodeChange', () => {
      wrapCurrentListItemsInP();
    });
  }
);
