import { Row, Col } from 'antd';
import { FC } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import i18n from 'i18next';

import { Typography, Radio, Tag } from 'components/basic';
import { ShowElement } from 'components/shared';
import styles from './styles.module.less';
import { ArrowRightIcon } from 'components/shared/SVG';
import { TypeChooserRadioButtonProps } from './types';
import { TypeChooserList, TypeChooserSkeleton } from './components';

const { UcBodyText } = Typography;

const TypeChooserRadioButton: FC<TypeChooserRadioButtonProps> = ({
  icon,
  title,
  description,
  className,
  value,
  disabled,
  isComingSoon = false,
  hideArrow = false,
  ...props
}) => {
  return (
    <Radio.Button
      className={`${styles.type_chooser} ${className || ''} ${
        disabled || isComingSoon ? styles.type_chooser_disabled : ''
      }`}
      value={value}
      disabled={disabled || isComingSoon}
      {...props}
    >
      <Row
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: `${title}`
        })}
        justify="space-between"
        wrap={false}
        className={styles.type_chooser_row}
      >
        <Col span={22}>
          <Row gutter={16} style={{ flexFlow: 'nowrap' }}>
            <ShowElement isShow={Boolean(icon)}>
              <Col>
                <div
                  className={`${styles.icon_wrapper} ${
                    disabled || isComingSoon ? styles.icon_wrapper_disabled : ''
                  }`}
                >
                  {icon}
                </div>
              </Col>
            </ShowElement>
            <Col flex={1}>
              <div className={styles.type_chooser_title}>
                <UcBodyText
                  size="base"
                  type={disabled || isComingSoon ? 'secondary' : 'default'}
                  ellipsis
                >
                  {title}
                </UcBodyText>
                <ShowElement isShow={isComingSoon}>
                  <Tag
                    className={styles.coming_soon_tag}
                    text={i18n.t('common:comingSoon')}
                  />
                </ShowElement>
              </div>
              <ShowElement isShow={Boolean(description)}>
                <div className={styles.type_chooser_description}>
                  <UcBodyText
                    size="small"
                    type={
                      disabled || isComingSoon ? 'secondary' : 'secondary-dark'
                    }
                    ellipsis
                  >
                    {description}
                  </UcBodyText>
                </div>
              </ShowElement>
            </Col>
          </Row>
        </Col>
        <Col>
          <div className={styles.icon_wrapper}>
            <ShowElement isShow={!hideArrow && !disabled && !isComingSoon}>
              <ArrowRightIcon />
            </ShowElement>
          </div>
        </Col>
      </Row>
    </Radio.Button>
  );
};

export default Object.assign(TypeChooserRadioButton, {
  TypeChooserList,
  TypeChooserSkeleton
});
