import { DATE_RANGE_KEY } from './cosntants';
import { SetDateRangeArgs } from './types';

class SessionStorageManager {
  static getDateRange() {
    const dateRange = sessionStorage.getItem(DATE_RANGE_KEY);

    if (dateRange) {
      return JSON.parse(dateRange);
    }

    return null;
  }

  static setDateRange(dateRange: SetDateRangeArgs) {
    const dateRangeJson = JSON.stringify(dateRange);

    sessionStorage.setItem(DATE_RANGE_KEY, dateRangeJson);
  }

  static clearDateRange() {
    sessionStorage.removeItem(DATE_RANGE_KEY);
  }
}

export default SessionStorageManager;
