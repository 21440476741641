import { FC } from 'react';

import { ShowElementProps } from './types';

const ShowElement: FC<ShowElementProps> = ({
  isShow,
  fallback = null,
  children
}) => {
  return <>{isShow ? children : fallback}</>;
};

export default ShowElement;
