import { FC, ReactNode } from 'react';
import { Layout } from 'antd';

import Header from 'components/global/Header';
import Logo from 'pages/projects/components/Logo';
import styles from '../styles.module.less';

const { Content } = Layout;

interface Props {
  avatarSrc: string;
  children: ReactNode;
  firstNameAndLastName: string;
}

const Web: FC<Props> = ({ avatarSrc, children, firstNameAndLastName }) => {
  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          breakpointMd
          avatarSrc={avatarSrc}
          projectsPage
          userName={firstNameAndLastName}
          defaultSelectedKeys={['projects']}
          style={{ height: '72px', zIndex: '3' }}
        >
          <Logo />
        </Header>

        <Content className={styles.my_project_content_layout}>
          {children}
        </Content>
      </Layout>
    </div>
  );
};

export default Web;
