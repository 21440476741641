import { FC, SyntheticEvent, useCallback, useRef } from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Project } from 'generatedHooks/accounts/generated';

import Card from '../Card';
import { LoadingWrapper, EmptyWrapper } from 'components/shared';
import Empty from '../EmptyWrapper';
import GridSkeleton from '../Skeleton';
import styles from 'pages/projects/Web/styles.module.less';

interface Props {
  loading: boolean;
  projects: Array<Project>;
  leaveProject: (projectId: string, projectName: string) => void;
  deleteProjectHandler: (projectId: string, projectName: string) => void;
  openNotificationsSettingsModal: (
    projectId: string,
    projectClientName: string
  ) => void;
}

const ProjectList: FC<Props> = ({
  loading,
  projects,
  deleteProjectHandler,
  leaveProject,
  openNotificationsSettingsModal
}) => {
  const navigate = useNavigate();
  const parent = useRef<HTMLDivElement>(null);

  const handleNavigate = useCallback(
    (e: SyntheticEvent<EventTarget>, name: string) => {
      e.target.toString() === parent?.current?.toString() &&
        navigate(`/${name}`);
    },
    []
  );

  return (
    <Row gutter={[18, 18]} className={styles.project_list_wrapper}>
      <LoadingWrapper loading={loading} indicator={<GridSkeleton count={12} />}>
        <EmptyWrapper isEmpty={Boolean(!projects.length)} indicator={<Empty />}>
          {projects.map(project => {
            return (
              <Col
                ref={parent}
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                xxl={6}
                key={project.id}
                style={{ maxHeight: '300px' }}
                onClick={e => handleNavigate(e, project.name)}
              >
                <Card
                  project={project}
                  leaveProject={leaveProject}
                  deleteProjectHandler={deleteProjectHandler}
                  openNotificationsSettingsModal={
                    openNotificationsSettingsModal
                  }
                />
              </Col>
            );
          })}
        </EmptyWrapper>
      </LoadingWrapper>
    </Row>
  );
};

export default ProjectList;
