import { Col, Row, Skeleton } from 'antd';

import { PageContainer } from 'components/wrapper';
import NestedPageHeader from 'pages/settings/components/NestedPageHeader';
import NestedPageWrapper from 'pages/settings/components/NestedPageWrapper';
import { Divider } from 'components/basic';
import styles from './styles.module.less';

const CreateShippingMethodSkeleton = () => {
  return (
    <PageContainer>
      <NestedPageHeader
        onBack={() => null}
        title={
          <Skeleton.Input
            className={styles.skeleton_title}
            style={{ width: 250 }}
          />
        }
      />
      <NestedPageWrapper>
        <div className={styles.block}>
          <Skeleton.Input style={{ height: 28, width: 250 }} />
          <Skeleton.Input style={{ height: 16 }} />
          <Skeleton.Input style={{ height: 32 }} />
        </div>
        <div className={styles.block}>
          <Skeleton.Input style={{ height: 28, width: 250 }} />
          <Skeleton.Input style={{ height: 16 }} />
          <Divider />
          <Row>
            <Col>
              <Skeleton.Input style={{ height: 28, width: 250 }} />
            </Col>
            <Col>
              <Skeleton.Input
                style={{ height: 28, width: 250, marginLeft: 16 }}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.block}>
          <Skeleton.Input style={{ height: 28, width: 250 }} />
          <Skeleton.Input style={{ height: 16 }} />
          <Divider />
          <Row style={{ width: 750 }}>
            <Row justify="space-between" style={{ width: '100%' }}>
              <Col>
                <Skeleton.Input style={{ height: 28, width: 300 }} />
              </Col>
              <Col>
                <Skeleton.Input style={{ height: 28, width: 300 }} />
              </Col>
            </Row>
            <Row
              justify="space-between"
              style={{ width: '100%', marginTop: 24 }}
            >
              <Col>
                <Skeleton.Input style={{ height: 28, width: 300 }} />
              </Col>
              <Col>
                <Skeleton.Input style={{ height: 28, width: 300 }} />
              </Col>
            </Row>
          </Row>
        </div>
      </NestedPageWrapper>
    </PageContainer>
  );
};

export default CreateShippingMethodSkeleton;
