import { FC, ReactNode } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import styles from './styles.module.less';

interface Props {
  value: string | number;
  children?: ReactNode;
}

interface ISingleCheckbox {
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  autoLabel?: boolean;
  className?: string;
  children?: ReactNode;
}

interface ISimpleCheckbox {
  disabled?: boolean;
  children?: ReactNode;
}

const UCCheckbox: FC<Props> = ({ value = '', children, ...rest }) => {
  if (children) {
    return (
      <Checkbox value={value} className={styles.ucCheckbox} {...rest}>
        {children}
      </Checkbox>
    );
  }

  return <Checkbox value={value} className={styles.ucCheckbox} />;
};

export const UCCheckboxSingle: FC<ISingleCheckbox> = ({
  children,
  checked,
  onChange,
  autoLabel,
  className,
  ...rest
}) => {
  if (children) {
    return (
      <Checkbox
        className={`${styles.ucCheckbox} ${className} ${
          autoLabel ? styles.ucAutoCheckbox : ''
        }`}
        checked={checked}
        {...rest}
      >
        {children}
      </Checkbox>
    );
  }

  return (
    <Checkbox
      onChange={onChange}
      className={`${styles.ucCheckbox}  ${className}`}
      checked={checked}
    />
  );
};

export const UCCheckboxSimple: FC<ISimpleCheckbox> = ({
  children,
  disabled
}) => {
  if (children) {
    return (
      <Checkbox className={styles.ucCheckbox} disabled={disabled}>
        {children}
      </Checkbox>
    );
  }

  return <Checkbox className={styles.ucCheckbox} disabled={disabled} />;
};

export default UCCheckbox;
