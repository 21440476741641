import { FC } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Button } from 'components/basic';
import styles from './styles.module.less';
import { BackButtonProps } from './types';
import { ArrowLeftIcon } from 'components/shared/SVG';

const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  return (
    <Button
      className={styles.back_button}
      icon={<ArrowLeftIcon />}
      onClick={onClick}
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: 'back'
      })}
    />
  );
};

export default BackButton;
