import { FC, ReactNode, useMemo } from 'react';
import { trimmer } from 'helpers';
import { deviceDetector } from 'containers/utils';

import Mobile from './Mobile';
import Web from './Web';

interface Props {
  user: any;
  children: ReactNode;
}

const PageLayout: FC<Props> = ({ children, user }) => {
  const avatarSrc = user?.avatarUrl || '';
  const { isMobile } = deviceDetector();

  const firstNameAndLastName = useMemo(() => {
    return trimmer(user?.firstName, user?.lastName);
  }, [user.firstName, user.lastName]);

  const Component = isMobile ? Mobile : Web;

  return (
    <Component
      avatarSrc={avatarSrc}
      firstNameAndLastName={firstNameAndLastName}
    >
      {children}
    </Component>
  );
};

export default PageLayout;
