import { Action, ActionTypes, State } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.Init:
      return {
        dashboardSiderAvailableRoutes:
          action.payload.dashboardSiderAvailableRoutes,
        noAnyAccess: action.payload.noAnyAccess
      };

    case ActionTypes.SetDashboardSidebarAvailableRoutes:
      return {
        ...state,
        dashboardSiderAvailableRoutes: action.payload
      };
    case ActionTypes.SetNoAnyAccess:
      return {
        ...state,
        noAnyAccess: action.payload as boolean
      };
    default:
      return state;
  }
};

export default reducer;
