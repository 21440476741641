import { useState, useEffect } from 'react';
import { KeyValuePair } from 'core/globalTypes';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const qsParseOptions = { ignoreQueryPrefix: true };
const qsStringifyOptions = { addQueryPrefix: true };

const useQuery = () => {
  const location = useLocation();
  const [queryObject, setQueryObject] = useState<KeyValuePair>(
    qs.parse(location.search, qsParseOptions)
  );

  const getQueryStringWithoutPageCount = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, count, ...rest } = queryObject;

    return qs.stringify(rest, qsStringifyOptions);
  };

  useEffect(() => {
    setQueryObject(qs.parse(location.search, qsParseOptions));
  }, [location.search]);

  return {
    queryObject,
    queryString: location.search,
    queryStringWithoutPageCount: getQueryStringWithoutPageCount()
  };
};

export default useQuery;
