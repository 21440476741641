import { ID, KeyValuePair, PureNullable, Undefinable } from 'core/globalTypes';
import MediaUpload from 'packages/MediaUpload';
import { FC } from 'react';

interface Props<T = KeyValuePair<PureNullable<ID>>> {
  value?: T;
  onChange?: (value: T) => void;
  locale?: string;
}

const PerLocaleSingleMedia: FC<Props> = ({
  value = {},
  onChange,
  locale = ''
}) => {
  const currentValue = value[locale];

  const handleChange = (id: PureNullable<ID>) => {
    onChange?.({
      ...value,
      [locale]: id
    });
  };

  return (
    <MediaUpload
      initialMediaIds={currentValue ? [+currentValue] : null}
      setIds={(ids: Undefinable<PureNullable<string[] | number[]>>) => {
        handleChange?.(ids?.length ? ids?.[0] : null);
      }}
      mediaFor={'categories'}
    />
  );
};

export default PerLocaleSingleMedia;
