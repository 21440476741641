import { createRoot } from 'react-dom/client';
import App from 'App';

import reportWebVitals from './reportWebVitals';
import { initializeSentryConfigurations } from './helpers';
import './styles.css';

initializeSentryConfigurations();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
console.log('%c Version: 2.0.2', 'font-size: 24px; color: #235df4');

reportWebVitals();
