import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import SocketService from './SocketService';

const { VITE_APP_SOCKET_SERVER_URL } = import.meta.env;

const useSocket = (initialize = false) => {
  const [socket, setSocket] = useState<null | SocketService>(null);
  const { projectName } = useParams();
  const initialRender = useRef(true);

  const connect = useCallback(() => {
    if (projectName) {
      const s = new SocketService(
        VITE_APP_SOCKET_SERVER_URL as string,
        projectName
      );

      setSocket(s);
    }
  }, [projectName]);

  useEffect(() => {
    connect();
  }, [connect]);

  useEffect(() => {
    return () => {
      if (initialRender.current) {
        initialRender.current = false;
      } else if (initialize && !initialRender.current) {
        socket?.disconnect();
        setSocket(null);
      }
    };
  }, [socket]);

  return useMemo(() => {
    if (socket) {
      return {
        on: socket.on,
        emit: socket.emit,
        off: socket.off,
        disconnect: socket.disconnect,
        socketInstance: socket.socket,
        join: socket.join,
        leave: socket.leave,
        joinAndOn: socket.joinAndOn
      };
    }

    return {
      on: null,
      emit: null,
      off: null,
      disconnect: null,
      socketInstance: null,
      join: null,
      leave: null,
      joinAndOn: null
    };
  }, [socket]);
};

export default useSocket;
