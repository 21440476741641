import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { NiladicVoid } from 'core/globalTypes';
import isEqual from 'lodash/isEqual';

import { Typography } from 'components/basic';
import styles from './styles.module.less';
import { MediaItemSize } from 'components/shared/UcUpload/types';
import { ShowElement } from 'components/shared';

interface Props {
  loading: boolean;
  onClick: NiladicVoid;
  size: MediaItemSize;
}

const { UcBodyText } = Typography;

const UploadButton: FC<Props> = ({ loading, onClick, size }) => {
  const { t } = useTranslation();

  return (
    <Flex
      onClick={onClick}
      vertical
      gap={8}
      justify="center"
      align="center"
      className={`${styles.upload_button} ${styles[size]}`}
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <ShowElement isShow={isEqual(size, MediaItemSize.Large)}>
        <UcBodyText>{t('upload')}</UcBodyText>
      </ShowElement>
    </Flex>
  );
};

export default UploadButton;
