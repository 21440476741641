import { FC, useMemo } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Button, Space } from 'components/basic';
import styles from './style.module.less';
import { ButtonGroupsProps } from './types';

const ButtonGroups: FC<ButtonGroupsProps> = ({
  value,
  multiple,
  data,
  onChange
}) => {
  const handleChangeItem = (_value: string | number) => {
    if (multiple) {
      onChange([...value, _value]);
    }
  };

  const handleButtonClick = (_value: string | number) => {
    if (!multiple) {
      onChange(_value);

      return;
    }

    const rowIndex = value?.indexOf(_value);

    if (rowIndex !== -1) {
      const data = value;
      data?.splice(rowIndex, 1);
      onChange([...data]);
    } else {
      handleChangeItem(_value);
    }
  };

  const isActive = (_value: any) =>
    useMemo(
      () => (multiple ? value.includes(_value) : value === _value),
      [multiple, value]
    );

  return (
    <Space size="middle" className={styles.filter_button_groups}>
      {data.map(item => {
        return (
          <Button
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Button,
              prefix: item.label
            })}
            key={item.value}
            onClick={() => handleButtonClick(item.value)}
            className={`${isActive(item.value) ? styles.active : ''}`}
          >
            {item.label}
          </Button>
        );
      })}
    </Space>
  );
};

export default ButtonGroups;
