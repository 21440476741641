import React, { FC } from 'react';
import { Button, Typography, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { AccessProps } from './types';

const { Text } = Typography;
const { TextArea } = Input;

export const Access: FC<AccessProps> = ({
  requestForPermissionHandler,
  isExistNoteForThisPermission,
  onChangeNote,
  loadingSentNote,
  pageViewOrAction
}) => {
  const { t } = useTranslation('permissions-and-limitations');

  const noAccessText = pageViewOrAction
    ? 'Request permission to view this page.'
    : 'Request permission to perform this action.';

  return (
    <>
      <Text style={{ marginBottom: '16px', color: '#595959' }}>
        {isExistNoteForThisPermission
          ? t(
              'requestWasSent',
              'Your request was sent to the project owner. You can send a request again as a reminder'
            )
          : noAccessText}
      </Text>
      {!isExistNoteForThisPermission && (
        <TextArea
          onPressEnter={requestForPermissionHandler}
          disabled={loadingSentNote}
          placeholder={t('limitationTextareaPlaceholder', 'Message (optional)')}
          style={{
            width: '370px',
            height: '120px',
            marginBottom: '24px'
          }}
          onChange={onChangeNote}
        />
      )}
      <Button
        loading={loadingSentNote}
        onClick={requestForPermissionHandler}
        type={'primary'}
      >
        {isExistNoteForThisPermission
          ? t('resendRequest', 'Resend Request')
          : t('sendRequest', 'Send Request')}
      </Button>
    </>
  );
};
