import { NiladicVoid } from 'core/globalTypes';
import { ReactNode } from 'react';

export enum HelpMenuItemsKeys {
  SUPPORT = 'support',
  RESOURCES = 'resources',
  KEYBOARD_SHORTCUTS = 'keyboard_shortcuts',
  UCRAFT_COMMUNITY = 'ucraft_community',
  GIVE_FEEDBACK = 'give_feedback',
  HIRE_AN_EXPERT = 'hire_an_expert',
  UCRAFT_STATUS = 'ucraft_status',
  HELP_CENTER = 'help_center',
  TECH_DOCS = 'technical_docs',
  API_DOCS = 'api_docs',
  RELEASE_NOTES = 'release_notes',
  BUG_REPORT = 'bug_report',
  APPLICATION_QRS = 'application_qrs',
  FEATURE_REQUEST = 'feature_request'
}

export enum HelpMenuItemType {
  LINK = 'link',
  GROUP = 'group',
  ACTION = 'action'
}

export interface IHelpMenuItemOptions {
  key: HelpMenuItemsKeys;
  type: HelpMenuItemType;
  onClick?: (callbacks: IHelpMenuHandlerCallbacks) => void;
  url?: string;
  icon?: ReactNode;
  disabled?: boolean;
}

export interface IHelpMenuHandlerCallbacks {
  openKeyboardShortcutsGuide: NiladicVoid;
  openQrsModal: NiladicVoid;
}
