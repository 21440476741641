import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

class SystemMessage {
  static success(
    message: ArgsProps['message'],
    config: Partial<ArgsProps> = {}
  ) {
    notification.success({
      message,
      props: {
        'data-testid': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Component,
          prefix: 'success-notification'
        })
      },
      ...config
    });
  }

  static error(message: ArgsProps['message'], config: Partial<ArgsProps> = {}) {
    notification.error({
      message,
      props: {
        'data-testid': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Component,
          prefix: 'error-notification'
        })
      },
      ...config
    });
  }

  static warning(
    message: ArgsProps['message'],
    config: Partial<ArgsProps> = {}
  ) {
    notification.warning({
      message,
      props: {
        'data-testid': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Component,
          prefix: 'warning-notification'
        })
      },
      ...config
    });
  }

  static info(message: ArgsProps['message'], config: Partial<ArgsProps> = {}) {
    notification.info({
      message,
      props: {
        'data-testid': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Component,
          prefix: 'info-notification'
        })
      },
      ...config
    });
  }
}

export default SystemMessage;
