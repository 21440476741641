import { PageContainer } from 'components/wrapper';
import styles from './styles.module.less';
import { NotificationsPreviewSkeleton } from '../NotificationsPreviewSkeleton';

export const NotificationsPreviewLazySkeleton = () => {
  return (
    <PageContainer className={styles.preview_container}>
      <NotificationsPreviewSkeleton />
    </PageContainer>
  );
};
