import { NiladicVoid, StateDispatcher } from 'core/globalTypes';
import {
  CategoryType,
  Condition as BaseCondition,
  ConditionAttributesQuery
} from 'generatedHooks/commerce/generated';

export interface SmartEntityConditionsProps {
  conditions: ConditionsCollection;
  setConditions: StateDispatcher<ConditionsCollection>;
  conditionAttributes: ConditionAttributesQuery['conditionAttributes'];
  showRequiredMark?: boolean;
  conditionTypeChooserLabel?: string;
  fetchedConditions?: BaseCondition[];
  onConditionsChange?: NiladicVoid;
  categoryType?: CategoryType;
}

export type ConditionOperatorsByType = Record<
  ConditionOperatorKeys,
  ConditionOperator
>;

export enum ConditionAttributeType {
  Price = 'price',
  Decimal = 'decimal',
  Integer = 'integer',
  Date = 'date',
  Text = 'text',
  Boolean = 'boolean',
  Select = 'select',
  Multiselect = 'multiselect',
  DateTime = 'datetime',
  Checkbox = 'checkbox',
  Addon = 'addon'
}

export interface Condition extends Omit<Required<BaseCondition>, '__typename'> {
  uuid: string;
  options: any;
  attributeType: ConditionAttributeType;
}

export type ConditionsCollection = Record<string, Condition>;

export enum ConditionsFormItemNames {
  ConditionType = 'conditionType'
}

export enum ConditionOperatorKeys {
  IsEqualTo = 'isEqualTo',
  NotEqualTo = 'notEqualTo',
  EqualsOrGreater = 'equalsOrGreater',
  EqualsOrLess = 'equalsOrLess',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  Contains = 'contains',
  NotContain = 'notContain',
  Every = 'every'
}

export interface ConditionOperator {
  operator: string;
  label: string;
  key: ConditionOperatorKeys;
}
