import React, { FC } from 'react';
import { MenuItemProps, Menu } from 'antd';

const { Item } = Menu;

interface Props extends MenuItemProps {}

const BaseMenuItem: FC<Props> = props => {
  return <Item {...props} />;
};

const UcMenuItem = Object.assign(Item, BaseMenuItem);

export default UcMenuItem;
