import { FC, ReactNode } from 'react';
import { Row, Col } from 'antd';
import { NiladicVoid } from 'core/globalTypes';

import styles from './styles.module.less';
import { Typography } from 'components/basic';
import { BodyTextProps } from 'components/basic/Typography/BodyText/types';
import { ShowElement } from 'components/shared';

interface Props extends BodyTextProps {
  icon?: ReactNode;
  onClick?: NiladicVoid;
}

const { UcBodyText } = Typography;

const PageTitle: FC<Props> = ({ text, icon, onClick, ...restProps }) => {
  return (
    <UcBodyText
      onClick={onClick}
      className={styles.page_title_wrapper}
      {...restProps}
    >
      <Row gutter={4} align="middle" wrap={false}>
        <Col>{text}</Col>
        <ShowElement isShow={!!icon}>
          <Col>{icon}</Col>
        </ShowElement>
      </Row>
    </UcBodyText>
  );
};

export default PageTitle;
