import { useState, useEffect } from 'react';
import * as React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { linkTypes } from 'apollo/link';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import useInfiniteScroll from '../UIComponents/useInfiniteScroll';
import {
  unsplashImages,
  unsplashImages_unsplashImages_data
} from '../__generated__/types';
import styles from './styles.module.less';
import { freeStockImageCategories } from '../constants';
import { unsplashImagesQuery } from '../queries/mediaQuery';
import Web from './Web';
import Mobile from 'components/mobile/media/StockImagesMobile';

const { Option } = Select;

type Props = {
  visible: boolean;
  loadRemoteMediaLoading: boolean;
  onLoadRemoteMedia: (
    url: string,
    fromStock?: boolean
  ) => Promise<{ id: string }>;
  onCancel: () => void;
  forMobile?: boolean;
  onInsert?: (ids: string[]) => void;
  setDefaultMediaId?: (arg: number | null) => void;
};

/**
 * Renders images from unsplash api.
 * @param visible - Detect if modal visible or not.
 * @param loadRemoteMediaLoading - Boolean for showing loading during saving.
 * @param onCancel - Function triggers after modal close.
 * @param onLoadRemoteMedia - Gets selected image ids.
 * @returns ReactNode.
 */
function StockImages({
  visible,
  loadRemoteMediaLoading,
  onCancel,
  onLoadRemoteMedia,
  forMobile,
  onInsert
}: Props) {
  const { t } = useTranslation(['media', 'common']);
  const [selectedImages, setSelectedImages] = useState<{
    [id: string]: string;
  }>({});

  const [search, setSearch] = useState<string | null>(null);
  const [category, setCategory] = useState<string | null>(null);

  const [previewImage, setPreviewImage] =
    useState<unsplashImages_unsplashImages_data | null>();

  /**
   * Filters images by category.
   * @param value - Category name.
   */
  function onSelectChange(value: string) {
    setSearch(null);
    setCategory(value);
  }

  /**
   * Imports selected messages from unsplash api.
   */
  async function onImportImages() {
    const requests = Object.keys(selectedImages).map(id =>
      onLoadRemoteMedia(selectedImages[id], true)
    );

    const result = await Promise.all(requests);

    if (forMobile && result && result.length) {
      if (onInsert) {
        onCancel();

        return onInsert(result.map(item => item.id));
      }
    }

    onCancel();
  }

  /**
   * Filters images by search value.
   * @param event - Search input event.
   */
  function onSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setCategory(null);
    setSearch(event.target.value);
  }

  /**
   * Query for getting images from unsplash by pages.
   */
  const [getImages, { data, loading }] = useLazyQuery<unsplashImages>(
    unsplashImagesQuery,
    {
      context: { urlType: linkTypes.builder },
      variables: {
        search: search || category,
        first: 50,
        page: 1
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const hasMorePages = data?.unsplashImages?.paginatorInfo?.hasMorePages;

  /**
   * @param trigger - triggers when need to get new data.
   * @param loading - Returns boolean value for loading state.
   * @param hasMore - Returns a boolean value to know if there are more pages or not.
   * @param global - If global is true, handleScroll function gets position
   * from document.documentElement, otherwise from target element.
   *
   * @returns onScroll callback to get the position of the DOM element and
   * getCurrentPage callback to get current page.
   */
  const { onScroll, getCurrentPage } = useInfiniteScroll({
    trigger: handleScroll,
    loading,
    hasMore: hasMorePages,
    global: false
  });

  const images = data?.unsplashImages?.data || [];

  function handleScroll() {
    getImages({
      context: { urlType: linkTypes.builder },
      variables: { page: getCurrentPage(), search: search || category }
    });
  }

  useEffect(() => {
    if (visible) {
      setPreviewImage(null);
      handleScroll();
    } else {
      setSelectedImages({});
      setSearch(null);
      setCategory(null);
      setPreviewImage(null);
    }
  }, [visible, search, category]);

  function toggleSelect(image: unsplashImages_unsplashImages_data) {
    if (selectedImages[image.id]) {
      const updatedSelected = { ...selectedImages };
      delete updatedSelected[image.id];
      setSelectedImages(updatedSelected);
    } else {
      setSelectedImages((prevSelected: { [id: string]: string }) => {
        return { ...prevSelected, [image.id]: image.regularUrl };
      });
    }
  }

  /**
   * Imports preview image from unsplash api.
   * @param image - Preview image.
   */
  async function onImportImage(image: unsplashImages_unsplashImages_data) {
    await onLoadRemoteMedia(image.regularUrl, true);
    setPreviewImage(null);
  }

  const SelectCategories = () => (
    <Select
      value={category as string}
      placeholder={t('selectPlaceholder')}
      onChange={onSelectChange}
      allowClear
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Dropdown,
        prefix: 'all-categories'
      })}
      className={styles.categoryDropdown}
    >
      {freeStockImageCategories.map(category => (
        <Option
          key={category}
          value={category}
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Option,
            prefix: category
          })}
        >
          {t(category)}
        </Option>
      ))}
    </Select>
  );

  const Component = forMobile ? Mobile : Web;

  return (
    <Component
      search={search}
      images={images}
      visible={visible}
      loading={loading}
      onCancel={onCancel}
      onSearch={onSearch}
      onScroll={onScroll}
      setSearch={setSearch}
      setCategory={setCategory}
      toggleSelect={toggleSelect}
      previewImage={previewImage}
      onImportImage={onImportImage}
      onImportImages={onImportImages}
      getCurrentPage={getCurrentPage}
      selectedImages={selectedImages}
      setPreviewImage={setPreviewImage}
      setSelectedImages={setSelectedImages}
      selectCategories={<SelectCategories />}
      loadRemoteMediaLoading={loadRemoteMediaLoading}
    />
  );
}

export default StockImages;
