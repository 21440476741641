import { forwardRef } from 'react';
import { Input, InputProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import styles from '../styles.module.less';

const Search = forwardRef((props: InputProps, ref?: any) => {
  return (
    <Input
      ref={ref}
      {...props}
      prefix={
        <SearchOutlined
          className={styles.SearchOutlinedIcon}
          style={{ paddingRight: 5 }}
        />
      }
      allowClear
    />
  );
});

export default Search;
