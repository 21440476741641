import { Skeleton, Space } from 'antd';
import { range } from 'lodash';

import { SettingsContentContainer } from 'pages/settings/components/SettingsContentContainer';
import styles from './styles.module.less';

export const SettingsListSkeleton = () => (
  <SettingsContentContainer customized={false}>
    <Space className={styles.settings_list_skeleton} direction={'vertical'}>
      {range(0, 3).map(element => (
        <Skeleton.Input
          key={element}
          active
          size="large"
          className={styles.settings_list_skeleton_item}
        />
      ))}
    </Space>
  </SettingsContentContainer>
);
