export enum FontWightTypes {
  bold = 'bold',
  medium = 'medium',
  regular = 'regular'
}

//this enum use "Heading" and "SubHeading" components
export enum HeadingTypes {
  h1 = 1,
  h2 = 2,
  h3 = 3,
  h4 = 4,
  h5 = 5
}
