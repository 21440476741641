import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressChartItem } from './components/progressChartItem';
import { ProgressStatisticsProps } from '../types';
import Skeleton from 'components/shared/Skeleton';
import Empty from 'components/shared/Empty';
import { UniversalEmptyStateIcon } from 'components/shared/SVG';

const ProgressStatistics: FC<ProgressStatisticsProps> = ({
  dataSource = [],
  countKey = '',
  labelKey = '',
  labelFormatter,
  loading
}) => {
  const { t } = useTranslation('common');

  const ProgressBars = dataSource.map((progressItem: any, index: number) => (
    <ProgressChartItem
      count={progressItem[countKey]}
      label={
        labelFormatter ? labelFormatter(progressItem) : progressItem[labelKey]
      }
      key={index}
    />
  ));

  return (
    <div>
      <Skeleton.List count={4} loading={loading}>
        {dataSource.length ? (
          ProgressBars
        ) : (
          <Empty
            icon={<UniversalEmptyStateIcon />}
            description={t(
              'common:dateRangeEmpty',
              'No data available for selected date range'
            )}
          />
        )}
      </Skeleton.List>
    </div>
  );
};

export default Object.assign(ProgressStatistics, {
  ProgressChartItem
});
