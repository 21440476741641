import React, { ReactElement, useState } from 'react';
import styles from 'packages/Media/styles.module.less';

import OverlayContext, { VisiblePosition } from './OverlayContext';
import BulkSelect from './BulkSelect';
import OverlayOptions from './OverlayOptions';

type Props = {
  onClick?: () => void;
  topRightContent?: ReactElement | null;
  topLeftContent?: ReactElement | null;
  centerContent?: ReactElement | null;
  bottomRightContent?: ReactElement | null;
  bottomRightStyles?: string;
  topRightStyles?: string;
};

function Overlay({
  topRightContent,
  topLeftContent,
  bottomRightContent,
  centerContent,
  onClick,
  bottomRightStyles = '',
  topRightStyles = ''
}: Props) {
  const [visiblePosition, setVisiblePosition] =
    useState<VisiblePosition>('all');

  return (
    <OverlayContext.Provider
      value={{
        visiblePosition,
        setVisiblePosition
      }}
    >
      <div
        style={{ opacity: visiblePosition !== 'all' ? '1' : '0' }}
        className={`${styles.OverlayWrapper} ant-options-wrapper`}
      >
        <div className={styles.ToggleArea} onClick={onClick} />
        <div
          className={styles.OverlayTopLeft}
          style={{
            display: ['all', 'left'].includes(visiblePosition)
              ? 'block'
              : 'none'
          }}
        >
          {topLeftContent}
        </div>

        <div
          style={{
            display: ['all', 'right'].includes(visiblePosition)
              ? 'block'
              : 'none'
          }}
        >
          {centerContent}
        </div>
        <div
          className={`${styles.OverlayTopRight} ${topRightStyles}`}
          style={{
            display: ['all', 'right'].includes(visiblePosition)
              ? 'block'
              : 'none'
          }}
        >
          {topRightContent}
        </div>
        <div
          className={`${styles.OverlayBottomRight} ${bottomRightStyles}`}
          style={{
            display: 'block'
          }}
        >
          {bottomRightContent}
        </div>
      </div>
    </OverlayContext.Provider>
  );
}

Overlay.BulkSelect = BulkSelect;
Overlay.Options = OverlayOptions;

export default Overlay;
