import { MouseEvent, ReactNode } from 'react';

import { BodyTextOnlyStyleProps } from 'components/basic/Typography/BodyText/types';

export enum ScreenType {
  auto = 'auto',
  halfOpen = 'halfOpen',
  fullScreen = 'fullScreen'
}

export enum PopupPaddingTypes {
  full = 'full',
  onlyBody = 'onlyBody',
  onlySides = 'onlySides'
}

export const popupOpenedStyles = {
  halfOpen: 'half-open-popup',
  fullScreenLeft: 'full-screen-popup-left',
  fullScreenRight: 'full-screen-popup-right',
  bodyBlackBackground: 'body-black-background'
};

export interface PopupProps {
  zIndex?: string;
  visible: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
  fullScreen?: boolean;
  forceRender?: boolean;
  saveDisable?: boolean;
  title?: string | ReactNode;
  onRightBarAction?: () => void;
  subtitle?: string | ReactNode;
  cancelValue?: string | ReactNode;
  rightBarValue?: string | ReactNode;
  titleProps?: BodyTextOnlyStyleProps;
  popupScreen?: ScreenType | keyof typeof ScreenType;
  position?: 'bottom' | 'top' | 'left' | 'right' | undefined;
  padding?: PopupPaddingTypes | keyof typeof PopupPaddingTypes;
}
