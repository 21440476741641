import { CoreConfig, KeyValuePair } from 'core/globalTypes';

const coreConfigsToObj = (configs: CoreConfig[]) => {
  const configsObj: KeyValuePair = {};

  for (let index = 0; index < configs.length; index++) {
    const element = configs[index];

    const codeSpl = element.code.split('.');
    const code = codeSpl[codeSpl.length - 1];

    configsObj[code] =
      element.value === '1'
        ? true
        : element.value === '0'
          ? false
          : element.value;
  }

  return configsObj;
};

export default coreConfigsToObj;
