import { FC, ReactNode } from 'react';
import { Input, Modal, Form } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onDeleteProject?: any;
  loading?: boolean;
  modalTitle?: ReactNode;
}

const DeleteProjectModal: FC<Props> = ({
  visible,
  loading,
  onDeleteProject,
  modalTitle,
  ...props
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('projects');

  const resetFormAfterCloseModal = () => {
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      await onDeleteProject(values.password);
      resetFormAfterCloseModal();
    } catch (error: any) {
      //this condition use when we do not want detect ant form error cases
      !error.errorFields &&
        form.setFields([
          {
            name: 'password',
            errors: [error.message]
          }
        ]);
    }
  };

  const onKeyUpEnter = (e: { keyCode: number }) => {
    return !loading && e.keyCode === 13 && handleSubmit();
  };

  return (
    <Modal
      maskClosable={false}
      title={modalTitle}
      closable={false}
      open={visible}
      onOk={handleSubmit}
      confirmLoading={loading}
      afterClose={resetFormAfterCloseModal}
      {...props}
    >
      <Form onKeyUp={onKeyUpEnter} form={form} layout="vertical">
        <Form.Item
          required={true}
          label={t('password')}
          name="password"
          rules={[{ required: true, message: t('passwordRequired') }]}
        >
          <Input.Password placeholder={t('password')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeleteProjectModal;
