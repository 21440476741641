import React, { useRef, MutableRefObject, useState } from 'react';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import AudioControls from './AudioControls';
import VideoControls from './VideoControls';
import { ControlsType } from './types';

dayjs.extend(utc);

type Props = {
  url: string;
  audio?: boolean;
};

function Player({ url, audio }: Props) {
  const ref = useRef() as MutableRefObject<ReactPlayer>;
  const [playing, setPlaying] = useState<boolean | undefined>();
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.2);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0
  });

  const format = duration > 3600 ? 'H:mm:ss' : 'm:ss';
  const controlsProps: ControlsType = {
    muted,
    playing,
    volume: muted ? 0 : volume * 100,
    duration: dayjs.utc(duration * 1000).format(format),
    progress: {
      played: progress.played * 100,
      loaded: progress.loaded * 100,
      playedSeconds: dayjs.utc(progress.playedSeconds * 1000).format(format)
    },
    togglePlay: () => setPlaying(!playing),
    toggleMuted: () => setMuted(!volume || !muted),
    changeVolume: e => {
      const v = parseFloat(e.target.value) / 100;
      setVolume(v);
      setMuted(!v);
    },
    changePlayed: e => {
      setProgress({ ...progress, played: parseFloat(e.target.value) / 100 });
      ref.current.seekTo(parseFloat(e.target.value) / 100);
    }
  };

  return (
    <>
      <ReactPlayer
        ref={ref}
        url={url}
        width={audio ? 'auto' : '100%'}
        height="100%"
        config={{
          file: {
            forceAudio: audio || false
          }
        }}
        playing={playing}
        muted={muted}
        volume={volume}
        progressInterval={100}
        onProgress={setProgress}
        onDuration={setDuration}
        onEnded={() => setPlaying(false)}
      />

      {audio ? (
        <AudioControls {...controlsProps} />
      ) : (
        <VideoControls {...controlsProps} />
      )}
    </>
  );
}

export default Player;
