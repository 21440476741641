import { useEffect, useMemo, useState } from 'react';
import { useSocket } from 'services/socketService';
import { useNavigate } from 'react-router-dom';
import {
  DASHBOARD_SIDEBAR_ROUTES,
  ORDERS_PATHS_BY_PRODUCT_TYPE_GROUP
} from 'core/constants/common';
import { useApolloClient } from '@apollo/client';
import { ProductTypeGroup } from 'generatedHooks/commerce/generated';
import { useProjectContext } from 'providers/ProjectProvider';

import { NotificationType } from 'components/global/Notifications/types';
import {
  INotification,
  NotificationConfigs,
  NotificationSocketEvents,
  NotificationsResponse
} from './types';
import { customerRegistered, orderPlaced } from 'hooks/useNotifications/utils';
import { AUDIO_FILE_PATHS_BY_TYPE } from 'hooks/useNotifications/constants';

const ordersPath = DASHBOARD_SIDEBAR_ROUTES.ORDERS.pathname;
const customersPath = DASHBOARD_SIDEBAR_ROUTES.CUSTOMERS.pathname;

interface UseNotificationsArgs {
  onImportClick?: (notificationItem: INotification) => void;
  onExportClick?: (notificationItem: INotification) => void;
  onOrderClick?: (notificationItem: INotification) => void;
  onCustomerClick?: (notificationItem: INotification) => void;
}

const useNotifications = ({
  onImportClick,
  onExportClick,
  onCustomerClick,
  onOrderClick
}: UseNotificationsArgs) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const apolloClient = useApolloClient();
  const { name: projectName } = useProjectContext();

  const [notificationConfigs, setNotificationConfigs] =
    useState<NotificationConfigs>({
      seen: true
    });

  const [loadings, setLoadings] = useState({
    readAll: false,
    unreadAll: false
  });

  const { on, join, leave, emit } = useSocket(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (on) {
      on('notification', onNewNotification);

      on(
        'notifications',
        ({ notifications, settings }: NotificationsResponse) => {
          if (notifications) {
            setLoadings({
              readAll: false,
              unreadAll: false
            });
          }

          setNotifications(notifications);
          setNotificationConfigs(settings);
        }
      );
    }
  }, [on]);

  const onOrderPlaced = () => orderPlaced(apolloClient);

  const onCustomerRegistered = () => customerRegistered(apolloClient);

  const notificationListenersMapping = useMemo(
    () => ({
      [NotificationType.OrderPlaced]: onOrderPlaced,
      [NotificationType.CustomerRegistered]: onCustomerRegistered,
      [NotificationType.Import]: () => {},
      [NotificationType.Export]: () => {}
    }),
    []
  );

  const onNewNotification = (notification: INotification) => {
    notificationListenersMapping[notification.type]();

    const notificationAudioFilePath =
      AUDIO_FILE_PATHS_BY_TYPE[notification.type];

    const notificationAudio = new Audio(
      `/assets/audio/${notificationAudioFilePath}`
    );

    notificationAudio.play().catch(e => console.error(e));

    setNotifications(prev => [notification, ...prev]);

    setNotificationConfigs({ seen: false });
  };

  useEffect(() => {
    if (join) {
      join('notification');
    }

    return () => {
      if (leave) {
        leave('notification');
      }
    };
  }, [join, leave]);

  const notificationHandler: Record<NotificationType, Function> = useMemo(
    () => ({
      [NotificationType.Import]: (notificationItem: INotification) => {
        onImportClick?.(notificationItem);
      },
      [NotificationType.Export]: (notificationItem: INotification) => {
        onExportClick?.(notificationItem);
      },
      [NotificationType.OrderPlaced]: (notificationItem: INotification) => {
        onOrderClick?.(notificationItem);

        const payload = notificationItem.notification.meta.payload;

        const orderId = payload.id;
        const orderType = payload.itemsType as Exclude<
          ProductTypeGroup,
          ProductTypeGroup.Collection
        >;

        const ordersPathByType =
          ORDERS_PATHS_BY_PRODUCT_TYPE_GROUP[orderType] || ordersPath;

        navigate(`/${projectName}/${ordersPathByType}/details/${orderId}`);
      },
      [NotificationType.CustomerRegistered]: (
        notificationItem: INotification
      ) => {
        onCustomerClick?.(notificationItem);

        const customerId = notificationItem.notification.meta.payload.id;

        navigate(`/${projectName}/${customersPath}/customer/${customerId}`);
      }
    }),
    []
  );

  const markAsSeen = (configs: NotificationConfigs) => {
    if (emit && !configs?.seen) {
      setNotificationConfigs({ seen: true });
      emit(NotificationSocketEvents.Seen);
    }
  };

  const markOneAsRead = (id: string) => {
    setNotifications(prev => {
      return prev.map(note => {
        if (note._id === id) {
          return {
            ...note,
            read: true
          };
        }

        return note;
      });
    });

    if (emit) {
      emit(NotificationSocketEvents.Read, id);
    }
  };

  const markOneAsUnread = (id: string) => {
    setNotifications(prev => {
      return prev.map(note => {
        if (note._id === id) {
          return {
            ...note,
            read: false
          };
        }

        return note;
      });
    });

    if (emit) {
      emit(NotificationSocketEvents.Unread, id);
    }
  };

  const markAllAsRead = () => {
    if (emit) {
      setLoadings(prev => ({
        ...prev,
        readAll: true
      }));
      emit(NotificationSocketEvents.ReadAll);
    }
  };

  const markAllAsUnread = () => {
    if (emit) {
      setLoadings(prev => ({
        ...prev,
        unreadAll: true
      }));
      emit(NotificationSocketEvents.UnreadAll);
    }
  };

  const deleteNotification = (id: string) => {
    if (emit) {
      setNotifications(prev => {
        return prev.filter(notification => notification._id !== id);
      });

      emit(NotificationSocketEvents.Delete, id);
    }
  };

  const toggleRead = (notificationItem: INotification) => {
    if (!notificationItem.read) {
      markOneAsRead(notificationItem._id);
    } else {
      markOneAsUnread(notificationItem._id);
    }
  };

  return {
    notifications,
    markOneAsRead,
    markAsSeen,
    markAllAsRead,
    markOneAsUnread,
    markAllAsUnread,
    deleteNotification,
    notificationHandler,
    notificationConfigs,
    toggleRead,
    loadings
  };
};

export default useNotifications;
