import React, { ChangeEvent } from 'react';

import styles from './styles.module.less';

type Props = {
  children?: React.ReactNode;
  strokeColor: string;
  trailColor: string;
  value?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Progress = ({ color, percent }: { color: string; percent: number }) => (
  <div
    className={styles.Progress}
    style={{ background: color, width: `${percent}%` }}
  />
);

function Slider({ children, strokeColor, trailColor, value, onChange }: Props) {
  return (
    <div className={styles.ProgressBar}>
      {children}
      <div
        className={styles.Progress}
        style={{ background: trailColor, width: '100%' }}
      />
      <div
        className={styles.Progress}
        style={{ background: strokeColor, width: `${value}%` }}
      />
      <input
        type={'range'}
        className={styles.Range}
        style={{}}
        value={value}
        color={strokeColor}
        onChange={onChange}
      />
    </div>
  );
}

Slider.Progress = Progress;

export default Slider;
