import { FC, memo, useCallback, useRef, useState } from 'react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import UploadFromMedia from 'packages/Media/UploadFromMedia';
import { AiApi } from 'axiosApi';
import { Form } from 'antd';
import {
  PRODUCT_DESCRIPTION_GENERATOR_CONTENT_TYPE,
  TAG_REMOVAL_REGEX
} from 'core/constants/common';
import { SystemMessage } from 'helpers';
import { useTranslation } from 'react-i18next';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import { createDataTestAttribute } from 'helpers/automationHelpers';

import {
  fontSizeFormats,
  plugins,
  quickbarsSelectionToolbar
} from './constants';
import { getMediaForRichText, getMediaUrl } from './utils';
import { ShowElement } from 'components/shared';
import AiButton from 'components/shared/AiButton';
import './styles.css';

// DOM model
import 'tinymce/models/dom/model';
// --------------------
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// --------------------

import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import './plugins/wrapListItemContentInParagraphPlugin';

interface Props {
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
  disabled?: boolean;
  height?: number;
  showAiButton?: boolean;
}

const { useWatch } = Form;

const Editor: FC<Props> = ({
  value = '',
  onChange,
  disabled,
  height = 400,
  showAiButton = false
}) => {
  const [visible, setVisible] = useState(false);
  const callbackRef = useRef<null | Function>(null);
  const [mediaType, setMediaType] = useState<'images' | 'videos'>('images');
  const [generating, setGenerating] = useState(false);

  const { t } = useTranslation();

  const name = useWatch('name') || '';

  const handleChange = (editorState: string) => {
    onChange?.(editorState);
  };

  const handleFilePicker = (
    callback: (value: string, meta?: Record<string, any>) => void,
    _: unknown,
    meta: Record<string, any>
  ) => {
    callbackRef.current = callback;

    if (meta.filetype === 'image') {
      setMediaType('images');
    }

    if (meta.filetype === 'media') {
      setMediaType('videos');
    }

    setVisible(true);
  };

  const handleCloseMedia = () => {
    setVisible(false);
    callbackRef.current = null;
  };

  const handleInsert = (ids: string[]) => {
    const { alt, filename } = getMediaForRichText(ids[0], mediaType);
    const url = getMediaUrl(filename, mediaType);

    callbackRef.current?.(url, {
      alt: alt || ''
    });
    handleCloseMedia();
  };

  const generateDescription = useCallback(async () => {
    try {
      setGenerating(true);
      const cleanValue = value.replace(TAG_REMOVAL_REGEX, '');
      const keywords = [cleanValue, name];

      const data = await AiApi.post('/generate-content', {
        keywords,
        content_type: PRODUCT_DESCRIPTION_GENERATOR_CONTENT_TYPE,
        count: 1
      });

      onChange?.(data.data[0]);
    } catch (error) {
      SystemMessage.error(t('somethingWentWrong'));
    } finally {
      setGenerating(false);
    }
  }, [value, name]);

  const isAiButtonDisabled = !(name || value);

  return (
    <div
      className={`braftEditorContainer`}
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.TextArea,
        prefix: 'description'
      })}
      data-testid="text-editor"
    >
      <TinyEditor
        onEditorChange={handleChange}
        value={value}
        disabled={disabled}
        init={{
          skin: false,
          content_css: false,
          branding: false,
          autosave_ask_before_unload: false,
          plugins: [...plugins, 'wrapListItemContentInParagraphPlugin'],
          menubar: 'file edit view insert format tools table help',
          toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | image media code link codesample | ltr rtl',
          toolbar_sticky: true,
          image_advtab: true,
          image_title: true,
          link_rel_list: [
            {
              title: 'Follow',
              value: ''
            },
            {
              title: 'No Follow',
              value: 'nofollow'
            }
          ],
          font_size_formats: fontSizeFormats,
          importcss_append: true,
          file_picker_callback: handleFilePicker,
          height,
          image_caption: true,
          quickbars_selection_toolbar: quickbarsSelectionToolbar,
          toolbar_mode: 'sliding',
          // skin: 'oxide',
          content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background:#f5f5f5; }`
        }}
      />
      <ShowElement isShow={visible}>
        <UploadFromMedia
          visible={visible}
          types={[mediaType]}
          onCancel={handleCloseMedia}
          initialIds={[]}
          onInsert={handleInsert}
        />
      </ShowElement>
      <ShowElement isShow={showAiButton}>
        <AiButton
          disabled={isAiButtonDisabled}
          loading={generating}
          generateDescription={generateDescription}
        />
      </ShowElement>
    </div>
  );
};

export default memo(Editor);
