import { Skeleton } from 'components/shared';
import styles from './styles.module.less';

export const SettingsTablesLazySkeleton = () => {
  return (
    <div className={styles.settings_skeleton_table}>
      <Skeleton.PageContainer />
    </div>
  );
};
