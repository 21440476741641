import { FC } from 'react';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  placeholder: string;
  onChange?: () => void;
}

const Password: FC<Props> = ({ placeholder, onChange }) => {
  return (
    <div className={styles.password_input_container}>
      <Input.Password
        onChange={onChange}
        placeholder={placeholder}
        style={{ boxShadow: 'none' }}
        className={styles.amd_mobile_password}
        iconRender={visible => {
          const Icon = visible ? EyeOutlined : EyeInvisibleOutlined;

          return <Icon style={{ color: 'black' }} />;
        }}
      />
    </div>
  );
};

export default Password;
