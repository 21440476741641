import React from 'react';
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  PlayCircleFilled
} from '@ant-design/icons';

import styles from './styles.module.less';
import SoundOffIcon from './SoundOff';
import SoundOnIcon from './SoundOn';
import Slider from './Slider';
import { ControlsType } from './types';

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   padding: 15px 25px;
//   position: absolute;
//   bottom: 0;
// `;
//
// const ControlsBackFace = styled.div`
//   width: 100%;
//   height: 136px;
//   position: absolute;
//   bottom: 0;
//   border-radius: 0 0 var(--ant-border-radius-base) var(--ant-border-radius-base);
//   background: linear-gradient(
//     to bottom,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(0, 0, 0, 0.45)
//   );
// `;
//
// const Volume = styled.div`
//   width: 75px;
// `;
//
// const VolumeIcon = styled.div`
//   color: #fff;
//   font-size: var(--ant-font-size-base);
//   line-height: var(--ant-font-size-base);
//   margin: 0 17px 0 25px;
//   cursor: pointer;
// `;
//
// const LightPlayIcon = styled(PlayCircleFilled)`
//   &&& {
//     position: absolute;
//     left: 50%;
//     color: rgba(255, 255, 255, 0.85);
//     font-size: 50px;
//     transform: translateX(-50%);
//     cursor: pointer;
//   }
// `;
//
// const PlayPauseIcons = styled.div`
//   color: rgb(255, 255, 255);
//   font-size: 21px;
//   line-height: 21px;
//   cursor: pointer;
// `;
//
// const Loading = styled.div`
//   flex-grow: 1;
//   margin-left: 26px;
// `;
//
// const Time = styled.div`
//   color: rgba(255, 255, 255, 0.65);
//   margin-left: 16px;
//
//   span:first-child {
//     color: rgba(255, 255, 255, 0.85);
//   }
// `;

function VideoControls({
  muted,
  togglePlay,
  toggleMuted,
  playing,
  duration,
  volume,
  progress,
  changePlayed,
  changeVolume
}: ControlsType) {
  if (playing === undefined) {
    return (
      <PlayCircleFilled
        className={styles.VideoLightPlayIcon}
        onClick={togglePlay}
      />
    );
  }

  return (
    <>
      <div className={styles.VideoControlsBackFace} />
      <div className={styles.VideoWrapper}>
        <div className={styles.VideoPlayPauseIcons} onClick={togglePlay}>
          {playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
        </div>
        <div className={styles.VideoLoading}>
          <Slider
            trailColor="rgba(255, 255, 255, 0.3)"
            strokeColor="#fff"
            value={progress.played}
            onChange={changePlayed}
          >
            <Slider.Progress
              percent={progress.loaded}
              color="rgba(255, 255, 255, 0.45)"
            />
          </Slider>
        </div>
        <div className={styles.VideoTime}>
          <span>{progress.playedSeconds}</span> / <span>{duration}</span>
        </div>
        <div className={styles.VideoVolumeIcon} onClick={toggleMuted}>
          {muted ? <SoundOffIcon /> : <SoundOnIcon />}
        </div>
        <div className={styles.VideoVolume}>
          <Slider
            value={volume}
            trailColor="rgba(255, 255, 255, 0.2)"
            strokeColor="#fff"
            onChange={changeVolume}
          />
        </div>
      </div>
    </>
  );
}

export default VideoControls;
