import React from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';

import styles from './styles.module.less';

type UCButtonShape = 'default' | 'circle' | 'round';

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset'
}

export type bgColor = 'black' | undefined;

interface ButtonProps {
  loading?: boolean;
  type?: ButtonType;
  htmlType?: ButtonTypes;
  block?: boolean;
  handleClick?: Function;
  className?: string;
  childClassName?: string;
  bgColor?: bgColor;
  disabled?: boolean;
  ghost?: boolean;
  danger?: boolean;
  shape?: UCButtonShape;
  icon?: React.ReactNode | undefined;
  rightIcon?: React.ReactNode | undefined;
  greyStyle?: boolean;
  children?: React.ReactNode;
}

const UCButton: React.FC<ButtonProps> = ({
  type = 'primary',
  htmlType = ButtonTypes.button,
  block,
  handleClick,
  className = '',
  childClassName = '',
  bgColor,
  disabled = false,
  ghost = false,
  shape = 'default',
  loading,
  danger,
  icon,
  rightIcon,
  greyStyle,
  children,
  ...rest
}) => {
  const handleButtonClick = (event: React.MouseEvent): void => {
    if (!disabled && handleClick) {
      handleClick(event);
    }
  };

  return (
    <Button
      loading={loading}
      type={type}
      htmlType={htmlType}
      block={block}
      onClick={handleButtonClick}
      className={`${styles.ucButton} ${className} ${
        greyStyle && styles.greyStyle
      } ${bgColor ? styles[bgColor] : ''}`}
      disabled={disabled}
      ghost={ghost}
      shape={shape}
      danger={danger}
      icon={icon}
      {...rest}
    >
      {childClassName ? (
        <span className={childClassName} onClick={handleButtonClick}>
          {children}
        </span>
      ) : (
        children
      )}
      {rightIcon}
    </Button>
  );
};

export default UCButton;
