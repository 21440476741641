import {
  Actions,
  LimitationsTypeEnums
} from 'providers/PermissionAndLimitationProvider';
import { ProductTypeGroup } from 'generatedHooks/commerce/generated';

const { Set, Create } = Actions;

//we duplicate this properies because have a two propery for payments
export const PAYMENT_METHOD_PERMISSIONS = {
  cash_on_delivery: Set.CashOnDelivery,
  cashondelivery: Set.CashOnDelivery,
  pos_on_delivery: Set.PosOnDelivery,
  money_transfer: Set.MoneyTransfer,
  paypal_rest: Set.PayPal,
  ameria: Set.AmeriavPos,
  ardshin: Set.Ardshinbank,
  evoca: Set.Evocabank,
  acba: Set.Acbabank,
  brain_tree: Set.Braintree,
  stripe: Set.UcraftPay,
  cashu: Set.CashU,
  mollie_payment: Set.Mollie,
  revolut: Set.Revolut,
  square: Set.Square,
  pay_tabs: Set.Paytabs,
  posondelivery: Set.PosOnDelivery,
  moneytransfer: Set.MoneyTransfer,
  braintree: Set.Braintree,
  stripe_connect: Set.UcraftPay,
  mollie: Set.Mollie,
  fastshift: Set.FastShift,
  fastpay: Set.FastPay,
  idram: Set.Idram,
  telr: Set.Telr,
  easy_pay: Set.EasyPay
};

export const PRODUCT_TYPE_CHOOSER = {
  [ProductTypeGroup.Physical]: Create.PhysicalProduct,
  [ProductTypeGroup.Digital]: Create.DigitalProduct,
  [ProductTypeGroup.BookingAppointment]: Create.AppointmentProduct,
  [ProductTypeGroup.BookingReservation]: Create.BookingProduct,
  [ProductTypeGroup.BookingEvent]: Create.EventProduct,
  [ProductTypeGroup.BookingTable]: Create.TableProduct,
  [ProductTypeGroup.BookingRental]: Create.RentalProduct,
  [ProductTypeGroup.GiftCard]: Create.GiftCardProduct
};

export const LIMITATIONS_CONSTANTS: {
  [key in Exclude<
    ProductTypeGroup,
    ProductTypeGroup.Collection | ProductTypeGroup.Subscription
  >]: LimitationsTypeEnums;
} = {
  PHYSICAL: LimitationsTypeEnums.PHYSICAL_PRODUCT,
  DIGITAL: LimitationsTypeEnums.DIGITAL_PRODUCT,
  BOOKING_APPOINTMENT: LimitationsTypeEnums.APPOINTMENT_PRODUCT,
  BOOKING_RESERVATION: LimitationsTypeEnums.BOOKING_PRODUCT,
  BOOKING_TABLE: LimitationsTypeEnums.TABLE_PRODUCT,
  BOOKING_RENTAL: LimitationsTypeEnums.RENTAL_PRODUCT,
  BOOKING_EVENT: LimitationsTypeEnums.EVENT_PRODUCT,
  GIFT_CARD: LimitationsTypeEnums.GIFT_CARD_PRODUCT
};
