import * as React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import { MenuOutlined, AppstoreOutlined } from '@ant-design/icons';

export enum SwitchValues {
  LIST,
  GRID
}

const { Group, Button } = Radio;

type Props = {
  value: SwitchValues;
  onChange: (event: RadioChangeEvent) => void;
};

const viewButtons: Array<{
  value: SwitchValues;
  content: React.ReactNode;
}> = [
  { value: SwitchValues.LIST, content: <MenuOutlined /> },
  { value: SwitchValues.GRID, content: <AppstoreOutlined /> }
];

function ModeSwitcher({ value, onChange }: Props) {
  return (
    <Group value={value} size="middle" onChange={onChange}>
      {viewButtons.map((button, index) => (
        <Button
          data-test={`mode-switcher-${button.value}`}
          style={{ padding: '0 8px' }}
          value={button.value}
          key={index}
        >
          {button.content}
        </Button>
      ))}
    </Group>
  );
}

export default ModeSwitcher;
