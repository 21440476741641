import { ChangeEvent, FC } from 'react';
import { KeyValuePair } from 'core/globalTypes';
import { InputProps } from 'antd';

import { Input } from 'components/basic';

export interface TranslatableBaseInputProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  locale?: string;
  value?: KeyValuePair<string>;
  onChange?: (v: KeyValuePair<string>) => void;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TranslatableInput: FC<TranslatableBaseInputProps> = ({
  locale = '',
  onChange,
  value = {},
  onInputChange,
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.({ ...value, [locale]: e.target.value });
    onInputChange?.(e);
  };

  return <Input onChange={handleChange} value={value[locale]} {...rest} />;
};

// const TranslatableInput = Object.assign(Input, TranslatableBaseInput);

// export default TranslatableInput;

export default TranslatableInput;
