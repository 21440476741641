import amex from 'assets/images/amex.png';
import visa from 'assets/images/visa.png';
import credit_card from 'assets/images/credit_card.png';
import master_card from 'assets/images/master_card.png';
import diners_club from 'assets/images/diners_club.png';
import discover from 'assets/images/discover.png';
import paypal_account from 'assets/images/paypal_account.png';
import { SubscriptionStatus } from 'generatedHooks/accounts/generated';
import { PresetStatusColorType } from 'antd/lib/_util/colors';

export const YEAR = 'y';
export const MONTH = 'm';

export const defaultPlanDurationType = 'y';
export const yearlyPlan = 12;
export const monthlyPlan = 1;
export const BANK_CARDS_IMAGES = {
  amex,
  visa,
  credit_card,
  master_card,
  diners_club,
  discover,
  paypal_account
};

export const SUBSCRIPTION_STATUS_PROPERTIES: Record<
  SubscriptionStatus,
  Record<'key' | 'defaultValue', string> &
    Record<'status', PresetStatusColorType>
> = {
  [SubscriptionStatus.None]: {
    key: `billing:${SubscriptionStatus.None}`,
    defaultValue: 'None',
    status: 'processing'
  },
  [SubscriptionStatus.Active]: {
    key: `billing:${SubscriptionStatus.Active}`,
    defaultValue: 'Active',
    status: 'success'
  },
  [SubscriptionStatus.Expired]: {
    key: `billing:${SubscriptionStatus.Expired}`,
    defaultValue: 'Expired',
    status: 'error'
  },
  [SubscriptionStatus.Cancelled]: {
    key: `billing:${SubscriptionStatus.Cancelled}`,
    defaultValue: 'Cancelled',
    status: 'error'
  },
  [SubscriptionStatus.TrialActive]: {
    key: `billing:${SubscriptionStatus.TrialActive}`,
    defaultValue: 'Trial Active',
    status: 'warning'
  },
  [SubscriptionStatus.TrialExpired]: {
    key: `billing:${SubscriptionStatus.TrialExpired}`,
    defaultValue: 'Trial Expired',
    status: 'error'
  },
  [SubscriptionStatus.Pending]: {
    key: `billing:${SubscriptionStatus.Pending}`,
    defaultValue: 'Pending',
    status: 'warning'
  }
};
