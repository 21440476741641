import { KeyValuePair, PureNullable } from 'core/globalTypes';
import { FC } from 'react';
import { MediaFile } from 'generatedHooks/commerce/generated';

import { UcUpload } from '../index';

interface Props<T = KeyValuePair<PureNullable<MediaFile>>> {
  value?: T;
  onChange?: (value: T) => void;
  locale?: string;
  dataTest?: string;
}

const PerLocalUpload: FC<Props> = ({
  value = {},
  onChange,
  locale = '',
  dataTest
}) => {
  const currentValue = value[locale];

  const handleChange = (mediaFile: PureNullable<MediaFile | MediaFile[]>) => {
    onChange?.({
      ...value,
      [locale]: mediaFile as PureNullable<MediaFile>
    });
  };

  return (
    <UcUpload
      dataTest={dataTest}
      onChange={handleChange}
      value={currentValue}
    />
  );
};

export default PerLocalUpload;
