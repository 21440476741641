import { FC } from 'react';

import { Typography, Radio } from 'components/basic';
import styles from './styles.module.less';
import { TypeChooserSkeleton } from 'components/shared/TypeChooserRadioButton/components';
import { TypeChooserListProps } from 'components/shared/TypeChooserRadioButton/types';

const { UcHeading } = Typography;

const TypeChooserList: FC<TypeChooserListProps> = ({
  dataSource,
  heading,
  loading,
  renderItem,
  maxHeight,
  onChange
}) => {
  const isHeadingString = typeof heading === 'string';

  return (
    <div
      className={`${styles.wrapper} ${heading ? styles.with_heading : ''}`}
      style={maxHeight ? { maxHeight } : {}}
    >
      {isHeadingString ? (
        <div className={styles.heading}>
          <UcHeading level={3} defaultMargin={false}>
            {heading}
          </UcHeading>
        </div>
      ) : (
        <div className={styles.heading}>{heading}</div>
      )}
      <div className={styles.list_wrapper}>
        <Radio.Group className={styles.radio_group} onChange={onChange}>
          {!loading ? dataSource?.map(renderItem) : <TypeChooserSkeleton />}
        </Radio.Group>
      </div>
    </div>
  );
};

export default TypeChooserList;
