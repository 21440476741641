import { FC } from 'react';

import styles from './styles.module.less';
import { TappableProps } from './types';
import { Typography } from 'components/basic';
import Item from './Item';
import { ShowElement } from 'components/shared';

const { UcBodyText } = Typography;

const Tappable: FC<TappableProps> = ({
  title,
  titleProps = { size: 'base', fontWeight: 'medium' },
  children
}) => {
  return (
    <div className={styles.tappable_list_group}>
      <ShowElement isShow={Boolean(title)}>
        <div className={styles.tappable_title_container}>
          <UcBodyText display="block" {...titleProps}>
            {title}
          </UcBodyText>
        </div>
      </ShowElement>

      <div className={styles.tappable_children_container}>{children}</div>
    </div>
  );
};

const ListTappable = Object.assign(Tappable, {
  Item
});

export default ListTappable;
