import { Steps, StepProps } from 'antd';

const { Step } = Steps;

const BaseStep = (props: StepProps) => {
  return <Step {...props} />;
};

const UcStep = Object.assign(BaseStep, Step);

export default UcStep;
