import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Select } from 'components/basic';
import SelectTagItems from '../SelectTagItems';
import { MultiSelectFilterProps } from './types';
import { filterSelectedTags } from './helpers';
import { ShowElement } from 'components/shared';

const MultiSelectFilter: FC<MultiSelectFilterProps> = ({
  name,
  value,
  maxTagCount,
  loading,
  placeholder,
  data = [],
  dropdownStyle,
  labelKey,
  onChange,
  valueKey,
  notFoundContent,
  onSearch,
  optionLabelProp,
  withoutTags = false,
  getPopupContainer,
  ...restProps
}) => {
  const { t } = useTranslation(['products']);
  const key = valueKey ? valueKey : 'id';
  const label = labelKey ? labelKey : 'name';

  const handleRemoveItem = (id: string) => {
    let copyValuesData = [...value];
    let copySelectData = [...data];
    copyValuesData = copyValuesData.filter(item => item !== id);
    copySelectData = copySelectData.filter(item =>
      copyValuesData.includes(item.id)
    );
    onChange({ ids: copyValuesData, name, data: copySelectData });
  };

  return (
    <>
      <Select
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Dropdown,
          prefix: name
        })}
        getPopupContainer={getPopupContainer}
        optionLabelProp={optionLabelProp}
        onSearch={onSearch}
        optionFilterProp="label"
        notFoundContent={notFoundContent}
        value={value}
        maxTagCount={maxTagCount}
        style={{ width: '100%' }}
        loading={loading}
        mode="multiple"
        showArrow
        dropdownStyle={dropdownStyle}
        filterOption={(input, option) => {
          return (
            option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        maxTagPlaceholder={t(
          `products:filters.selectedItems${value.length === 1 ? '' : 'Plural'}`,
          {
            count: value.length || 0
          }
        )}
        placeholder={placeholder}
        onChange={(ids, data) => {
          onChange({ ids, name, data });
        }}
        options={data?.map(datum => ({
          label: datum[label],
          value: datum[key],
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Option,
            prefix: datum[label]
          })
        }))}
        {...restProps}
      ></Select>
      <ShowElement isShow={!withoutTags}>
        <SelectTagItems
          valueKey={key}
          labelKey={label}
          data={filterSelectedTags(data, value, key)}
          onChange={index => handleRemoveItem(index)}
        />
      </ShowElement>
    </>
  );
};

export default MultiSelectFilter;
