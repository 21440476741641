import { ReactNode } from 'react';
import { ButtonProps as AntButtonProps } from 'antd';

export enum TextSizeTypes {
  small = 'small',
  base = 'base'
}

export enum IconPositionTypes {
  left = 'left',
  right = 'right'
}

export enum ButtonColor {
  red = 'red',
  blue = 'blue',
  gray = 'gray',
  black = 'black',
  green = 'green',
  orange = 'orange',
  transparent = 'transparent'
}

export enum ButtonSizeTypes {
  large = 'large',
  small = 'small',
  medium = 'medium',
  largeFullWidth = 'largeFullWidth'
}

export interface ButtonProps extends AntButtonProps {
  text?: string;
  icon?: ReactNode;
  outlet?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  color?: ButtonColor | keyof typeof ButtonColor;
  buttonSize?: ButtonSizeTypes | keyof typeof ButtonSizeTypes;
  iconPosition?: IconPositionTypes | keyof typeof IconPositionTypes;
}
