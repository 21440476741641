import { Row, Col } from 'antd';
import { FC } from 'react';

import { SectionWrapperChartHeaderProps } from 'components/shared/SectionWrapperChart/types';
import { Typography } from 'components/basic';
import styles from './styles.module.less';
import ShowElement from 'components/shared/ShowElement';

const { UcSubHeading } = Typography;

const SectionWrapperChartHeader: FC<SectionWrapperChartHeaderProps> = ({
  title,
  navigationElement
}) => {
  const hasNavigation = Boolean(navigationElement);

  return (
    <Row className={styles.section_title_container} align="middle" wrap={false}>
      <Col flex={1}>
        <UcSubHeading level={5} fontWeight="bold" defaultMargin={false}>
          {title}
        </UcSubHeading>
      </Col>
      <ShowElement isShow={hasNavigation}>
        <Col className={styles.section_title_container_navigation_side}>
          {navigationElement}
        </Col>
      </ShowElement>
    </Row>
  );
};

export default SectionWrapperChartHeader;
