import { createContext } from 'react';

export type VisiblePosition = 'all' | 'left' | 'right' | 'center';

type VisibleContextType = {
  visiblePosition: VisiblePosition;
  setVisiblePosition: (position: VisiblePosition) => void;
};

const OverlayContext = createContext<VisibleContextType>({
  visiblePosition: 'all',
  setVisiblePosition: () => undefined
});

export default OverlayContext;
