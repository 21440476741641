import { Flex } from 'antd';
import { FC, MouseEventHandler, ReactNode } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { Button } from 'components/basic';

interface HeaderButtonProps {
  icon: ReactNode;
  dataTestPrefix: string;
  className?: string;
  ButtonWrapper?: FC<{ children: ReactNode }>;
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

const HeaderButton: FC<HeaderButtonProps> = ({
  icon,
  dataTestPrefix,
  ButtonWrapper = ({ children }) => <>{children}</>,
  className,
  onClick,
  ...forwardedProps
}) => {
  return (
    <Flex
      {...forwardedProps}
      align="center"
      justify="center"
      className={`${styles.header_button_wrapper} ${className}`}
    >
      <ButtonWrapper>
        <Button
          onClick={onClick}
          icon={icon}
          className={styles.header_button}
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Icon,
            prefix: dataTestPrefix
          })}
        />
      </ButtonWrapper>
    </Flex>
  );
};

export default HeaderButton;
