import { ReactNode } from 'react';

type State = {
  element: ReactNode;
};

const initialState: State = {
  element: null
};

export default initialState;
