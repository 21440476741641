import { ExportOutlined, ImportOutlined } from '@ant-design/icons';
import { TFunction } from 'react-i18next';
import { ProductTypeGroup } from 'generatedHooks/commerce/generated';

import {
  UserIcon,
  OrdersIcon,
  EventsIcon,
  BookingsIcon
} from 'components/shared/SVG';
import {
  NotificationItemProps,
  NotificationSubType,
  NotificationType
} from './types';
import { ENTITY_PLURALS } from 'components/global/Notifications/constants';
import { Typography } from 'components/basic';
import {
  INotificationMeta,
  INotificationPayload
} from 'hooks/useNotifications/types';

const { UcLink } = Typography;

export const getNotificationItemByType = (
  t: TFunction,
  { scope, payload }: INotificationMeta
): Record<NotificationType | NotificationSubType, NotificationItemProps> => {
  const entity = scope?.split('.')[0] || 'product';

  return {
    [NotificationType.Import]: {
      icon: ImportOutlined,
      transComponentProps: {
        i18nkey: 'messages:import',
        defaultValue: 'Your data has been successfully imported.'
      }
    },
    [NotificationType.Export]: {
      icon: ExportOutlined,
      transComponentProps: {
        i18nkey: 'messages:export',
        values: {
          entities: t(
            `messages:${ENTITY_PLURALS[entity]}`,
            ENTITY_PLURALS[entity]
          )
        },
        defaultValue:
          'Your {{entities}} have been successfully exported. You can also download your exported {{entities}} <download>here</download>',
        components: {
          download: (
            <UcLink
              download={payload.filename}
              href={`${import.meta.env.VITE_APP_SSO_URL}/exported-docs/${
                payload.projectId
              }/${payload.filename}`}
              target="_blank"
              rel="noreferrer"
              type="primary"
              style={{ display: 'inline' }}
            />
          )
        }
      }
    },
    [NotificationType.OrderPlaced]: {
      icon: OrdersIcon,
      transComponentProps: {
        i18nkey: 'messages:orderPlaced',
        defaultValue: 'You have a new order.'
      }
    },
    [NotificationType.CustomerRegistered]: {
      icon: UserIcon,
      transComponentProps: {
        i18nkey: 'messages:customerRegistered',
        defaultValue: 'You have a new registration.'
      }
    },
    [NotificationSubType.EventOrderPlaced]: {
      icon: EventsIcon,
      transComponentProps: {
        i18nkey: 'messages:eventOrderPlaced',
        defaultValue: 'You have new ticket purchase.'
      }
    },
    [NotificationSubType.BookingOrderPlaced]: {
      icon: BookingsIcon,
      transComponentProps: {
        i18nkey: 'messages:bookingOrderPlaced',
        defaultValue: 'You have a new booking.'
      }
    }
  };
};

export const getNotificationSubType = (
  type: NotificationType,
  payload: INotificationPayload
) => {
  if (payload.itemsType === ProductTypeGroup.BookingEvent) {
    return NotificationSubType.EventOrderPlaced;
  }

  if (payload.itemsType === ProductTypeGroup.BookingReservation) {
    return NotificationSubType.BookingOrderPlaced;
  }

  return type;
};
