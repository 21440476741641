import Table from './Table';
import Grid from './Grid';

function Skeleton() {
  return null;
}

Skeleton.Table = Table;
Skeleton.Grid = Grid;

export default Skeleton;
