import {
  mediaWithFolders_mediaWithFolders_data_Image,
  mediaWithFolders_mediaWithFolders_data_Video,
  mediaWithFolders_mediaWithFolders_data_Audio,
  mediaWithFolders_mediaWithFolders_data_Document
} from './__generated__/types';

export type PageType = 'images' | 'audios' | 'videos' | 'docs' | 'vectors';

export enum MediaModal {
  stock,
  link,
  setting,
  preview,
  move
}

export type MediaWithoutFolder =
  | mediaWithFolders_mediaWithFolders_data_Image
  | mediaWithFolders_mediaWithFolders_data_Video
  | mediaWithFolders_mediaWithFolders_data_Audio
  | mediaWithFolders_mediaWithFolders_data_Document;
