import { OperatingSystems } from 'core/globalTypes';

const hasOS = (OS: OperatingSystems) => {
  const agent = window.navigator.userAgent.toUpperCase();

  return agent.search(OS) !== -1;
};

const detectOS = (): OperatingSystems => {
  let os: OperatingSystems;

  if (hasOS(OperatingSystems.MAC)) {
    os = OperatingSystems.MAC;
  } else if (hasOS(OperatingSystems.LINUX)) {
    os = OperatingSystems.LINUX;
  } else if (hasOS(OperatingSystems.WINDOWS)) {
    os = OperatingSystems.WINDOWS;
  } else {
    os = OperatingSystems.OTHER;
  }

  return os;
};

export default detectOS;
