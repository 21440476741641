/**
 * It takes an array of objects, and returns an array of objects, where each object is a flattened version of the original
 * object
 * @param data - Array<any>
 * @returns An array of objects with the children property removed.
 */
export const flatten = (data: Array<any>) => {
  return data.reduce((r, { children, ...rest }) => {
    r.push(rest);

    if (children) {
      r.push(...flatten(children));
    }

    return r;
  }, []);
};
