import { Avatar, Space } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { RelatedUser } from 'generatedHooks/accounts/generated';

type Props = {
  members: Array<RelatedUser>;
  size?: AvatarProps['size'];
};

const maxCount = 4;

function Members({ members, size }: Props) {
  return (
    <Space size={2}>
      <Avatar.Group
        maxStyle={{ color: '#595959', backgroundColor: '#F5F5F5' }}
        size={size}
        maxCount={2}
      >
        {members
          .slice(0, maxCount)
          .filter(m => !!m)
          .map((member, index) => (
            <Avatar src={member?.avatarUrl} key={index}>
              {member?.firstName
                ?.split(' ')
                ?.map((i: string) => i[0])
                ?.join('')
                .toUpperCase()}
            </Avatar>
          ))}
      </Avatar.Group>
    </Space>
  );
}

export default Members;
