import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterQuery, useHistory, useScreenBreakpoints } from 'hooks';
import { SortTypes } from 'core/globalTypes';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Select } from 'components/basic';
import { SortValues } from './constants';
import { SortByProps } from './types';
import { ShowElement } from 'components/shared';

const SortBy: FC<SortByProps> = ({ defaultSortData, width = 'auto' }) => {
  const { queryObject } = useFilterQuery();
  const { push } = useHistory();
  const screens = useScreenBreakpoints();
  const { t } = useTranslation('common');
  const value = queryObject?.sort || SortTypes.Newest;

  const sortData = defaultSortData ? defaultSortData : SortValues(t);

  const handleDropDownChange = (sort: string) => {
    push({ sort });
  };

  return (
    <ShowElement isShow={screens.sm}>
      <Select
        value={value}
        onChange={handleDropDownChange}
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Dropdown,
          prefix: 'sort-by'
        })}
        style={{ width }}
      >
        {sortData.map(item => {
          return (
            <Select.Option
              data-test={createDataTestAttribute({
                dataTestAttribute: DataTestAttributes.Option,
                prefix: item.value
              })}
              key={item.value}
              value={item.value}
            >
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </ShowElement>
  );
};

export default SortBy;
