import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import { HTMLAttributes } from 'react';

const StarIcon = (props: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Icon,
        prefix: 'star'
      })}
      {...props}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1887 5.51643L10.2215 4.93987L8.4481 1.34456C8.39966 1.24612 8.31998 1.16643 8.22154 1.118C7.97466 0.996122 7.67466 1.09768 7.55123 1.34456L5.77779 4.93987L1.8106 5.51643C1.70123 5.53206 1.60123 5.58362 1.52466 5.66175C1.4321 5.75688 1.3811 5.88487 1.38286 6.01759C1.38461 6.15032 1.43899 6.27691 1.53404 6.36956L4.40435 9.168L3.72623 13.1196C3.71032 13.2115 3.7205 13.306 3.75559 13.3925C3.79068 13.4789 3.84929 13.5538 3.92477 13.6086C4.00025 13.6634 4.08958 13.696 4.18263 13.7026C4.27568 13.7092 4.36873 13.6897 4.45123 13.6461L7.99966 11.7805L11.5481 13.6461C11.645 13.6977 11.7575 13.7149 11.8653 13.6961C12.1372 13.6492 12.32 13.3914 12.2731 13.1196L11.595 9.168L14.4653 6.36956C14.5434 6.293 14.595 6.193 14.6106 6.08362C14.6528 5.81018 14.4622 5.55706 14.1887 5.51643Z"
          fill="white"
          stroke="#D9D9D9"
        />
      </svg>
    </span>
  );
};

export default StarIcon;
