import { ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  color?: string;
  children: ReactNode;
  backgroundColor?: string;
}

const CustomTag = ({ color, backgroundColor, children, ...res }: Props) => {
  return (
    <div {...res} className={styles.tag} style={{ backgroundColor }}>
      <span className={styles.activity} style={{ backgroundColor: color }} />
      {children}
    </div>
  );
};

export default CustomTag;
