import { FC, Dispatch, SetStateAction, useMemo } from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ImportableEntity } from 'components/global/ImportModal/types';
import { getImportSteps } from 'components/global/ImportModal/utils';
import styles from './styles.module.less';

const { Step } = Steps;

interface Props {
  entityType: ImportableEntity;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isStepDisabled: (index: number) => boolean;
}

const ImportProgress: FC<Props> = ({
  entityType,
  currentStep,
  setCurrentStep,
  isStepDisabled
}) => {
  const { t } = useTranslation('importModal');

  const steps = useMemo(() => getImportSteps(entityType), [entityType]);

  return (
    <Steps
      onChange={c => setCurrentStep(c)}
      current={currentStep}
      className={styles.steps}
      progressDot
    >
      {steps.map((step, index) => (
        <Step
          className={styles.step}
          title={<span title={t(step)}>{t(step)}</span>}
          key={step}
          disabled={isStepDisabled(index)}
        />
      ))}
    </Steps>
  );
};

export default ImportProgress;
