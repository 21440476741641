import { createContext, ReactNode, useEffect, useState } from 'react';
import { KeyValuePair } from 'core/globalTypes';
import { isEqual } from 'lodash';

import { HOORY_BASE_URL, HOORY_EMBED } from '../core/constants/common';

const { VITE_APP_HOORY_WEBSITE_TOKEN, VITE_APP_HOORY_WEBSITE_TOKEN_FREE } =
  import.meta.env;

interface Props {
  children: ReactNode;
}

export const HooryContext = createContext<KeyValuePair>({
  hoory: {}
});

export enum HOORY_SUPPORT_TYPES {
  HUMAN = 'HUMAN',
  AI = 'AI'
}

const HooryProvider = ({ children }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [supportType, setSupportType] = useState<HOORY_SUPPORT_TYPES>(
    HOORY_SUPPORT_TYPES.AI
  );

  useEffect(() => {
    const token = isEqual(supportType, HOORY_SUPPORT_TYPES.HUMAN)
      ? VITE_APP_HOORY_WEBSITE_TOKEN
      : VITE_APP_HOORY_WEBSITE_TOKEN_FREE;

    if (isReady) {
      window?.hoorySDK?.reRun({
        websiteToken: token,
        baseUrl: HOORY_BASE_URL
      });
      window?.$hoory?.toggleBubbleVisibility('hide');
    } else {
      const hooryScript = document.createElement('script');
      hooryScript.innerHTML = HOORY_EMBED(token);
      hooryScript.setAttribute('id', 'hoory-script');
      document.body.appendChild(hooryScript);
      window.addEventListener('hoory:ready', () => {
        window?.$hoory?.toggleBubbleVisibility('hide');
        setIsReady(true);
      });
    }
  }, [supportType]);

  return (
    <HooryContext.Provider
      value={{
        hoory: {
          setUserInfo: window?.$hoory?.setUser,
          openChat: () => window?.$hoory?.toggle('open'),
          isChatInitialized: isReady,
          hideButton: true,
          setSupportType
        }
      }}
    >
      {children}
    </HooryContext.Provider>
  );
};

export default HooryProvider;
