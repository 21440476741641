function getFileExtension(filename: string) {
  const ext = /^.+\.([^.]+)$/.exec(filename);

  return ext == null ? '' : ext[1];
}

export default function validateFileExtension(
  name: string,
  extensions: string
) {
  const fileExtension = getFileExtension(name).toLowerCase();
  const extensionsList = extensions.replace(/\./g, '').split(',');

  return extensionsList.includes(fileExtension);
}
