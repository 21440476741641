import { FC } from 'react';
import { linkTypes } from 'apollo/link';
import { Form, Typography, Space } from 'antd';
import { KeyValuePair } from 'core/globalTypes';
import { useTranslation } from 'react-i18next';
import { useDeleteProjectMutation } from 'generatedHooks/accounts/generated';

import styles from './styles.module.less';
import Popup from 'components/mobile/Popup';
import Password from 'components/mobile/Input/Password';
import { Button } from 'components/mobile';

const { Title, Text } = Typography;

interface Props {
  visible: boolean;
  onClose: () => void;
  options: KeyValuePair;
}

const DeleteProjectPopup: FC<Props> = ({ visible, onClose, options }) => {
  const { t } = useTranslation(['projects', 'common']);
  const [form] = Form.useForm();

  const [deleteProject, { loading }] = useDeleteProjectMutation();

  const resetCloseModal = () => {
    onClose();
    form.resetFields();
  };

  const handleDelete = async () => {
    const { id } = options;

    try {
      const { password } = await form.validateFields();

      await deleteProject({
        variables: { input: { id, password } },
        context: { urlType: linkTypes.accounts },
        update(cache) {
          cache.modify({
            fields: {
              projects: () => {}
            }
          });
        }
      });
      resetCloseModal();
    } catch (error: any) {
      !error.errorFields &&
        form.setFields([
          {
            name: 'password',
            errors: [error.message]
          }
        ]);
    }
  };

  return (
    <Popup
      zIndex="5"
      position="bottom"
      visible={visible}
      cancelValue={t('common:back')}
      forceRender={true}
      onClose={resetCloseModal}
      popupScreen="halfOpen"
    >
      <div className={styles.delete_project_popup_content}>
        <div>
          <Title level={2}>
            {t('projects:modalTitleDelete', 'Delete Project', {
              projectName: options.name
            })}
          </Title>

          <Text className={styles.description_content}>
            {t(
              'projects:modalContentDelete',
              'After removing from project, you will lose your owner role.'
            )}
          </Text>

          <Form form={form} className={styles.delete_project_form}>
            <Form.Item
              required={true}
              label={
                <div className={styles.label_conde_content}>
                  {t('password')} <span>({t('required', 'Required')})</span>
                </div>
              }
              name="password"
              rules={[{ required: true, message: t('passwordRequired') }]}
            >
              <Password placeholder={t('password', 'password')} />
            </Form.Item>
          </Form>
        </div>

        <Space direction="vertical">
          <Button
            color="red"
            loading={loading}
            onClick={handleDelete}
            text={t('delete', 'Delete')}
          />
          <Button
            color="black"
            onClick={resetCloseModal}
            text={t('common:cancel', 'Cancel')}
          />
        </Space>
      </div>
    </Popup>
  );
};

export default DeleteProjectPopup;
