import { useMutation } from '@apollo/client';
import { linkTypes } from 'apollo/link';

import { PageType } from './types';
import { mediaTypeMapper } from './constants';
import { createMediaMutation } from './queries/mediaQuery';

type Props = {
  type: PageType;
  parentId: string;
};

/**
 * Creates a function for create media.
 * @param type - Media type.
 * @param parentId - Parent type.
 * @returns onCreateMedia function and loading state for create media.
 */
function useCreateMedia({ type, parentId }: Props) {
  const refetchOption = {
    refetchQueries: ['mediaWithFolders'],
    awaitRefetchQueries: true
  };

  const [createMedia, { loading: createMediaLoading }] = useMutation(
    createMediaMutation,
    {
      ...refetchOption,
      context: { urlType: linkTypes.builder, hideMessages: true }
    }
  );

  /**
   * Creates a media.
   * @param name - Media name.
   * @param thumbnail - Media thumbnail.
   * @returns created media data.
   */
  async function onCreateMedia(name: string, thumbnail: string) {
    const variables = {
      input: { type: mediaTypeMapper[type], name, thumbnail, parentId }
    };

    const { data } = await createMedia({ variables });

    return data.createMedia;
  }

  return { onCreateMedia, createMediaLoading };
}

export default useCreateMedia;
