import {
  Dispatch,
  SetStateAction,
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  UploadFile,
  UploadChangeParam,
  RcFile
} from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { NiladicVoid, StateDispatcher, Undefinable } from 'core/globalTypes';
import { SystemMessage } from 'helpers';

import styles from './styles.module.less';
import StepContentHeading from 'components/global/ImportModal/components/StepContentHeading';
import { ImportableEntity } from 'components/global/ImportModal/types';
import SampleDownload from './components/SampleDownload';
import FileUploadDragger from './components/FileUploadDragger';
import {
  getIsValidSize,
  readFileByType
} from 'components/global/ImportModal/utils';
import { VALID_MIME_TYPES_ARRAY } from 'components/global/ImportModal/constants';

interface Props {
  setHeader: StateDispatcher<Undefinable<string[]>>;
  setFile: (
    file: UploadFile[]
  ) => void | Dispatch<SetStateAction<UploadFile<any>[]>>;
  file: Undefinable<UploadFile[]>;
  showUploadList: boolean;
  setShowUploadList: (isShowing: boolean) => void;
  resetFields: NiladicVoid;
  entityType: ImportableEntity;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  attributeFamilyId: string;
  productFamily: Undefinable<string>;
}

const FileUploadStep: FC<Props> = ({
  setHeader,
  setFile,
  file,
  showUploadList,
  setShowUploadList,
  entityType,
  resetFields,
  setIsLoading,
  attributeFamilyId,
  productFamily
}) => {
  const { t } = useTranslation('importModal');
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [workerImportError, setWorkerImportError] = useState(false);
  const worker = useMemo(() => new Worker('/importWorker.js'), []);

  const beforeUploadHandler = (file: File) => {
    const isValidType = VALID_MIME_TYPES_ARRAY.includes(file.type);

    const isValidSize = getIsValidSize(file.size);

    if (!isValidType) {
      SystemMessage.error(
        `${t('fileTypeError', 'Only XLSX, XLS, and CSV formats are allowed.')}`
      );
    }

    if (!isValidSize) {
      SystemMessage.error(
        `${t('fileSizeError', 'Your file is larger than 64 MB.')}`
      );
    }

    if (isValidSize && !isValidType) {
      setFile([]);
    }

    return isValidSize && isValidType;
  };

  const onFileUploaded = useCallback(
    (fileResult: FileReader['result'], isCsv: boolean) => {
      if (!workerImportError) {
        worker.addEventListener('message', e => {
          setIsLoading(false);
          setFileUploadLoading(false);
          setHeader(e.data);
        });

        setIsLoading(true);
        setFileUploadLoading(true);
        worker.postMessage({ fileResult, isCsv });
      } else {
        /**
         * This way we handle the case when the load of external script (XLSX) fails in the Import Worker.
         * In this case we read the header of the file without worker as a fallback implementation.
         */
        import('components/global/ImportModal/utils/readFileHeader').then(
          result => {
            const readFileHeader = result.default;

            setHeader(readFileHeader(fileResult, isCsv));
          }
        );
      }
    },
    [workerImportError]
  );

  const onCustomRequest = (subject: UploadRequestOption<any>) => {
    if (subject) {
      subject.file && readFileByType(subject.file as Blob, onFileUploaded);
      subject.onSuccess?.('ok');
    }
  };

  const onChangeHandler = (info: UploadChangeParam<UploadFile<any>>) => {
    resetFields();

    switch (info?.file?.status) {
      case 'uploading':
        setIsLoading(true);
        setShowUploadList(true);
        break;
      case 'done':
        setIsLoading(false);
        setFile([info.file.originFileObj as RcFile]);
        break;
      default:
        setIsLoading(false);
        setShowUploadList(false);
        setFile([]);
        break;
    }
  };

  const onRemoveHandler = () => {
    setFile([]);
    setHeader([]);
  };

  useEffect(() => {
    worker.addEventListener('message', ({ data }) => {
      const hasError = data.error && data.errorType === 'script_load_error';

      if (hasError) {
        setWorkerImportError(true);
      }
    });
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <StepContentHeading
          title={t('fileUploadTitle')}
          description={t('fileUploadDescription')}
        />
        <FileUploadDragger
          file={file}
          onChangeHandler={onChangeHandler}
          onCustomRequest={onCustomRequest}
          onRemoveHandler={onRemoveHandler}
          beforeUploadHandler={beforeUploadHandler}
          showUploadList={showUploadList}
          fileUploadLoading={fileUploadLoading}
        />
      </div>
      <SampleDownload
        attributeFamilyId={attributeFamilyId}
        entityType={entityType}
        productFamily={productFamily}
      />
    </>
  );
};

export default FileUploadStep;
