import { ImportableEntity, Steps } from '../types';

const getStepNumbers = (entityType: ImportableEntity): Steps => {
  const STEP_NUMBERS = {
    UPLOAD: 0,
    MAPPING: 1,
    IMPORTING: 2,
    FINISH: 3
  } as const;

  if (entityType === 'Product') {
    return {
      FAMILY: 0,
      UPLOAD: 1,
      MAPPING: 2,
      IMPORTING: 3,
      FINISH: 4
    } as const;
  }

  return STEP_NUMBERS;
};

export default getStepNumbers;
