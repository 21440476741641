import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Search } from 'components/shared';
import styles from './styles.module.less';
import SortBy from 'components/shared/SortBy';
import { DEFAULT_SORT_DATA } from 'pages/projects/constants';

const Filter: FC = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.my_project_filter_container}>
      <div className={styles.projects_search}>
        <Search
          key="search"
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Input,
            prefix: 'search'
          })}
          placeholder={t('common:search', 'Search')}
        />
      </div>
      <div className={styles.projects_sort}>
        <SortBy width="200px" defaultSortData={DEFAULT_SORT_DATA(t)} />
      </div>
    </div>
  );
};

export default Filter;
