import { FC } from 'react';

import styles from './styles.module.less';

interface Props {
  width?: string;
  height?: number;
  borderColor?: string;
  position?: 'start' | 'end' | 'middle';
  className?: string;
}

const Separator: FC<Props> = ({
  width = '100%',
  height = 0,
  borderColor = '#595959',
  position = 'middle',
  className
}) => {
  return (
    <div className={`${styles.separatorBody} ${styles[position]} ${className}`}>
      <hr style={{ width, borderBottomWidth: `${height}px`, borderColor }} />
    </div>
  );
};

export default Separator;
