import React, { FC } from 'react';
import { Flex } from 'antd';

import { FooterProps } from 'components/basic/Drawer/types';
import styles from './styles.module.less';
import { ShowElement } from 'components/shared';
import { Button } from 'components/basic';

const Footer: FC<FooterProps> = ({
  okText,
  onCancel,
  customFooter,
  cancelText,
  onOk,
  cancelButtonProps,
  okButtonProps,
  confirmLoading
}) => {
  if (customFooter) {
    return (
      <Flex justify="end" align="center" gap={10}>
        {customFooter}
      </Flex>
    );
  }

  return (
    <div className={styles.drawerDefaultFooter}>
      <ShowElement isShow={Boolean(onCancel && cancelText)}>
        <Button {...cancelButtonProps} onClick={onCancel}>
          {cancelText}
        </Button>
      </ShowElement>

      <ShowElement isShow={Boolean(onOk && okText)}>
        <Button
          {...okButtonProps}
          loading={okButtonProps?.loading || confirmLoading}
          onClick={okButtonProps?.onClick || onOk}
          type="primary"
        >
          {okText}
        </Button>
      </ShowElement>
    </div>
  );
};

export default Footer;
