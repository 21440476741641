import { TimePicker, TimeRangePickerProps } from 'antd';

const { RangePicker } = TimePicker;

const BaseRangeTimePicker = (props: TimeRangePickerProps) => {
  return <RangePicker {...props} size="large" />;
};

const UcRangeTimePicker = Object.assign(BaseRangeTimePicker, RangePicker);

export default UcRangeTimePicker;
