import { Steps, StepsProps } from 'antd';

import UcStep from '../Step';

const BaseSteps = (props: StepsProps) => {
  return <Steps {...props} />;
};

const UcSteps = Object.assign(Steps, BaseSteps, { Step: UcStep });

export default UcSteps;
