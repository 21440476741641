import React, { FC, useState } from 'react';
import Carousel from 'react-image-gallery';
import { Popup } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { filesize } from 'filesize';
import dayjs from 'dayjs';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIconImagePreview.svg';
import { KeyValuePair, PureNullable } from 'core/globalTypes';

import CarouselItem from './CarouselItem';
import CarouselThumbItem from './CarouselThumbItem';
import { MediaWithFoldersDataImage } from 'pages/products/products/Mobile/types';
import {
  CarouselData,
  convertCarouselData
} from 'pages/products/products/Mobile/components/utils/types';
import styles from './styles.module.less';
import PopupSheetHeader from 'components/mobile/PopupSheetHeader';
import MainTitle from 'components/mobile/titles/MainTitle';
import Separator from 'components/mobile/Separator';
import { ImagePreviewProps } from './types';
import useMedia from 'hooks/useMedia';

const ImagePreview: FC<ImagePreviewProps> = ({
  images,
  visible,
  onClose,
  fullScreen,
  zIndex,
  type,
  activeId,
  onInsert,
  closedPopups,
  selectedMedias,
  handleItemSelected
}) => {
  const { t } = useTranslation(['products', 'common']);
  const [isOpenDetails, setOpenDetails] = useState(false);
  const [currentItemDetails, setCurrentItemDetails] =
    useState<PureNullable<MediaWithFoldersDataImage>>(null);

  const { imagePath } = useMedia();

  const handleAddImages = () => {
    onInsert(selectedMedias);
    onClose();
    closedPopups();
  };

  const handleInfoIcon = (id: string) => {
    const currentItem = images.find(el => el.id === id) ?? null;
    setCurrentItemDetails(currentItem as MediaWithFoldersDataImage);
    setOpenDetails(!isOpenDetails);
  };

  const handleCloseInfoModal = () => setOpenDetails(false);

  const details: KeyValuePair<string>[] = [
    {
      key: 'name',
      value: `${currentItemDetails?.name}.${currentItemDetails?.extension}`
    },
    { key: 'size', value: filesize(currentItemDetails?.size || 0) },
    {
      key: 'uploaded',
      value: dayjs(currentItemDetails?.createdAt).format('MMM D, YYYY')
    },
    { key: 'owner', value: 'user' },
    {
      key: 'dimensions',
      value: `${currentItemDetails?.width} x ${currentItemDetails?.height}`
    }
  ];

  return (
    <Popup
      style={{ '--z-index': `${zIndex}` }}
      visible={visible}
      position="bottom"
      bodyClassName={`${styles.popup} ${fullScreen ? styles.fullscreen : ''}`}
      onMaskClick={onClose}
    >
      <PopupSheetHeader
        lSpan={5}
        mSpan={13}
        rSpan={6}
        left={
          <span>
            <CloseIcon onClick={onClose} className={styles.svgStyle} />
          </span>
        }
        styleTitle={styles.titleModal}
        addClasses={styles.headerWrapper}
        middle={
          <span className={styles.titleModal}>
            {t('products:products.imagePreview')}{' '}
          </span>
        }
        right={
          <div className={styles.titleWrapper} onClick={handleAddImages}>
            <p> {t('products:products.addImage')}</p>
          </div>
        }
      />

      <Modal
        closable={false}
        open={isOpenDetails}
        onCancel={handleCloseInfoModal}
        zIndex={3000}
        footer={null}
        maskClosable
        width={240}
        className={styles.detailsPopup}
        styles={{
          body: {
            backgroundColor: '#262626',
            color: '#FFFFFF',
            borderRadius: '8px',
            padding: '12px 16px'
          }
        }}
      >
        <div className={styles.popoverContent}>
          <MainTitle
            title={t('media:detailsTitle')}
            font={16}
            addClass={styles.textColor}
          />
          <Separator width={'calc(100% - 32px)'} borderColor={'#a6a5a5'} />
          {details.map(({ key, value }) => (
            <div key={key} className={styles.itemWrapper}>
              {t(`media:${key}`)}:&ensp;
              <strong className="textEllipsis">{value}</strong>
            </div>
          ))}
        </div>
      </Modal>

      <Carousel
        items={convertCarouselData(images) as CarouselData[]}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={false}
        showThumbnails
        showIndex
        startIndex={Number(activeId)}
        renderThumbInner={(item: any) => (
          <CarouselThumbItem
            isSelected={selectedMedias.includes(item.id)}
            handleItemSelected={handleItemSelected}
            thumbnail={item.thumbnail ?? item.original}
            type={type}
            {...item}
          />
        )}
        renderItem={item => (
          <CarouselItem
            handleInfoIcon={handleInfoIcon}
            name={item.description ?? ''}
            imagePath={imagePath}
            {...item}
          />
        )}
      />
      <div className={styles.carouselMode}>
        <span className={styles.selectedText}>
          {selectedMedias.length} {t('products:products.itemSelected')}
        </span>
      </div>
    </Popup>
  );
};

export default ImagePreview;
