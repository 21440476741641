import qs from 'qs';
import { KeyValuePair } from 'core/globalTypes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'hooks';

const qsStringifyOptions = {
  addQueryPrefix: true
};

const useHistory = () => {
  const [, setSearchParams] = useSearchParams();
  const { queryObject } = useQuery();
  const navigate = useNavigate();

  const push = (data: KeyValuePair, navigateOption?: KeyValuePair) => {
    navigate(
      qs.stringify({ ...queryObject, ...data }, qsStringifyOptions),
      navigateOption || {}
    );
  };

  const resetQuery = (queryName: string | Array<string>) => {
    if (typeof queryName === 'string') {
      delete queryObject[queryName];
    }

    if (Array.isArray(queryName)) {
      queryName.forEach(name => {
        delete queryObject[name];
      });
    }

    const queryString = qs.stringify({ ...queryObject }, qsStringifyOptions);

    navigate(queryString);
  };

  const resetAll = () => {
    setSearchParams({});
  };

  return {
    push,
    resetQuery,
    resetAll
  };
};

export default useHistory;
