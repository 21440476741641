import { Space, Skeleton } from 'antd';

import styles from 'pages/bookings/bookings/bookingProduct/components/Services/components/ServicesModal/components/ServicesListSkeleton/styles.module.less';

export const ProjectListSkeleton = () => {
  return (
    <Space
      size={24}
      className={styles.projects_skeletons_container}
      direction="vertical"
    >
      <Skeleton.Input />
      <Skeleton.Input />
      <Skeleton.Input />
      <Skeleton.Input />
    </Space>
  );
};
