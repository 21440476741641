import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';

import UcSelectOption from './Option';
import CustomTag from './components/CustomTag';

interface BaseSelectProps extends SelectProps<any> {}

const BaseSelect: FC<BaseSelectProps> = props => {
  const { tagRender, mode } = props;

  const _tagRender =
    tagRender || mode
      ? (props: CustomTagProps) => <CustomTag {...props} />
      : undefined;

  return (
    <Select
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      autoComplete="new-state"
      size="large"
      {...props}
      tagRender={_tagRender}
    />
  );
};

const UcSelect = Object.assign(BaseSelect, Select, {
  Option: UcSelectOption
});

export default UcSelect;
