import { deviceTypes } from 'core/constants/common';

const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return deviceTypes.tablet;
  }

  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    return deviceTypes.mobile;
  }

  return deviceTypes.desktop;
};

export default getDeviceType;
