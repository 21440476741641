import {
  RUDDERSTUCK_ANONYMOUS_ID,
  RUDDERSTUCK_SESSION_ID
} from 'core/constants/common';

const getAnalyticsHeaders = () => {
  const anonymousId = localStorage.getItem(RUDDERSTUCK_ANONYMOUS_ID);
  const sessionId = localStorage.getItem(RUDDERSTUCK_SESSION_ID);

  return {
    'X-Anonymous-Id': anonymousId || '',
    'X-Analytics-Session-Id': sessionId || ''
  };
};

export default getAnalyticsHeaders;
