import { DatePicker, DatePickerProps } from 'antd';
import React from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import UcRangeDatePicker from '../RangeDatePicker';
import { CloseIcon } from 'components/shared/SVG';
import styles from './styles.module.less';

const BaseDatePicker = (props: DatePickerProps) => {
  return (
    <DatePicker
      clearIcon={
        <CloseIcon
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Icon,
            prefix: 'close'
          })}
          onClick={e => e.stopPropagation()}
        />
      }
      {...props}
      size="large"
      className={`${styles.date_picker} ${props.className}`}
    />
  );
};

const UcDatePicker = Object.assign(BaseDatePicker, DatePicker, {
  RangePicker: UcRangeDatePicker
});

export default UcDatePicker;
