import { FC, useCallback, SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card as AntCard } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { imageErrorHandler, renderProjectStatusBadgeColor } from 'helpers';
import { renderProjectStatus } from 'helpers/commonHelpers/renderProjectStatus';

import { MENU_ITEMS, dropDownActionTypes } from 'pages/projects/constants';
import Avatar from './avatar';
import styles from './styles.module.less';
import CustomTag from 'pages/projects/components/CustomTag';
import MobilePopover from 'components/mobile/MobilePopover';
import ImgIcon from 'components/mobile/ImgIcon';
import { Typography } from 'components/basic';
import { CardProps } from './types';

const { UcBodyText } = Typography;

const { VITE_APP_SSO_URL } = import.meta.env;

const Card: FC<CardProps> = ({
  project,
  cardImgSrc,
  showCardImg,
  mobileMockupSrc,
  deleteProjectHandler,
  avatarMaxShowCount = 3,
  openNotificationsSettingsModal
}) => {
  const { users, id, name, clientName, url, screenshotUrl } = project;
  const navigate = useNavigate();
  const parent = useRef<HTMLDivElement>(null);

  const { t } = useTranslation(['projects', 'common', 'menu']);

  const handleNavigate = useCallback((e: SyntheticEvent<EventTarget>) => {
    e.target.toString() === parent?.current?.toString() && navigate(`/${name}`);
    window.median?.tabNavigation.selectTab(0); //TODO
  }, []);

  const menuActions = {
    [dropDownActionTypes.delete]: () =>
      deleteProjectHandler(id, clientName || ''),
    [dropDownActionTypes.preview]: () => navigate(`/${name}`),
    [dropDownActionTypes.duplicate]: () => {
      //is absent backend functional
    },
    [dropDownActionTypes.goToVisualEditor]: () => {
      window.open(
        `${VITE_APP_SSO_URL}/callback?projectId=${id}&returnUrl=/admin/~/visual-editor`
      );
    },
    [dropDownActionTypes.goToPublicMode]: () => {
      window.open(url);
    },
    [dropDownActionTypes.notificationSettings]: () => {
      openNotificationsSettingsModal(id, clientName);
    }
  };

  return (
    <div
      ref={parent}
      onClick={handleNavigate}
      className={`${styles.amd_card_container}`}
    >
      <div
        className={`${styles.img_container} ${
          showCardImg ? styles.active_img_card : ''
        }`}
        style={{ backgroundImage: `url(${screenshotUrl}), url(${cardImgSrc})` }}
      >
        {mobileMockupSrc && (
          <div className={styles.right_mobile_content}>
            <img
              src={screenshotUrl}
              alt={clientName}
              onError={e => imageErrorHandler(e, mobileMockupSrc)}
            />
          </div>
        )}
      </div>

      <AntCard
        className={`${showCardImg ? styles.show_card_card_img : ''}`}
        title={
          <UcBodyText size="base" fontWeight="medium">
            {clientName}
          </UcBodyText>
        }
        extra={
          <MobilePopover
            valueKey="value"
            labelKey="label"
            placement="bottomRight"
            onChange={e => menuActions[e]()}
            menuItems={MENU_ITEMS(t, true)}
          >
            <ImgIcon paddingType="small" icon="ellipsisOutlined" />
          </MobilePopover>
        }
      >
        <div
          className={styles.and_mobile_card_body_container}
          data-clickable="no_click"
        >
          <Avatar
            data={users?.data || []}
            avatarMaxShowCount={avatarMaxShowCount}
          />
          <div className={styles.right_content}>
            <CustomTag color={renderProjectStatusBadgeColor(project)}>
              <span style={{ color: '#8C8C8C' }}>
                {renderProjectStatus(project, t)}
              </span>
            </CustomTag>
          </div>
        </div>
      </AntCard>
    </div>
  );
};

export default Card;
