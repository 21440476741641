import { logout, redirectToAccounts } from 'containers/utils';
import { StateDispatcher } from 'core/globalTypes';

const avatarMenuActions = (
  t: Function,
  setMyProjectPopupOpen: StateDispatcher<boolean>
) => [
  {
    key: 'projects',
    onClick: () => {
      setMyProjectPopupOpen(true);
    },
    text: t('projects:myProject', 'My Projects')
  },
  {
    key: 'account_settings',
    onClick: redirectToAccounts,
    text: t('common:accountSettings', 'Account Settings')
  },
  {
    key: 'logOut',
    onClick: logout,
    text: t('common:logOut', 'Log Out')
  }
];

export { avatarMenuActions };
