export enum Elements {
  number = 'cardNumberElement',
  cvc = 'cardCvcElement',
  expiry = 'cardExpiryElement'
}

export interface BillingInformationProps {
  completeElementsHandler: Function;
}

export type BillingInfoInitialState = {
  [key in Elements]: { message: null | string; complete: boolean };
};
