import { Permission } from 'core/globalTypes';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

export enum Devices {
  Mobile = 'MOBILE',
  Desktop = 'DESKTOP'
}

/*  #Sider Menu  */

export type SubMenuProps = {
  subTitle: string;
  key: string;
  url: string;
  permission?: Permission;
};

export type MenuProps = {
  icon: ReactNode;
  subMenu?: SubMenuProps[];
  subTitle?: string;
  title: string;
  url?: string;
  key: string;
  permission?: Permission;
};

export type Props = {
  collapsed?: boolean;
  children?: ReactNode;
  menus: MenuProps[];
};

export type MenuFn = (t: TFunction) => MenuProps[];
