import { Devices } from 'containers/types.d';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

export function deviceDetector() {
  const mobileWindow = useReactiveVar(isMobileDevice);

  const device = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
    ? Devices.Mobile
    : Devices.Desktop;

  const isMobile = device !== 'DESKTOP' || mobileWindow;

  return {
    device,
    isMobile: isMobile //TODO change
  };
}
