import React, { FC } from 'react';
import { Spin } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { ArrowLeftIcon } from 'components/shared/SVG';
import HeaderPageContainer from './components/headerPageContainer';
import styles from './styles.module.less';
import { PageContainerProps } from './types';
import { ShowElement, LoadingWrapper } from 'components/shared';
import HeaderContent from 'components/mobile/Header/Content';
import PageTitle from 'components/mobile/pageTitle';

const PageContainer: FC<PageContainerProps> = ({
  children,
  className,
  extra,
  title,
  content,
  onBack,
  loading,
  ghost,
  childrenClassName,
  extraSaveBtn,
  tagTitle,
  loadingTitle,
  loader: Loader
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  return (
    <div className={`${className} ${styles.pageContainer}`}>
      <Spin spinning={loading && !Loader}>
        <ShowElement
          isShow={!isMobile}
          fallback={
            <>
              <ShowElement isShow={!!onBack}>
                <HeaderContent position="left">
                  <ArrowLeftIcon onClick={onBack} />
                </HeaderContent>
              </ShowElement>
              <HeaderContent position={onBack ? 'center' : 'left'}>
                <PageTitle
                  size="base"
                  ellipsis
                  fontWeight="bold"
                  text={title || ''}
                />
              </HeaderContent>
              <HeaderContent position="right">{extraSaveBtn}</HeaderContent>
            </>
          }
        >
          <div
            className={`${
              title || loadingTitle ? styles.headerContainerWrap : ''
            }`}
          >
            <div className={`ghostPageContainer ${ghost ? styles.ghost : ''}`}>
              <ShowElement isShow={Boolean(title || loadingTitle)}>
                <HeaderPageContainer
                  onBack={onBack}
                  title={title}
                  extra={extra}
                  extraSaveBtn={extraSaveBtn}
                  tagTitle={tagTitle}
                  loadingTitle={loadingTitle}
                  subtitle={content}
                />
              </ShowElement>
            </div>
          </div>
        </ShowElement>

        <LoadingWrapper
          loading={loading && Loader}
          indicator={Loader ? <Loader /> : <></>}
        >
          <div className={childrenClassName}>{children}</div>
        </LoadingWrapper>
      </Spin>
    </div>
  );
};

PageContainer.defaultProps = {
  loader: null,
  title: '',
  content: false,
  className: '',
  loading: false,
  ghost: false,
  extra: null,
  childrenClassName: '',
  extraSaveBtn: null,
  tagTitle: null,
  loadingTitle: false
};

export default PageContainer;
