import { ReactNode, useState, MouseEvent, useMemo } from 'react';
import { Tooltip } from 'antd';
import {
  CloseOutlined,
  Loading3QuartersOutlined,
  ExclamationCircleFilled,
  CheckCircleFilled,
  DownOutlined
} from '@ant-design/icons';
import Tween, { TweenOneGroup } from 'rc-tween-one';
import { useTranslation } from 'react-i18next';
// import {
//   CheckIcon,
//   CloseIcon,
//   CollapseIcon,
//   ListItem,
//   LoadingIcon,
//   Size,
//   Title,
//   List,
//   Text,
//   Image,
//   ErrorMessage,
//   Wrapper,
//   toggleAnimation
// } from './styles';

import styles from './styles.module.less';

export type ListItemType = {
  id: string;
  name: string;
  size: string;
  total: number;
  loaded?: number;
  image?: ReactNode;
  errorMessage?: string;
  onItemClose?: () => void;
};

type Props = {
  list: ListItemType[];
  onClose: () => void;
  position?: string;
};

function getIcon(item: ListItemType) {
  return item.errorMessage ? (
    <ExclamationCircleFilled style={{ color: 'var(--ant-error-color' }} />
  ) : item.loaded === item.total ? (
    <CheckCircleFilled className={styles.CheckIcon} />
  ) : (
    <div className={styles.LoadingIcon}>
      <Loading3QuartersOutlined spin />
      <CloseOutlined onClick={item.onItemClose} />
    </div>
  );
}

function Uploader({ list, onClose }: Props) {
  const { t } = useTranslation('media');
  const [open, setOpen] = useState(true);
  const isLoadingInProgress = useMemo(
    () => list.some(v => !v.errorMessage && v.total !== v.loaded),
    [list]
  );

  function onUploaderClose(e: MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    list.forEach(v => v.total !== v.loaded && v.onItemClose && v.onItemClose());

    // if (isLoadingInProgress) {
    //   Modal.confirm({
    //     title: t('Uploader.cancelTitle'),
    //     content: t('Uploader.cancelContent'),
    //     okText: t('Uploader.yes'),
    //     cancelText: t('Uploader.no'),
    //     icon: <ExclamationCircleOutlined data-test="confirm-modal-icon" />,
    //     okButtonProps: { danger: true },
    //     onOk: onClose
    //   });
    // } else {
    onClose();
    // }
  }

  const { total, loaded, count } = list.reduce(
    (prev, { total, loaded = 0 }) => {
      return {
        count: loaded === total ? prev.count + 1 : prev.count,
        total: prev.total + total,
        loaded: prev.loaded + loaded
      };
    },
    { count: 0, total: 0, loaded: 0 }
  );

  const percent = Math.round((loaded / total) * 100) || 0;
  const key = isLoadingInProgress
    ? 'uploading'
    : count > 1
      ? 'uploadsComplete'
      : 'uploadComplete';

  const title = `${t(`Uploader.${key}`)} ${count}/${list.length} (${percent}%)`;

  return (
    <div
      className={styles.Wrapper}
      style={{ position: 'absolute' }}
      data-test="upload-notification-wrapper"
    >
      <div className={styles.Title} onClick={() => setOpen(!open)}>
        <div>{title}</div>
        <DownOutlined
          open={open}
          style={{ transform: `rotate(${open ? '0' : '180'}deg)` }}
        />
        <CloseOutlined className={styles.CloseIcon} onClick={onUploaderClose} />
      </div>
      <Tween
        animation={[
          {
            maxHeight: 1,
            duration: 0
          },
          {
            maxHeight: 230,
            duration: 250,
            ease: 'easeOutQuad'
          }
        ]}
        reverse={!open}
      >
        <div className={styles.List} data-test="list-item">
          <TweenOneGroup exclusive>
            {list.map(item => {
              return (
                <div className={styles.ListItem} key={item.id}>
                  <div className={styles.Image}>{item.image}</div>
                  <div className={styles.Text}>
                    <Tooltip placement="bottom" title={item.name}>
                      <span data-test="fileName">{item.name}</span>
                    </Tooltip>
                    <div
                      className={styles.ErrorMessage}
                      data-test="error-message"
                    >
                      {item.errorMessage}
                    </div>
                  </div>
                  <div className={styles.Size}>{item.size} </div>
                  {getIcon(item)}
                </div>
              );
            })}
          </TweenOneGroup>
        </div>
      </Tween>
    </div>
  );
}

export default Uploader;
