import { gql } from '@apollo/client';

export const mediaImageFragment = gql`
  fragment MediaImageFragment on MediaInterface {
    id
    name
    type
    size
    thumbnail
    createdAt
    filename
    extension
    mimeType
    ... on Image {
      alt
      webPFilename
    }
    tags {
      name
    }
  }
`;

/* Queries */
export const getMediaQuery = gql`
  query mediaWithFolders(
    $type: MediaType!
    $search: String
    $orderBy: [QueryMediaWithFoldersOrderByOrderByClause!]
    $first: Int!
    $page: Int
    $parentId: ID
  ) {
    mediaWithFolders(
      type: $type
      search: $search
      orderBy: $orderBy
      first: $first
      page: $page
      parentId: $parentId
    ) {
      data {
        ... on MediaInterface {
          ...MediaImageFragment
        }
      }
      paginatorInfo {
        total
        hasMorePages
      }
    }
  }
  ${mediaImageFragment}
`;

export const getMediaQueries = gql`
  query mediaWithFoldersInitial(
    $type: MediaType!
    $search: String
    $orderBy: [QueryMediaWithFoldersOrderByOrderByClause!]
    $first: Int!
    $page: Int
    $parentId: ID
  ) {
    images: mediaWithFolders(
      type: $type
      search: $search
      orderBy: $orderBy
      first: $first
      page: $page
      parentId: $parentId
    ) {
      data {
        ... on MediaInterface {
          ...MediaImageFragment
        }
      }
    }
    vectors: mediaWithFolders(
      type: VECTOR
      search: $search
      orderBy: $orderBy
      first: $first
      page: $page
      parentId: $parentId
    ) {
      data {
        ... on MediaInterface {
          ...MediaImageFragment
        }
      }
    }
  }
  ${mediaImageFragment}
`;

export const getSingleMediaQuery = gql`
  query media($type: MediaType!, $id: ID!) {
    media(type: $type, id: $id) {
      ... on MediaInterface {
        id
        name
        parents {
          id
          name
        }
      }
    }
  }
`;

export const getFoldersQuery = gql`
  query Folders($type: MediaType!, $parentId: ID, $first: Int!) {
    folders(type: $type, parentId: $parentId, first: $first) {
      data {
        id
        name
        parent {
          id
        }
      }
    }
  }
`;

export const mediaFileFragment = gql`
  fragment MediaFileFragment on MediaInterface {
    id
    name
    type
    thumbnail
    size
    createdAt
    filename
    tags {
      name
    }
  }
`;

export const getMediaByIdsQuery = gql`
  query mediaByIds($ids: [ID!]!) {
    mediaByIds(ids: $ids) {
      ... on MediaInterface {
        ...MediaFileFragment
      }
      ... on Image {
        alt
      }
    }
  }
  ${mediaFileFragment}
`;

export const unsplashImagesQuery = gql`
  query unsplashImages($search: String, $first: Int!, $page: Int) {
    unsplashImages(
      search: $search
      collections: []
      first: $first
      page: $page
    ) {
      data {
        id
        regularUrl
        thumbUrl
        twitterUsername
        profileImageSmall
        userProfileLink
        altDescription
        width
        height
        username
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

/* Mutations */
export const uploadMediaMutation = gql`
  mutation uploadMedia($input: UploadMediaInput!) {
    uploadMedia(input: $input) {
      name
      thumbnail
    }
  }
`;

export const createMediaMutation = gql`
  mutation createMedia($input: CreateMediaInput!) {
    createMedia(input: $input) {
      ... on MediaInterface {
        id
        name
      }
    }
  }
`;

export const deleteMediaMutation = gql`
  mutation deleteMedia($input: DeleteMediaInput!) {
    deleteMedia(input: $input)
  }
`;

export const updateMediaMutation = gql`
  mutation updateMedia($input: UpdateMediaInput!) {
    updateMedia(input: $input) {
      ... on MediaInterface {
        id
        name
        description
        ... on Image {
          title
        }
        ... on Video {
          title
        }
        ... on Audio {
          title
        }
        ... on Document {
          title
        }
        ... on Vector {
          title
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export const updateFolderMutation = gql`
  mutation updateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      id
      name
      description
      tags {
        id
        name
      }
    }
  }
`;

export const createFolderMutation = gql`
  mutation createFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      ... on MediaInterface {
        id
        name
        parent {
          id
        }
        ... on Folder {
          children {
            id
          }
        }
      }
    }
  }
`;

export const moveToMutation = gql`
  mutation moveMedia($input: MoveMediaInput!) {
    moveMedia(input: $input) {
      ... on MediaInterface {
        id
      }
    }
  }
`;

export const duplicateMediaMutation = gql`
  mutation duplicateMedia($input: DuplicateMediaInput!) {
    duplicateMedia(input: $input) {
      ... on MediaInterface {
        id
      }
    }
  }
`;

export const loadRemoteMediaMutation = gql`
  mutation loadRemoteMedia($input: LoadRemoteMediaInput!) {
    loadRemoteMedia(input: $input) {
      name
      thumbnail
    }
  }
`;

export const attachTagsToMediaMutation = gql`
  mutation attachTagsToMedia($input: AttachTagsToMedia!) {
    attachTagsToMedia(input: $input) {
      ... on MediaInterface {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

export const detachTagsFromMediaMutation = gql`
  mutation detachTagsFromMedia($input: DetachTagsFromMedia!) {
    detachTagsFromMedia(input: $input)
  }
`;
