import {
  OrderFulfillStatus,
  OrderPaymentStatus
} from 'generatedHooks/commerce/generated';

import { OrderActions } from 'pages/orderDetails/types';
import { ACTIONS } from './ACTIONS';

const physicalOrderLifecycleActions = {
  paymentStatus: {
    [OrderPaymentStatus.Paid]: [
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.CANCEL],
      ACTIONS[OrderActions.RETURN],
      ACTIONS[OrderActions.DISPATCH],
      ACTIONS[OrderActions.FULFILL]
    ],
    [OrderPaymentStatus.PartiallyPaid]: [
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.CANCEL],
      ACTIONS[OrderActions.RETURN],
      ACTIONS[OrderActions.DISPATCH],
      ACTIONS[OrderActions.FULFILL]
    ],
    [OrderPaymentStatus.Unpaid]: [
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.RETURN],
      ACTIONS[OrderActions.CANCEL],
      ACTIONS[OrderActions.DISPATCH],
      ACTIONS[OrderActions.FULFILL]
    ],
    [OrderPaymentStatus.InProgress]: [
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.MAKE_UNPAID]
    ],
    [OrderPaymentStatus.Refunded]: [],
    [OrderPaymentStatus.PartiallyRefunded]: [
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.DISPATCH],
      ACTIONS[OrderActions.FULFILL]
    ],
    [OrderPaymentStatus.AwaitingPayment]: []
  },
  fulfillStatus: {
    [OrderFulfillStatus.Fulfilled]: [
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.CANCEL]
    ],
    [OrderFulfillStatus.Unfulfilled]: [
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.MAKE_UNPAID],
      ACTIONS[OrderActions.CANCEL],
      ACTIONS[OrderActions.DISPATCH]
    ],
    [OrderFulfillStatus.Canceled]: [ACTIONS[OrderActions.REFUND]],
    [OrderFulfillStatus.Returned]: [ACTIONS[OrderActions.REFUND]],
    [OrderFulfillStatus.Dispatched]: [
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.CANCEL],
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.FULFILL]
    ],
    [OrderFulfillStatus.Delivered]: [
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.RETURN],
      ACTIONS[OrderActions.REFUND]
    ],
    [OrderFulfillStatus.PartiallyReturned]: [ACTIONS[OrderActions.REFUND]],
    [OrderFulfillStatus.PartiallyFulfilled]: [
      ACTIONS[OrderActions.MAKE_PAID],
      ACTIONS[OrderActions.RETURN],
      ACTIONS[OrderActions.REFUND],
      ACTIONS[OrderActions.FULFILL]
    ],
    [OrderFulfillStatus.PickedUp]: [
      ACTIONS[OrderActions.RETURN],
      ACTIONS[OrderActions.REFUND]
    ]
  }
};

export default physicalOrderLifecycleActions;
