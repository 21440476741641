import React, { FC, useEffect, useState } from 'react';
import { QueryLazyOptions } from '@apollo/client/react/types/types';
import { OperationVariables } from '@apollo/client/core';
import { useTranslation } from 'react-i18next';
import { MediaWithoutFolder, PageType } from 'packages/Media/types';

import { IDataItem } from 'components/mobile/MobileDropdown';
import { IconButton } from 'components/mobile/buttons/IconButton/IconButton';
import PopupSheetHeader from 'components/mobile/PopupSheetHeader';
import ImgIcon from 'components/mobile/ImgIcon';
import PopupHeadTitle from 'components/mobile/PopupHeadTitle';
import styles from './styles.module.less';
import { MediaWithFoldersData } from 'pages/products/products/Mobile/types';
import PopupSheet from 'components/mobile/PopupSheet';
import UCButton from 'components/mobile/buttons/UCButton';
import MediaViewSection from 'components/mobile/media/UploadFormMediaMobile/MediaViewSection';
import ImagePreview from 'pages/products/products/Mobile/components/ImagePreview';
import MobilePopover from 'components/mobile/MobilePopover';

export enum PopupTypes {
  mediaCarousel = 'mediaCarousel',
  canceling = 'canceling',
  folder = 'folder'
}

type MediaType = MediaWithoutFolder | null;

export interface IFolderInfo {
  state: boolean;
  name: string;
}

const initFolderState = {
  state: false,
  name: ''
};

type Props = {
  order: string;
  type: PageType;
  column: string;
  loading: boolean;
  visible?: boolean;
  multiSelect: boolean;
  closePopup: () => void;
  selectedMedias: string[];
  total: number | undefined;
  initialIds: string[] | [];
  mediaManagerData: IDataItem[];
  hiddenIds: string[] | undefined;
  onInsert: (ids: string[]) => void;
  media: MediaWithFoldersData[] | [];
  onOverlayClick: (ard: string) => void;
  setSearch: (arg: string | null) => void;
  setPreviewMedia: (arg: MediaType) => void;
  setParentId?: (arg: string | null) => void;
  setParentIdMob?: (arg: string | null) => void;
  onBreadcrumbClick: (id: string | null) => void;
  setSelectedMedias: (ids: string[] | []) => void;
  setDefaultMediaId?: (arg: number | null) => void;
  getImages: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
};

const UploadFormMediaMobile: FC<Props> = ({
  type,
  total,
  media,
  order,
  column,
  visible,
  loading,
  onInsert,
  setSearch,
  hiddenIds,
  getImages,
  initialIds,
  closePopup,
  multiSelect,
  selectedMedias,
  onOverlayClick,
  mediaManagerData,
  setSelectedMedias,
  onBreadcrumbClick,
  setParentId: setParentIdMain,
  setParentIdMob: setParentId
}) => {
  const { t } = useTranslation(['common', 'products']);
  const [selectedImageId, setSelectedImageId] = useState<string | number>();
  const [openedPopup, setOpenPopup] = useState<PopupTypes | undefined>();
  const [folderInfo, setFolderInfo] = useState<IFolderInfo>(initFolderState);
  const [isAddedNewItem, setAddedNewItem] = useState<{
    [key: string]: boolean;
  } | null>(null);

  useEffect(() => {
    setSelectedMedias(initialIds);
  }, [initialIds?.length, visible]);

  const openMediaCarousel = (id: string) => {
    const idx = media.findIndex(item => item.id === id);
    setSelectedImageId(idx);
    setOpenPopup(PopupTypes.mediaCarousel);
  };

  const closeSubPopup = () => {
    setOpenPopup(undefined);
  };

  const closeFolderPopup = () => {
    setOpenPopup(undefined);
    setParentIdMain && setParentIdMain(null);
    setFolderInfo(initFolderState);
    onBreadcrumbClick(null);
  };

  const closeMediaPopup = () => {
    selectedMedias.length && isAddedNewItem
      ? setOpenPopup(PopupTypes.canceling)
      : closePopup();
  };

  const cancelSelectedImages = () => {
    setSelectedMedias([]);
    setOpenPopup(undefined);
    closePopup();
  };

  const onInsertSelectedImages = () => {
    setOpenPopup(undefined);
    closePopup();
    onInsert(selectedMedias);
  };

  const searchInputValue = (value: string) => {
    setSearch(value);
  };

  const handleCheckCancelingModal = (id: string) => {
    setAddedNewItem(prevState => {
      if (prevState && prevState[id]) {
        delete prevState[id];

        return { ...prevState };
      }

      return { ...prevState, [id]: true };
    });
  };

  const resetCheckedItems = () => setAddedNewItem(null);

  return (
    <>
      <PopupSheetHeader
        lSpan={6}
        mSpan={12}
        rSpan={6}
        left={
          <IconButton
            className={styles.closeMedia}
            handleClick={closeMediaPopup}
            type={'default'}
          >
            {t('common:cancel')}
          </IconButton>
        }
        middle={t('common:mediaManager')}
        right={
          <MobilePopover
            menuItems={mediaManagerData}
            placement="bottomRight"
            className={styles.popoverStyle}
            styleContent={styles.contentStyle}
          >
            <ImgIcon icon={'plusIcon'} paddingType="big" />
          </MobilePopover>
        }
      />
      <MediaViewSection
        resetCheckedItems={resetCheckedItems}
        handleCheckCancelingModal={handleCheckCancelingModal}
        closePopup={closePopup}
        mediaManagerData={mediaManagerData}
        total={total}
        media={media}
        hiddenIds={hiddenIds}
        selectedMedias={selectedMedias}
        onOverlayClick={onOverlayClick}
        multiSelect={multiSelect}
        loading={loading}
        type={type}
        setParentId={setParentId}
        setParentIdMain={setParentIdMain}
        getImages={getImages}
        order={order}
        column={column}
        setSearch={setSearch}
        onInsert={onInsert}
        setSelectedMedias={setSelectedMedias}
        initialIds={initialIds}
        visible={visible}
        searchInputValue={searchInputValue}
        openMediaCarousel={openMediaCarousel}
        setFolderInfo={setFolderInfo}
        folderInfo={folderInfo}
        setOpenPopup={setOpenPopup}
        onInsertSelectedImages={onInsertSelectedImages}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PopupSheet
        onClose={closeFolderPopup}
        visible={openedPopup === PopupTypes.folder}
        zIndex={'1006'}
        radius={true}
        outSeparator={true}
        padding={true}
      >
        <PopupSheetHeader
          lSpan={6}
          mSpan={12}
          rSpan={6}
          left={
            <IconButton handleClick={closeFolderPopup} type={'back'}>
              {t('common:back')}
            </IconButton>
          }
          middle={`${folderInfo.name} ${t('common:images')}`}
        />
        <MediaViewSection
          type={type}
          media={media}
          total={total}
          order={order}
          column={column}
          loading={loading}
          visible={visible}
          onInsert={onInsert}
          hiddenIds={hiddenIds}
          getImages={getImages}
          setSearch={setSearch}
          initialIds={initialIds}
          folderInfo={folderInfo}
          closePopup={closePopup}
          multiSelect={multiSelect}
          setParentId={setParentId}
          setOpenPopup={setOpenPopup}
          setFolderInfo={setFolderInfo}
          selectedMedias={selectedMedias}
          onOverlayClick={onOverlayClick}
          setParentIdMain={setParentIdMain}
          mediaManagerData={mediaManagerData}
          searchInputValue={searchInputValue}
          resetCheckedItems={resetCheckedItems}
          setSelectedMedias={setSelectedMedias}
          openMediaCarousel={openMediaCarousel}
          onBreadcrumbClick={onBreadcrumbClick}
          onInsertSelectedImages={onInsertSelectedImages}
          handleCheckCancelingModal={handleCheckCancelingModal}
        />
      </PopupSheet>
      <PopupSheet
        onClose={closeSubPopup}
        visible={openedPopup === PopupTypes.canceling}
        zIndex={'1006'}
        outSeparator={true}
        radius={true}
        padding={true}
      >
        <PopupHeadTitle title={t('common:canceling')} />
        <p className={styles.noteText}>
          {t('common:cancelingText', { count: selectedMedias.length })}
        </p>
        <div>
          <UCButton
            bgColor={'black'}
            type={'primary'}
            handleClick={() => {
              onInsertSelectedImages();
              resetCheckedItems();
            }}
            block={true}
            className={styles.saveIdesBtn}
          >
            {t('products:saveSelectedImages')}
          </UCButton>
          <UCButton
            handleClick={cancelSelectedImages}
            type={'default'}
            block={true}
          >
            {t('products:cancelGoBack')}
          </UCButton>
        </div>
      </PopupSheet>
      <ImagePreview
        type={type}
        images={media}
        visible={openedPopup === PopupTypes.mediaCarousel}
        onClose={closeSubPopup}
        selectedMedias={selectedMedias}
        closedPopups={closePopup}
        fullScreen
        zIndex={1100}
        activeId={selectedImageId}
        onInsert={onInsert}
        handleItemSelected={onOverlayClick}
      />
    </>
  );
};

export default UploadFormMediaMobile;
