import { countLimitationsQuery } from '../gql';
import { FetchingGraphqlService } from './fetchingGraphql';
import LimitationsTypeEnums from '../core/globalTypes';

export class LimitationsService {
  private static get token() {
    return `Bearer ${localStorage.getItem('token')}`;
  }

  static async getLimitation(
    url: string,
    remoteHost: string,
    projectName: string,
    entities: LimitationsTypeEnums[],
    variables = {},
    query = ''
  ) {
    try {
      const Authorization = this.token;
      const headers = {
        'remote-host': `${projectName}.${remoteHost}`,
        Authorization
      };

      const convertedLimitations: any = {};
      const limitationsRes = await FetchingGraphqlService.fetchGraphql(
        url,
        headers,
        query || countLimitationsQuery,
        {
          entities,
          ...variables
        }
      );

      if (!('errors' in limitationsRes)) {
        const {
          data: { entityCountLimitation }
        } = limitationsRes;

        for (let i = 0; i < entityCountLimitation.length; i++) {
          convertedLimitations[entityCountLimitation[i].entity] = {
            ...entityCountLimitation[i]
          };
        }

        return convertedLimitations;
      }

      throw new Error(`Something went wrong, Can't getLimitation`);
    } catch (err) {
      throw new Error(`Something went wrong, Can't getLimitation`);
    }
  }
}
