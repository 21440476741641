import { Spin } from 'antd';

import styles from './styles.module.less';

type Props = {
  visible: boolean;
  tip?: string;
};

// This component absolutely positions itself and fills the wrapper
// Make sure to set the parent's position to relative
function Loading({ visible, tip }: Props) {
  return (
    <div
      className={
        visible ? styles.LoadingWrapperVisible : styles.LoadingWrapperInVisible
      }
    >
      <Spin tip={tip} />
    </div>
  );
}

export default Loading;
