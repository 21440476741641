import {
  projectQuery,
  requestPermissionsStoryMutation,
  projectPermissionsQuery,
  requestPermissionMutation
} from 'providers/PermissionAndLimitationProvider/gql';

import { FetchingGraphqlService } from './fetchingGraphql';

export class PermissionsService {
  private static get token() {
    return `Bearer ${localStorage.getItem('token')}`;
  }

  private static async projectQuery(url: string, projectId: string) {
    const Authorization = PermissionsService.token;

    try {
      const project = await FetchingGraphqlService.fetchGraphql(
        url,
        {
          Authorization
        },
        projectQuery,
        {
          id: projectId
        }
      );

      if (!('errors' in project)) {
        return project;
      }

      throw Error(`Something went wrong, Can't get project`);
    } catch (e) {
      throw Error(`Something went wrong, Can't get project`);
    }
  }

  private static async permissionsStoryQuery(
    url: string,
    projectId: string,
    permissionsStoryHeaders: any
  ) {
    try {
      const permissionsStory = await FetchingGraphqlService.fetchGraphql(
        url,
        permissionsStoryHeaders,
        requestPermissionsStoryMutation,
        {
          input: {
            projectId
          }
        }
      );

      if (!('errors' in permissionsStory)) {
        const notes: { [key: string]: object } = {};
        const story = permissionsStory.data.requestedPermissions;

        for (let i = 0; i < story.length; i++) {
          notes[story[i].id] = story[i].permissionRequest;
        }

        return notes;
      }

      throw new Error(`Something went wrong, Can't get notes permission`);
    } catch (e) {
      throw new Error(`Something went wrong, Can't get notes permission`);
    }
  }

  private static async projectPermissionQuery(
    url: string,
    projectId: string,
    projectPermissionsHeader: any
  ) {
    try {
      const permissions = await FetchingGraphqlService.fetchGraphql(
        url,
        projectPermissionsHeader,
        projectPermissionsQuery,
        {
          projectId
        }
      );

      if (!('errors' in permissions)) {
        return permissions.data.projectPermissions.map(
          (permission: { [key in 'alias']: string }) => permission.alias
        );
      }

      throw new Error(`Something went wrong,Can't get Project permissions`);
    } catch (e) {
      throw new Error(`Something went wrong,Can't get Project permissions`);
    }
  }

  static async requestForPermission(
    url: string,
    I: string,
    projectId: string,
    note: string
  ) {
    try {
      const Authorization = PermissionsService.token;
      const resPermission = await FetchingGraphqlService.fetchGraphql(
        url,
        {
          Authorization
        },
        requestPermissionMutation,
        {
          input: {
            permissionAlias: I,
            projectId,
            note
          }
        }
      );

      if (!('errors' in resPermission)) {
        return resPermission.data;
      }

      throw new Error(`Cant request for permission`);
    } catch (e) {
      throw new Error(`Cant request for permission`);
    }
  }

  static async permissionsInitialQuery(
    url: string,
    remoteHost: string,
    projectId: string
  ) {
    try {
      const project = await PermissionsService.projectQuery(url, projectId);
      const AuthorizationHeaders = {
        Authorization: PermissionsService.token
      };

      const notes = await PermissionsService.permissionsStoryQuery(
        url,
        projectId,
        AuthorizationHeaders
      );

      const permissions = await PermissionsService.projectPermissionQuery(
        url,
        projectId,
        AuthorizationHeaders
      );

      return {
        project: project.data.project,
        notes,
        permissions
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
}
