import { ReactNode } from 'react';

import { PERMISSION_CONSTANTS } from '../constants';

export type PermissionActionsType = typeof PERMISSION_CONSTANTS;

export enum ActionTypesEnum {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  See = 'See',
  Access = 'Access',
  Refund = 'Refund',
  Move = 'Move',
  Duplicate = 'Duplicate',
  Set = 'Set',
  Invite = 'Invite',
  Upsell = 'Upsell',
  CrossSell = 'CrossSell',
  RequestAccess = 'RequestAccess',
  Buy = 'Buy',
  Connect = 'Connect',
  Disconnect = 'Disconnect',
  Has = 'Has',
  Manage = 'Manage'
}

export enum PageTypesEnum {
  Page = 'Page',
  Variants = 'Variants',
  Currencies = 'Currencies',
  Orders = 'Orders',
  Category = 'Category',
  Settings = 'Settings',
  Customer = 'Customer',
  Product = 'Product',
  PhysicalProduct = 'PhysicalProduct',
  DigitalProduct = 'DigitalProduct',
  AppointmentProduct = 'AppointmentProduct',
  EventProduct = 'EventProduct',
  TableProduct = 'TableProduct',
  RentalProduct = 'RentalProduct',
  Member = 'Member',
  CashOnDelivery = 'CashOnDelivery',
  PosOnDelivery = 'PosOnDelivery',
  MoneyTransfer = 'MoneyTransfer',
  PayPal = 'PayPal',
  AmeriavPos = 'AmeriavPos',
  Ardshinbank = 'Ardshinbank',
  Evocabank = 'Evocabank',
  Acbabank = 'Acbabank',
  Braintree = 'Braintree',
  UcraftPay = 'UcraftPay',
  CashU = 'CashU',
  Mollie = 'Mollie',
  Revolut = 'Revolut',
  Square = 'Square',
  Paytabs = 'Paytabs',
  CurrencyExchangeRates = 'CurrencyExchangeRates',
  FreeShipping = 'FreeShipping',
  InStorePickup = 'InStorePickup',
  UPS = 'UPS',
  Usps = 'USPS',
  SendCloud = 'SendCloud',
  CanadaPost = 'CanadaPost',
  DHL = 'DHL',
  PerProductShippingRate = 'PerProductShippingRate',
  FlatShipping = 'FlatShipping',
  Aramex = 'Aramex',
  Role = 'Role',
  PasswordOnPage = 'PasswordOnPage',
  PageVersionB = 'PageVersionB',
  AccessControlList = 'AccessControlList',
  Analytics = 'Analytics',
  PaymentGateways = 'PaymentGateways',
  ShippingGateways = 'ShippingGateways',
  Collection = 'Collection',
  CollectionItem = 'CollectionItem',
  ProductFamily = 'ProductFamily',
  CustomCheckout = 'CustomCheckout',
  VisualEditor = 'VisualEditor',
  InternationalShippingProfile = 'InternationalShippingProfile',
  LocalShippingProfile = 'LocalShippingProfile',
  InventorySource = 'InventorySource',
  Domain = 'Domain',
  NotificationTemplate = 'NotificationTemplate',
  Field = 'Field',
  Attribute = 'Attribute',
  ManualDiscount = 'ManualDiscount',
  AutomaticDiscount = 'AutomaticDiscount',
  TaxRate = 'TaxRate',
  HooryHumanSupport = 'HooryHumanSupport',
  Dashboard = 'Dashboard',
  Products = 'Products',
  ItemManagement = 'ItemManagement',
  Customers = 'Customers',
  Discounts = 'Discounts',
  ActivityLog = 'ActivityLog',
  OwnProducts = 'OwnProducts',
  OwnOrders = 'OwnOrders',
  OwnCollectionItems = 'OwnCollectionItems',
  Ineco = 'Ineco'
}

export enum EnvironmentEnum {
  REMOTE_HOST = 'REMOTE_HOST',
  SSO_URL = 'SSO_URL',
  COMMERCE_URL = 'COMMERCE_URL',
  BUILDER_URL = 'BUILDER_URL'
}

enum LimitationsTypeEnums {
  PHYSICAL_PRODUCT = 'PHYSICAL_PRODUCT',
  DIGITAL_PRODUCT = 'DIGITAL_PRODUCT',
  APPOINTMENT_PRODUCT = 'APPOINTMENT_PRODUCT',
  BOOKING_PRODUCT = 'RESERVATION_PRODUCT',
  RENTAL_PRODUCT = 'RENTAL_PRODUCT',
  EVENT_PRODUCT = 'EVENT_PRODUCT',
  TABLE_PRODUCT = 'TABLE_PRODUCT',
  GIFT_CARD_PRODUCT = 'GIFT_CARD_PRODUCT',
  INVENTORY_SOURCE = 'INVENTORY_SOURCE',
  COLLECTION_ITEM = 'COLLECTION_ITEM',
  TAX_RATE = 'TAX_RATE',
  LANGUAGE = 'LANGUAGE',
  TEAM_MEMBER = 'TEAM_MEMBER',
  PRODUCT = 'PRODUCT'
}

export interface PermissionAndLimitationProviderProps {
  children?: ReactNode;
  userId: number | null;
  projectId: string;
  reactiveVarPermissionHandler?: (perm: number[]) => void;
  environment: IEnvironment;
  redirectHandler: () => void;
}

export type IEnvironment = {
  [key in EnvironmentEnum]: string;
};

export interface Owners {
  id: string;
}

export default LimitationsTypeEnums;

export interface IntermediateWrapperProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled: boolean;
  icon: ReactNode;
}
