const ImportCompletedFolderIcon = () => {
  return (
    <svg
      width="140"
      height="149"
      viewBox="0 0 140 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.60714 43.006C9.02584 34.0686 16.1182 26.5 25.0745 26.5H116.926C125.882 26.5 132.974 34.0686 132.393 43.006L126.474 134.006C125.944 142.159 119.177 148.5 111.007 148.5H30.9932C22.8233 148.5 16.0561 142.159 15.5258 134.006L9.60714 43.006Z"
        fill="#4DA789"
        stroke="url(#paint0_linear_371_9994)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5539 12.156C33.3212 5.01551 39.7293 0 47.0853 0H124L105.438 75H16L31.5539 12.156Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.6494 18H103.649L101.012 28H49.0117L51.6494 18Z"
        fill="#DAF2EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6376 37H81.6376L79 47H44L46.6376 37Z"
        fill="#DAF2EA"
      />
      <g filter="url(#filter0_b_371_9994)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.29842 60.2041C0.5977 50.9195 7.94205 43 17.253 43H28H49.0472C53.4821 43 57.718 44.8407 60.744 48.0829L79.256 67.9171C82.282 71.1593 86.5179 73 90.9528 73H112H122.227C131.727 73 139.134 81.2279 138.139 90.675L133.508 134.675C132.651 142.818 125.784 149 117.596 149H22.838C14.4685 149 7.5132 142.55 6.88333 134.204L1.29842 60.2041Z"
          fill="#75D9BD"
        />
        <path
          d="M1.79701 60.1665C1.11818 51.172 8.23302 43.5 17.253 43.5H28H49.0472C53.3435 43.5 57.4471 45.2832 60.3785 48.4241L78.8904 68.2583C82.011 71.6017 86.3793 73.5 90.9528 73.5H112H122.227C131.43 73.5 138.606 81.4708 137.642 90.6226L133.011 134.623C132.18 142.511 125.528 148.5 117.596 148.5H22.838C14.73 148.5 7.99209 142.251 7.38191 134.166L1.79701 60.1665Z"
          stroke="url(#paint1_radial_371_9994)"
        />
      </g>
      <rect x="15" y="57" width="38" height="10" rx="5" fill="white" />
      <defs>
        <filter
          id="filter0_b_371_9994"
          x="-12.3395"
          y="29.4086"
          width="164.16"
          height="133.183"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="6.7957" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_371_9994"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_371_9994"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_371_9994"
          x1="-16.5656"
          y1="74.2258"
          x2="60.768"
          y2="134.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.29535" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_371_9994"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5042 53.282) rotate(64.6838) scale(63.6185 117.188)"
        >
          <stop stopColor="white" stopOpacity="0.973411" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default ImportCompletedFolderIcon;
