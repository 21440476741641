import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { ReactComponent as PackageBox } from 'assets/icons/leftArrow.svg';

import Popup from 'components/mobile/Popup';
import MyProject from 'pages/projects';
import styles from './style.module.less';
import { MyProjectsProps } from './types';
import { ImgIcon } from 'components/mobile';
import { ShowElement } from 'components/shared';

const MyProjectPopup: FC<MyProjectsProps> = ({ visible, onClose }) => {
  const { t } = useTranslation(['projects', 'common']);

  const handleAddProject = () => {
    window.open(
      `${import.meta.env.VITE_APP_SSO_URL}/callback?target=onboarding-start`
    );
  };

  return (
    <Popup
      zIndex="4"
      onClose={onClose}
      visible={visible}
      popupScreen="fullScreen"
      forceRender
      title={t('projects:myProject', 'My Projects')}
      cancelValue={<Icon component={PackageBox} />}
      rightBarValue={
        <ImgIcon icon="plusIcon" paddingType="big" onClick={handleAddProject} />
      }
    >
      <div className={styles.my_project_popup_body}>
        <ShowElement isShow={visible}>
          <MyProject isOpenedDashboard />
        </ShowElement>
      </div>
    </Popup>
  );
};

export default MyProjectPopup;
