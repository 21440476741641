import { Image, ImageProps } from 'antd';
import { FC } from 'react';

interface Props extends ImageProps {}

const BaseImage: FC<Props> = props => {
  return <Image {...props} />;
};

const UcImage = Object.assign(Image, BaseImage);

export default UcImage;
