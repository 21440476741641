import { Skeleton } from 'antd';
import { FC } from 'react';

import { TagSkeletonProps } from 'components/shared/Skeleton/types';

const Tag: FC<TagSkeletonProps> = ({ styles = {} }) => {
  return (
    <Skeleton.Input
      active
      style={{ width: 60, height: 24, borderRadius: 20, ...styles }}
    />
  );
};

export default Tag;
