import React from 'react';
import { Card, Skeleton, Space } from 'antd';

import styles from 'pages/settings/pages/team/pages/inviteMember/styles.module.less';

export const InviteMemberLazySkeleton = () => {
  return (
    <div className={styles.invite_members} data-test="team-invite-members-page">
      <Card className={styles.invite_members_card}>
        <Space direction={'vertical'} className={styles.skeleton_space}>
          <Skeleton.Input className={styles.skeleton_title} active={true} />
          <Skeleton
            paragraph={{ rows: 2 }}
            className={styles.skeleton_title}
            active={true}
          />
          <Skeleton.Input className={styles.skeleton_subtitle} active={true} />
          <Skeleton
            paragraph={{ rows: 2 }}
            className={styles.skeleton_permissions_title}
            active={true}
          />
        </Space>
      </Card>
    </div>
  );
};
