import React, { FC } from 'react';
import { Select } from 'antd';
import { OptionProps } from 'antd/es/mentions';

const { Option } = Select;

interface BaseSelectOptionProps extends OptionProps {}

const BaseSelectOption: FC<BaseSelectOptionProps> = ({
  children,
  ...props
}) => {
  return <Option {...props}>{children}</Option>;
};

const UcSelectOption = Object.assign(BaseSelectOption, Option);

export default UcSelectOption;
