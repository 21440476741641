import { TextProps } from 'antd/lib/typography/Text';
import { BaseType } from 'antd/lib/typography/Base';
import { ReactNode } from 'react';

import { FontWightTypes } from '../types';

export enum BodyTextTypes {
  base = 'base',
  large = 'large',
  small = 'small',
  extraSmall = 'extraSmall',
  extraLarge = 'extraLarge'
}

export enum Cursor {
  MozInitial = '-moz-initial',
  Inherit = 'inherit',
  Initial = 'initial',
  Revert = 'revert',
  Unset = 'unset',
  MozGrab = '-moz-grab',
  WebkitGrab = '-webkit-grab',
  Alias = 'alias',
  AllScroll = 'all-scroll',
  Auto = 'auto',
  Cell = 'cell',
  ColResize = 'col-resize',
  ContextMenu = 'context-menu',
  Copy = 'copy',
  Crosshair = 'crosshair',
  Default = 'default',
  EResize = 'e-resize',
  EwResize = 'ew-resize',
  Grab = 'grab',
  Grabbing = 'grabbing',
  Help = 'help',
  Move = 'move',
  NResize = 'n-resize',
  NeResize = 'ne-resize',
  NeswResize = 'nesw-resize',
  NoDrop = 'no-drop',
  None = 'none',
  NotAllowed = 'not-allowed',
  NsResize = 'ns-resize',
  NwResize = 'nw-resize',
  NwseResize = 'nwse-resize',
  Pointer = 'pointer',
  Progress = 'progress',
  RowResize = 'row-resize',
  SResize = 's-resize',
  SeResize = 'se-resize',
  SwResize = 'sw-resize',
  Text = 'text',
  VerticalText = 'vertical-text',
  WResize = 'w-resize',
  Wait = 'wait',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out'
}

export interface BodyTextOnlyStyleProps {
  size?: BodyTextTypes | keyof typeof BodyTextTypes;
  fontWeight?: FontWightTypes | keyof typeof FontWightTypes;
  cursor?: Cursor | keyof typeof Cursor;
}

export interface BodyTextProps
  extends Omit<TextProps, 'type'>,
    BodyTextOnlyStyleProps {
  text?: string;
  className?: string;
  ellipsis?: boolean;
  children?: ReactNode;
  display?: 'block' | 'inline' | 'inlineBlock';
  type?: BaseType | 'secondary-dark' | 'default';
}
