import React, { FC } from 'react';
import usePermissionAndLimitationContext from 'providers/PermissionAndLimitationProvider/hooks/usePermissionAndLimitationContext';
import { ChildrenWithProps } from 'providers/PermissionAndLimitationProvider/core/helpers';
import { Popover } from 'antd';
import { LimitationAccessAlert } from 'providers/PermissionAndLimitationProvider/components/LimitationAccessAlert';
import LimitationsTypeEnums, {
  IntermediateWrapperProps
} from 'providers/PermissionAndLimitationProvider/core/globalTypes';
import { LockOutlined } from '@ant-design/icons';
import { antPopoverConfigs } from 'providers/PermissionAndLimitationProvider/core/constants';
import useQuicklyCheckPermissionAndLimitation from 'providers/PermissionAndLimitationProvider/hooks/useQuicklyCheckPermissionAndLimitation';

import { ChildrenProps, LimitationProps } from './types';
import IntermediateWrapper from '../IntermediateWrapper';

//@ts-ignore
const Limitation: FC<LimitationProps> = ({
  children,
  entity,
  loading,
  redirectHandlerForUpgrade,
  hasCanPermission,
  disabledAt = false,
  ...restProps
}) => {
  const {
    state: { isOwner, redirectHandler }
  } = usePermissionAndLimitationContext();

  const { checkLimitation } = useQuicklyCheckPermissionAndLimitation();

  if (hasCanPermission) {
    /**
     * This is the case when Limitation component is wrapped with Can component.
     * For some reason the children component is not triggering the onMouseEnter,
     * and it's being wrapped by additional div-s to trigger those events.
     */

    const { onMouseLeave, onMouseEnter, onClick, ...rest } =
      restProps as IntermediateWrapperProps;

    return (
      <IntermediateWrapper
        disabled={rest.disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {ChildrenWithProps(children, { ...rest })}
      </IntermediateWrapper>
    );
  }

  const LimitationWrapper = (
    entity: LimitationsTypeEnums | LimitationsTypeEnums[] | undefined
  ) => {
    const disabled = checkLimitation({
      disabledAt,
      entity
    });

    const propertiesChildren: ChildrenProps = {
      disabled
    };

    if (loading !== undefined) {
      propertiesChildren.loading = loading;
    }

    if (disabled) {
      return (
        <Popover
          {...antPopoverConfigs}
          content={
            <LimitationAccessAlert
              isOwner={isOwner}
              redirectHandler={redirectHandlerForUpgrade || redirectHandler}
            />
          }
        >
          <IntermediateWrapper disabled>
            {ChildrenWithProps(children, {
              ...propertiesChildren,
              icon: <LockOutlined />
            })}
          </IntermediateWrapper>
        </Popover>
      );
    }

    return ChildrenWithProps(children, { ...restProps });
  };

  return <>{LimitationWrapper(entity)}</>;
};

Limitation.defaultProps = {
  loading: false
};

export default Limitation;
