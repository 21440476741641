import { Form } from 'antd';
import { FormProps } from 'antd/lib/form/Form';

import UcFormItem, { ControlTypes, UcFormItemProps } from '../form-item';

export interface UcFormOptions extends Array<UcFormItemProps<ControlTypes>> {}

export interface UcFormProps<T> extends FormProps<T> {
  options?: UcFormOptions;
}

const BaseForm = <Values extends object>(props: UcFormProps<Values>) => {
  const { options, children, ...rest } = props;

  return (
    <Form<Values> {...rest}>
      <>
        {options?.map((option, idx) => {
          return <UcFormItem key={idx} {...option} />;
        })}
        {children}
      </>
    </Form>
  );
};

const UcForm = Object.assign(BaseForm, Form, { Item: UcFormItem });

export default UcForm;
