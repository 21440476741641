export default function getRoleColor(role: string) {
  switch (role?.toLowerCase()) {
    case 'owner':
      return 'geekblue';
    case 'designer':
      return 'volcano';
    case 'admin':
      return 'cyan';
    case 'billing admin':
      return 'green';
    case 'content writer':
      return 'purple';
    case 'marketing specialist':
      return 'magenta';
    default:
      return 'blue';
  }
}
