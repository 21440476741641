import {
  useState,
  useEffect,
  useMemo,
  useDeferredValue,
  useLayoutEffect
} from 'react';
import { KeyValuePair, SortTypes } from 'core/globalTypes';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const qsParseOptions = { ignoreQueryPrefix: true };
const qsStringifyOptions = { addQueryPrefix: true };

interface ISortFilterData {
  filterConstant?: KeyValuePair;
  sortObj?: KeyValuePair;
  defaultSortValue?: string;
  initialFilters?: KeyValuePair;
  additionalFilterKeys?: string[];
}

const useFilterQuery = (initialData?: ISortFilterData) => {
  const location = useLocation();
  const [queryObject, setQueryObject] = useState<KeyValuePair>(
    qs.parse(location.search, qsParseOptions)
  );

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [filtersQueryString, setFiltersQueryString] = useState('');
  const filtersQueryStringDeferred = useDeferredValue(filtersQueryString);

  const getQueryStringWithoutPageCount = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, count, ...rest } = queryObject;

    return qs.stringify(rest, qsStringifyOptions);
  };

  const filterKeys = useMemo(() => {
    if (initialData?.filterConstant) {
      return Object.keys(initialData?.filterConstant);
    }

    return [];
  }, [initialData?.filterConstant]);

  useEffect(() => {
    setQueryObject(qs.parse(location.search, qsParseOptions));
  }, [location.search]);

  useLayoutEffect(() => {
    setIsFiltersChanged(filtersQueryStringDeferred !== filtersQueryString);
  }, [filtersQueryStringDeferred, filtersQueryString]);

  useLayoutEffect(() => {
    const filterQueryObject: typeof queryObject = {};

    const searchQueryParam = queryObject['search'];

    if (searchQueryParam !== undefined) {
      filterQueryObject['search'] = searchQueryParam;
    }

    initialData?.additionalFilterKeys?.forEach(key => {
      if (queryObject[key] !== undefined) {
        filterQueryObject[key] = queryObject[key];
      }
    });

    filterKeys.forEach(key => {
      const filterQueryParam = queryObject[key];

      if (filterQueryParam !== undefined) {
        filterQueryObject[key] = queryObject[key];
      }
    });

    setFiltersQueryString(qs.stringify(filterQueryObject, qsStringifyOptions));
  }, [queryObject, filterKeys]);

  const filteredQueryData = useMemo(() => {
    const filter: any = [];

    if (initialData?.filterConstant) {
      filterKeys.forEach((item: keyof typeof queryObject) => {
        if (queryObject[item] || typeof queryObject[item] === 'boolean') {
          filter.push({
            ...initialData?.filterConstant?.[item],
            value: queryObject[item]
          });
        }
      });
    }

    return filter;
  }, [initialData, filterKeys]);

  const hasFiltersOrSearch = useMemo(() => {
    const hasFiltersOrSearch = Boolean(
      filteredQueryData.length ||
        queryObject.search ||
        initialData?.additionalFilterKeys?.some(key => queryObject[key])
    );

    return hasFiltersOrSearch;
  }, [
    filteredQueryData.length,
    queryObject,
    initialData?.additionalFilterKeys
  ]);

  const orderQueryData = () => {
    const orderBy: any = [];
    const sort = queryObject.sort;

    if (initialData?.sortObj) {
      orderBy.push(
        initialData.sortObj?.[sort] || initialData.sortObj[SortTypes.Newest]
      );
    }

    return orderBy;
  };

  return {
    queryObject,
    filteredQueryData: filteredQueryData,
    queryString: location.search,
    queryStringWithoutPageCount: getQueryStringWithoutPageCount(),
    orderBy: orderQueryData(),
    isFiltersChanged,
    hasFiltersOrSearch
  };
};

export default useFilterQuery;
