import { FC, useCallback } from 'react';
import { Space } from 'antd';
import { countries } from 'countries-list';
import { isEqual } from 'lodash';

import Form from 'components/core/form';
import { LoadingWrapper } from 'components/shared';
import { LocaleProps } from './types';
import { Skeleton as CustomSkeleton } from '../index';
import styles from './styles.module.less';

const Locale: FC<LocaleProps> = ({
  locales,
  localesLoading,
  localeKey = 'internationalCode'
}) => {
  const siteCountries = countries;

  const country = useCallback((country: string | undefined) => {
    return Object.values(siteCountries).find(x => {
      return isEqual(x.name, country);
    });
  }, []);

  return (
    <LoadingWrapper
      loading={localesLoading}
      indicator={<CustomSkeleton.Input width={100} height={40} />}
    >
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => {
          return prev.locale !== next.locale;
        }}
      >
        {() => (
          <Form.Item
            name="locale"
            noStyle
            type="select"
            innerProps={{
              className: styles.localeSelect,
              options: locales?.map((l: any) => ({
                value: l[localeKey],
                label: (
                  <Space>
                    <span>{country(l?.country)?.emoji}</span>
                    <span>{l.name}</span>
                  </Space>
                )
              }))
            }}
          />
        )}
      </Form.Item>
    </LoadingWrapper>
  );
};

export default Locale;
