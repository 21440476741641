import React, { FC } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import {
  CustomerServiceFilled,
  FolderOpenOutlined,
  PlayCircleFilled
} from '@ant-design/icons';
import { OperationVariables } from '@apollo/client/core';
import { QueryLazyOptions } from '@apollo/client/react/types/types';
import { useTranslation } from 'react-i18next';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';
import Overlay from 'packages/Media/UIComponents/Overlay';
import Empty from 'packages/Media/Empty';
import { PageType } from 'packages/Media/types';

import MobileSearch from 'components/mobile/Search';
import MainTitle from 'components/mobile/titles/MainTitle';
import { UCCheckboxSingle } from 'components/mobile/UCCheckbox';
import { MediaWithFoldersData } from 'pages/products/products/Mobile/types';
import { Skeleton } from 'components/shared';
import { IDataItem } from 'components/mobile/MobileDropdown';
import styles from 'components/mobile/media/UploadFormMediaMobile/styles.module.less';
import {
  IFolderInfo,
  PopupTypes
} from 'components/mobile/media/UploadFormMediaMobile';
import useMedia from 'hooks/useMedia';

interface Props {
  closePopup: () => void;
  mediaManagerData: IDataItem[];
  total: number | undefined;
  media: MediaWithFoldersData[] | [];
  hiddenIds: string[] | undefined;
  selectedMedias: string[];
  onOverlayClick: (ard: string) => void;
  multiSelect: boolean;
  loading: boolean;
  type: PageType;
  setParentId?: (arg: string | null) => void;
  setParentIdMain?: (arg: string | null) => void;
  getImages: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  order: string;
  column: string;
  setSearch: (arg: string | null) => void;
  onInsert: (ids: string[]) => void;
  setSelectedMedias: (ids: string[] | []) => void;
  initialIds: string[] | [];
  visible?: boolean;
  searchInputValue: (value: string) => void;
  openMediaCarousel: (id: string) => void;
  setFolderInfo: (arg: IFolderInfo) => void;
  folderInfo: IFolderInfo;
  setOpenPopup: (arg: PopupTypes) => void;
  onInsertSelectedImages: () => void;
  onBreadcrumbClick: (id: string | null) => void;
  handleCheckCancelingModal: (id: string) => void;
  resetCheckedItems: () => void;
}

const MediaViewSection: FC<Props> = ({
  total,
  media,
  hiddenIds,
  selectedMedias,
  onOverlayClick,
  loading,
  type,
  setParentIdMain,
  searchInputValue,
  openMediaCarousel,
  setFolderInfo,
  setOpenPopup,
  onInsertSelectedImages,
  onBreadcrumbClick,
  handleCheckCancelingModal,
  resetCheckedItems
}) => {
  const { t } = useTranslation(['common', 'products']);
  const { thumbnailPath } = useMedia();

  return (
    <>
      <MobileSearch
        placeholder={t('common:search')}
        onChange={searchInputValue}
      />
      <Row className={styles.ImagesContainer}>
        <Col span={24} className={styles.ImagesContainer}>
          {total && total > 0 ? (
            <Row gutter={[16, 16]}>
              {media.map((v: MediaWithFoldersData) => {
                const isItemHidden = hiddenIds?.includes(v.id);
                const isFolder = v.__typename === 'Folder';
                const checked = selectedMedias.includes(v.id);

                if (isItemHidden || isFolder) {
                  return null;
                }

                const cover =
                  type === 'audios' ? (
                    v.thumbnail ? (
                      <LazyImage
                        src={thumbnailPath[type] + v.thumbnail}
                        alt="media"
                        height="100%"
                      />
                    ) : (
                      <CustomerServiceFilled className={styles.AudioIcon} />
                    )
                  ) : (
                    <div className={styles.image}>
                      <LazyImage
                        src={thumbnailPath[type] + v.thumbnail}
                        alt="media"
                        height={'100%'}
                        width={'100%'}
                      />
                      {type === 'videos' && (
                        <PlayCircleFilled className={styles.PlayIcon} />
                      )}
                    </div>
                  );

                return (
                  <Col span={8} key={v.id} className={styles.imageColWrapper}>
                    <Card
                      className={styles.styledCard}
                      bodyStyle={{
                        padding: '8px 0'
                      }}
                      cover={
                        <div className={styles.coverWrapper}>
                          {cover}
                          <Overlay
                            onClick={
                              isFolder
                                ? undefined
                                : () => openMediaCarousel(v.id)
                            }
                            bottomRightContent={
                              isFolder ? null : (
                                <UCCheckboxSingle
                                  checked={checked}
                                  onChange={() => {
                                    onOverlayClick(v.id);
                                    handleCheckCancelingModal(v.id);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                      }
                    >
                      <Meta
                        description={
                          <Typography.Paragraph
                            className={styles.FileName}
                            ellipsis
                            title={v.name}
                          >
                            {`${v?.name}${'.' + v?.extension}`}
                          </Typography.Paragraph>
                        }
                      />
                    </Card>
                  </Col>
                );
              })}
              {loading && <Skeleton.Grid count={9} width={100} height={100} />}
            </Row>
          ) : (
            <>
              {total === 0 ? (
                <Empty type={type} />
              ) : (
                <Row gutter={[24, 18]}>
                  <Skeleton.Grid count={6} width={90} height={90} />
                </Row>
              )}
            </>
          )}
        </Col>
        <Col span={24}>
          {total && total > 0 ? (
            <Row gutter={[16, 16]}>
              {media.map((v: MediaWithFoldersData) => {
                const isFolder = v.__typename === 'Folder';

                if (!isFolder) {
                  return null;
                }

                const cover = (
                  <>
                    <MainTitle
                      title={t('common:folders')}
                      font={16}
                      addClass={styles.headerTitle}
                    />
                    <FolderOpenOutlined className={styles.folderIcon} />
                  </>
                );

                return (
                  <Col span={8} key={v.id}>
                    <Card
                      className={styles.styledCardFolder}
                      bodyStyle={{
                        padding: '8px 0'
                      }}
                      cover={
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            setParentIdMain && setParentIdMain(v.id);
                            onBreadcrumbClick(v.id);
                            setFolderInfo({ state: true, name: v?.name });
                            setOpenPopup(PopupTypes.folder);
                          }}
                          className={styles.coverWrapper}
                        >
                          {cover}
                        </div>
                      }
                    >
                      <Meta
                        description={
                          <>
                            <Typography.Paragraph
                              className={styles.fileName}
                              ellipsis
                              title={v.name}
                            >
                              {`${v?.name}`}
                            </Typography.Paragraph>
                            <div className={styles.count}>
                              {'children' in v && v?.children?.length}&nbsp;
                              {t('images')}
                            </div>
                          </>
                        }
                      />
                    </Card>
                  </Col>
                );
              })}
              {loading && <Skeleton.Grid count={6} width={90} height={90} />}
            </Row>
          ) : (
            <>
              {total === 0 ? (
                <Empty type={type} />
              ) : (
                <Row gutter={[24, 18]}>
                  <Skeleton.Grid count={3} width={100} height={100} />
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>

      <Row className={styles.mediasAddBlock}>
        <div className={styles.mediasAddBlockBtnWrapper}>
          <Col span={9} className={styles.mediasAddCol}>
            <span>
              {selectedMedias.length} {t('common:itemSelected')}
            </span>
          </Col>
          <Col span={2} className={styles.mediasAddColBtn}>
            <Button
              disabled={!selectedMedias.length}
              type="default"
              onClick={() => {
                onInsertSelectedImages();
                resetCheckedItems();
              }}
              className={styles.addBtn}
            >
              {t('common:addSelection')} &nbsp; {`${selectedMedias.length}`}
            </Button>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default MediaViewSection;
