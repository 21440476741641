import { Empty, EmptyProps } from 'antd';
import { FC } from 'react';

import styles from './styles.module.less';

interface Props extends EmptyProps {
  fullHeight?: boolean;
}

const BaseEmpty: FC<Props> = props => {
  return (
    <Empty
      {...props}
      className={`${props.className || ''} ${
        props.fullHeight ? styles.empty_full_height : ''
      }`}
    />
  );
};

const UcEmpty = Object.assign(BaseEmpty, Empty);

export default UcEmpty;
