import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { MenuProps } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import { ActionSheet } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/action-sheet';
import { changeStatusbarColor } from 'helpers';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import useLimitationFunction from 'providers/PermissionAndLimitationProvider/hooks/useLimitationFunction';
import { LimitationFunctionChildren } from 'providers/PermissionAndLimitationProvider/hooks/types';

import { Dropdown } from 'components/basic';
import { ActionMenuItem, ActionMenuProps } from './types';
import { ImgIcon } from 'components/shared';
import styles from './styles.module.less';

const ActionMenu: FC<ActionMenuProps> = ({
  permissionConfig,
  onEdit,
  onDelete,
  onDuplicate,
  style,
  iconSize,
  className,
  onHide,
  onShow,
  actions,
  light = false,
  loading = false,
  backgroundOnHover,
  duplicateLimitationEntities
}) => {
  const { t } = useTranslation('common');
  const CanFunction = useCanFunction('menu');
  const isMobile = useReactiveVar(isMobileDevice);
  const [visible, setVisible] = useState(false);

  const LimitationFunction = useLimitationFunction();

  useEffect(() => {
    return () => {
      if (isMobile) {
        changeStatusbarColor('fffffF');
      }
    };
  }, []);

  const actionList: ActionMenuItem[] = actions || [
    {
      key: 'edit',
      text: t('edit', 'Edit'),
      action: onEdit,
      isShow: true,
      permission: permissionConfig?.edit,
      dataTest: createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Option,
        prefix: 'edit'
      })
    },
    {
      key: 'duplicate',
      isShow: true,
      text: t('duplicate', 'Duplicate'),
      action: onDuplicate,
      dataTest: createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Option,
        prefix: 'duplicate'
      }),
      permission: permissionConfig?.create,
      limitationEntities: duplicateLimitationEntities || []
    },
    {
      key: 'hide',
      text: t('hide', 'Hide'),
      action: onHide,
      isShow: true,
      dataTest: createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Option,
        prefix: 'hide'
      })
    },
    {
      key: 'show',
      text: t('show', 'Show'),
      action: onShow,
      isShow: true,
      dataTest: createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Option,
        prefix: 'show'
      })
    },
    {
      key: 'delete',
      text: t('delete', 'Delete'),
      action: onDelete,
      permission: permissionConfig?.delete,
      isShow: true,
      danger: true,
      dataTest: createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Option,
        prefix: 'delete'
      })
    }
  ];

  const menuItems = actionList.map(
    ({
      key,
      isShow = true,
      text,
      action,
      danger,
      dataTest,
      permission,
      limitationEntities
    }) => {
      return Boolean(action) && isShow
        ? LimitationFunction({
            entity: limitationEntities,
            isMobile,
            children: CanFunction({
              I: permission?.I,
              Or: permission?.Or,
              RequestAccess: permission?.RequestAccess,
              isMobile,
              children: {
                key,
                'data-test':
                  dataTest ||
                  createDataTestAttribute({
                    dataTestAttribute: DataTestAttributes.Option,
                    prefix: key
                  }),
                danger,
                [isMobile ? 'text' : 'label']: text,
                onClick: () => {
                  action?.();
                }
              }
            }) as LimitationFunctionChildren
          })
        : null;
    }
  );

  const filteredMenuItems = useMemo(() => {
    return menuItems.filter(Boolean) as Action[];
  }, [menuItems]);

  const icon = (
    <ImgIcon
      onClick={e => {
        e.stopPropagation();
        setVisible(true);
        changeStatusbarColor('737373');
      }}
      size={iconSize}
      icon={light ? 'ellipsis-light' : 'ellipsis'}
      data-testid="ellipsis"
      data-test="action-menu-dropdown"
      backgroundOnHover={backgroundOnHover}
    />
  );

  if (isMobile) {
    return (
      <>
        {icon}
        <ActionSheet
          closeOnAction
          actions={filteredMenuItems}
          visible={visible}
          onClose={() => {
            setVisible(false);
            changeStatusbarColor('ffffff');
          }}
        />
      </>
    );
  }

  return !loading ? (
    <Dropdown
      trigger={['click']}
      className={`columnActions`}
      placement="bottomRight"
      overlayClassName={styles.actionMenu}
      dropdownRender={menu => (
        <div onClick={e => e.stopPropagation()}>{menu}</div>
      )}
      menu={
        {
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Menu,
            prefix: 'action'
          }),
          className: `${className || ''}`,
          style: { minWidth: 95, marginTop: 8, ...style },
          items: menuItems
        } as MenuProps
      }
    >
      {icon}
    </Dropdown>
  ) : (
    <LoadingOutlined spin />
  );
};

export default memo(ActionMenu);
