// Mobile app only
const changeStatusbarColor = (color: string) => {
  if (window.median) {
    window.median.statusbar.set({
      style: 'light',
      color,
      overlay: false
    });
  }
};

export default changeStatusbarColor;
