import { BulkActionEntity } from 'generatedHooks/commerce/generated';
import { RowSelectionProps } from 'services/bulkActions/types';

export const defaultPagination = {
  size: 'small',
  showQuickJumper: false,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50', '100', '200'],
  defaultPageSize: 20,
  position: ['bottom', 'left']
};

export const rowSelectionPropsFallback: RowSelectionProps = {
  entity: '' as BulkActionEntity,
  allRowsSelected: false,
  excludedRowKeys: [],
  selectedRowKeys: [],
  setAllRowsSelected: () => {},
  setExcludedRowKeys: () => {},
  setSelectedRowKeys: () => {},
  resetSelections: () => {},
  totalEntitiesSelected: 0,
  totalEntitiesCount: 0
};
