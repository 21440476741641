export enum HapticStyleNameEnum {
  ImpactLight = 'impactLight',
  ImpactMedium = 'impactMedium',
  ImpactHeavy = 'impactHeavy',
  NotificationSuccess = 'notificationSuccess',
  NotificationWarning = 'notificationWarning',
  NotificationError = 'notificationError'
}

export class HapticHelper {
  static triggerVibrationEvent(style: HapticStyleNameEnum) {
    window?.median?.haptics.trigger({ style });
  }
}
