import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { MediaEmptyStateIcon } from 'components/shared/SVG';
import styles from './styles.module.less';
import { PageType } from './types';

const { Title } = Typography;

// const Container = styled.div`
//   height: 70vh;
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   align-content: center;
//   flex-wrap: wrap;
//
//   > * {
//     width: 100%;
//     text-align: center;
//   }
// `;

// const File = styled.div`
//   font-size: 50px;
//   color: var(--ant-black-25);
// `;
//
// const P = styled.p`
//   color: var(--ant-black-45);
// `;

/**
 * Renders media empty component.
 * @param type - Media type.
 * @returns ReactNode.
 */
function Empty({ type }: { type: PageType }) {
  const { t } = useTranslation('media');

  return (
    <div className={styles.Container}>
      <div className={styles.File}>
        <MediaEmptyStateIcon />
      </div>
      <Title level={4}>{t('emptyTitle')}</Title>
      <p className={styles.P}>{t('emptySubtitle', { type })}</p>
    </div>
  );
}

export default Empty;
