import { Typography } from 'components/basic';
import styles from './styles.module.less';
import { ArrowRightIcon } from 'components/shared/SVG';

const { UcLink } = Typography;

const LinkNavigation = () => {
  return (
    <UcLink to="/products" className={styles.link}>
      Real Time Full Report <ArrowRightIcon className={styles.link_icon} />
    </UcLink>
  );
};

export default LinkNavigation;
