import { ChangeEvent, FC, useState } from 'react';
import { PureNullable } from 'core/globalTypes';

import { Input } from 'components/basic';
import { NameInputProps } from './types';

const NumberInput: FC<NameInputProps> = ({
  value = '',
  onChange,
  onChangeValue,
  ...res
}) => {
  const [number, setNumber] = useState<PureNullable<number>>(null);

  const triggerChange = (changedValue: number | string) => {
    onChange?.(changedValue);
  };

  const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === '') {
      setNumber(null);
      onChangeValue?.(e);

      triggerChange('');
    } else {
      if (!/^\d*\.?\d*$/.test(e.target.value.trim())) {
        return;
      }

      const newNumber = Math.abs(Number(e.target.value.trim()));

      setNumber(newNumber);
      onChangeValue?.(e);

      triggerChange(newNumber);
    }
  };

  return (
    <span>
      <Input
        type="text"
        value={number ?? value}
        onChange={onNumberChange}
        {...res}
      />
    </span>
  );
};

export default NumberInput;
