import { HTMLAttributeAnchorTarget } from 'react';

interface IOptions {
  url: string;
  fileName: string;
  target?: HTMLAttributeAnchorTarget;
}

const downloadFile = ({ url, fileName, target = '_self' }: IOptions) => {
  const link = document.createElement('a');
  link.target = target;
  link.style.display = 'none';
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
