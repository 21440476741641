import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { Flex } from 'antd';
import { linkTypes } from 'apollo/link';
import {
  useUploadMediaFilesMutation,
  MediaFile
} from 'generatedHooks/commerce/generated';
import { Maybe, PureNullable, Undefinable } from 'core/globalTypes';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { SystemMessage } from 'helpers';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from '@hello-pangea/dnd';
import { reorder } from 'helpers/commonHelpers/reorder';
import { mediaTypeAcceptFormats } from 'packages/Media/constants';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import MediaItem from './components/MediaItem';
import UploadButton from './components/UploadButton';
import { MediaItemSize } from './types';
import { ShowElement } from '../';
import MediaInfoModal from './components/MediaInfoModal';

interface Props {
  onChange?: (value: PureNullable<MediaFile | MediaFile[]>) => void;
  value?: PureNullable<MediaFile | MediaFile[]>;
  multiple?: boolean;
  setDefaultImage?: (imageId: Maybe<string>) => void;
  defaultMediaId?: Maybe<string>;
  onImageUploaded?: Undefinable<Function>;
  accept?: Undefinable<string>;
  draggable?: boolean;
  size?: MediaItemSize;
  dataTest?: string;
}

const UcUpload: FC<Props> = ({
  onChange,
  value,
  multiple = false,
  setDefaultImage,
  defaultMediaId,
  onImageUploaded,
  accept,
  draggable = false,
  size = MediaItemSize.Large,
  dataTest
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] =
    useState<Undefinable<MediaFile>>(undefined);

  const fileInputRef = useRef<PureNullable<HTMLInputElement>>(null);

  const orderedValue = useMemo(
    () => orderBy(value as MediaFile[], ['order'], 'asc'),
    [value]
  );

  const [uploadMedia, { loading: uploadMediaLoading }] =
    useUploadMediaFilesMutation({
      context: {
        urlType: linkTypes.file,
        hideMessages: true
      },
      fetchPolicy: 'network-only'
    });

  const removeImage = (uid?: string) => {
    if (uid && isArray(value)) {
      const newValue = value?.filter(v => !isEqual(v?.id, uid));
      onChange?.(newValue);

      if (onImageUploaded) {
        onImageUploaded(newValue);
      }
    } else {
      onChange?.(null);
    }
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (
        !result.destination ||
        result.destination.index === result.source.index
      ) {
        return;
      }

      const reorderedList = reorder(
        orderedValue,
        result.source.index,
        result.destination.index
      )?.map((mediaFile, index) => ({
        ...mediaFile,
        order: index + 1
      }));

      onChange?.(reorderedList);
    },
    [value, onChange]
  );

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files as FileList;

    if (files?.length) {
      try {
        const data = await uploadMedia({
          variables: {
            files
          }
        });

        const newFiles = data?.data?.uploadMediaFiles as MediaFile[];

        if (multiple) {
          const newValue =
            value && isArray(value) ? [...value, ...newFiles] : newFiles;

          onChange?.(
            newValue?.map((mediaFile, index) => ({
              ...mediaFile,
              order: index + 1
            }))
          );

          if (onImageUploaded) {
            onImageUploaded(newValue);
          }
        } else {
          onChange?.(newFiles[0]);

          if (onImageUploaded) {
            onImageUploaded(newFiles);
          }
        }
      } catch (error) {
        SystemMessage.error(t('somethingWentWrongWhileUploading'));
      } finally {
        event.target.value = '';
        setLoading(false);
      }
    }
  };

  const onOpenFileDialog = () => fileInputRef.current?.click();

  return (
    <>
      <input
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Input,
          prefix: `${dataTest}-upload`
        })}
        multiple={multiple}
        hidden
        onChange={handleChange}
        ref={fileInputRef}
        type="file"
        accept={accept || mediaTypeAcceptFormats['images']}
      />
      <ShowElement
        fallback={
          <ShowElement isShow={!multiple}>
            <UploadButton
              size={size}
              onClick={onOpenFileDialog}
              loading={loading || uploadMediaLoading}
            />
          </ShowElement>
        }
        isShow={Boolean(!multiple && value)}
      >
        <div onClick={value ? onOpenFileDialog : undefined}>
          <MediaItem
            size={size}
            setSelectedFile={setSelectedFile}
            onChange={onChange}
            value={value}
            loading={loading || uploadMediaLoading}
            file={value as MediaFile}
            removeImage={removeImage}
          />
        </div>
      </ShowElement>
      <ShowElement isShow={multiple}>
        <Flex>
          <UploadButton
            onClick={onOpenFileDialog}
            size={size}
            loading={loading || uploadMediaLoading}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction="horizontal" droppableId="images">
              {provided => (
                <Flex
                  style={{ overflowX: 'auto', paddingRight: 200 }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {orderedValue?.map((file, index) => (
                    <Draggable
                      isDragDisabled={!draggable}
                      key={file.id}
                      draggableId={file.id}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginLeft: '12px'
                          }}
                        >
                          <MediaItem
                            size={size}
                            setSelectedFile={setSelectedFile}
                            onChange={onChange}
                            value={value}
                            loading={false}
                            isDefault={isEqual(defaultMediaId, file.id)}
                            file={file}
                            setDefaultImage={setDefaultImage}
                            removeImage={() => removeImage(file.id)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </ShowElement>
      <ShowElement isShow={!!selectedFile}>
        <MediaInfoModal
          onChange={onChange}
          value={value}
          file={selectedFile}
          onCancel={() => setSelectedFile(undefined)}
          open={!!selectedFile}
        />
      </ShowElement>
    </>
  );
};

export default UcUpload;
