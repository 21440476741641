import { TableRowSelection } from 'antd/lib/table/interface';
import { uniq } from 'lodash';
import { useLayoutEffect } from 'react';
import { RowSelectionProps } from 'services/bulkActions/types';

interface UseRowSelectionArgs extends RowSelectionProps {
  dataSourceKeys: string[];
  loading: boolean;
  totalEntitiesSelected: number;
  totalEntitiesCount: number;
}

const useRowSelection = <T>({
  allRowsSelected,
  excludedRowKeys,
  selectedRowKeys,
  setExcludedRowKeys,
  setSelectedRowKeys,
  dataSourceKeys,
  loading,
  totalEntitiesCount,
  totalEntitiesSelected
}: UseRowSelectionArgs) => {
  const showBulkPanel = totalEntitiesSelected > 0;

  const onSelectionChange: TableRowSelection<T>['onChange'] = keys => {
    const selectedKeys = keys as string[];
    const newSelectedKeys = selectedKeys.filter(
      key => !selectedRowKeys.includes(key)
    );

    const deselectedKeys = selectedRowKeys.filter(
      key => dataSourceKeys.includes(key) && !selectedKeys.includes(key)
    );

    if (allRowsSelected) {
      if (deselectedKeys.length) {
        setExcludedRowKeys(prev => {
          const excluded = uniq(prev.concat(deselectedKeys));

          return excluded;
        });
      } else if (newSelectedKeys.length) {
        setExcludedRowKeys(prev => {
          const excluded = uniq(
            prev.filter(key => !newSelectedKeys.includes(key))
          );

          return excluded;
        });
      }
    }

    setSelectedRowKeys(prev => {
      const preservedRowKeys = prev.filter(
        key => !deselectedKeys.includes(key)
      );

      return uniq(preservedRowKeys.concat(selectedKeys));
    });
  };

  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    if (allRowsSelected) {
      const allKeys = dataSourceKeys.filter(
        id => !excludedRowKeys.includes(id)
      );

      setSelectedRowKeys(prev => {
        return uniq(prev.concat(allKeys));
      });
    }
  }, [dataSourceKeys, allRowsSelected, loading]);

  return {
    totalEntitiesCount,
    totalEntitiesSelected,
    showBulkPanel,
    onSelectionChange
  };
};

export default useRowSelection;
