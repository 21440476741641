import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

const { TextArea } = Input;

const BaseTextArea = (props: TextAreaProps) => {
  return <TextArea {...props} />;
};

const UcTextArea = Object.assign(TextArea, BaseTextArea);

export default UcTextArea;
