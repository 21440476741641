import { Skeleton, Col } from 'antd';

type Props = {
  count?: number;
};

function GridSkeleton({ count = 10 }: Props) {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Col key={i}>
          <Skeleton.Input
            active
            style={{
              width: 180,
              height: 140,
              borderRadius: 'var(--ant-border-radius-base)'
            }}
          />
        </Col>
      ))}
    </>
  );
}

export default GridSkeleton;
