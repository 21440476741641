/**
 * Finds the Ant Design confirmation modal(opened by Modal.confirm()) node and returns `true` if it exists in the DOM, otherwise returns false.
 */

const isConfirmModalOpen = (): boolean => {
  //NOTE: Usage of this className should be changed if it is after Ant Design updates.
  const modalNode = document.querySelector(
    'div.ant-modal-confirm[role=dialog]'
  );

  return Boolean(modalNode);
};

export default isConfirmModalOpen;
