import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  components: {
    Form: {
      labelColor: '#595959'
    },
    Layout: {
      headerBg: '#F1F1F1',
      siderBg: '#F1F1F1',
      headerHeight: 88,
      bodyBg: '#F1F1F1',
      lightSiderBg: '#F1F1F1'
    },
    Menu: {
      itemHeight: 44,
      itemActiveBg: '#ffffff'
    },
    Collapse: {},
    Select: {
      colorBorder: '#f5f5f5',
      optionSelectedBg: '#f0f0f0',
      colorBgBase: '#f5f5f5',
      selectorBg: '#f5f5f5'
    },
    Table: {
      paddingContentHorizontal: 24,
      headerBg: '#ffffff',
      fontSize: 14,
      cellPaddingInline: 24
    },
    Skeleton: {
      gradientFromColor: 'rgba(0, 0, 0, 0.04)'
    },
    Input: {
      colorBgBase: '#f5f5f5',
      colorBorder: '#f5f5f5',
      colorBgContainer: '#f5f5f5'
    },
    InputNumber: {
      colorBgBase: '#f5f5f5',
      colorBorder: '#f5f5f5',
      colorBgContainer: '#f5f5f5'
    },
    Checkbox: {
      borderRadiusSM: 6
    },
    Tooltip: {
      colorTextLightSolid: '#434343',
      colorBgSpotlight: '#ffffff',
      fontSize: 14
    },
    Button: {
      colorBgContainer: '#f5f5f5',
      colorBgContainerDisabled: '#e0f1ff',
      fontSizeLG: 14,
      fontWeight: 500,
      borderRadius: 12,
      borderRadiusLG: 12,
      borderRadiusXS: 12,
      borderRadiusSM: 12,
      borderRadiusOuter: 12
    },
    Card: {
      borderRadius: 12,
      boxShadow: 'none',
      borderRadiusLG: 12
    },
    Tabs: {
      inkBarColor: 'unset',
      titleFontSizeLG: 16
    },
    Typography: {
      fontFamilyCode: 'Inter',
      fontSize: 14
    },
    Modal: {
      titleFontSize: 16
    },
    Tag: {
      fontSize: 12,
      fontSizeSM: 12
    },
    Steps: {},
    Dropdown: {
      paddingBlock: 8
    }
  },
  token: {
    colorPrimary: '#235DF4',
    colorLink: '#235DF4',
    fontFamilyCode: 'Inter',
    fontFamily: 'Inter',
    'red-6': '#F5222D',
    borderRadius: 12,
    colorBgLayout: '#F1F1F1',
    colorTextLabel: '#595959',
    fontSize: 14,
    fontSizeSM: 14,
    fontSizeLG: 14,
    fontSizeXL: 24,
    // lineHeight: 24,
    // lineHeightSM: 22,
    // lineHeightLG: 24,
    fontSizeHeading1: 32,
    fontSizeHeading2: 28,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    // lineHeightHeading1: 44,
    // lineHeightHeading2: 40,
    // lineHeightHeading3: 36,
    // lineHeightHeading4: 28,
    'blue-1': '#f0f8ff',
    'blue-2': '#e0f1ff',
    'blue-3': '#b6d9fc',
    'blue-4': '#86b7f0',
    'blue-5': '#5b96e3',
    'blue-7': '#2156b0',
    'blue-8': '#133b8a',
    'blue-9': '#092463',
    'blue-10': '#05143d'
  }
};
