import { FC, useEffect, useState } from 'react';
import { PureNullable, Undefinable } from 'core/globalTypes';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Input } from 'components/basic';
import styles from './styles.module.less';
import { NumberInputProps } from './types';
import useDebounce from 'hooks/useDebounce';

const NumberInput: FC<NumberInputProps> = ({
  min = 1,
  value,
  onChange,
  prefix,
  placeholder,
  name
}) => {
  const [inputValue, setInputValue] =
    useState<PureNullable<Undefinable<number>>>(value);

  const debouncedSearchValue = useDebounce(inputValue, 600);

  useEffect(() => {
    onChange?.({ value: debouncedSearchValue || null });
  }, [debouncedSearchValue]);

  const handleInputChange = (value: PureNullable<number>) => {
    setInputValue(value);
  };

  return (
    <Input.InputNumber
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Input,
        prefix: name
      })}
      min={min}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleInputChange}
      className={styles.filter_number_input}
      prefix={prefix}
    />
  );
};

export default NumberInput;
