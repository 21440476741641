import React from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Space } from 'components/basic';
import styles from './styles.module.less';
import HeaderContent from 'components/mobile/Header/Content';
import PageTitle from 'components/mobile/pageTitle';
import { ShowElement } from 'components/shared';

interface Props {
  title?: string;
  extra?: React.ReactNode;
  currentPageTitle?: string;
}

const SettingsPageHeader = ({ extra, currentPageTitle, title }: Props) => {
  const { t } = useTranslation('common');
  const isMobile = useReactiveVar(isMobileDevice);

  return (
    <Row
      wrap={false}
      justify="space-between"
      align="top"
      className={styles.settings_header}
    >
      <ShowElement
        isShow={!isMobile}
        fallback={
          <HeaderContent position="left">
            <PageTitle
              size="base"
              fontWeight="bold"
              ellipsis
              text={currentPageTitle || ''}
            />
          </HeaderContent>
        }
      >
        <Col>
          <Space>
            <Space align="center" size={24} style={{ alignItems: 'baseline' }}>
              <Space size={4} direction="vertical">
                <Breadcrumb
                  data-test={createDataTestAttribute({
                    dataTestAttribute: DataTestAttributes.Text,
                    prefix: 'settings-title'
                  })}
                  className={styles.breadcrumb}
                  separator={<Breadcrumb.Separator />}
                  items={[
                    {
                      title: title || t('settings'),
                      className: styles.breadcrumb_item_title
                    },
                    {
                      title: currentPageTitle
                    }
                  ]}
                />
              </Space>
            </Space>
          </Space>
        </Col>
      </ShowElement>
      <ShowElement
        isShow={!isMobile}
        fallback={<HeaderContent position="right">{extra}</HeaderContent>}
      >
        <Col>{extra}</Col>
      </ShowElement>
    </Row>
  );
};

export default SettingsPageHeader;
