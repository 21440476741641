import { FC } from 'react';

import { ProductListProps } from './types';
import styles from './styles.module.less';
import { Typography } from 'components/basic';
import { ShowElement } from 'components/shared';
import { Item } from './components';

const { UcBodyText } = Typography;

const List: FC<ProductListProps> = ({ title, className = '', children }) => {
  return (
    <div className={`${styles.product_list_main_container} ${className}`}>
      <ShowElement isShow={Boolean(title)}>
        <div className={styles.mobile_product_heading_container}>
          <UcBodyText fontWeight="bold" size="base">
            {title}
          </UcBodyText>
        </div>
      </ShowElement>

      <div className={styles.mobile_product_list_container}>{children}</div>
    </div>
  );
};

const ProductList = Object.assign(List, {
  Item
});

export default ProductList;
