import {
  TIME_FORMAT_WITHOUT_SECONDS,
  TIME_FORMAT_WITHOUT_SECONDS_12H
} from 'core/constants/date-constants';
import { coreConfigs } from 'apollo/cache';

import { TimeFormats } from 'pages/settings/pages/ProjectSetup/components/Formats/types';

const getTimeFormat = () => {
  const { time_format } = coreConfigs();

  return time_format === TimeFormats.format1
    ? TIME_FORMAT_WITHOUT_SECONDS
    : TIME_FORMAT_WITHOUT_SECONDS_12H;
};

export default getTimeFormat;
