import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

export type MediaThumbnailPath = {
  images: string;
  videos: string;
  docs: string;
  audios: string;
  vectors: string;
  IMAGE: string;
  VIDEO: string;
  DOCUMENT: string;
  AUDIO: string;
  VECTOR: string;
};

export const pathNames = [
  'media_image',
  'media_image_thumb',
  'media_vector',
  'media_vector_thumb',
  'media_original_image',
  'media_audio',
  'media_audio_thumb',
  'media_video',
  'media_video_thumb',
  'media_doc',
  'media_doc_thumb',
  'font'
];

const useMedia = () => {
  const configs = pathNames.reduce(
    (configsPaths: { [name: string]: string }, currentPathName: string) => {
      const config = client.readFragment({
        id: `Setting:${currentPathName}`,
        fragment: gql`
          fragment setting on Setting {
            value
          }
        `
      });

      return { ...configsPaths, [currentPathName]: config?.value };
    },
    {}
  );

  const imagePath = `${configs.media_image}/`;
  const imageThumbnailPath = `${configs.media_image_thumb}/`;
  const videoPath = `${configs.media_video}/`;
  const videoThumbnailPath = `${configs.media_video_thumb}/`;
  const audioPath = `${configs.media_audio}/`;
  const audioThumbnailPath = `${configs.media_audio_thumb}/`;
  const docsPath = `${configs.media_doc}/`;
  const docsThumbnailPath = `${configs.media_doc_thumb}/`;
  const fontPath = `${configs.font}/`;
  const vectorPath = configs.media_vector ? `${configs.media_vector}/` : '';
  const vectorThumbnailPath = configs.media_vector_thumb
    ? `${configs.media_vector_thumb}/`
    : '';

  const thumbnailPath: MediaThumbnailPath = {
    images: imageThumbnailPath,
    videos: videoThumbnailPath,
    docs: docsThumbnailPath,
    audios: audioThumbnailPath,
    IMAGE: imageThumbnailPath,
    vectors: vectorThumbnailPath,
    VIDEO: videoThumbnailPath,
    DOCUMENT: docsThumbnailPath,
    AUDIO: audioThumbnailPath,
    VECTOR: vectorThumbnailPath
  };

  return {
    imagePath,
    imageThumbnailPath,
    videoPath,
    videoThumbnailPath,
    audioPath,
    audioThumbnailPath,
    docsPath,
    docsThumbnailPath,
    thumbnailPath,
    fontPath,
    vectorThumbnailPath,
    vectorPath
  };
};

export default useMedia;
