import { ProductTypeGroup } from 'generatedHooks/commerce/generated';

import {
  DigitalProductIcon,
  PhysicalProductIcon,
  SubscriptionProductIcon,
  AppointmentProductIcon,
  RentalProductIcon,
  EventProductIcon,
  TableProductIcon,
  GiftCardProductIcon
} from 'components/shared/SVG';
import { ProductType } from 'pages/products/productFamily/types';

export const PRODUCT_TYPE_ICONS = {
  [ProductType.PHYSICAL]: PhysicalProductIcon,
  [ProductType.DIGITAL]: DigitalProductIcon,
  [ProductType.SUBSCRIPTION]: SubscriptionProductIcon,
  [ProductType.BOOKING_APPOINTMENT]: AppointmentProductIcon,
  [ProductType.BOOKING_RESERVATION]: AppointmentProductIcon,
  [ProductType.BOOKING_EVENT]: EventProductIcon,
  [ProductType.BOOKING_RENTAL]: RentalProductIcon,
  [ProductType.BOOKING_TABLE]: TableProductIcon,
  [ProductType.GIFT_CARD]: GiftCardProductIcon
};

export const COMING_SOON_PRODUCT_TYPES = [ProductTypeGroup.Subscription];
