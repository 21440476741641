import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import Button from 'components/basic/Button';
import styles from './styles.module.less';
import { AiIcon } from '../SVG';

interface Props {
  generateDescription: () => Promise<void>;
  loading: boolean;
  disabled: boolean;
}

const AiButton: FC<Props> = ({ generateDescription, loading, disabled }) => {
  const { t } = useTranslation();

  return (
    <Flex className={styles.container} justify="end" gap={24} align="end">
      <Button
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'generate-ai'
        })}
        size="small"
        icon={<AiIcon />}
        disabled={disabled}
        className={`${!disabled ? styles.ai_button : styles.ai_button_disabled}`}
        onClick={generateDescription}
        loading={loading}
      >
        {t('common:generateWithAi')}
      </Button>
    </Flex>
  );
};

export default AiButton;
