import { ImportableEntity } from '../types';

const getContinueText = (entityType: ImportableEntity) => {
  const TEXT_KEYS = [
    'continue',
    'startImport',
    'goToDashboard',
    'goToDashboard'
  ];

  if (entityType === 'Product') {
    TEXT_KEYS.unshift('continue');
  }

  return TEXT_KEYS;
};

export default getContinueText;
