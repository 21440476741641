import { FC, ReactNode } from 'react';

import styles from './styles.module.less';

/**
 * Use this wrapper only for nested pages like Invite Memeber page, Shipping/Payment method
 * coniguration page, Notification template preview, etc.
 */

interface Props {
  className?: string;
  children?: ReactNode;
}

const NestedPageWrapper: FC<Props> = ({ children, className }) => {
  return (
    <div className={`${styles.settings_page_wrapper} ${className || ''}`}>
      {children}
    </div>
  );
};

export default NestedPageWrapper;
