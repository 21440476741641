import { Input } from 'antd';
import { PasswordProps } from 'antd/es/input';

const { Password } = Input;

const BasePassword = (props: PasswordProps) => {
  return <Password {...props} size="large" />;
};

const UcPassword = Object.assign(BasePassword, Password);

export default UcPassword;
