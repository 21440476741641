import { UcraftLogoIcon } from 'components/shared/SVG';
import styles from './styles.module.less';

function Logo() {
  return (
    <div className={styles.StyledLogo}>
      <UcraftLogoIcon />
    </div>
  );
}

export default Logo;
