import { StateDispatcher } from 'core/globalTypes';
import {
  BulkActionEntity,
  BulkActionOperation,
  BulkActionProcessStatus,
  CheckBulkActionProcessStatus
} from 'generatedHooks/commerce/generated';
import i18n from 'i18next';
import { BULK_ACTIONS_META_DATA } from 'services/bulkActions/constants';
import { getEntityTranslationKey } from 'services/bulkActions/helpers';
import { OperationMetaData } from 'services/bulkActions/types';
import { CompletedProcessTypes } from 'services/bulkActions/hooks/useBulkActionsProcess/types';
import { SystemMessage } from 'helpers';

export const manageProcessesCheckResult = (
  checkedProcesses: CheckBulkActionProcessStatus[],
  setCompletedProcessesReport: StateDispatcher<
    Omit<CheckBulkActionProcessStatus, '__typename'>[]
  >
) => {
  const completedProcesses: string[] = [];

  checkedProcesses.forEach(process => {
    const { status, entity, completedIds, failedIds } = process;

    const operation = process.operation as
      | BulkActionOperation.Delete
      | BulkActionOperation.MakeInvisible
      | BulkActionOperation.MakeVisible;

    const metadata = BULK_ACTIONS_META_DATA[entity][
      operation
    ] as OperationMetaData;

    if (status === BulkActionProcessStatus.Done) {
      const processReport = { ...process };

      const completedProcessType = getCompletedProcessType(
        completedIds,
        failedIds
      );

      delete processReport.__typename;

      completedProcesses.push(process.processId);
      setCompletedProcessesReport(prevReport => [...prevReport, processReport]);

      showMessageOnCompletedProcess(completedProcessType, metadata, entity);
    }

    if (status === BulkActionProcessStatus.Failed) {
      completedProcesses.push(process.processId);
      SystemMessage.error(<>{i18n.t(metadata.failureMessageKey)}</>);
    }
  });

  return { completedProcesses };
};

const getCompletedProcessType = (
  completedIds: string[],
  failedIds: string[]
): CompletedProcessTypes => {
  const completedCount = completedIds.length;
  const failedCount = failedIds.length;

  let type: CompletedProcessTypes;

  if (completedCount && !failedCount) {
    type = CompletedProcessTypes.ALL_SUCCEEDED;
  }

  if (completedCount && failedCount) {
    type = CompletedProcessTypes.PARTIALLY_SUCCEEDED;
  }

  if (failedCount && !completedCount) {
    type = CompletedProcessTypes.ALL_FAILED;
  }

  if (!failedCount && !completedCount) {
    type = CompletedProcessTypes.EMPTY_REPORT;
  }

  return type!;
};

const showMessageOnCompletedProcess = (
  processType: CompletedProcessTypes,
  metadata: OperationMetaData,
  entity: BulkActionEntity
) => {
  const { successMessageKey, failureMessageKey, partialSuccessMessageKey } =
    metadata;

  const mapping = {
    [CompletedProcessTypes.ALL_SUCCEEDED]: () => {
      SystemMessage.success(
        <>
          {i18n.t(successMessageKey, {
            entity: i18n.t(getEntityTranslationKey(entity))
          })}
        </>
      );
    },
    [CompletedProcessTypes.PARTIALLY_SUCCEEDED]: () => {
      SystemMessage.warning(
        <>
          {i18n.t(partialSuccessMessageKey, {
            entity: i18n.t(getEntityTranslationKey(entity))
          })}
        </>
      );
    },
    [CompletedProcessTypes.ALL_FAILED]: () => {
      SystemMessage.error(<>{i18n.t(failureMessageKey)}</>);
    },
    [CompletedProcessTypes.EMPTY_REPORT]: () => {}
  };

  mapping[processType]();
};
