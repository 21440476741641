import { Col, QRCode, Row } from 'antd';
import { NiladicVoid } from 'core/globalTypes';
import { FC } from 'react';
import Icon from '@ant-design/icons';

import { Divider, Modal } from 'components/basic';
import styles from './styles.module.less';
import { GooglePlayBadge, AppStoreBadge } from '../SVG';

const { VITE_APP_MERCHANT_APP_IOS_URL, VITE_APP_MERCHANT_APP_ANDROID_URL } =
  import.meta.env;

interface Props {
  visible: boolean;
  closeModal: NiladicVoid;
}

const ApplicationQR: FC<Props> = ({ visible, closeModal }) => {
  return (
    <Modal
      open={visible}
      onCancel={closeModal}
      footer={null}
      width={800}
      centered
      rootClassName={styles.modal}
      maskClosable
      title={<></>}
    >
      <Row wrap={false}>
        <Col flex={'1 1 50%'} className={styles.col}>
          <a
            href={VITE_APP_MERCHANT_APP_IOS_URL}
            target="_blank"
            rel="noreferrer"
          >
            <Icon component={AppStoreBadge} />
          </a>
          <QRCode
            rootClassName={styles.qr}
            value={VITE_APP_MERCHANT_APP_IOS_URL}
          />
        </Col>
        <Col className={styles.divider_column}>
          <Divider type="vertical" className={styles.divider} />
        </Col>
        <Col flex={'1 1 50%'} className={styles.col}>
          <a
            href={VITE_APP_MERCHANT_APP_ANDROID_URL}
            target="_blank"
            rel="noreferrer"
          >
            <Icon component={GooglePlayBadge} />
          </a>
          <QRCode
            rootClassName={styles.qr}
            value={VITE_APP_MERCHANT_APP_ANDROID_URL}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ApplicationQR;
