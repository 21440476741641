import {
  ItemType,
  MenuItemType,
  SubMenuType
} from 'antd/lib/menu/hooks/useItems';
import { PureNullable } from 'core/globalTypes';
import { Col, Row } from 'antd';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { MENU_ITEMS_DATA, MOBILE_MENU_ITEMS } from './constants';
import { CreateMenuItemsArgs, SiderMenuItemType } from './types';
import { ShowElement } from 'components/shared';
import { Tag } from 'components/basic';

export const createMenuItems = ({
  Can,
  label,
  navigate,
  onClick,
  t,
  isMobile
}: CreateMenuItemsArgs) =>
  MENU_ITEMS_DATA.concat(isMobile ? MOBILE_MENU_ITEMS : []).map(
    ({
      key,
      i18nKey,
      defaultLabel,
      icon,
      children = [],
      url,
      permission,
      type,
      betaMode,
      dataTestKeyPrefix
    }) => {
      if (type === SiderMenuItemType.SingleItem) {
        return Can({
          I: permission?.I,
          RequestAccess: permission?.RequestAccess,
          children: {
            key,
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.LeftSidebarMenuItem,
              prefix: dataTestKeyPrefix
            }),
            icon,
            label: label(t(i18nKey, defaultLabel)),
            onClick: () => onClick(url as string, type)
          }
        });
      }

      let firstMenuItemUrl = '';

      const submenu: SubMenuType = {
        key,
        icon,
        label: (
          <Row
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.LeftSidebarMenuItem,
              prefix: dataTestKeyPrefix
            })}
            justify="space-between"
            wrap={false}
          >
            <Col className="textEllipsis">{t(i18nKey, defaultLabel)}</Col>
            <Col>
              <ShowElement isShow={Boolean(betaMode)}>
                <Tag type="warning">Beta</Tag>
              </ShowElement>
            </Col>
          </Row>
        ),
        children: []
      };

      const submenuChildren = children.reduce((accumulator, child) => {
        const menuItem = Can({
          I: child.permission?.I,
          Or: child.permission?.Or,
          RequestAccess: child.permission?.RequestAccess,
          children: {
            key: child.key,
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.LeftSidebarMenuItem,
              prefix: child.dataTestKeyPrefix
            }),
            title: t(child.i18nKey, child.defaultLabel),
            className: 'sider_menu_submenu_item',
            label: label(t(child.i18nKey, child.defaultLabel)),
            onClick: () => onClick(child.url, type)
          }
        }) as PureNullable<MenuItemType>;

        if (menuItem) {
          if (!menuItem.disabled && !firstMenuItemUrl) {
            firstMenuItemUrl = child.url;
          }

          accumulator.push(menuItem);
        }

        return accumulator;
      }, [] as ItemType[]);

      if (!submenuChildren.length) {
        return null;
      }

      submenu.children = submenuChildren;

      submenu.onTitleClick = () => {
        if (firstMenuItemUrl) {
          navigate(firstMenuItemUrl);
        }
      };

      return submenu;
    }
  );
