import { CloseOutlined } from '@ant-design/icons';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { FC } from 'react';

import { Tag } from 'components/basic';
import styles from './styles.module.less';

const CustomTag: FC<CustomTagProps> = props => {
  return (
    <Tag
      color="blue"
      className={`ant-select-selection-item ${styles.custom_tag}`}
    >
      {props.label}
      <CloseOutlined onClick={props.onClose} />
    </Tag>
  );
};

export default CustomTag;
