import { FC } from 'react';

import styles from './styles.module.less';
import { Typography } from 'components/basic';
import { RedArrowDownIcon } from 'components/shared/SVG/RedArrowDownIcon';

const { UcBodyText } = Typography;

const ExtraElementRealTime: FC = () => {
  return (
    <div className={styles.extra_real_time}>
      <UcBodyText
        fontWeight="regular"
        size="small"
        type="secondary-dark"
        className={styles.extra_real_time_in_last}
      >
        in last 1 hour
      </UcBodyText>
      <span className={styles.extra_real_time_static}>
        <UcBodyText size="base" className={styles.extra_real_time_static_up}>
          ^ 1.32
        </UcBodyText>
        <UcBodyText size="base" className={styles.extra_real_time_static_down}>
          <RedArrowDownIcon />
          1.32
        </UcBodyText>
      </span>
    </div>
  );
};

export default ExtraElementRealTime;
