import React, {
  ReactElement,
  useState,
  useEffect,
  CSSProperties,
  ComponentType,
  MouseEvent
} from 'react';
import { Dropdown, Menu } from 'antd';
import * as icons from '@ant-design/icons';
import { DropDownProps } from 'antd/lib/dropdown';
import styles from 'packages/Media/styles.module.less';

// export const EllipsisIcon = styled.div<{ color: string }>`
//   font-size: 16px;
//   color: ${props => props.color};
//
//   :hover {
//     color: var(--ant-primary-color);
//     cursor: pointer;
//   }
// `;
//
// const Container = styled.div<{ visible: boolean; alwaysShow: boolean }>`
//   display: inline-block;
//   min-width: 30px;
//   text-align: right;
//   opacity: ${props =>
//     props.alwaysShow || props.visible ? '1 !important' : '0'};
//
//   .ant-Table-row-selected & {
//     opacity: 0 !important;
//     pointer-events: none;
//   }
// `;

export type OptionsItemType = {
  onClick?: (e?: MouseEvent<HTMLElement>) => void;
  title?: ReactElement | string;
  icon: ReactElement;
  danger?: boolean;
  hide?: boolean;
} | null;

type Props = {
  items: OptionsItemType[];
  onToggle?: (visible: boolean) => void;
  color?: string;
  size?: number;
  iconStyles?: CSSProperties;
  alwaysShow?: boolean;
  placement?: DropDownProps['placement'];
  icon?: string;
};

function Options({
  items,
  onToggle,
  color = 'inherit',
  size = 160,
  iconStyles,
  alwaysShow = false,
  placement = 'bottomRight',
  icon = 'EllipsisOutlined'
}: Props) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    onToggle && onToggle(visible);
  }, [visible]);

  const menu = (
    <Menu data-test="context-item" style={{ width: `${size}px` }}>
      {items.map((item, index) => {
        if (!item) {
          return <Menu.Divider key={index} />;
        }

        if (item.hide) {
          return null;
        }

        const { title, onClick, ...itemProps } = item;

        return (
          <Menu.Item
            {...itemProps}
            onClick={e => {
              setVisible(false);
              // @ts-ignore
              onClick && onClick(e.domEvent);
            }}
            key={index}
            data-test={title}
          >
            {title}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const Icon = icons[icon as keyof typeof icons] as ComponentType;

  return (
    <div
      style={{
        opacity: alwaysShow || visible ? '1 !important' : '0'
      }}
      className={`${styles.OptionContainer} ant-options`}
      data-test="context-menu"
    >
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement={placement}
        onOpenChange={setVisible}
      >
        <div
          className={styles.EllipsisIcon}
          onClick={e => e.stopPropagation()}
          style={{ color, ...iconStyles }}
        >
          <Icon />
        </div>
      </Dropdown>
    </div>
  );
}

export default Options;
