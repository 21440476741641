import React, { FC, MouseEventHandler, useMemo } from 'react';

import { Button as BaseButton } from 'components/basic';
import styles from './styles.module.less';
import {
  ButtonProps,
  ButtonSizeTypes,
  ButtonColor,
  IconPositionTypes
} from './types';
import { ShowElement } from 'components/shared';

const Button: FC<ButtonProps> = ({
  text = '',
  color = ButtonColor.blue,
  loading = false,
  disabled = false,
  onClick,
  icon,
  children,
  outlet = false,
  iconPosition = IconPositionTypes.left,
  buttonSize = ButtonSizeTypes.largeFullWidth
}) => {
  const handleButtonClick: MouseEventHandler<HTMLElement> = (event): void => {
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  const buttonContent = useMemo(() => {
    if (children) {
      return children;
    }

    return (
      <span className={`${styles.button_value_container}`}>
        <span className={`${styles.button_text_content}`}>{text}</span>
      </span>
    );
  }, [text, children]);

  return (
    <BaseButton
      loading={loading}
      disabled={disabled}
      onClick={handleButtonClick}
      className={`${styles.mobile_base_button} ${
        disabled ? styles.disabled_style : ''
      } ${styles[buttonSize]} ${styles[color]} ${
        outlet ? styles.outlet_style : ''
      }`}
    >
      <ShowElement
        isShow={Boolean(icon) && buttonSize === ButtonSizeTypes.largeFullWidth}
      >
        <span
          className={`${styles.button_icon_content} ${styles[iconPosition]}`}
        >
          {icon}
        </span>
      </ShowElement>
      {buttonContent}
    </BaseButton>
  );
};

export default Button;
