import { ApolloClient } from '@apollo/client';

import { cache } from './cache';
import { link } from './link';

const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: import.meta.env.NODE_ENV === 'development'
});

export default client;
