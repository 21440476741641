import Lottie from 'lottie-react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { animation } from './constants';
import styles from './styles.module.less';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const ImportLoading = () => {
  return (
    <div
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Component,
        prefix: 'progress'
      })}
      className={styles.progress}
    >
      <Lottie
        {...defaultOptions}
        style={{
          maxWidth: 400,
          width: '100%',
          height: 200
        }}
      />
    </div>
  );
};

export default ImportLoading;
