import { CouponType } from 'generatedHooks/commerce/generated';

import { DiscountCreateFromValues } from './types';

export const defaultValues: DiscountCreateFromValues = {
  name: '',
  description: '',
  startsFrom: null,
  endsTill: null,
  couponType: CouponType.NoCoupon,
  usagePerCustomer: 0,
  usesPerCoupon: 0,
  conditionType: true,
  conditions: [],
  discountAmount: null,
  discountQuantity: 0,
  endOtherRules: false,
  customerGroups: [],
  channels: [],
  status: '1',
  freeShipping: false,
  couponCode: '',
  actionType: 'by_percent',
  discountStep: 0,
  sortOrder: 0,
  timesUsed: 0,
  applyToShipping: false,
  usesAttributeConditions: false,
  isEndTime: false,
  endDate: null,
  endTime: null,
  startDate: null,
  startTime: null,
  isLimit: false
};

export const MANUAL = 'manual';

export const AUTOMATIC = 'automatic';

export const initialActivePanels = ['1', '2', '3', '4'];

export const dropdownStyle = { minWidth: 250 };
