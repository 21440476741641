import MediaList, { MediaFor } from 'packages/MediaUpload';

interface Props {
  value?: string[];
  onChange?: Function;
  mediaFor: MediaFor;
}

const Media = ({ value = [], onChange, mediaFor }: Props) => {
  return (
    <MediaList
      mediaFor={mediaFor}
      hasDefault={false}
      initialMediaIds={(value || [])?.map(Number)}
      setIds={(ids: string[] | number[] | null | undefined) => {
        const res = Array.isArray(ids) ? ids.map(Number) : [];

        onChange?.(res);
      }}
      ids={(value || [])?.map(Number)}
    />
  );
};

export default Media;
