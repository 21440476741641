export const projectPermissionsQuery = `
    query projectPermissions($projectId: ID!){
        projectPermissions(projectId: $projectId) {
          id
          alias
        }
    }
`;

export const projectQuery = `
    query project($id: ID!){
        project(id: $id) {
          id
          name
          owners(first: 1000, page: 1) {
            data {
              id
            }
          }
        }
    }
`;

export const countLimitationsQuerySso = `
   query entityCountLimitation($entities: [LimitationType!]!, $projectId: ID!) {
     entityCountLimitation(entities: $entities, projectId: $projectId) {
       entity
       count
       limit
       limitReached
    }
  }
`;

export const countLimitationsQuery = `
   query entityCountLimitation($entities: [LimitationType!]!) {
     entityCountLimitation(entities: $entities) {
       entity
       count
       limit
       limitReached
    }
  }
`;
