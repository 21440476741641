import { BulkActionsProcess } from 'services/bulkActions/types';

import { Action, ActionTypes } from './types';

export const add = (payload: BulkActionsProcess): Action => ({
  type: ActionTypes.ADD_NEW_PROCESS,
  payload
});

export const remove = (payload: string): Action => ({
  type: ActionTypes.REMOVE_PROCESS,
  payload
});
