import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.less';

type Props = {
  items: { name: string; id: string }[];
  onClick: (parentId: string | null) => void;
};

function FoldersBreadcrumb({ items = [], onClick }: Props) {
  const { t } = useTranslation('common');

  return (
    <Breadcrumb className={styles.BreadcrumbTag}>
      <Breadcrumb.Item
        href=""
        onClick={e => {
          e.preventDefault();
          onClick(null);
        }}
      >
        <HomeOutlined />
        {t('allFiles')}
      </Breadcrumb.Item>
      {items.map(({ name, id }) => (
        <Breadcrumb.Item
          key={id}
          href=""
          onClick={e => {
            e.preventDefault();
            onClick(id);
          }}
        >
          {name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default FoldersBreadcrumb;
