import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import StepContentHeading from 'components/global/ImportModal/components/StepContentHeading';
import styles from './styles.module.less';
import { ImportableEntity } from 'components/global/ImportModal/types';
import { IMPORTABLE_ENTITY_KEYS } from 'components/global/ImportModal/constants';
import ImportLoading from './components/ImportLoading';

const { Link } = Typography;

type Props = {
  entityType: ImportableEntity;
  onClose: () => void;
};

const ImportingStep: React.FC<Props> = ({ entityType, onClose }: Props) => {
  const { t } = useTranslation('importModal');

  return (
    <div className={styles.wrapper}>
      <StepContentHeading
        title={t('importingTitle')}
        description={t('importingDescription', {
          data: t(IMPORTABLE_ENTITY_KEYS[entityType])
        })}
        secondaryText={
          <Trans i18nKey={'importModal:safelyClose'}>
            You may now safely <Link onClick={onClose}>close</Link> the import
            tool. We will notify you as soon as we finish your import!
          </Trans>
        }
      />
      <ImportLoading />
    </div>
  );
};

export default ImportingStep;
