import { TFunction } from 'react-i18next';
import { SortTypes, FilterOption } from 'core/globalTypes';

export const SortValues = (t: TFunction): FilterOption[] => [
  {
    value: SortTypes.Az,
    name: 'A-Z'
  },
  {
    value: SortTypes.Za,
    name: 'Z-A'
  },
  {
    value: SortTypes.Newest,
    name: t('common:sortNew', 'Newest')
  },
  {
    value: SortTypes.Oldest,
    name: t('common:sortOld', 'Oldest')
  }
];
