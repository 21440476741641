import React from 'react';

import withIcon from './withIcon';

function SoundOff() {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 16 16"
      version="1.1"
    >
      <title>1.General/Icon/Fill/Sound-Off</title>
      <g
        id="1.General/Icon/Fill/Sound-Off"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.60133797,2.00001497 L7.68344316,2.00400519 C7.98981292,2.03305162 8.27107476,2.33137397 8.27105582,2.56192763 C8.27474088,2.58539477 8.27386214,11.1528679 8.27188005,13.1480156 L8.27105582,13.5597312 C8.27105582,13.795776 7.99146027,14.0878119 7.68425737,14.1168205 C7.53315534,14.1309082 7.30538514,14.1259093 7.14790199,13.9684262 C7.14790199,13.9684262 3.59102098,10.6341271 3.43991896,10.4840855 L3.43991896,10.4840855 L2.24386503,10.4840855 C1.57460426,10.4840855 1,9.94151939 1,9.27223968 L1,9.27223968 L1,6.8485481 C1,6.17930626 1.57456639,5.63670231 2.2439029,5.63670231 L2.2439029,5.63670231 L3.43910475,5.63670231 C3.59020677,5.488308 7.14708778,2.1523995 7.14708778,2.1523995 C7.29239567,2.00709161 7.53234113,1.98991748 7.68344316,2.00400519 Z M10.2220215,5.22200978 C10.5180345,4.92599674 10.997969,4.92599674 11.2939977,5.22200978 L11.2939977,5.22200978 L12.9912882,6.91930024 L14.6885786,5.22200978 C14.984623,4.92599674 15.4645418,4.92599674 15.7605705,5.22200978 C16.0565835,5.51803845 16.0565835,5.99797296 15.7605705,6.29398599 L15.7605705,6.29398599 L14.0632957,7.99127646 L15.7605861,9.68858256 C16.0565992,9.98459559 16.0565992,10.4645301 15.7605861,10.7605431 C15.4645575,11.0565718 14.9846386,11.0565718 14.6885943,10.7605431 L14.6885943,10.7605431 L12.9913038,9.06326831 L11.2940134,10.7605431 C10.9979847,11.0565718 10.5180502,11.0565718 10.2220371,10.7605431 C9.92600847,10.4645301 9.92600847,9.98459559 10.2220371,9.68858256 L10.2220371,9.68858256 L11.919312,7.99127646 L10.2220215,6.29398599 C9.92599283,5.99795732 9.92599283,5.51802281 10.2220215,5.22200978 Z"
          id="Combined-Shape"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default withIcon(SoundOff);
