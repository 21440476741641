import { Input, InputRef } from 'antd';
import { SearchProps } from 'antd/es/input';
import { ForwardedRef, forwardRef } from 'react';

const { Search } = Input;

const BaseSearch = forwardRef(
  (props: SearchProps, ref: ForwardedRef<InputRef>) => {
    return <Search {...props} size="large" ref={ref} />;
  }
);

const UcSearch = Object.assign(BaseSearch, Search);

export default UcSearch;
