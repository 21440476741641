import { FC, useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd-mobile';

import { ArrowLeftIcon } from 'components/shared/SVG';
import { NavBarProps } from './types';
import { Typography } from 'components/basic';
import { ShowElement, Suspensor } from 'components/shared';
import styles from './styles.module.less';

const { UcBodyText } = Typography;

const NavBar: FC<NavBarProps> = ({
  title,
  leftValue,
  rightValue,
  headline,
  rightDisabled = false,
  rightLoading = false,
  rightBarAction,
  leftBarAction,
  loading = false
}) => {
  const titleContent = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <UcBodyText ellipsis size="base" fontWeight="bold">
          {title}
        </UcBodyText>
      );
    }

    return title;
  }, [title, loading]);

  const leftBarContent = useMemo(() => {
    if (typeof leftValue === 'string') {
      return (
        <UcBodyText
          size="small"
          onClick={() => leftBarAction && leftBarAction()}
        >
          <ArrowLeftIcon className={styles.icon_size} />
          {leftValue}
        </UcBodyText>
      );
    }

    return leftValue;
  }, [leftValue, leftBarAction]);

  const rightBarContent = useMemo(() => {
    if (typeof rightValue === 'string') {
      return (
        <UcBodyText
          size="small"
          disabled={rightDisabled || rightLoading}
          onClick={() => rightBarAction && rightBarAction()}
        >
          <ShowElement isShow={rightLoading}>
            <LoadingOutlined className={styles.right_loading_icon} />
          </ShowElement>
          {rightValue}
        </UcBodyText>
      );
    }

    return rightValue;
  }, [rightValue, rightBarAction, rightDisabled, rightLoading]);

  return (
    <Suspensor
      loading={loading}
      fallback={
        <div className={styles.nav_bar_container}>
          <Skeleton.Title animated />
          <Skeleton.Title animated />
          <Skeleton.Title animated />
        </div>
      }
    >
      <div className={styles.nav_bar_container}>
        <div className={`${styles.left_bar} ${styles.column}`}>
          {leftBarContent}
        </div>
        <div className={`${styles.center_bar} ${styles.column}`}>
          <div className={styles.title_content}>{titleContent}</div>
          <div>
            <ShowElement isShow={Boolean(headline && !loading)}>
              <UcBodyText type="secondary" size="small" fontWeight="regular">
                {headline}
              </UcBodyText>
            </ShowElement>
          </div>
        </div>
        <div className={`${styles.right_bar} ${styles.column}`}>
          {rightBarContent}
        </div>
      </div>
    </Suspensor>
  );
};

export default NavBar;
