import { ProductType } from 'generatedHooks/commerce/generated';

export const PRODUCT_URL_SLUGS: {
  [key in ProductType]: string;
} = {
  [ProductType.Simple]: 'physical-product',
  [ProductType.Configurable]: 'physical-product',
  [ProductType.Downloadable]: 'digital-product',
  [ProductType.BookingRental]: 'rental',
  [ProductType.BookingAppointment]: 'booking-appointment-product',
  [ProductType.BookingReservation]: 'booking',
  [ProductType.BookingEvent]: 'event',
  [ProductType.Subscription]: '',
  [ProductType.BookingTable]: 'booking-table-product',
  [ProductType.GiftCard]: 'gift-card-product'
};

export const URL_PATHS: { [key in ProductType]?: string } = {
  [ProductType.BookingEvent]: 'events/events',
  [ProductType.BookingReservation]: 'bookings/bookings',
  [ProductType.Simple]: 'commerce/products',
  [ProductType.BookingRental]: 'rentals/rentals'
};
