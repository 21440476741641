export enum CardVew {
  list,
  grid
}

export type PopupModel = {
  name: string;
  options: {};
};

export const redirectUrl = 'https://onboarding.beta.ucraft.dev/project-name';
