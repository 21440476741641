import { ChangeEvent, useEffect, useRef } from 'react';
import { KeyValuePair } from 'core/globalTypes';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';

interface Props {
  value?: KeyValuePair;
  onChange?: Function;
  selectedLocale: string;
  dataTest?: string;
}

const CodeEditor = ({
  value = {},
  onChange,
  selectedLocale,
  dataTest
}: Props) => {
  const codeEditorRef = useRef<null | HTMLTextAreaElement>(null);
  const lineCounterRef = useRef<null | HTMLTextAreaElement>(null);
  const lineCountCache = useRef(0);

  useEffect(() => {
    if (value?.[selectedLocale] !== undefined) {
      lineCounter(value[selectedLocale]);
    }
  }, [value, selectedLocale]);

  const onCodeEditorKeyDown = (e: any) => {
    const { keyCode } = e;
    const { selectionStart = 0, selectionEnd = 0 } =
      codeEditorRef.current || {};

    if (keyCode === 9) {
      // TAB = 9
      e.preventDefault();

      const valueWithTab =
        value?.[selectedLocale].slice(0, selectionStart) +
        '\t' +
        value?.[selectedLocale].slice(selectionEnd);

      onChange?.({
        ...value,
        [selectedLocale]: valueWithTab
      });

      codeEditorRef.current?.setSelectionRange(
        selectionStart + 2,
        selectionStart + 2
      );
    }
  };

  const lineCounter = (value: string) => {
    const lineCount = value?.split('\n').length as number;
    const outarr = [];

    if (lineCounterRef.current && lineCountCache.current != lineCount) {
      for (let x = 0; x < lineCount; x++) {
        outarr[x] = x + 1;
      }

      lineCounterRef.current.value = outarr.join('\n');
    }

    lineCountCache.current = lineCount;
  };

  const onScroll = () => {
    if (codeEditorRef.current && lineCounterRef.current) {
      lineCounterRef.current.scrollTop = codeEditorRef.current.scrollTop;
      lineCounterRef.current.scrollLeft = codeEditorRef.current.scrollLeft;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.({ ...value, [selectedLocale]: e.target.value });
  };

  return (
    <p>
      <textarea
        className={styles.lineCounter}
        ref={lineCounterRef}
        wrap="off"
        readOnly
        defaultValue={1}
      />
      <textarea
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.TextArea,
          prefix: dataTest
        })}
        value={value[selectedLocale] || ''}
        className={styles.codeEditor}
        onKeyDown={onCodeEditorKeyDown}
        onChange={handleChange}
        ref={codeEditorRef}
        wrap="off"
        onScroll={onScroll}
      ></textarea>
    </p>
  );
};

export default CodeEditor;
