import { FC } from 'react';

import { LoadingWrapper, Skeleton, Empty } from 'components/shared';
import { LocaleProps } from 'components/shared/Table/types';

const Locale: FC<LocaleProps> = ({
  loading,
  primary,
  secondary,
  icon,
  footer
}) => {
  return (
    <LoadingWrapper indicator={<Skeleton.Table />} loading={loading}>
      <Empty
        icon={icon}
        title={primary}
        description={secondary}
        footer={footer}
      />
    </LoadingWrapper>
  );
};

export default Locale;
