import { FC } from 'react';
import { Typography } from 'antd';

import { TitleLevelProps } from './types';
import styles from './styles.module.less';

const { Title } = Typography;

interface Props {
  title: string;
  level?: TitleLevelProps | undefined;
}

const PopupHeadTitle: FC<Props> = ({ title, level }) => {
  return (
    <div className={styles.titleWrapper}>
      <Title level={level} className={styles.title}>
        {title}
      </Title>
    </div>
  );
};

export default PopupHeadTitle;
