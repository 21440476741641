import { BulkActionsProcess } from '../types';
import { BULK_PROCESS_STORAGE_KEY } from './constants';

class BulkActionsProcessController {
  private projectId: string;

  static hasInstance = false;
  static instance: InstanceType<typeof this>;

  constructor(projectId: string) {
    if (BulkActionsProcessController.hasInstance) {
      this.projectId = BulkActionsProcessController.instance.projectId;

      return BulkActionsProcessController.instance;
    }

    BulkActionsProcessController.hasInstance = true;
    BulkActionsProcessController.instance = this;

    this.projectId = projectId;
  }

  private storageKey() {
    return `${BULK_PROCESS_STORAGE_KEY}:${this.projectId}`;
  }

  private getProjectProcesses() {
    try {
      const storageDataJson = localStorage.getItem(this.storageKey()) || '{}';

      const processes = JSON.parse(storageDataJson);

      return processes;
    } catch (error: any) {
      return {};
    }
  }

  writeProcessToStorage(process: BulkActionsProcess) {
    const processes = this.getProjectProcesses();

    processes[process.processId] = process;

    localStorage.setItem(this.storageKey(), JSON.stringify(processes));
  }

  readProcessFromStorage(processId: string) {
    const processes = this.getProjectProcesses();

    return processes[processId];
  }

  readProjectProcessesFromStorage() {
    return this.getProjectProcesses();
  }

  removeProcessFromStorage(processId: string) {
    const processes = this.getProjectProcesses();

    delete processes[processId];

    localStorage.setItem(this.storageKey(), JSON.stringify(processes));
  }

  evictProcessesFromStorageByProject() {
    localStorage.removeItem(this.storageKey());
  }
}

export default BulkActionsProcessController;
