import React, { FC } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/product-types/info.svg';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';

import styles from './styles.module.less';
import { CarouselItemProps } from '../types';

const CarouselItem: FC<CarouselItemProps> = ({
  original,
  name,
  id = '0',
  handleInfoIcon,
  imagePath
}) => {
  return (
    <div className={styles.carouselItemWrapper}>
      <div className={styles.carouselImgWrapper}>
        <LazyImage
          src={`${imagePath}${original}`}
          alt="media"
          height="100%"
          data-id={id}
        />
      </div>
      <div className={styles.carouselItemInfo}>
        <strong className="textEllipsis"> {name}</strong>
        <span onClick={() => handleInfoIcon(id)}>
          <InfoIcon />
        </span>
      </div>
    </div>
  );
};

export default CarouselItem;
