import { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  children?: ReactNode;
}

const Cover: FC<Props> = ({ children }) => {
  return <div className={styles.cover}>{children}</div>;
};

export default Cover;
