import dayjs, { Dayjs } from 'dayjs';
import { PureNullable } from 'core/globalTypes';
import { getFullDateFormat } from 'helpers/dateHelpers';

const dayjsToString = (value: PureNullable<Dayjs>, format?: string) => {
  const defaultFormat = getFullDateFormat();
  const currentFormat = format || defaultFormat;

  return value ? dayjs(value, defaultFormat).format(currentFormat) : null;
};

export default dayjsToString;
