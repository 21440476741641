import { FC } from 'react';

import { LoadingWrapperProps } from './types';

const LoadingWrapper: FC<LoadingWrapperProps> = ({
  indicator,
  loading,
  children
}) => {
  return <>{loading ? indicator : children}</>;
};

export default LoadingWrapper;
