import { ProductFruits as ReactProductFruits } from 'react-product-fruits';
import { useReactiveVar } from '@apollo/client';
import { currentUser } from 'apollo/cache';
import { useParams } from 'react-router-dom';
import { memo } from 'react';
import { isEqual } from 'lodash';

const { VITE_APP_PRODUCT_FRUITS_WORKSPACE_CODE } = import.meta.env;

const ProductFruits = () => {
  const currUser = useReactiveVar(currentUser);
  const { projectName } = useParams();

  if (!currUser || !VITE_APP_PRODUCT_FRUITS_WORKSPACE_CODE) {
    return <></>;
  }

  return (
    <ReactProductFruits
      workspaceCode={VITE_APP_PRODUCT_FRUITS_WORKSPACE_CODE}
      language="en"
      user={{
        username: currUser.email,
        email: currUser.email,
        props: {
          projectName: projectName as string
        }
      }}
    />
  );
};

export default memo(ProductFruits, isEqual);
