import React, { FC } from 'react';
import { Typography } from 'antd';

import { LimitationAccessAlertProps } from './types';
import { UpgradePackage } from '../UpgradePackage';

const { Title, Text } = Typography;

export const LimitationAccessAlert: FC<LimitationAccessAlertProps> = ({
  isOwner,
  redirectHandler
}) => {
  const isOwnerDrawer = isOwner ? (
    <UpgradePackage
      title={`You are exceeding the limit. In order to add more you need to update your plan.`}
      upgradeHandler={() => redirectHandler?.()}
    />
  ) : (
    <Text style={{ marginBottom: '16px', color: '#595959' }}>
      You have exceeded the limitation.
    </Text>
  );

  return (
    <div
      style={{
        padding: '16px',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box'
      }}
    >
      <Title level={4}>Limited</Title>
      {isOwnerDrawer}
    </div>
  );
};
