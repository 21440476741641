import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import useCheckPermissions from 'providers/PermissionAndLimitationProvider/hooks/useCheckPermissions';
import { useProjectContext } from 'providers/ProjectProvider';

import { ProtectedPageCanProps } from './types';
import Can from '../Can';

const ProtectedPageCan: FC<ProtectedPageCanProps> = ({
  children,
  permission
}) => {
  const {
    I = '',
    Or = '',
    RequestAccess = '',
    baseRoutePermissions
  } = permission || {};

  const { name: projectName } = useProjectContext();

  const {
    I: baseI = '',
    Or: baseOr = '',
    RequestAccess: baseRequestAccess = ''
  } = baseRoutePermissions || {};

  const shouldCheckBaseRoutePermission = Boolean(
    permission?.baseRoutePermissions
  );

  const permissions = useCheckPermissions([
    I,
    Or,
    RequestAccess,
    baseI,
    baseOr,
    baseRequestAccess
  ]);

  const notFound = <Navigate to="~" replace state={{ projectName }} />;

  if (shouldCheckBaseRoutePermission) {
    // Check if base route permission should be checked
    if (!permissions[baseI] && !permissions[baseOr]) {
      //Checking if has permission
      if (permissions[baseRequestAccess]) {
        //Checking if can request permission
        return (
          <Can I={baseI} Or={baseOr} isView>
            {children}
          </Can>
        );
      }

      //Otherwise navigating to 404
      return notFound;
    }
  }

  if (!I && !Or) {
    return <>{children}</>;
  }

  if (permissions[I] || permissions[Or]) {
    return <>{children}</>;
  }

  if (permissions[RequestAccess]) {
    return (
      <Can I={I} Or={Or} isView>
        {children}
      </Can>
    );
  }

  return notFound;
};

export default ProtectedPageCan;
