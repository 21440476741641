import { FC, useState } from 'react';
import { linkTypes } from 'apollo/link';
import {
  Project,
  ProjectsQuery,
  useProjectsQuery
} from 'generatedHooks/accounts/generated';
import { deviceDetector } from 'containers/utils';
import useInfiniteScroll from 'packages/Media/UIComponents/useInfiniteScroll';
import { useTranslation } from 'react-i18next';
import { useDebounce, useFilterQuery } from 'hooks';

import Mobile from 'pages/projects/Mobile';
import Web from 'pages/projects/Web';
import usePagination from 'hooks/usePagination';
import { DEFAULT_PAGE_SIZE, SORT_DATA_GROUPS } from './constants';
import { SeoHelmet } from 'components/shared';

interface Props {
  isOpenedDashboard?: boolean;
}

const Projects: FC<Props> = ({ isOpenedDashboard = false }) => {
  const { t } = useTranslation('projects');
  const [searchValue, setSearchValue] = useState<string>('');
  const { isMobile } = deviceDetector();

  const debouncedSearchValue = useDebounce(searchValue, 600);

  const { orderBy, queryObject } = useFilterQuery({
    sortObj: SORT_DATA_GROUPS
  });

  const { page, count, onPaginationChange } = usePagination({
    page: 1,
    count: DEFAULT_PAGE_SIZE
  });

  const { data, loading, fetchMore } = useProjectsQuery({
    variables: {
      first: count,
      page: page,
      search:
        isMobile && isOpenedDashboard
          ? debouncedSearchValue
          : queryObject.search,
      orderBy
    },
    context: { urlType: linkTypes.accounts }
  });

  const projects = (data?.projects?.data as Project[]) || [];
  const hasMorePages = data?.projects?.paginatorInfo.hasMorePages;

  const handleScroll = () => {
    fetchMore({
      variables: { page: getCurrentPage() },
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log({ prev, fetchMoreResult });

        if (!fetchMoreResult) {
          return prev;
        }

        return {
          projects: {
            paginatorInfo: fetchMoreResult?.projects?.paginatorInfo,
            data: [
              ...(prev?.projects?.data || []),
              ...(fetchMoreResult?.projects?.data || [])
            ]
          }
        } as ProjectsQuery;
      }
    });
  };

  const { onScroll, getCurrentPage } = useInfiniteScroll({
    trigger: handleScroll,
    loading: loading,
    hasMore: hasMorePages,
    global: false
  });

  return (
    <>
      <SeoHelmet excludeProjectName={true} title={t('metaTitle', 'Projects')} />
      {isMobile ? (
        <Mobile
          loading={loading}
          onScroll={onScroll}
          projects={projects}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isOpenedDashboard={isOpenedDashboard}
        />
      ) : (
        <Web
          page={page}
          pageSize={count}
          data={data}
          loading={loading}
          projects={projects}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};

export default Projects;
