import React, { FC, ReactNode } from 'react';
import { Row, Col, Typography } from 'antd';

import styles from './styles.module.less';

const { Title } = Typography;

interface Props {
  left?: ReactNode;
  middle?: ReactNode;
  right?: ReactNode;
  addClasses?: string;
  lSpan?: number;
  mSpan?: number;
  rSpan?: number;
  styleTitle?: string;
}

const PopupSheetHeader: FC<Props> = ({
  left,
  middle,
  right,
  addClasses,
  lSpan,
  mSpan,
  rSpan,
  styleTitle
}) => {
  const colSpan = middle ? 8 : 12;

  return (
    <Row className={`${styles.headerMain} ${addClasses ? addClasses : ''}`}>
      {left && (
        <Col span={lSpan ? lSpan : colSpan} className={styles.headerLeft}>
          {left}
        </Col>
      )}
      {middle && (
        <Col
          span={mSpan ? mSpan : colSpan}
          className={`${styleTitle ? styleTitle : styles.middle}`}
        >
          <Title level={5} className={styles.title}>
            {middle}
          </Title>
        </Col>
      )}
      {right && (
        <Col span={rSpan ? rSpan : colSpan} className={styles.right}>
          {right}
        </Col>
      )}
    </Row>
  );
};

export default PopupSheetHeader;
