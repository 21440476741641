import { Operation } from '@apollo/client';
import { FULL_DATE_FORMAT } from 'core/constants/date-constants';
import { KeyValuePair } from 'core/globalTypes';
import { QueryFormSubmissionsConditionColumn } from 'generatedHooks/commerce/generated';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const list: KeyValuePair<{
  format: string;
  columns: string[];
}> = {
  formSubmissions: {
    format: FULL_DATE_FORMAT,
    columns: [QueryFormSubmissionsConditionColumn.SubmittedAt]
  }
};

export const timeVariableToTimezone = (operation: Operation) => {
  const operationName = operation.operationName;
  const fieldsForFormat = list[operationName];

  if (fieldsForFormat) {
    const filters = operation?.variables?.input?.filters?.map(
      (filter: { column: string; operator: string; value: any }) => {
        if (fieldsForFormat.columns.includes(filter.column)) {
          return {
            ...filter,
            value: dayjs(filter.value).utc(false).format(fieldsForFormat.format)
          };
        }

        return filter;
      }
    );

    operation.variables.input.filters = filters;
  }

  return operation;
};
