import { useReactiveVar } from '@apollo/client';
import { ButtonProps } from 'antd';
import { isMobileDevice } from 'apollo/cache';
import { FC } from 'react';

import { CheckOutlined } from 'components/shared/SVG';
import { Button } from 'components/basic';

interface Props extends ButtonProps {}

const SaveButton: FC<Props> = props => {
  const isMobile = useReactiveVar(isMobileDevice);

  return <Button icon={isMobile ? <CheckOutlined /> : undefined} {...props} />;
};

export default SaveButton;
