import React from 'react';

import withIcon from './withIcon';

function SoundOn() {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 16 16"
      version="1.1"
    >
      <title>1.General/Icon/Fill/Sound-On</title>
      <g
        id="1.General/Icon/Fill/Sound-On"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="save" />
        <path
          d="M7.68344316,14.1179305 C7.53234113,14.1320182 7.29239567,14.1148441 7.14708778,13.9695362 C7.14708778,13.9695362 3.59020677,10.6336277 3.43910475,10.4852334 L2.2439029,10.4852334 C1.57456639,10.4852334 1,9.94262947 1,9.27338763 L1,6.84969605 C1,6.18041634 1.57460426,5.63785026 2.24386503,5.63785026 L3.43991896,5.63785026 C3.59102098,5.4878086 7.14790199,2.15350958 7.14790199,2.15350958 C7.30538514,1.99602643 7.53315534,1.99102756 7.68425737,2.00511527 C7.99146027,2.03412383 8.27105582,2.32615973 8.27105582,2.56220457 C8.27355525,2.5887137 8.27522154,13.53348 8.27105582,13.5600081 C8.27107476,13.7905618 7.98981292,14.0888841 7.68344316,14.1179305 L7.68344316,14.1179305 Z M10.7123002,11.2337186 C10.4357532,11.5103224 9.98737029,11.5103224 9.71076649,11.2337186 C9.43423843,10.9571148 9.43423843,10.5085993 9.71076649,10.2319577 C10.9072938,9.03516526 10.9072938,7.08784268 9.71076649,5.89103135 C9.43423843,5.61440861 9.43423843,5.16594993 9.71076649,4.88932719 C9.8491252,4.75100636 10.0302961,4.68185541 10.2115428,4.68185541 C10.3927895,4.68185541 10.5740173,4.75100636 10.7122813,4.88925145 C12.4610505,6.63843724 12.4610505,9.48453283 10.7123002,11.2337186 L10.7123002,11.2337186 Z M13.1332462,12.5693999 C12.8567182,12.8460037 12.4083163,12.8459848 12.1317504,12.5693999 C11.8552034,12.2927961 11.8552034,11.8442995 12.1317504,11.5676579 C14.0645687,9.6343662 14.0645687,6.48867961 12.1317504,4.555369 C11.8552034,4.27872733 11.8552034,3.83021184 12.1317504,3.55360804 C12.2700712,3.41534401 12.4512421,3.34619306 12.6325267,3.34619306 C12.8137355,3.34619306 12.9950011,3.41534401 13.1332273,3.55366484 C14.3370067,4.75780405 15,6.35874702 15,8.06156078 C15.0000189,9.76445027 14.3370635,11.3653554 13.1332462,12.5693999 L13.1332462,12.5693999 Z"
          id="Shape"
          fill="currentColor"
          transform="translate(8.000000, 8.060968) scale(1, -1) translate(-8.000000, -8.060968) "
        />
      </g>
    </svg>
  );
}

export default withIcon(SoundOn);
