import { FC } from 'react';
import { Tag, TagProps } from 'antd';

import styles from './styles.module.less';
import { TagType } from './types';

export interface BaseTagProps extends TagProps {
  type?: TagType;
  text?: string;
}

const BaseTag: FC<BaseTagProps> = ({
  type = 'default',
  text,
  className,
  children,
  ...props
}) => {
  return (
    <Tag
      {...props}
      className={`${styles.custom_tag} ${!props.color ? styles[type] : ''} ${
        className ? className : ''
      }`}
    >
      {text ? <span>{text}</span> : children}
    </Tag>
  );
};

const UcTag = Object.assign(BaseTag, Tag);

export default UcTag;
