import { Card, Tabs } from 'antd';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { Suspense, useState, useMemo, useLayoutEffect } from 'react';
import qs from 'qs';
import { useSettingsStateContext } from 'providers/SettingsProvider';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { useScreenBreakpoints } from 'hooks';

import SettingsPageHeader from 'pages/settings/components/SettingsPageHeader';
import useMenuActiveUrl from 'hooks/useMenuActiveUrl';
import { MenuProps } from './menuTypes';
import { data, generateSettingsTabsByPermissions } from './menu';
import { InviteMemberLazySkeleton } from './components/InviteMemberLazySkeleton';
import { OtherPaymentsLazySkeleton } from './components/OtherPaymentsLazySkeleton';
import { NotificationsPreviewLazySkeleton } from './components/NotificationsPreviewLazySkeleton';
import CreateShippingMethodSkeleton from './pages/shippingDelivery/pages/ShippingProfileCreateUpdate/components/CreateShippingMethodSkeleton';
import { SeoHelmet, ShowElement } from 'components/shared';
import CreateSelfPickupSkeleton from 'pages/settings/pages/shippingDelivery/pages/SelfPickupCreateUpdate/components/CreateSelfPickupSkeleton';
import styles from './index.module.less';

const Settings = () => {
  const [showTabBar, setShowTabBar] = useState<boolean>(true);
  const { t } = useTranslation('settings');
  const navigate = useNavigate();
  const CanFunction = useCanFunction('tab');

  const paths = data(t)
    .map((x: MenuProps) => x.key)
    .flat();

  const menuActiveUrl = useMenuActiveUrl(paths);

  const screens = useScreenBreakpoints();
  // const [active, setActive] = useState(menuActiveUrl || 'project-setup');
  const { search, pathname } = useLocation();

  const { child } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  /**
   * Checking if the current page is third-level nested.
   * In this case the Settings tabs will be hidden.
   */

  const isNestedPage = useMemo(() => {
    return pathname.split('/').filter(Boolean).length > 3;
  }, [pathname]);

  useLayoutEffect(() => {
    setShowTabBar(!isNestedPage);
  }, [isNestedPage]);

  const renderLazyFallback = (
    FallbackComponent: React.FunctionComponent<{}> | undefined
  ) => {
    if (child) {
      switch (child) {
        case 'member':
          return <InviteMemberLazySkeleton />;
        case 'other-payments':
          return <OtherPaymentsLazySkeleton />;
        case 'notifications':
          return <NotificationsPreviewLazySkeleton />;
        case 'shipping':
          return <CreateShippingMethodSkeleton />;
        case 'self-pickup':
          return <CreateSelfPickupSkeleton />;
      }
    }

    return FallbackComponent ? <FallbackComponent /> : false;
  };

  const { element } = useSettingsStateContext();

  const onTabChange = (key: string) => {
    // setActive(key);
    navigate(key);
  };

  useLayoutEffect(() => {
    /**
     * Scrolling to top when switching between settings pages.
     */
    const scrollElement = document.querySelector(
      '#settingsPageCard div.ant-tabs-content-holder'
    );

    scrollElement && (scrollElement.scrollTop = 0);
  }, [menuActiveUrl]);

  const tabs = useMemo(() => {
    return generateSettingsTabsByPermissions(t, CanFunction);
  }, []);

  return (
    <div className={styles.settings_container}>
      <ShowElement isShow={showTabBar}>
        <SettingsPageHeader
          extra={element}
          currentPageTitle={t(menuActiveUrl || '')}
        />
      </ShowElement>
      <SeoHelmet title={t('settings')} />
      <Card bodyStyle={{ padding: 0 }} bordered={false} id="settingsPageCard">
        <Tabs
          activeKey={menuActiveUrl}
          tabPosition={screens.xl ? 'left' : 'top'}
          className={`${styles.tabs} ${
            isNestedPage ? styles.tabs_nested_page : ''
          }`}
          onChange={onTabChange}
          tabBarStyle={!showTabBar ? { display: 'none' } : {}}
          destroyInactiveTabPane
          items={tabs.map(({ tab, fallback: Fallback, disabled, key }) => ({
            key: key,
            label: tab,
            style: {
              fontWeight: 400,
              paddingLeft: 0
            },
            children: (
              <Suspense fallback={renderLazyFallback(Fallback)}>
                <Outlet />
              </Suspense>
            ),
            disabled
          }))}
        />
      </Card>
    </div>
  );
};

export default Settings;
