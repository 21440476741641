import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from 'providers/ProjectProvider';
import { useScreenBreakpoints } from 'hooks';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { Button } from 'components/basic';

const PreviewButton = () => {
  const { previewUrl } = useProjectContext();
  const { t } = useTranslation('common');
  const { md } = useScreenBreakpoints();

  return (
    <Button
      className={styles.btn}
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: 'preview'
      })}
      onClick={() => window.open(previewUrl, '_blank')}
      icon={
        <EyeOutlined
          style={{
            fontSize: 20
          }}
        />
      }
    >
      {md && t('preview')}
    </Button>
  );
};

export default PreviewButton;
