export const animation = {
  v: '4.8.0',
  meta: {
    g: 'LottieFiles AE ',
    a: '',
    k: '',
    d: '',
    tc: ''
  },
  fr: 25,
  ip: 0,
  op: 61,
  w: 818,
  h: 364,
  nm: 'Loader',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Front file',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.255],
                y: [1]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              t: 0,
              s: [0]
            },
            {
              i: {
                x: [0.405],
                y: [1]
              },
              o: {
                x: [0.497],
                y: [0]
              },
              t: 25,
              s: [2]
            },
            {
              t: 60,
              s: [0]
            }
          ],
          ix: 10
        },
        p: {
          a: 0,
          k: [411.357, 191.5, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [410.241, 187, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [190, 190, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.209,
                          y: 1
                        },
                        o: {
                          x: 0.333,
                          y: 0
                        },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-2.761, 0],
                              [0, 0],
                              [0, -2.761],
                              [0, 0],
                              [2.761, 0],
                              [0, 0],
                              [0, 2.761],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [2.761, 0],
                              [0, 0],
                              [0, 2.761],
                              [0, 0],
                              [-2.761, 0],
                              [0, 0],
                              [0, -2.761]
                            ],
                            v: [
                              [-14, -5],
                              [14, -5],
                              [19, 0],
                              [19, 0],
                              [14, 5],
                              [-14, 5],
                              [-19, 0],
                              [-19, 0]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: {
                          x: 0.405,
                          y: 1
                        },
                        o: {
                          x: 0.497,
                          y: 0
                        },
                        t: 25,
                        s: [
                          {
                            i: [
                              [-2.761, 0],
                              [0, 0],
                              [0, -2.761],
                              [0, 0],
                              [2.761, 0],
                              [0, 0],
                              [0, 2.761],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [2.761, 0],
                              [0, 0],
                              [0, 2.761],
                              [0, 0],
                              [-2.761, 0],
                              [0, 0],
                              [0, -2.761]
                            ],
                            v: [
                              [-8.607, -5.096],
                              [19.393, -5.096],
                              [24.393, -0.096],
                              [24.393, -0.096],
                              [19.393, 4.904],
                              [-8.607, 4.904],
                              [-13.607, -0.096],
                              [-13.607, -0.096]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 60,
                        s: [
                          {
                            i: [
                              [-2.761, 0],
                              [0, 0],
                              [0, -2.761],
                              [0, 0],
                              [2.761, 0],
                              [0, 0],
                              [0, 2.761],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [2.761, 0],
                              [0, 0],
                              [0, 2.761],
                              [0, 0],
                              [-2.761, 0],
                              [0, 0],
                              [0, -2.761]
                            ],
                            v: [
                              [-14, -5],
                              [14, -5],
                              [19, 0],
                              [19, 0],
                              [14, 5],
                              [-14, 5],
                              [-19, 0],
                              [-19, 0]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [1, 1, 1, 1],
                    ix: 4
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [374.5, 153],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 7',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.209,
                          y: 1
                        },
                        o: {
                          x: 0.333,
                          y: 0
                        },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-9.311, 0],
                              [0, 0],
                              [0, 0],
                              [-3.026, -3.242],
                              [0, 0],
                              [-4.435, 0],
                              [0, 0],
                              [0, 0],
                              [0.995, -9.447],
                              [0, 0],
                              [8.188, 0],
                              [0, 0],
                              [0.63, 8.346]
                            ],
                            o: [
                              [-0.7, -9.285],
                              [0, 0],
                              [0, 0],
                              [4.435, 0],
                              [0, 0],
                              [3.026, 3.242],
                              [0, 0],
                              [0, 0],
                              [9.5, 0],
                              [0, 0],
                              [-0.857, 8.143],
                              [0, 0],
                              [-8.37, 0],
                              [0, 0]
                            ],
                            v: [
                              [-68.442, -35.796],
                              [-52.487, -53],
                              [-41.74, -53],
                              [-20.693, -53],
                              [-8.996, -47.917],
                              [9.516, -28.083],
                              [21.213, -23],
                              [42.26, -23],
                              [52.487, -23],
                              [68.399, -5.325],
                              [63.768, 38.675],
                              [47.856, 53],
                              [-46.902, 53],
                              [-62.857, 38.204]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: {
                          x: 0.405,
                          y: 1
                        },
                        o: {
                          x: 0.497,
                          y: 0
                        },
                        t: 25,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-9.311, 0],
                              [0, 0],
                              [0, 0],
                              [-3.026, -3.242],
                              [0, 0],
                              [-4.435, 0],
                              [0, 0],
                              [0, 0],
                              [0.995, -9.447],
                              [0, 0],
                              [8.188, 0],
                              [0, 0],
                              [0.63, 8.346]
                            ],
                            o: [
                              [-0.7, -9.285],
                              [0, 0],
                              [0, 0],
                              [4.435, 0],
                              [0, 0],
                              [3.026, 3.242],
                              [0, 0],
                              [0, 0],
                              [9.5, 0],
                              [0, 0],
                              [-0.857, 8.143],
                              [0, 0],
                              [-8.37, 0],
                              [0, 0]
                            ],
                            v: [
                              [-63.049, -35.892],
                              [-47.094, -53.096],
                              [-36.347, -53.096],
                              [-15.3, -53.096],
                              [-3.603, -48.014],
                              [9.646, -28.179],
                              [21.343, -23.096],
                              [40.812, -23.041],
                              [51.039, -23.041],
                              [66.951, -5.366],
                              [59.558, 38.73],
                              [43.646, 53.055],
                              [-44.271, 53],
                              [-60.226, 38.204]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 60,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-9.311, 0],
                              [0, 0],
                              [0, 0],
                              [-3.026, -3.242],
                              [0, 0],
                              [-4.435, 0],
                              [0, 0],
                              [0, 0],
                              [0.995, -9.447],
                              [0, 0],
                              [8.188, 0],
                              [0, 0],
                              [0.63, 8.346]
                            ],
                            o: [
                              [-0.7, -9.285],
                              [0, 0],
                              [0, 0],
                              [4.435, 0],
                              [0, 0],
                              [3.026, 3.242],
                              [0, 0],
                              [0, 0],
                              [9.5, 0],
                              [0, 0],
                              [-0.857, 8.143],
                              [0, 0],
                              [-8.37, 0],
                              [0, 0]
                            ],
                            v: [
                              [-68.442, -35.796],
                              [-52.487, -53],
                              [-41.74, -53],
                              [-20.693, -53],
                              [-8.996, -47.917],
                              [9.516, -28.083],
                              [21.213, -23],
                              [42.26, -23],
                              [52.487, -23],
                              [68.399, -5.325],
                              [63.768, 38.675],
                              [47.856, 53],
                              [-46.902, 53],
                              [-62.857, 38.204]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.458823531866, 0.850980401039, 0.741176486015, 1],
                    ix: 4
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [410.24, 187],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [410.24, 187],
                ix: 2
              },
              a: {
                a: 0,
                k: [410.24, 187],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 6',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Paper',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.255],
                y: [1]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              t: 0,
              s: [0]
            },
            {
              i: {
                x: [0.398],
                y: [1]
              },
              o: {
                x: [0.497],
                y: [0]
              },
              t: 28,
              s: [-2]
            },
            {
              t: 60,
              s: [0]
            }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.255,
                y: 1
              },
              o: {
                x: 0.333,
                y: 0
              },
              t: 0,
              s: [411.85, 80.35, 0],
              to: [0, 0.75, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.398,
                y: 1
              },
              o: {
                x: 0.497,
                y: 0
              },
              t: 28,
              s: [411.85, 84.85, 0],
              to: [0, 0, 0],
              ti: [0, 0.75, 0]
            },
            {
              t: 60,
              s: [411.85, 80.35, 0]
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [410.5, 128.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [190, 190, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.255,
                          y: 1
                        },
                        o: {
                          x: 0.333,
                          y: 0
                        },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-16.181, -5],
                              [18.819, -5],
                              [16.181, 5],
                              [-18.819, 5]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: {
                          x: 0.398,
                          y: 1
                        },
                        o: {
                          x: 0.497,
                          y: 0
                        },
                        t: 28,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-18.848, -4.04],
                              [12.205, -4.04],
                              [9.567, 5.96],
                              [-21.486, 5.96]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 60,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-16.181, -5],
                              [18.819, -5],
                              [16.181, 5],
                              [-18.819, 5]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.854901969433, 0.949019610882, 0.917647063732, 1],
                    ix: 4
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [403.319, 133],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 5',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.255,
                          y: 1
                        },
                        o: {
                          x: 0.333,
                          y: 0
                        },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-24.682, -5],
                              [27.318, -5],
                              [24.681, 5],
                              [-27.319, 5]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: {
                          x: 0.398,
                          y: 1
                        },
                        o: {
                          x: 0.497,
                          y: 0
                        },
                        t: 28,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-27.348, -4.04],
                              [20.704, -4.04],
                              [18.067, 5.96],
                              [-29.985, 5.96]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 60,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-24.682, -5],
                              [27.318, -5],
                              [24.681, 5],
                              [-27.319, 5]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.854901969433, 0.949019610882, 0.917647063732, 1],
                    ix: 4
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [416.831, 114],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 4',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.255,
                          y: 1
                        },
                        o: {
                          x: 0.333,
                          y: 0
                        },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-7.356, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [1.767, -7.14],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-38.446, -25.344],
                              [-22.915, -37.5],
                              [54, -37.5],
                              [35.438, 37.5],
                              [-54, 37.5]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: {
                          x: 0.398,
                          y: 1
                        },
                        o: {
                          x: 0.497,
                          y: 0
                        },
                        t: 28,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-7.356, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [1.767, -7.14],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-41.113, -24.384],
                              [-25.582, -36.54],
                              [47.386, -36.54],
                              [34.122, 37.5],
                              [-51.368, 37.5]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 60,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-7.356, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [1.767, -7.14],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-38.446, -25.344],
                              [-22.915, -37.5],
                              [54, -37.5],
                              [35.438, 37.5],
                              [-54, 37.5]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [1, 1, 1, 1],
                    ix: 4
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [410.5, 128.5],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 3',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'File Back',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.255],
                y: [1]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              t: 0,
              s: [0]
            },
            {
              i: {
                x: [0.405],
                y: [1]
              },
              o: {
                x: [0.497],
                y: [0]
              },
              t: 25,
              s: [2]
            },
            {
              t: 60,
              s: [0]
            }
          ],
          ix: 10
        },
        p: {
          a: 0,
          k: [413.75, 175.35, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [190, 190, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.255,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-8.956, 0],
                          [0, 0],
                          [0.581, -8.937],
                          [0, 0],
                          [8.17, 0],
                          [0, 0],
                          [0.53, 8.153]
                        ],
                        o: [
                          [-0.581, -8.937],
                          [0, 0],
                          [8.956, 0],
                          [0, 0],
                          [-0.53, 8.153],
                          [0, 0],
                          [-8.17, 0],
                          [0, 0]
                        ],
                        v: [
                          [-61.393, -44.494],
                          [-45.926, -61],
                          [45.926, -61],
                          [61.393, -44.494],
                          [55.474, 46.506],
                          [40.007, 61],
                          [-40.007, 61],
                          [-55.474, 46.506]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: {
                      x: 0.405,
                      y: 1
                    },
                    o: {
                      x: 0.497,
                      y: 0
                    },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-8.956, 0],
                          [0, 0],
                          [0.581, -8.937],
                          [0, 0],
                          [8.17, 0],
                          [0, 0],
                          [0.53, 8.153]
                        ],
                        o: [
                          [-0.581, -8.937],
                          [0, 0],
                          [8.956, 0],
                          [0, 0],
                          [-0.53, 8.153],
                          [0, 0],
                          [-8.17, 0],
                          [0, 0]
                        ],
                        v: [
                          [-58.532, -42.394],
                          [-43.065, -58.9],
                          [43.524, -56.006],
                          [58.991, -39.5],
                          [52.824, 45.979],
                          [37.357, 60.473],
                          [-37.393, 57.578],
                          [-52.86, 43.084]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 60,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-8.956, 0],
                          [0, 0],
                          [0.581, -8.937],
                          [0, 0],
                          [8.17, 0],
                          [0, 0],
                          [0.53, 8.153]
                        ],
                        o: [
                          [-0.581, -8.937],
                          [0, 0],
                          [8.956, 0],
                          [0, 0],
                          [-0.53, 8.153],
                          [0, 0],
                          [-8.17, 0],
                          [0, 0]
                        ],
                        v: [
                          [-61.393, -44.494],
                          [-45.926, -61],
                          [45.926, -61],
                          [61.393, -44.494],
                          [55.474, 46.506],
                          [40.007, 61],
                          [-40.007, 61],
                          [-55.474, 46.506]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'gs',
              o: {
                a: 0,
                k: 100,
                ix: 9
              },
              w: {
                a: 0,
                k: 1,
                ix: 10
              },
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 1, 1, 1, 0.5, 1, 1, 1, 1, 1, 1, 1, 0, 0.295, 0.5, 0.153,
                    1, 0.01
                  ],
                  ix: 8
                }
              },
              s: {
                a: 0,
                k: [-60, -58.5],
                ix: 4
              },
              e: {
                a: 0,
                k: [17.334, -118.803],
                ix: 5
              },
              t: 1,
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: {
                a: 0,
                k: 4,
                ix: 13
              },
              bm: 0,
              nm: 'Gradient Stroke 1',
              mn: 'ADBE Vector Graphic - G-Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.301960796118, 0.654901981354, 0.537254929543, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Icon 9',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: -40,
              s: [0]
            },
            {
              i: {
                x: [0.762],
                y: [1]
              },
              o: {
                x: [0.314],
                y: [0]
              },
              t: -34,
              s: [100]
            },
            {
              i: {
                x: [0.738],
                y: [1]
              },
              o: {
                x: [0.399],
                y: [0]
              },
              t: 15,
              s: [100]
            },
            {
              t: 20,
              s: [0]
            }
          ],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: -40,
              s: [42.451, 341.7, 0],
              to: [60.667, -85.083, 0],
              ti: [-233.917, 1.083, 0]
            },
            {
              t: 19.9990234375,
              s: [409.201, 183.45, 0]
            }
          ],
          ix: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        a: {
          a: 0,
          k: [514.501, 195, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              t: -40,
              s: [100, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              t: -10,
              s: [190, 190, 100]
            },
            {
              t: 20,
              s: [190, 190, 100]
            }
          ],
          ix: 6,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.594, -0.001],
                        [0.081, 0.005],
                        [0.403, 0.493],
                        [-0.063, 0.633],
                        [0, 0],
                        [0.035, 0.106],
                        [0.075, 0.083],
                        [0.103, 0.045],
                        [0.112, 0],
                        [0.45, 0.45],
                        [0, 0.636],
                        [-0.45, 0.45],
                        [-0.637, 0],
                        [-0.715, -0.317],
                        [-0.525, -0.58],
                        [-0.244, -0.743],
                        [0.078, -0.779],
                        [0, 0],
                        [0.44, -0.399]
                      ],
                      o: [
                        [-0.081, 0.005],
                        [-0.633, -0.064],
                        [-0.403, -0.492],
                        [0, 0],
                        [0.011, -0.112],
                        [-0.036, -0.106],
                        [-0.076, -0.083],
                        [-0.102, -0.045],
                        [-0.637, 0],
                        [-0.45, -0.45],
                        [0, -0.637],
                        [0.45, -0.45],
                        [0.782, 0],
                        [0.716, 0.317],
                        [0.525, 0.58],
                        [0.245, 0.743],
                        [0, 0],
                        [-0.06, 0.591],
                        [-0.44, 0.399]
                      ],
                      v: [
                        [0.742, 8.392],
                        [0.499, 8.392],
                        [-1.119, 7.523],
                        [-1.651, 5.765],
                        [-0.8, -2.715],
                        [-0.837, -3.046],
                        [-1.005, -3.332],
                        [-1.275, -3.527],
                        [-1.6, -3.595],
                        [-3.297, -4.298],
                        [-4, -5.995],
                        [-3.297, -7.692],
                        [-1.6, -8.395],
                        [0.671, -7.915],
                        [2.552, -6.554],
                        [3.719, -4.547],
                        [3.971, -2.239],
                        [3.126, 6.232],
                        [2.349, 7.77]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [-0.269]
                        },
                        t: -40,
                        s: [1, 1, 1, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 2,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      },
                      {
                        t: 20,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [526.5, 187.395],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 2',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.001],
                        [0.365, 0.34],
                        [0.04, 0.498],
                        [-0.307, 0.395],
                        [-0.493, 0.084],
                        [0, 0],
                        [-0.263, -0.053],
                        [-0.222, -0.152],
                        [-0.145, -0.227],
                        [-0.044, -0.265],
                        [0.063, -0.261],
                        [0.16, -0.216],
                        [0.232, -0.136],
                        [0.266, -0.034]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.019],
                        [-0.5, -0.004],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.264, -0.054],
                        [0.264, 0.053],
                        [0.222, 0.152],
                        [0.145, 0.227],
                        [0.045, 0.265],
                        [-0.063, 0.262],
                        [-0.161, 0.216],
                        [-0.232, 0.136],
                        [0, 0]
                      ],
                      v: [
                        [105.094, 9.909],
                        [97.878, 11.115],
                        [97.558, 11.144],
                        [96.213, 10.608],
                        [95.582, 9.305],
                        [95.996, 7.917],
                        [97.238, 7.173],
                        [104.434, 5.96],
                        [105.233, 5.958],
                        [105.969, 6.269],
                        [106.525, 6.843],
                        [106.811, 7.589],
                        [106.783, 8.387],
                        [106.445, 9.111],
                        [105.85, 9.644],
                        [105.094, 9.902]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.002],
                        [0.365, 0.341],
                        [0.04, 0.498],
                        [-0.306, 0.394],
                        [-0.493, 0.085],
                        [0, 0],
                        [-0.415, -0.309],
                        [-0.085, -0.51],
                        [0.292, -0.427],
                        [0.507, -0.104]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.016],
                        [-0.5, -0.005],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.513, -0.066],
                        [0.415, 0.308],
                        [0.086, 0.51],
                        [-0.293, 0.427],
                        [0, 0]
                      ],
                      v: [
                        [103.753, 17.762],
                        [99.785, 18.44],
                        [99.465, 18.466],
                        [98.12, 17.929],
                        [97.489, 16.626],
                        [97.903, 15.239],
                        [99.145, 14.494],
                        [103.113, 13.832],
                        [104.56, 14.21],
                        [105.34, 15.486],
                        [105.018, 16.946],
                        [103.772, 17.774]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.104, 0.621],
                        [0, 0],
                        [1.036, 0.739],
                        [1.255, -0.21],
                        [0, 0],
                        [0.74, -1.035],
                        [-0.208, -1.255],
                        [0, 0],
                        [-0.865, -0.734],
                        [-1.134, -0.003],
                        [-0.265, 0.044],
                        [0, 0],
                        [-0.534, 0.334],
                        [-0.366, 0.513],
                        [-0.142, 0.614]
                      ],
                      o: [
                        [0, 0],
                        [-0.211, -1.255],
                        [-1.036, -0.739],
                        [0, 0],
                        [-1.255, 0.21],
                        [-0.739, 1.035],
                        [0, 0],
                        [0.187, 1.119],
                        [0.864, 0.734],
                        [0.269, 0],
                        [0, 0],
                        [0.622, -0.104],
                        [0.535, -0.335],
                        [0.366, -0.513],
                        [0.142, -0.615]
                      ],
                      v: [
                        [114.831, 21.282],
                        [111.75, 2.859],
                        [109.802, -0.255],
                        [106.223, -1.08],
                        [93.51, 1.048],
                        [90.396, 2.992],
                        [89.567, 6.568],
                        [92.649, 24.99],
                        [94.277, 27.859],
                        [97.372, 29],
                        [98.175, 28.933],
                        [110.889, 26.808],
                        [112.64, 26.145],
                        [114.005, 24.861],
                        [114.774, 23.154]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [-0.269]
                        },
                        t: -40,
                        s: [1, 1, 1, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 2,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      },
                      {
                        t: 20,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [409, 182],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: -40,
      op: 61,
      st: -40,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Icon 8',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: -20,
              s: [0]
            },
            {
              i: {
                x: [0.762],
                y: [1]
              },
              o: {
                x: [0.314],
                y: [0]
              },
              t: -14,
              s: [100]
            },
            {
              i: {
                x: [0.738],
                y: [1]
              },
              o: {
                x: [0.399],
                y: [0]
              },
              t: 35,
              s: [100]
            },
            {
              t: 40,
              s: [0]
            }
          ],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: -20,
              s: [42.451, 341.7, 0],
              to: [60.667, -85.083, 0],
              ti: [-233.917, 1.083, 0]
            },
            {
              t: 39.9990234375,
              s: [409.201, 183.45, 0]
            }
          ],
          ix: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        a: {
          a: 0,
          k: [514.501, 195, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              t: -20,
              s: [100, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              t: 10,
              s: [190, 190, 100]
            },
            {
              t: 40,
              s: [190, 190, 100]
            }
          ],
          ix: 6,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.594, -0.001],
                        [0.081, 0.005],
                        [0.403, 0.493],
                        [-0.063, 0.633],
                        [0, 0],
                        [0.035, 0.106],
                        [0.075, 0.083],
                        [0.103, 0.045],
                        [0.112, 0],
                        [0.45, 0.45],
                        [0, 0.636],
                        [-0.45, 0.45],
                        [-0.637, 0],
                        [-0.715, -0.317],
                        [-0.525, -0.58],
                        [-0.244, -0.743],
                        [0.078, -0.779],
                        [0, 0],
                        [0.44, -0.399]
                      ],
                      o: [
                        [-0.081, 0.005],
                        [-0.633, -0.064],
                        [-0.403, -0.492],
                        [0, 0],
                        [0.011, -0.112],
                        [-0.036, -0.106],
                        [-0.076, -0.083],
                        [-0.102, -0.045],
                        [-0.637, 0],
                        [-0.45, -0.45],
                        [0, -0.637],
                        [0.45, -0.45],
                        [0.782, 0],
                        [0.716, 0.317],
                        [0.525, 0.58],
                        [0.245, 0.743],
                        [0, 0],
                        [-0.06, 0.591],
                        [-0.44, 0.399]
                      ],
                      v: [
                        [0.742, 8.392],
                        [0.499, 8.392],
                        [-1.119, 7.523],
                        [-1.651, 5.765],
                        [-0.8, -2.715],
                        [-0.837, -3.046],
                        [-1.005, -3.332],
                        [-1.275, -3.527],
                        [-1.6, -3.595],
                        [-3.297, -4.298],
                        [-4, -5.995],
                        [-3.297, -7.692],
                        [-1.6, -8.395],
                        [0.671, -7.915],
                        [2.552, -6.554],
                        [3.719, -4.547],
                        [3.971, -2.239],
                        [3.126, 6.232],
                        [2.349, 7.77]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [-0.269]
                        },
                        t: -20,
                        s: [1, 1, 1, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 22,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      },
                      {
                        t: 40,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [526.5, 187.395],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 2',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.001],
                        [0.365, 0.34],
                        [0.04, 0.498],
                        [-0.307, 0.395],
                        [-0.493, 0.084],
                        [0, 0],
                        [-0.263, -0.053],
                        [-0.222, -0.152],
                        [-0.145, -0.227],
                        [-0.044, -0.265],
                        [0.063, -0.261],
                        [0.16, -0.216],
                        [0.232, -0.136],
                        [0.266, -0.034]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.019],
                        [-0.5, -0.004],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.264, -0.054],
                        [0.264, 0.053],
                        [0.222, 0.152],
                        [0.145, 0.227],
                        [0.045, 0.265],
                        [-0.063, 0.262],
                        [-0.161, 0.216],
                        [-0.232, 0.136],
                        [0, 0]
                      ],
                      v: [
                        [105.094, 9.909],
                        [97.878, 11.115],
                        [97.558, 11.144],
                        [96.213, 10.608],
                        [95.582, 9.305],
                        [95.996, 7.917],
                        [97.238, 7.173],
                        [104.434, 5.96],
                        [105.233, 5.958],
                        [105.969, 6.269],
                        [106.525, 6.843],
                        [106.811, 7.589],
                        [106.783, 8.387],
                        [106.445, 9.111],
                        [105.85, 9.644],
                        [105.094, 9.902]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.002],
                        [0.365, 0.341],
                        [0.04, 0.498],
                        [-0.306, 0.394],
                        [-0.493, 0.085],
                        [0, 0],
                        [-0.415, -0.309],
                        [-0.085, -0.51],
                        [0.292, -0.427],
                        [0.507, -0.104]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.016],
                        [-0.5, -0.005],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.513, -0.066],
                        [0.415, 0.308],
                        [0.086, 0.51],
                        [-0.293, 0.427],
                        [0, 0]
                      ],
                      v: [
                        [103.753, 17.762],
                        [99.785, 18.44],
                        [99.465, 18.466],
                        [98.12, 17.929],
                        [97.489, 16.626],
                        [97.903, 15.239],
                        [99.145, 14.494],
                        [103.113, 13.832],
                        [104.56, 14.21],
                        [105.34, 15.486],
                        [105.018, 16.946],
                        [103.772, 17.774]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.104, 0.621],
                        [0, 0],
                        [1.036, 0.739],
                        [1.255, -0.21],
                        [0, 0],
                        [0.74, -1.035],
                        [-0.208, -1.255],
                        [0, 0],
                        [-0.865, -0.734],
                        [-1.134, -0.003],
                        [-0.265, 0.044],
                        [0, 0],
                        [-0.534, 0.334],
                        [-0.366, 0.513],
                        [-0.142, 0.614]
                      ],
                      o: [
                        [0, 0],
                        [-0.211, -1.255],
                        [-1.036, -0.739],
                        [0, 0],
                        [-1.255, 0.21],
                        [-0.739, 1.035],
                        [0, 0],
                        [0.187, 1.119],
                        [0.864, 0.734],
                        [0.269, 0],
                        [0, 0],
                        [0.622, -0.104],
                        [0.535, -0.335],
                        [0.366, -0.513],
                        [0.142, -0.615]
                      ],
                      v: [
                        [114.831, 21.282],
                        [111.75, 2.859],
                        [109.802, -0.255],
                        [106.223, -1.08],
                        [93.51, 1.048],
                        [90.396, 2.992],
                        [89.567, 6.568],
                        [92.649, 24.99],
                        [94.277, 27.859],
                        [97.372, 29],
                        [98.175, 28.933],
                        [110.889, 26.808],
                        [112.64, 26.145],
                        [114.005, 24.861],
                        [114.774, 23.154]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [-0.269]
                        },
                        t: -20,
                        s: [1, 1, 1, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 22,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      },
                      {
                        t: 40,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [409, 182],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: -20,
      op: 61,
      st: -20,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Icon 7',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 0,
              s: [0]
            },
            {
              i: {
                x: [0.762],
                y: [1]
              },
              o: {
                x: [0.314],
                y: [0]
              },
              t: 6,
              s: [100]
            },
            {
              i: {
                x: [0.738],
                y: [1]
              },
              o: {
                x: [0.399],
                y: [0]
              },
              t: 55,
              s: [100]
            },
            {
              t: 60,
              s: [0]
            }
          ],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 0,
              s: [42.451, 341.7, 0],
              to: [60.667, -85.083, 0],
              ti: [-233.917, 1.083, 0]
            },
            {
              t: 59.9990234375,
              s: [409.201, 183.45, 0]
            }
          ],
          ix: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        a: {
          a: 0,
          k: [514.501, 195, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              t: 0,
              s: [100, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              t: 30,
              s: [190, 190, 100]
            },
            {
              t: 60,
              s: [190, 190, 100]
            }
          ],
          ix: 6,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.594, -0.001],
                        [0.081, 0.005],
                        [0.403, 0.493],
                        [-0.063, 0.633],
                        [0, 0],
                        [0.035, 0.106],
                        [0.075, 0.083],
                        [0.103, 0.045],
                        [0.112, 0],
                        [0.45, 0.45],
                        [0, 0.636],
                        [-0.45, 0.45],
                        [-0.637, 0],
                        [-0.715, -0.317],
                        [-0.525, -0.58],
                        [-0.244, -0.743],
                        [0.078, -0.779],
                        [0, 0],
                        [0.44, -0.399]
                      ],
                      o: [
                        [-0.081, 0.005],
                        [-0.633, -0.064],
                        [-0.403, -0.492],
                        [0, 0],
                        [0.011, -0.112],
                        [-0.036, -0.106],
                        [-0.076, -0.083],
                        [-0.102, -0.045],
                        [-0.637, 0],
                        [-0.45, -0.45],
                        [0, -0.637],
                        [0.45, -0.45],
                        [0.782, 0],
                        [0.716, 0.317],
                        [0.525, 0.58],
                        [0.245, 0.743],
                        [0, 0],
                        [-0.06, 0.591],
                        [-0.44, 0.399]
                      ],
                      v: [
                        [0.742, 8.392],
                        [0.499, 8.392],
                        [-1.119, 7.523],
                        [-1.651, 5.765],
                        [-0.8, -2.715],
                        [-0.837, -3.046],
                        [-1.005, -3.332],
                        [-1.275, -3.527],
                        [-1.6, -3.595],
                        [-3.297, -4.298],
                        [-4, -5.995],
                        [-3.297, -7.692],
                        [-1.6, -8.395],
                        [0.671, -7.915],
                        [2.552, -6.554],
                        [3.719, -4.547],
                        [3.971, -2.239],
                        [3.126, 6.232],
                        [2.349, 7.77]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [-0.269]
                        },
                        t: 0,
                        s: [1, 1, 1, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 42,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      },
                      {
                        t: 60,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [526.5, 187.395],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 2',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.001],
                        [0.365, 0.34],
                        [0.04, 0.498],
                        [-0.307, 0.395],
                        [-0.493, 0.084],
                        [0, 0],
                        [-0.263, -0.053],
                        [-0.222, -0.152],
                        [-0.145, -0.227],
                        [-0.044, -0.265],
                        [0.063, -0.261],
                        [0.16, -0.216],
                        [0.232, -0.136],
                        [0.266, -0.034]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.019],
                        [-0.5, -0.004],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.264, -0.054],
                        [0.264, 0.053],
                        [0.222, 0.152],
                        [0.145, 0.227],
                        [0.045, 0.265],
                        [-0.063, 0.262],
                        [-0.161, 0.216],
                        [-0.232, 0.136],
                        [0, 0]
                      ],
                      v: [
                        [105.094, 9.909],
                        [97.878, 11.115],
                        [97.558, 11.144],
                        [96.213, 10.608],
                        [95.582, 9.305],
                        [95.996, 7.917],
                        [97.238, 7.173],
                        [104.434, 5.96],
                        [105.233, 5.958],
                        [105.969, 6.269],
                        [106.525, 6.843],
                        [106.811, 7.589],
                        [106.783, 8.387],
                        [106.445, 9.111],
                        [105.85, 9.644],
                        [105.094, 9.902]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.002],
                        [0.365, 0.341],
                        [0.04, 0.498],
                        [-0.306, 0.394],
                        [-0.493, 0.085],
                        [0, 0],
                        [-0.415, -0.309],
                        [-0.085, -0.51],
                        [0.292, -0.427],
                        [0.507, -0.104]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.016],
                        [-0.5, -0.005],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.513, -0.066],
                        [0.415, 0.308],
                        [0.086, 0.51],
                        [-0.293, 0.427],
                        [0, 0]
                      ],
                      v: [
                        [103.753, 17.762],
                        [99.785, 18.44],
                        [99.465, 18.466],
                        [98.12, 17.929],
                        [97.489, 16.626],
                        [97.903, 15.239],
                        [99.145, 14.494],
                        [103.113, 13.832],
                        [104.56, 14.21],
                        [105.34, 15.486],
                        [105.018, 16.946],
                        [103.772, 17.774]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.104, 0.621],
                        [0, 0],
                        [1.036, 0.739],
                        [1.255, -0.21],
                        [0, 0],
                        [0.74, -1.035],
                        [-0.208, -1.255],
                        [0, 0],
                        [-0.865, -0.734],
                        [-1.134, -0.003],
                        [-0.265, 0.044],
                        [0, 0],
                        [-0.534, 0.334],
                        [-0.366, 0.513],
                        [-0.142, 0.614]
                      ],
                      o: [
                        [0, 0],
                        [-0.211, -1.255],
                        [-1.036, -0.739],
                        [0, 0],
                        [-1.255, 0.21],
                        [-0.739, 1.035],
                        [0, 0],
                        [0.187, 1.119],
                        [0.864, 0.734],
                        [0.269, 0],
                        [0, 0],
                        [0.622, -0.104],
                        [0.535, -0.335],
                        [0.366, -0.513],
                        [0.142, -0.615]
                      ],
                      v: [
                        [114.831, 21.282],
                        [111.75, 2.859],
                        [109.802, -0.255],
                        [106.223, -1.08],
                        [93.51, 1.048],
                        [90.396, 2.992],
                        [89.567, 6.568],
                        [92.649, 24.99],
                        [94.277, 27.859],
                        [97.372, 29],
                        [98.175, 28.933],
                        [110.889, 26.808],
                        [112.64, 26.145],
                        [114.005, 24.861],
                        [114.774, 23.154]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [-0.269]
                        },
                        t: 0,
                        s: [1, 1, 1, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 42,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      },
                      {
                        t: 60,
                        s: [0.831372618675, 0.850980460644, 0.84705889225, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [409, 182],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Icon 5',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: -40,
              s: [100]
            },
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 13.999,
              s: [100]
            },
            {
              t: 19.9990234375,
              s: [0]
            }
          ],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: -40,
              s: [409.451, 181.7, 0],
              to: [229.667, 0.917, 0],
              ti: [-41.917, -68.917, 0]
            },
            {
              t: 19.9990234375,
              s: [782.201, 340.45, 0]
            }
          ],
          ix: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        a: {
          a: 0,
          k: [514.501, 195, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0]
              },
              t: -40,
              s: [190, 190, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 8.895]
              },
              o: {
                x: [0.497, 0.497, 0.497],
                y: [0, 0, 0]
              },
              t: -10,
              s: [190, 190, 100]
            },
            {
              t: 19.9990234375,
              s: [100, 100, 100]
            }
          ],
          ix: 6,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.594, -0.001],
                        [0.081, 0.005],
                        [0.403, 0.493],
                        [-0.063, 0.633],
                        [0, 0],
                        [0.035, 0.106],
                        [0.075, 0.083],
                        [0.103, 0.045],
                        [0.112, 0],
                        [0.45, 0.45],
                        [0, 0.636],
                        [-0.45, 0.45],
                        [-0.637, 0],
                        [-0.715, -0.317],
                        [-0.525, -0.58],
                        [-0.244, -0.743],
                        [0.078, -0.779],
                        [0, 0],
                        [0.44, -0.399]
                      ],
                      o: [
                        [-0.081, 0.005],
                        [-0.633, -0.064],
                        [-0.403, -0.492],
                        [0, 0],
                        [0.011, -0.112],
                        [-0.036, -0.106],
                        [-0.076, -0.083],
                        [-0.102, -0.045],
                        [-0.637, 0],
                        [-0.45, -0.45],
                        [0, -0.637],
                        [0.45, -0.45],
                        [0.782, 0],
                        [0.716, 0.317],
                        [0.525, 0.58],
                        [0.245, 0.743],
                        [0, 0],
                        [-0.06, 0.591],
                        [-0.44, 0.399]
                      ],
                      v: [
                        [0.742, 8.392],
                        [0.499, 8.392],
                        [-1.119, 7.523],
                        [-1.651, 5.765],
                        [-0.8, -2.715],
                        [-0.837, -3.046],
                        [-1.005, -3.332],
                        [-1.275, -3.527],
                        [-1.6, -3.595],
                        [-3.297, -4.298],
                        [-4, -5.995],
                        [-3.297, -7.692],
                        [-1.6, -8.395],
                        [0.671, -7.915],
                        [2.552, -6.554],
                        [3.719, -4.547],
                        [3.971, -2.239],
                        [3.126, 6.232],
                        [2.349, 7.77]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [0]
                        },
                        t: -40,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [0.779]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: -28,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        t: 19.9990234375,
                        s: [0.854901969433, 0.949019610882, 0.917647063732, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [526.5, 187.395],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 2',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.001],
                        [0.365, 0.34],
                        [0.04, 0.498],
                        [-0.307, 0.395],
                        [-0.493, 0.084],
                        [0, 0],
                        [-0.263, -0.053],
                        [-0.222, -0.152],
                        [-0.145, -0.227],
                        [-0.044, -0.265],
                        [0.063, -0.261],
                        [0.16, -0.216],
                        [0.232, -0.136],
                        [0.266, -0.034]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.019],
                        [-0.5, -0.004],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.264, -0.054],
                        [0.264, 0.053],
                        [0.222, 0.152],
                        [0.145, 0.227],
                        [0.045, 0.265],
                        [-0.063, 0.262],
                        [-0.161, 0.216],
                        [-0.232, 0.136],
                        [0, 0]
                      ],
                      v: [
                        [105.094, 9.909],
                        [97.878, 11.115],
                        [97.558, 11.144],
                        [96.213, 10.608],
                        [95.582, 9.305],
                        [95.996, 7.917],
                        [97.238, 7.173],
                        [104.434, 5.96],
                        [105.233, 5.958],
                        [105.969, 6.269],
                        [106.525, 6.843],
                        [106.811, 7.589],
                        [106.783, 8.387],
                        [106.445, 9.111],
                        [105.85, 9.644],
                        [105.094, 9.902]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.002],
                        [0.365, 0.341],
                        [0.04, 0.498],
                        [-0.306, 0.394],
                        [-0.493, 0.085],
                        [0, 0],
                        [-0.415, -0.309],
                        [-0.085, -0.51],
                        [0.292, -0.427],
                        [0.507, -0.104]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.016],
                        [-0.5, -0.005],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.513, -0.066],
                        [0.415, 0.308],
                        [0.086, 0.51],
                        [-0.293, 0.427],
                        [0, 0]
                      ],
                      v: [
                        [103.753, 17.762],
                        [99.785, 18.44],
                        [99.465, 18.466],
                        [98.12, 17.929],
                        [97.489, 16.626],
                        [97.903, 15.239],
                        [99.145, 14.494],
                        [103.113, 13.832],
                        [104.56, 14.21],
                        [105.34, 15.486],
                        [105.018, 16.946],
                        [103.772, 17.774]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.104, 0.621],
                        [0, 0],
                        [1.036, 0.739],
                        [1.255, -0.21],
                        [0, 0],
                        [0.74, -1.035],
                        [-0.208, -1.255],
                        [0, 0],
                        [-0.865, -0.734],
                        [-1.134, -0.003],
                        [-0.265, 0.044],
                        [0, 0],
                        [-0.534, 0.334],
                        [-0.366, 0.513],
                        [-0.142, 0.614]
                      ],
                      o: [
                        [0, 0],
                        [-0.211, -1.255],
                        [-1.036, -0.739],
                        [0, 0],
                        [-1.255, 0.21],
                        [-0.739, 1.035],
                        [0, 0],
                        [0.187, 1.119],
                        [0.864, 0.734],
                        [0.269, 0],
                        [0, 0],
                        [0.622, -0.104],
                        [0.535, -0.335],
                        [0.366, -0.513],
                        [0.142, -0.615]
                      ],
                      v: [
                        [114.831, 21.282],
                        [111.75, 2.859],
                        [109.802, -0.255],
                        [106.223, -1.08],
                        [93.51, 1.048],
                        [90.396, 2.992],
                        [89.567, 6.568],
                        [92.649, 24.99],
                        [94.277, 27.859],
                        [97.372, 29],
                        [98.175, 28.933],
                        [110.889, 26.808],
                        [112.64, 26.145],
                        [114.005, 24.861],
                        [114.774, 23.154]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [0]
                        },
                        t: -40,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [0.779]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: -28,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        t: 19.9990234375,
                        s: [0.854901969433, 0.949019610882, 0.917647063732, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [409, 182],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: -40,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Icon 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: -20,
              s: [100]
            },
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 33.999,
              s: [100]
            },
            {
              t: 39.9990234375,
              s: [0]
            }
          ],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: -20,
              s: [409.451, 181.7, 0],
              to: [229.667, 0.917, 0],
              ti: [-41.917, -68.917, 0]
            },
            {
              t: 39.9990234375,
              s: [782.201, 340.45, 0]
            }
          ],
          ix: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        a: {
          a: 0,
          k: [514.501, 195, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0]
              },
              t: -20,
              s: [190, 190, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 8.895]
              },
              o: {
                x: [0.497, 0.497, 0.497],
                y: [0, 0, 0]
              },
              t: 10,
              s: [190, 190, 100]
            },
            {
              t: 39.9990234375,
              s: [100, 100, 100]
            }
          ],
          ix: 6,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.594, -0.001],
                        [0.081, 0.005],
                        [0.403, 0.493],
                        [-0.063, 0.633],
                        [0, 0],
                        [0.035, 0.106],
                        [0.075, 0.083],
                        [0.103, 0.045],
                        [0.112, 0],
                        [0.45, 0.45],
                        [0, 0.636],
                        [-0.45, 0.45],
                        [-0.637, 0],
                        [-0.715, -0.317],
                        [-0.525, -0.58],
                        [-0.244, -0.743],
                        [0.078, -0.779],
                        [0, 0],
                        [0.44, -0.399]
                      ],
                      o: [
                        [-0.081, 0.005],
                        [-0.633, -0.064],
                        [-0.403, -0.492],
                        [0, 0],
                        [0.011, -0.112],
                        [-0.036, -0.106],
                        [-0.076, -0.083],
                        [-0.102, -0.045],
                        [-0.637, 0],
                        [-0.45, -0.45],
                        [0, -0.637],
                        [0.45, -0.45],
                        [0.782, 0],
                        [0.716, 0.317],
                        [0.525, 0.58],
                        [0.245, 0.743],
                        [0, 0],
                        [-0.06, 0.591],
                        [-0.44, 0.399]
                      ],
                      v: [
                        [0.742, 8.392],
                        [0.499, 8.392],
                        [-1.119, 7.523],
                        [-1.651, 5.765],
                        [-0.8, -2.715],
                        [-0.837, -3.046],
                        [-1.005, -3.332],
                        [-1.275, -3.527],
                        [-1.6, -3.595],
                        [-3.297, -4.298],
                        [-4, -5.995],
                        [-3.297, -7.692],
                        [-1.6, -8.395],
                        [0.671, -7.915],
                        [2.552, -6.554],
                        [3.719, -4.547],
                        [3.971, -2.239],
                        [3.126, 6.232],
                        [2.349, 7.77]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [0]
                        },
                        t: -20,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [0.779]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: -8,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        t: 39.9990234375,
                        s: [0.854901969433, 0.949019610882, 0.917647063732, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [526.5, 187.395],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 2',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.001],
                        [0.365, 0.34],
                        [0.04, 0.498],
                        [-0.307, 0.395],
                        [-0.493, 0.084],
                        [0, 0],
                        [-0.263, -0.053],
                        [-0.222, -0.152],
                        [-0.145, -0.227],
                        [-0.044, -0.265],
                        [0.063, -0.261],
                        [0.16, -0.216],
                        [0.232, -0.136],
                        [0.266, -0.034]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.019],
                        [-0.5, -0.004],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.264, -0.054],
                        [0.264, 0.053],
                        [0.222, 0.152],
                        [0.145, 0.227],
                        [0.045, 0.265],
                        [-0.063, 0.262],
                        [-0.161, 0.216],
                        [-0.232, 0.136],
                        [0, 0]
                      ],
                      v: [
                        [105.094, 9.909],
                        [97.878, 11.115],
                        [97.558, 11.144],
                        [96.213, 10.608],
                        [95.582, 9.305],
                        [95.996, 7.917],
                        [97.238, 7.173],
                        [104.434, 5.96],
                        [105.233, 5.958],
                        [105.969, 6.269],
                        [106.525, 6.843],
                        [106.811, 7.589],
                        [106.783, 8.387],
                        [106.445, 9.111],
                        [105.85, 9.644],
                        [105.094, 9.902]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.002],
                        [0.365, 0.341],
                        [0.04, 0.498],
                        [-0.306, 0.394],
                        [-0.493, 0.085],
                        [0, 0],
                        [-0.415, -0.309],
                        [-0.085, -0.51],
                        [0.292, -0.427],
                        [0.507, -0.104]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.016],
                        [-0.5, -0.005],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.513, -0.066],
                        [0.415, 0.308],
                        [0.086, 0.51],
                        [-0.293, 0.427],
                        [0, 0]
                      ],
                      v: [
                        [103.753, 17.762],
                        [99.785, 18.44],
                        [99.465, 18.466],
                        [98.12, 17.929],
                        [97.489, 16.626],
                        [97.903, 15.239],
                        [99.145, 14.494],
                        [103.113, 13.832],
                        [104.56, 14.21],
                        [105.34, 15.486],
                        [105.018, 16.946],
                        [103.772, 17.774]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.104, 0.621],
                        [0, 0],
                        [1.036, 0.739],
                        [1.255, -0.21],
                        [0, 0],
                        [0.74, -1.035],
                        [-0.208, -1.255],
                        [0, 0],
                        [-0.865, -0.734],
                        [-1.134, -0.003],
                        [-0.265, 0.044],
                        [0, 0],
                        [-0.534, 0.334],
                        [-0.366, 0.513],
                        [-0.142, 0.614]
                      ],
                      o: [
                        [0, 0],
                        [-0.211, -1.255],
                        [-1.036, -0.739],
                        [0, 0],
                        [-1.255, 0.21],
                        [-0.739, 1.035],
                        [0, 0],
                        [0.187, 1.119],
                        [0.864, 0.734],
                        [0.269, 0],
                        [0, 0],
                        [0.622, -0.104],
                        [0.535, -0.335],
                        [0.366, -0.513],
                        [0.142, -0.615]
                      ],
                      v: [
                        [114.831, 21.282],
                        [111.75, 2.859],
                        [109.802, -0.255],
                        [106.223, -1.08],
                        [93.51, 1.048],
                        [90.396, 2.992],
                        [89.567, 6.568],
                        [92.649, 24.99],
                        [94.277, 27.859],
                        [97.372, 29],
                        [98.175, 28.933],
                        [110.889, 26.808],
                        [112.64, 26.145],
                        [114.005, 24.861],
                        [114.774, 23.154]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [0]
                        },
                        t: -20,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [0.779]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: -8,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        t: 39.9990234375,
                        s: [0.854901969433, 0.949019610882, 0.917647063732, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [409, 182],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: -20,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Icon',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 0,
              s: [100]
            },
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 53.999,
              s: [100]
            },
            {
              t: 59.9990234375,
              s: [0]
            }
          ],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 0,
              s: [409.451, 181.7, 0],
              to: [229.667, 0.917, 0],
              ti: [-41.917, -68.917, 0]
            },
            {
              t: 59.9990234375,
              s: [782.201, 340.45, 0]
            }
          ],
          ix: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        },
        a: {
          a: 0,
          k: [514.501, 195, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0]
              },
              t: 0,
              s: [190, 190, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 8.895]
              },
              o: {
                x: [0.497, 0.497, 0.497],
                y: [0, 0, 0]
              },
              t: 30,
              s: [190, 190, 100]
            },
            {
              t: 59.9990234375,
              s: [100, 100, 100]
            }
          ],
          ix: 6,
          x: 'var $bm_rt;\n$bm_rt = loopOut();'
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.594, -0.001],
                        [0.081, 0.005],
                        [0.403, 0.493],
                        [-0.063, 0.633],
                        [0, 0],
                        [0.035, 0.106],
                        [0.075, 0.083],
                        [0.103, 0.045],
                        [0.112, 0],
                        [0.45, 0.45],
                        [0, 0.636],
                        [-0.45, 0.45],
                        [-0.637, 0],
                        [-0.715, -0.317],
                        [-0.525, -0.58],
                        [-0.244, -0.743],
                        [0.078, -0.779],
                        [0, 0],
                        [0.44, -0.399]
                      ],
                      o: [
                        [-0.081, 0.005],
                        [-0.633, -0.064],
                        [-0.403, -0.492],
                        [0, 0],
                        [0.011, -0.112],
                        [-0.036, -0.106],
                        [-0.076, -0.083],
                        [-0.102, -0.045],
                        [-0.637, 0],
                        [-0.45, -0.45],
                        [0, -0.637],
                        [0.45, -0.45],
                        [0.782, 0],
                        [0.716, 0.317],
                        [0.525, 0.58],
                        [0.245, 0.743],
                        [0, 0],
                        [-0.06, 0.591],
                        [-0.44, 0.399]
                      ],
                      v: [
                        [0.742, 8.392],
                        [0.499, 8.392],
                        [-1.119, 7.523],
                        [-1.651, 5.765],
                        [-0.8, -2.715],
                        [-0.837, -3.046],
                        [-1.005, -3.332],
                        [-1.275, -3.527],
                        [-1.6, -3.595],
                        [-3.297, -4.298],
                        [-4, -5.995],
                        [-3.297, -7.692],
                        [-1.6, -8.395],
                        [0.671, -7.915],
                        [2.552, -6.554],
                        [3.719, -4.547],
                        [3.971, -2.239],
                        [3.126, 6.232],
                        [2.349, 7.77]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [0]
                        },
                        t: 0,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [0.779]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 12,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        t: 59.9990234375,
                        s: [0.854901969433, 0.949019610882, 0.917647063732, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [526.5, 187.395],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 2',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.001],
                        [0.365, 0.34],
                        [0.04, 0.498],
                        [-0.307, 0.395],
                        [-0.493, 0.084],
                        [0, 0],
                        [-0.263, -0.053],
                        [-0.222, -0.152],
                        [-0.145, -0.227],
                        [-0.044, -0.265],
                        [0.063, -0.261],
                        [0.16, -0.216],
                        [0.232, -0.136],
                        [0.266, -0.034]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.019],
                        [-0.5, -0.004],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.264, -0.054],
                        [0.264, 0.053],
                        [0.222, 0.152],
                        [0.145, 0.227],
                        [0.045, 0.265],
                        [-0.063, 0.262],
                        [-0.161, 0.216],
                        [-0.232, 0.136],
                        [0, 0]
                      ],
                      v: [
                        [105.094, 9.909],
                        [97.878, 11.115],
                        [97.558, 11.144],
                        [96.213, 10.608],
                        [95.582, 9.305],
                        [95.996, 7.917],
                        [97.238, 7.173],
                        [104.434, 5.96],
                        [105.233, 5.958],
                        [105.969, 6.269],
                        [106.525, 6.843],
                        [106.811, 7.589],
                        [106.783, 8.387],
                        [106.445, 9.111],
                        [105.85, 9.644],
                        [105.094, 9.902]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.107, -0.002],
                        [0.365, 0.341],
                        [0.04, 0.498],
                        [-0.306, 0.394],
                        [-0.493, 0.085],
                        [0, 0],
                        [-0.415, -0.309],
                        [-0.085, -0.51],
                        [0.292, -0.427],
                        [0.507, -0.104]
                      ],
                      o: [
                        [0, 0],
                        [-0.106, 0.016],
                        [-0.5, -0.005],
                        [-0.366, -0.34],
                        [-0.04, -0.498],
                        [0.306, -0.395],
                        [0, 0],
                        [0.513, -0.066],
                        [0.415, 0.308],
                        [0.086, 0.51],
                        [-0.293, 0.427],
                        [0, 0]
                      ],
                      v: [
                        [103.753, 17.762],
                        [99.785, 18.44],
                        [99.465, 18.466],
                        [98.12, 17.929],
                        [97.489, 16.626],
                        [97.903, 15.239],
                        [99.145, 14.494],
                        [103.113, 13.832],
                        [104.56, 14.21],
                        [105.34, 15.486],
                        [105.018, 16.946],
                        [103.772, 17.774]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.104, 0.621],
                        [0, 0],
                        [1.036, 0.739],
                        [1.255, -0.21],
                        [0, 0],
                        [0.74, -1.035],
                        [-0.208, -1.255],
                        [0, 0],
                        [-0.865, -0.734],
                        [-1.134, -0.003],
                        [-0.265, 0.044],
                        [0, 0],
                        [-0.534, 0.334],
                        [-0.366, 0.513],
                        [-0.142, 0.614]
                      ],
                      o: [
                        [0, 0],
                        [-0.211, -1.255],
                        [-1.036, -0.739],
                        [0, 0],
                        [-1.255, 0.21],
                        [-0.739, 1.035],
                        [0, 0],
                        [0.187, 1.119],
                        [0.864, 0.734],
                        [0.269, 0],
                        [0, 0],
                        [0.622, -0.104],
                        [0.535, -0.335],
                        [0.366, -0.513],
                        [0.142, -0.615]
                      ],
                      v: [
                        [114.831, 21.282],
                        [111.75, 2.859],
                        [109.802, -0.255],
                        [106.223, -1.08],
                        [93.51, 1.048],
                        [90.396, 2.992],
                        [89.567, 6.568],
                        [92.649, 24.99],
                        [94.277, 27.859],
                        [97.372, 29],
                        [98.175, 28.933],
                        [110.889, 26.808],
                        [112.64, 26.145],
                        [114.005, 24.861],
                        [114.774, 23.154]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.667],
                          y: [1]
                        },
                        o: {
                          x: [0.167],
                          y: [0]
                        },
                        t: 0,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [0.779]
                        },
                        o: {
                          x: [0.333],
                          y: [0]
                        },
                        t: 12,
                        s: [0.301960796118, 0.654901981354, 0.537254929543, 1]
                      },
                      {
                        t: 59.9990234375,
                        s: [0.854901969433, 0.949019610882, 0.917647063732, 1]
                      }
                    ],
                    ix: 4,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();'
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5
                  },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [409, 182],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Layer 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 61,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
