import { Input, InputProps, InputRef } from 'antd';
import { FC, ForwardedRef } from 'react';

import UcGroupInput from '../InputGroup';
import UcInputNumber from '../InputNumber';
import UcPassword from '../Password';
import UcSearch from '../Search';

interface Props extends InputProps {
  _ref: ForwardedRef<InputRef>;
}

const BaseInput: FC<Props> = ({ _ref, ...props }) => {
  return <Input {...props} size="large" ref={_ref} />;
};

const UcInput = Object.assign(BaseInput, Input, {
  Group: UcGroupInput,
  InputNumber: UcInputNumber,
  Password: UcPassword,
  Search: UcSearch
});

export default UcInput;
