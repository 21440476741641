import styles from './styles.module.less';
import { Elements } from './types';

export const stripeElementOptions = {
  hidePostalCode: true,
  classes: {
    invalid: styles.invalidNumber,
    focus: styles.focused
  },
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#8C8C8C'
      }
    },
    invalid: {
      iconColor: 'red'
    }
  }
};

export const initialStateBillingInfo = {
  [Elements.number]: {
    message: null,
    complete: false
  },
  [Elements.cvc]: {
    message: null,
    complete: false
  },
  [Elements.expiry]: {
    message: null,
    complete: false
  }
};
