import { FC } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title.d';

import { customGenericClassNames } from '../helpers';
import {
  HeadingTypes,
  FontWightTypes
} from 'components/basic/Typography/types.d';
import styles from './styles.module.less';

const { Title } = Typography;

interface Props extends Omit<TitleProps, 'className'> {
  text?: string;
  ellipsis?: boolean;
  className?: string;
  level?: HeadingTypes;
  defaultMargin?: boolean;
  fontWeight?: FontWightTypes | keyof typeof FontWightTypes;
}

const Heading: FC<Props> = ({
  text,
  type,
  className = '',
  ellipsis = true,
  defaultMargin = true,
  level = HeadingTypes.h1,
  fontWeight = FontWightTypes.bold,
  children,
  ...restProps
}) => {
  return (
    <Title
      level={level}
      className={`${className} ${customGenericClassNames(type, ellipsis)} ${
        styles.headingWrapper
      } ${styles[HeadingTypes[level]]} ${styles[fontWeight]} ${
        !defaultMargin ? styles.deleteMargin : ''
      }`}
      {...restProps}
    >
      {text || children}
    </Title>
  );
};

export default Heading;
