import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { FooterProps } from './types';
import { Button, Space } from 'components/basic';
import styles from './styles.module.less';

const Footer: FC<FooterProps> = ({
  onOk,
  closeText,
  saveText,
  loading,
  hasChanges,
  onClose
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  if (isMobile) {
    return (
      <div className={styles.footer_container}>
        <Button
          loading={loading}
          onClick={onOk}
          className={styles.mobile_button}
        >
          {saveText}
        </Button>
      </div>
    );
  }

  return (
    <Space>
      <Button
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'filter-cancel'
        })}
        onClick={onClose}
      >
        {closeText}
      </Button>

      <Button
        type="primary"
        onClick={onOk}
        loading={loading}
        disabled={hasChanges}
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'filter-save'
        })}
      >
        <span>
          <span>{saveText}</span>
        </span>
      </Button>
    </Space>
  );
};

export default Footer;
