import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/basic';
import styles from './styles.module.less';

const { UcBodyText } = Typography;

const Forbidden = () => {
  const { t } = useTranslation('errors');

  const SUBTITLE = (
    <div className={styles.forbidden_container}>
      <UcBodyText size={'extraLarge'}>403</UcBodyText>
      <UcBodyText style={{ marginLeft: 12, marginRight: 12 }} size={'large'}>
        |
      </UcBodyText>
      <UcBodyText size={'large'}>
        {t('unauthorized', 'This action is unauthorized.')}
      </UcBodyText>
    </div>
  );

  return (
    <Result
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      icon={null}
      status={undefined}
      title=""
      subTitle={SUBTITLE}
    />
  );
};

export default Forbidden;
