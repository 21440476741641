import { FC } from 'react';
import { Skeleton } from 'antd';

import styles from 'components/shared/Skeleton/styles.module.less';
import { TableSkeletonProps } from 'components/shared/Skeleton/types';

const TableSkeleton: FC<TableSkeletonProps> = ({
  colCount = 1,
  rowCount = 6
}) => {
  return (
    <div className={styles.container_table_container}>
      <table className={styles.table}>
        <tbody>
          {[...Array(rowCount)].map((_, i) => (
            <tr key={i}>
              {[...Array(colCount)].map((_, i) => (
                <td key={i}>
                  <Skeleton.Input
                    className={styles.sceleton}
                    active
                    style={{ minWidth: '100%', height: 23 }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
