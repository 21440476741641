import { FC } from 'react';
import { ProtectedPageCan } from 'providers/PermissionAndLimitationProvider';
import { PermissionsRouteResponsibleContainerProps } from 'routes/types';

import { PAGE_PERMISSIONS } from './constants';

const PermissionProtectedRouteWrapper: FC<
  PermissionsRouteResponsibleContainerProps
> = ({ children, routeAlias }) => {
  const permission = PAGE_PERMISSIONS[routeAlias];

  if (permission !== undefined) {
    return (
      <ProtectedPageCan permission={permission}>{children}</ProtectedPageCan>
    );
  }

  return <>{children}</>;
};

export default PermissionProtectedRouteWrapper;
