import { Skeleton } from 'antd';

import styles from './styles.module.less';

export const NotificationsPreviewSkeleton = () => {
  return (
    <div>
      <Skeleton className={styles.input__skeleton} active />
      <Skeleton.Input className={styles.preview__skeleton} active={true} />
    </div>
  );
};
