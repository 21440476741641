import { UserProjectNotificationSetting } from 'generatedHooks/accounts/generated';

import { FormItemNames } from './types';

export const FORM_ITEMS: { name: FormItemNames; labelKey: string }[] = [
  {
    name: FormItemNames.ReceiveMailNotification,
    labelKey: 'projects:receiveMailNotifcations'
  },
  {
    name: FormItemNames.ReceivePushNotification,
    labelKey: 'projects:receivePushNotifcations'
  }
];

export const DEFAULT_NOTIFICATION_SETTINGS: Omit<
  UserProjectNotificationSetting,
  '__typename'
> = {
  receiveMailNotification: true,
  receivePushNotification: true
};
