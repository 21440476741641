import React, { FC, useEffect, useState } from 'react';
import { changeStatusbarColor, nameToAbbreviation } from 'helpers';
import { Avatar as AndAvatar, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeyValuePair } from 'core/globalTypes';

import PopupSheet from 'components/mobile/PopupSheet';
import { avatarMenuActions } from './utils';
import { Typography } from 'components/basic';
import styles from './styles.module.less';
import MyProjectPopup from 'components/mobile/MyProjectPopup';

interface Props {
  width?: number;
  avatar?: string;
  userName: string;
  menuItems?: Array<KeyValuePair>;
}

const { UcBodyText } = Typography;

const Avatar: FC<Props> = ({ avatar, userName, width = 36 }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('projects');
  const [myProjectsPopupOpen, setMyProjectsPopupOpen] = useState(false);

  const handleClosePopup = () => {
    setVisible(false);
    changeStatusbarColor('fffffF');
  };

  useEffect(() => {
    return () => {
      changeStatusbarColor('fffffF');
    };
  }, []);

  return (
    <div>
      <AndAvatar
        src={avatar}
        size={{ xs: width, sm: width }}
        onClick={() => {
          setVisible(true);
          changeStatusbarColor('303030');
        }}
      >
        {nameToAbbreviation(userName)}
      </AndAvatar>

      <MyProjectPopup
        onClose={() => {
          setMyProjectsPopupOpen(false);
        }}
        visible={myProjectsPopupOpen}
      />

      <PopupSheet
        radius
        outSeparator
        onClose={handleClosePopup}
        visible={visible}
      >
        <div className={styles.avatar_menu_list}>
          <List
            itemLayout="horizontal"
            dataSource={avatarMenuActions(t, setMyProjectsPopupOpen)}
            renderItem={item => (
              <List.Item
                onClick={() => {
                  handleClosePopup();
                  item.onClick();
                }}
              >
                <UcBodyText fontWeight="regular" size="base">
                  {item.text}
                </UcBodyText>
              </List.Item>
            )}
          />
        </div>
      </PopupSheet>
    </div>
  );
};

export default Avatar;
