import { PrinterOutlined, ReloadOutlined } from '@ant-design/icons';

import {
  ActionsPositions,
  IOrderAction,
  OrderActions,
  OrderActionsDataTests,
  OrderChildActions
} from '../types';

const PRIMARY_BUTTON = 'primary';
const DEFAULT_BUTTON = 'default';

export const ACTIONS: Record<OrderActions, IOrderAction> = {
  [OrderActions.FULFILL]: {
    key: OrderActions.FULFILL,
    type: PRIMARY_BUTTON,
    position: ActionsPositions.Bottom,
    childActions: [
      {
        key: OrderChildActions.ManualFulfill
      },
      {
        key: OrderChildActions.CarrierFulfill
      }
    ],
    dataTest: OrderActionsDataTests.FULFILL
  },
  [OrderActions.CANCEL]: {
    key: OrderActions.CANCEL,
    type: DEFAULT_BUTTON,
    position: ActionsPositions.ActionsMenu,
    dataTest: OrderActionsDataTests.CANCEL
  },
  [OrderActions.RETURN]: {
    key: OrderActions.RETURN,
    type: DEFAULT_BUTTON,
    position: ActionsPositions.ActionsMenu,
    dataTest: OrderActionsDataTests.RETURN
  },
  [OrderActions.DISPATCH]: {
    key: OrderActions.DISPATCH,
    type: PRIMARY_BUTTON,
    position: ActionsPositions.Bottom,
    dataTest: OrderActionsDataTests.DISPATCH
  },
  [OrderActions.MAKE_PAID]: {
    key: OrderActions.MAKE_PAID,
    type: DEFAULT_BUTTON,
    position: ActionsPositions.Bottom,
    dataTest: OrderActionsDataTests.MAKE_PAID
  },
  [OrderActions.MAKE_UNPAID]: {
    key: OrderActions.MAKE_UNPAID,
    type: DEFAULT_BUTTON,
    position: ActionsPositions.Bottom,
    dataTest: OrderActionsDataTests.MAKE_UNPAID
  },
  [OrderActions.PRINT]: {
    key: OrderActions.PRINT,
    type: DEFAULT_BUTTON,
    icon: <PrinterOutlined />,
    position: ActionsPositions.ActionsMenu,
    dataTest: OrderActionsDataTests.PRINT
  },
  [OrderActions.REFUND]: {
    key: OrderActions.REFUND,
    type: DEFAULT_BUTTON,
    icon: <ReloadOutlined />,
    position: ActionsPositions.Bottom,
    dataTest: OrderActionsDataTests.REFUND
  }
};
