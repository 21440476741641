import React, { FC } from 'react';
import { Switch, SwitchProps } from 'antd';

interface BaseSwitchProps extends SwitchProps {}

const BaseSwitch: FC<BaseSwitchProps> = props => {
  return <Switch {...props} />;
};

const UcSwitch = Object.assign(Switch, BaseSwitch);

export default UcSwitch;
