import React, { ComponentType } from 'react';
import Icon from '@ant-design/icons';

type Props = {
  style?: React.CSSProperties;
};

export default function withIcon(component: ComponentType) {
  return (props: Props) => <Icon component={component} {...props} />;
}
