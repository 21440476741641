import React, { ReactNode } from 'react';

interface Props {
  loading: boolean;
  fallback: ReactNode;
  children: ReactNode;
}

const Suspensor = ({ loading, fallback, children }: Props) => {
  return <>{loading ? fallback : children}</>;
};

export default Suspensor;
