import { FC } from 'react';

import styles from './style.module.less';
import { SubTextProps } from './types';

const SubText: FC<SubTextProps> = ({ value }) => {
  return <div className={styles.subText}>{value}</div>;
};

export default SubText;
