import { AppstoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Typography, Empty } from 'components/basic';
import styles from './styles.module.less';

const { UcBodyText, UcSubHeading } = Typography;

const EmptyWrapper = () => {
  const { t } = useTranslation('projects');

  return (
    <div className={styles.my_project_empty_wrapper}>
      <Empty
        imageStyle={{
          marginBottom: '0'
        }}
        image={
          <AppstoreOutlined
            style={{
              fontSize: '80px',
              color: '#BFBFBF',
              marginBottom: '0px'
            }}
            color="#BFBFBF"
          />
        }
        description={
          <>
            <UcSubHeading level={4} style={{ marginBottom: '5px' }}>
              {t('projects:noProjects', 'There are no projects created')}
            </UcSubHeading>
            <UcBodyText type="secondary">
              {t(
                'projects:descNoProjects',
                'In order to create a new project, please click on "+ New Project" button'
              )}
            </UcBodyText>
          </>
        }
      />
    </div>
  );
};

export default EmptyWrapper;
