import {
  Attribute,
  ConditionOperator,
  ProductTypeGroup,
  QueryProductsConditionColumn,
  useFilterableAttributesQuery
} from 'generatedHooks/commerce/generated';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFilterQuery, useHistory } from 'hooks';
import { KeyValuePair } from 'core/globalTypes';

import {
  FilterItem,
  MultiSelectFilter
} from 'components/shared/FilterDrawer/Components';
import AttributeOptionsFilter from 'components/shared/AttributeOptionsFilter';

interface Props {
  title?: string;
  attributesListParams: {
    groupTypes: ProductTypeGroup[];
    operator: ConditionOperator;
  };
}

const AttributesFilter: FC<Props> = ({ attributesListParams, title }) => {
  const { t } = useTranslation(['common', 'products']);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);
  const { queryObject } = useFilterQuery();
  const { push, resetQuery } = useHistory();

  const attributesValues = queryObject.attributeFilter || {};

  const { data: attributes, loading: attributesLoading } =
    useFilterableAttributesQuery({
      fetchPolicy: 'network-only',
      variables: {
        ...attributesListParams
      }
    });

  const filterableAttributesData = useMemo(() => {
    if (attributes?.dashboardFilterableAttributes) {
      return attributes?.dashboardFilterableAttributes;
    }

    return [];
  }, [attributes]);

  useEffect(() => {
    if (!selectedAttributes.length && Object.keys(attributesValues).length) {
      const attributes = filterableAttributesData.filter(attr =>
        Object.keys(attributesValues).includes(attr.code)
      );

      setSelectedAttributes(attributes as Attribute[]);
    }
  }, [attributesValues, filterableAttributesData]);

  const handleAttributeValueChange = (events: KeyValuePair) => {
    const { ids } = events;

    const data = filterableAttributesData.filter(item =>
      ids.includes(item.code)
    );

    setSelectedAttributes(data as Attribute[]);

    if (!ids.length) {
      resetQuery('attributeFilter');
    }

    const attrValues = JSON.parse(JSON.stringify(attributesValues));

    Object.keys(attributesValues).forEach(av => {
      if (!ids.includes(av)) {
        delete attrValues[av];
      }
    });

    push({ attributeFilter: attrValues });
  };

  const handleChangeFilter = (code: string, data: any) => {
    if (!data || !data.value || !data.value.length) {
      const attributeFilter = JSON.parse(
        JSON.stringify(queryObject.attributeFilter || {})
      );

      delete attributeFilter[code];

      push({
        attributeFilter
      });
    } else {
      push({
        attributeFilter: {
          ...queryObject.attributeFilter,
          [code]: data
        }
      });
    }
  };

  return (
    <>
      <FilterItem
        title={title || t('products:filters.attributes', 'Attributes')}
      >
        <MultiSelectFilter
          name={QueryProductsConditionColumn.AttributeId}
          placeholder={t('products:filters.attributes', 'Attributes')}
          loading={attributesLoading}
          maxTagCount={0}
          data={filterableAttributesData}
          valueKey="code"
          onChange={handleAttributeValueChange}
          value={selectedAttributes.map(sa => sa.code)}
        />
      </FilterItem>

      <AttributeOptionsFilter
        filter={attributesValues}
        setFilter={handleChangeFilter}
        attributesValues={selectedAttributes}
      />
    </>
  );
};

export default AttributesFilter;
