import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { List } from 'components/basic';
import { ShortcutsListItem } from 'components/shared/KeyboardShortcutsGuide/types';
import renderKeyboardKey from 'components/shared/KeyboardShortcutsGuide/components/ShortcutsList/renderKeyboardKey';

export interface ShortcutsListProps {
  dataSource: ShortcutsListItem[];
}

const ShortcutsList: FC<ShortcutsListProps> = ({ dataSource }) => {
  const { t } = useTranslation('keyboardShortcuts');

  const renderShortcutItem = ({ key, label, operator }: ShortcutsListItem) => {
    return (
      <List.Item key={key} extra={renderKeyboardKey(label, operator || '')}>
        <span>
          <Trans t={t} i18nKey={key} components={{ bold: <strong /> }} />
        </span>
      </List.Item>
    );
  };

  return (
    <List
      split={false}
      dataSource={dataSource}
      renderItem={renderShortcutItem}
    />
  );
};

export default ShortcutsList;
