import { coreConfigs } from 'apollo/cache';

import getTimeFormat from './getTimeFormat';

const getFullDateFormat = () => {
  const { date_format } = coreConfigs();

  return `${date_format} ${getTimeFormat()}`;
};

export default getFullDateFormat;
