import { FC } from 'react';
import { Skeleton, Col } from 'antd';

import { GridSkeletonProps } from 'components/shared/Skeleton/types';

const GridSkeleton: FC<GridSkeletonProps> = ({
  count = 10,
  width = 180,
  height = 140
}) => {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Col key={i}>
          <Skeleton.Input
            active
            style={{
              width: width,
              height: height,
              borderRadius: 'var(--uc-border-radius-base)'
            }}
          />
        </Col>
      ))}
    </>
  );
};

export default GridSkeleton;
