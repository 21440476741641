import i18n from 'i18next';
import {
  ConditionOperator,
  QueryOrdersConditionColumn,
  QueryOrdersOrderColumn,
  SortOrder
} from 'generatedHooks/commerce/generated';
import { SortTypes, KeyValuePair } from 'core/globalTypes';
import { countries, Country } from 'countries-list';
import countriesWithCode from 'core/jsons/CountriesWithCode.json';
import { TFunction } from 'react-i18next';

import { FilterData } from './types';
import { textAppliedData } from 'components/shared/OrdersFilter/constant';
import { FilterElement } from 'components/shared/Filter/types';
import { SortValues } from 'components/shared/SortBy/constants';

export const siteCountries = countries as Record<string, Country>;

export const OrderFilterEnum = {
  ...QueryOrdersConditionColumn,
  FromDate: 'from',
  ToDate: 'to',
  GteItemsCount: 'gteItemsCount',
  LteItemsCount: 'lteItemsCount',
  EqItemsCount: 'eqItemsCount'
};

export const ORDERS_FILTER_INITIAL_STATE = {
  [OrderFilterEnum.PaymentStatus]: {
    column: QueryOrdersConditionColumn.PaymentStatus,
    operator: ConditionOperator.Eq,
    value: ''
  },
  [OrderFilterEnum.PaymentMethod]: {
    column: QueryOrdersConditionColumn.PaymentMethod,
    operator: ConditionOperator.In,
    value: ''
  },
  [OrderFilterEnum.ShippingStatus]: {
    column: QueryOrdersConditionColumn.ShippingStatus,
    operator: ConditionOperator.Eq,
    value: ''
  },
  [OrderFilterEnum.ItemsCount]: {
    column: QueryOrdersConditionColumn.ItemsCount,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [OrderFilterEnum.GteItemsCount]: {
    column: QueryOrdersConditionColumn.ItemsCount,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [OrderFilterEnum.LteItemsCount]: {
    column: QueryOrdersConditionColumn.ItemsCount,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [OrderFilterEnum.EqItemsCount]: {
    column: QueryOrdersConditionColumn.ItemsCount,
    operator: ConditionOperator.Eq,
    value: ''
  },
  [OrderFilterEnum.ShippingMethod]: {
    column: QueryOrdersConditionColumn.ShippingMethod,
    operator: ConditionOperator.In,
    value: ''
  },
  [OrderFilterEnum.ShippingCountry]: {
    column: QueryOrdersConditionColumn.ShippingCountry,
    operator: ConditionOperator.In,
    value: ''
  },
  [OrderFilterEnum.FromDate]: {
    column: QueryOrdersConditionColumn.OrderDate,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [OrderFilterEnum.ToDate]: {
    column: QueryOrdersConditionColumn.OrderDate,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [OrderFilterEnum.Price]: {
    column: QueryOrdersConditionColumn.Price,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [OrderFilterEnum.TaxApplied]: {
    column: QueryOrdersConditionColumn.TaxApplied,
    operator: ConditionOperator.Eq,
    value: ''
  },
  DATE: {}
};

export const SORT_DATA = {
  [SortTypes.Newest]: {
    column: QueryOrdersOrderColumn.OrderDate,
    order: SortOrder.Desc
  },
  [SortTypes.Oldest]: {
    column: QueryOrdersOrderColumn.OrderDate,
    order: SortOrder.Asc
  }
};

export const DEFAULT_SORT_DATA = [
  {
    value: SortTypes.Newest,
    name: i18n.t('common:sortNew', 'Newest')
  },
  {
    value: SortTypes.Oldest,
    name: i18n.t('common:sortOld', 'Oldest')
  }
];

/* A map of functions that will be used to generate the options for the filter dropdowns. */
export const byFilterGenerateDataOptions: KeyValuePair = {
  [QueryOrdersConditionColumn.ShippingCountry]: (options: Array<string>) =>
    options.map(code => {
      const findData = countriesWithCode.find(
        item => item?.code === code || item?.name === code
      );

      return findData ? findData : { code, value: code };
    }),
  [QueryOrdersConditionColumn.PaymentStatus]: (options: Array<string>) => {
    return options.map(status => ({
      label: status.toUpperCase(),
      value: status
    }));
  },
  [QueryOrdersConditionColumn.ShippingStatus]: (options: Array<string>) => {
    return options.map(status => ({
      label: status.toUpperCase(),
      value: status
    }));
  },
  [QueryOrdersConditionColumn.ShippingMethod]: (options: Array<string>) => {
    return options.map(method => ({
      value: method,
      label: i18n.t(
        `shipping:shippingMethodsContent.${method.split('_')[0]}.title`,
        method
      )
    }));
  },
  [QueryOrdersConditionColumn.PaymentMethod]: (options: Array<string>) => {
    return options.map(method => ({
      value: method,
      label: i18n.t(`payments:paymentMethods.${method}.title`)
    }));
  }
};

const ordersTranslatedOptions = (
  t: TFunction,
  options: Array<KeyValuePair>,
  translateKey: string
) =>
  options.map(({ label, value }) => ({
    label: t(`orders:orders.${translateKey}.${label}`),
    value
  }));

export const filters = ({
  t,
  symbol,
  availableFilters
}: {
  t: TFunction;
  symbol: string;
  availableFilters: KeyValuePair<FilterData>;
}): FilterElement[] => [
  {
    type: 'select',
    filterProps: {
      title: t('common:sortBy', 'Sort By'),
      showTopBorder: false,
      showOnlyMobile: true
    },
    data: SortValues(t),
    valueKey: 'value',
    emptyValue: SortTypes.Newest,
    name: 'sort',
    placeholder: t('common:sortBy', 'Sort By')
  },
  {
    type: 'select',
    filterProps: {
      title: t('filterableTable.payment', 'Payment'),
      showTopBorder: true,
      showOnlyMobile: true
    },
    name: QueryOrdersConditionColumn.PaymentStatus,
    labelKey: 'label',
    valueKey: 'value',
    placeholder: t('filterableTable.payment', 'Payment'),
    data: ordersTranslatedOptions(
      t,
      availableFilters[QueryOrdersConditionColumn.PaymentStatus].options,
      'statuses'
    ),
    emptyValue: null
  },
  {
    type: 'select',
    filterProps: {
      title: t('filterableTable.fulfillment'),
      showTopBorder: true,
      showOnlyMobile: true
    },
    name: QueryOrdersConditionColumn.ShippingStatus,
    labelKey: 'label',
    valueKey: 'value',
    placeholder: t('filterableTable.fulfillment'),
    data: ordersTranslatedOptions(
      t,
      availableFilters[QueryOrdersConditionColumn.ShippingStatus]?.options,
      'statuses'
    ),
    emptyValue: null
  },
  {
    type: 'number',
    filterProps: {
      title: t('orders:orders.filters.itemsCount', 'Items Count'),
      showTopBorder: false
    },
    name: OrderFilterEnum.ItemsCount,
    emptyValue: '',
    placeholder: t('orders:orders.filters.itemsCount', 'Items Count')
  },
  {
    type: 'number',
    filterProps: {
      title: t('orders:orders.filters.price', 'Price'),
      showTopBorder: true
    },
    name: OrderFilterEnum.Price,
    emptyValue: '',
    placeholder: t('orders:orders.filters.price', 'Price'),
    prefix: <span>{symbol}</span>
  },
  {
    type: 'multiselect',
    filterProps: {
      title: t('orders:orders.filters.shippingMethod', 'Shipping Method'),
      showTopBorder: true
    },
    labelKey: 'label',
    valueKey: 'value',
    maxTagCount: 0,
    data: availableFilters[QueryOrdersConditionColumn.ShippingMethod].options,

    name: OrderFilterEnum.ShippingMethod,
    placeholder: t('orders:orders.filters.shippingMethod', 'Shipping Method'),
    emptyValue: []
  },
  {
    type: 'multiselect',
    filterProps: {
      title: t('orders:orders.filters.paymentMethod', 'Payment Method'),
      showTopBorder: true
    },
    labelKey: 'label',
    valueKey: 'value',
    maxTagCount: 0,
    data: availableFilters[QueryOrdersConditionColumn.PaymentMethod].options,
    name: QueryOrdersConditionColumn.PaymentMethod,
    placeholder: t('orders:orders.filters.paymentMethod', 'Payment Method'),
    emptyValue: []
  },
  {
    type: 'multiselect',
    filterProps: {
      title: t('orders:orders.filters.country', 'Country'),
      showTopBorder: true
    },
    labelKey: 'name',
    valueKey: 'code',
    maxTagCount: 0,
    data: availableFilters[OrderFilterEnum.ShippingCountry].options,
    name: OrderFilterEnum.ShippingCountry,
    placeholder: t('orders:orders.filters.country', 'Country'),
    emptyValue: []
  },
  {
    type: 'select',
    filterProps: {
      title: t('orders:orders.filters.taxApplied', 'Tax Applied'),
      showTopBorder: true
    },
    name: OrderFilterEnum.TaxApplied,
    placeholder: t('orders:orders.filters.taxApplied', 'Tax Applied'),
    data: textAppliedData(t),
    emptyValue: null
  },
  {
    type: 'dateRange',
    filterProps: {
      title: t('orders:orders.filters.orderDate', 'Order Date'),
      showTopBorder: true
    },
    pushToUrl: true,
    fromKey: OrderFilterEnum.FromDate,
    toKey: OrderFilterEnum.ToDate,
    emptyValue: undefined,
    name: 'orderDate',
    onChange: undefined
  }
];
