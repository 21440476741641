export const plugins = [
  'print',
  'preview',
  'paste',
  'blocks',
  'fontfamily',
  'fontsize',
  'importcss',
  'searchreplace',
  'autolink',
  'directionality',
  'code',
  'visualblocks',
  'visualchars',
  'fullscreen',
  'image',
  'link',
  'codesample',
  'table',
  'charmap',
  'hr',
  'nonbreaking',
  'toc',
  'insertdatetime',
  'advlist',
  'lists',
  'wordcount',
  'imagetools',
  'textpattern',
  'noneditable',
  'help',
  'charmap',
  'quickbars',
  'media'
];

export const quickbarsSelectionToolbar =
  'bold italic | quicklink h2 h3 blockquote image code';

export const fontSizeFormats = '8px 10px 12px 14px 16px 18px 24px 36px 48px';

export const mediaTypeMapping = {
  images: 'Image',
  videos: 'Video'
};

export type MediaTypeMapping = keyof typeof mediaTypeMapping;
