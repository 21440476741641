import { FC } from 'react';
import { Can } from 'providers/PermissionAndLimitationProvider';

import { ManagePermissionsProps } from './types';

const ManagePermissions: FC<ManagePermissionsProps> = ({
  code,
  permissionList,
  children,
  checkPermissions = true,
  ...canProps
}) => {
  const existPermissionOrNot = code && code in permissionList;

  if (!checkPermissions) {
    return <>{children}</>;
  }

  return (
    <>
      {existPermissionOrNot ? (
        <Can I={permissionList[code]} {...canProps}>
          {children}
        </Can>
      ) : (
        children
      )}
    </>
  );
};

export default ManagePermissions;
