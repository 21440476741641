import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Space, Typography } from 'components/basic';
import styles from './styles.module.less';

const { UcHeading, UcBodyText } = Typography;

const NoAccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.no_access_wrapper}>
      <Space direction="vertical" size={24} style={{ maxWidth: '100%' }}>
        <div>
          <UcHeading
            level={4}
            fontWeight="regular"
            text={t('limitedAccess')}
            ellipsis={false}
          />
          <UcBodyText
            size="small"
            type="secondary"
            text={t('noAnyAccessSecondary')}
          />
        </div>
        <Button type="primary" onClick={() => navigate('/')}>
          {t('gotToMyProjects')}
        </Button>
      </Space>
    </div>
  );
};

export default NoAccess;
