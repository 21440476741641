import { FC, useEffect, useState } from 'react';
import { SearchBar } from 'antd-mobile';
import { SearchOutlined } from '@ant-design/icons';
import { useFilterQuery, useHistory, useDebounce } from 'hooks';

import { SearchProps } from './types';
import styles from './styles.module.less';

const Search: FC<SearchProps> = ({
  value,
  clearable = true,
  onChange,
  debounce = 600,
  maxFilterCount = 1,
  name = 'search',
  syncWithQueryParams = true,
  placeholder
}) => {
  const { queryObject } = useFilterQuery();
  const { push, resetQuery } = useHistory();
  const [inputValue, setInputValue] = useState(
    syncWithQueryParams ? queryObject.search || '' : ''
  );

  const debouncedSearchValue = useDebounce(inputValue, debounce);

  useEffect(() => {
    if (!syncWithQueryParams) {
      return;
    }

    const pushToQueryString = () => {
      if (debouncedSearchValue) {
        if (
          maxFilterCount &&
          debouncedSearchValue.trim('').length >= maxFilterCount
        ) {
          return push({ [name]: debouncedSearchValue });
        }
      }

      resetQuery('search');
    };

    // when we pass onChange function then it don't added search param in URL
    if (!onChange) {
      pushToQueryString();
    }
  }, [debouncedSearchValue, syncWithQueryParams]);

  const handleChange = (value: string) => {
    onChange ? onChange(value) : setInputValue(value);
  };

  return (
    <div className={styles.mobile_search_bar_container}>
      <SearchBar
        clearable={clearable}
        onChange={handleChange}
        placeholder={placeholder}
        value={value || inputValue}
        icon={<SearchOutlined className={styles.amd_search_icon} />}
      />
    </div>
  );
};

export default Search;
