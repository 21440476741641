import { DASHBOARD_SIDEBAR_ROUTES } from 'core/constants/common';
import { DashboardSidebarRouteKey, KeyValuePair } from 'core/globalTypes';
import { useSidebarAvailableRoutes } from 'hooks';
import { Navigate } from 'react-router-dom';

import NoAccess from './components/NoAccess';

const routePermissionsMapping = Object.entries(DASHBOARD_SIDEBAR_ROUTES).reduce(
  (accumulator: KeyValuePair<string[]>, [key, route]) => {
    const { permission } = route;

    accumulator[key] = [permission.I];

    if (permission?.Or !== undefined) {
      accumulator[key].push(permission.Or);
    }

    return accumulator;
  },
  {}
);

const ProjectNavigator = () => {
  const { dashboardSiderAvailableRoutes } = useSidebarAvailableRoutes();

  for (const key of Object.values(DashboardSidebarRouteKey)) {
    const routePermissions = routePermissionsMapping[key];

    if (
      routePermissions.some(
        permission => dashboardSiderAvailableRoutes[permission]
      )
    ) {
      return <Navigate to={DASHBOARD_SIDEBAR_ROUTES[key].pathname} replace />;
    }
  }

  return <NoAccess />;
};

export default ProjectNavigator;
