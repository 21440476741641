import { FC, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as LeftArrow } from 'assets/icons/leftArrow.svg';

import { Typography } from 'components/basic';
import { HeaderProps } from './types';
import MobileIconWrapper from 'components/mobile/mobileIconWrapper';
import { ShowElement } from 'components/shared';
import styles from './style.module.less';

const { UcBodyText } = Typography;

const Header: FC<HeaderProps> = ({
  title,
  header,
  onRightBarAction,
  onLeftBarAction,
  rightBarValue,
  rightBarOptions = { disabled: false },
  leftBarValue,
  subtitle,
  titleProps
}) => {
  const leftBar = useMemo(() => {
    if (typeof leftBarValue === 'string') {
      return (
        <div className={styles.left_bar_content}>
          <Icon component={LeftArrow} />
          <UcBodyText fontWeight="regular" size="small">
            {leftBarValue}
          </UcBodyText>
        </div>
      );
    } else {
      return <MobileIconWrapper size="small" icon={leftBarValue} />;
    }
  }, [onLeftBarAction, leftBarValue]);

  const rightBar = useMemo(() => {
    if (typeof rightBarValue === 'string') {
      return (
        <div className={styles.right_bar_value}>
          <UcBodyText size="small" fontWeight="regular">
            {rightBarValue}
          </UcBodyText>
        </div>
      );
    } else {
      return <span>{rightBarValue}</span>;
    }
  }, [onRightBarAction, rightBarValue]);

  const titleContent = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <div
          className={`${styles.popup_header_title_content} ${styles.popup_header_bar}`}
        >
          <UcBodyText fontWeight="bold" size="base" {...titleProps}>
            {title}
          </UcBodyText>
          <ShowElement isShow={Boolean(subtitle)}>
            <UcBodyText size="small" className={styles.subtitle}>
              {subtitle}
            </UcBodyText>
          </ShowElement>
        </div>
      );
    }

    return title;
  }, [title, subtitle]);

  if (header) {
    return <div>{header}</div>;
  }

  return (
    <div className={styles.amd_popup_header}>
      <div
        onClick={() => onLeftBarAction?.()}
        className={`${styles.popup_header_left_bar} ${styles.popup_header_bar}`}
      >
        {leftBar}
      </div>

      <div>
        <ShowElement isShow={Boolean(titleContent)}>{titleContent}</ShowElement>
      </div>

      <div
        onClick={() => onRightBarAction?.()}
        className={`${styles.popup_header_right_bar} ${
          styles.popup_header_bar
        } ${rightBarOptions.disabled ? styles.disable_save_button : ''}`}
      >
        {rightBar}
      </div>
    </div>
  );
};

export default Header;
