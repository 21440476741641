import { CheckboxProps } from 'antd';
import { KeyValuePair } from 'core/globalTypes';
import { FC } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Checkbox } from '..';

export interface TranslatableBaseCheckboxProps
  extends Omit<CheckboxProps, 'checked' | 'onChange'> {
  locale?: string;
  checked?: KeyValuePair<boolean>;
  onChange?: (v: KeyValuePair<boolean>) => void;
}

const TranslatableCheckbox: FC<TranslatableBaseCheckboxProps> = ({
  locale = '',
  checked = {},
  onChange,
  ...restProps
}) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    onChange?.({ ...checked, [locale]: e.target.checked });
  };

  return (
    <Checkbox
      onChange={handleChange}
      checked={checked[locale]}
      {...restProps}
    />
  );
};

export default TranslatableCheckbox;
