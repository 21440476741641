import { FC } from 'react';
import { KeyValuePair } from 'core/globalTypes';
import { useTranslation } from 'react-i18next';

import { FilterDrawer } from 'components/shared';
import { AttributesType } from './constants';
import { AttributeOptionsFilterProps } from './types';

const { FilterItem, SelectFilter, MultiSelectFilter } = FilterDrawer;

const AttributeOptionsFilter: FC<AttributeOptionsFilterProps> = ({
  setFilter,
  filter,
  attributesValues
}) => {
  const { t } = useTranslation(['common', 'products']);

  if (!attributesValues.length) {
    return null;
  }

  const handleAttributeChange = (
    code: string,
    attributeId: string,
    value: KeyValuePair
  ) => {
    setFilter(code, {
      attributeId,
      ...value
    });
  };

  const componentBtAttributesType = {
    [AttributesType.SELECT]: (props: KeyValuePair) => {
      const { name, data, value, attributeId, code } = props;

      return (
        <SelectFilter
          name={name}
          data={data}
          value={value}
          valueKey="id"
          labelKey="label"
          placeholder={name}
          onChange={({ value }) =>
            handleAttributeChange(code, attributeId, {
              value: value ? [value] : []
            })
          }
        />
      );
    },
    [AttributesType.MULTISELECT]: (props: KeyValuePair) => {
      const { name, code, value, attributeId, data } = props;

      return (
        <MultiSelectFilter
          name={name}
          data={data}
          valueKey="id"
          value={value}
          labelKey="label"
          maxTagCount={0}
          maxTagPlaceholder={`${name} ${value?.length} ${t(
            'products:products.items',
            'Items'
          )}`}
          placeholder={name}
          onChange={({ ids }) =>
            handleAttributeChange(code, attributeId, { value: ids })
          }
        />
      );
    },
    [AttributesType.ADDON]: (props: KeyValuePair) => {
      const { name, code, value, attributeId, data } = props;

      return (
        <MultiSelectFilter
          name={name}
          data={data}
          valueKey="id"
          value={value}
          labelKey="label"
          maxTagCount={0}
          maxTagPlaceholder={`${name} ${value?.length} ${t(
            'products:products.items',
            'Items'
          )}`}
          placeholder={name}
          onChange={({ ids }) =>
            handleAttributeChange(code, attributeId, { value: ids })
          }
        />
      );
    },
    [AttributesType.CHECKBOX]: (props: KeyValuePair) => {
      const { name, code, value, attributeId, data } = props;

      return (
        <MultiSelectFilter
          name={name}
          data={data}
          valueKey="id"
          value={value}
          labelKey="label"
          maxTagCount={0}
          maxTagPlaceholder={`${name} ${value?.length} ${t(
            'products:products.items',
            'Items'
          )}`}
          placeholder={name}
          onChange={({ ids }) =>
            handleAttributeChange(code, attributeId, { value: ids })
          }
        />
      );
    }
  };

  return (
    <>
      {attributesValues.map(attribute => {
        if (Object.hasOwn(AttributesType, attribute.type)) {
          return (
            <FilterItem title={attribute.name} key={attribute.id}>
              {componentBtAttributesType[attribute.type] &&
                componentBtAttributesType[attribute.type]({
                  name: attribute.name,
                  code: attribute.code,
                  data: attribute.options,
                  attributeId: attribute.id,
                  value: filter?.[attribute.code]
                    ? filter[attribute.code].value
                    : []
                })}
            </FilterItem>
          );
        }

        return null;
      })}
    </>
  );
};

export default AttributeOptionsFilter;
