import { Collapse, CollapsePanelProps } from 'antd';
import { FC, ReactNode } from 'react';

export interface UcPanelProps extends CollapsePanelProps {
  children?: ReactNode;
}

const BasePanel: FC<UcPanelProps> = props => {
  return <Collapse.Panel {...props}>{props.children}</Collapse.Panel>;
};

const UcPanel = Object.assign(BasePanel, Collapse.Panel);

export default UcPanel;
