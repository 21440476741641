import { KeyboardShortcutKeys } from 'core/globalTypes';
import { To } from 'react-router-dom';
import { NAVIGATION_SHORTCUTS_PERMISSIONS } from 'providers/NavigationProvider/constants';
import { DASHBOARD_SIDEBAR_ROUTES } from 'core/constants/common';

const {
  Orders,
  Products,
  Analytics,
  Customers,
  Discounts,
  ActivityLog,
  Settings
} = NAVIGATION_SHORTCUTS_PERMISSIONS;

const {
  ORDERS,
  PRODUCTS,
  ANALYTICS_OVERVIEW,
  CUSTOMERS,
  DISCOUNTS,
  SETTINGS,
  ACTIVITY_LOG
} = DASHBOARD_SIDEBAR_ROUTES;

const getCommonKeymap = (
  navigate: (to: To, permissions: { I: string; Or?: string }) => void
) => {
  return [
    {
      key: KeyboardShortcutKeys.O,
      handler: () => navigate(ORDERS.pathname, Orders),
      withCtrl: false
    },
    {
      key: KeyboardShortcutKeys.P,
      handler: () => navigate(PRODUCTS.pathname, Products),
      withCtrl: false
    },
    {
      key: KeyboardShortcutKeys.A,
      handler: () => navigate(ANALYTICS_OVERVIEW.pathname, Analytics),
      withCtrl: false
    },
    {
      key: KeyboardShortcutKeys.C,
      handler: () => navigate(CUSTOMERS.pathname, Customers),
      withCtrl: false
    },
    {
      key: KeyboardShortcutKeys.D,
      handler: () => navigate(DISCOUNTS.pathname, Discounts),
      withCtrl: false
    },
    {
      key: KeyboardShortcutKeys.S,
      handler: () => navigate(SETTINGS.pathname, Settings),
      withCtrl: false
    },
    {
      key: KeyboardShortcutKeys.L,
      handler: () => navigate(ACTIVITY_LOG.pathname, ActivityLog),
      withCtrl: false
    }
  ];
};

export default getCommonKeymap;
