import { Typography } from 'antd';

import UcLink from 'components/basic/Typography/Link';
import UcHeading from 'components/basic/Typography/Heading';
import UcBodyText from 'components/basic/Typography/BodyText';
import UcSubHeading from 'components/basic/Typography/SubHeading';

export default Object.assign(Typography, {
  UcLink,
  UcHeading,
  UcBodyText,
  UcSubHeading
});
