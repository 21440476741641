import { SyntheticEvent } from 'react';

const imageErrorHandler = (
  { currentTarget }: SyntheticEvent<HTMLImageElement>,
  fallbackSrc: string
) => {
  currentTarget.onerror = null;
  currentTarget.src = fallbackSrc;
};

export default imageErrorHandler;
