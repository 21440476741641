import { Col, Row } from 'antd';
import {
  Script,
  ScriptPosition,
  ScriptQuery,
  ScriptTranslationInput
} from 'generatedHooks/builder/generated';
import { TFunction } from 'react-i18next';
import { KeyValuePair, Maybe } from 'core/globalTypes';
import { Locale } from 'generatedHooks/commerce/generated';

import { Locale as LocalesDropdown } from 'components/shared';
import { DeletableScript, InitialActionsProps } from './types';
import { CodeInjectionFormData } from '../types';
import { FIELDS_LABELS_TRANSLATIONS } from '../constants';

const isScriptDeleted = (initialScript: string, currentScript: string) => {
  const trimmedInitialString = initialScript.trim();
  const trimmedCurrentScript = currentScript.trim();

  return Boolean(trimmedInitialString && !trimmedCurrentScript);
};

export const initialFormDataAdapter = (
  scriptsData: ScriptQuery['script'],
  locales: Locale[]
) => {
  const scriptMapping: Record<ScriptPosition, Script> = (
    scriptsData || []
  ).reduce(
    (accumulator, currentScript) => {
      return {
        ...accumulator,
        [currentScript.position]: currentScript
      };
    },
    {} as Record<ScriptPosition, Script>
  );

  const fallbackScript = scriptsData
    ? {
        [ScriptPosition.TopHead]:
          scriptMapping[ScriptPosition.TopHead]?.content,
        [ScriptPosition.BottomHead]:
          scriptMapping[ScriptPosition.BottomHead]?.content,
        [ScriptPosition.TopBody]:
          scriptMapping[ScriptPosition.TopBody]?.content,
        [ScriptPosition.BottomBody]:
          scriptMapping[ScriptPosition.BottomBody]?.content
      }
    : {};

  const contents: Record<ScriptPosition, KeyValuePair<Maybe<string>>> = {
    [ScriptPosition.TopHead]: {},
    [ScriptPosition.BottomHead]: {},
    [ScriptPosition.TopBody]: {},
    [ScriptPosition.BottomBody]: {}
  };

  if (scriptsData) {
    locales?.forEach(locale => {
      Object.values(ScriptPosition).forEach(position => {
        const translation = scriptMapping[position]?.translations.find(
          tr => tr.languageCode === locale.internationalCode
        );

        contents[position][locale.internationalCode || ''] = translation
          ? translation.content
          : fallbackScript[position];
      });
    });
  }

  return contents;
};

export const scriptsMutationInputAdapter = (
  data: CodeInjectionFormData & { locale?: string },
  initialFormData: CodeInjectionFormData,
  defaultLocale: string
) => {
  const deleteScriptMessage: DeletableScript[] = [];

  // Deleting locale as in this case we need only script fields
  delete data.locale;

  const translations = Object.keys(data).reduce<ScriptTranslationInput[]>(
    (accumulator: ScriptTranslationInput[], key: string) => {
      const position = key as ScriptPosition;

      Object.keys(data[position]).forEach(language => {
        const initialContent = initialFormData[position][language] || '';
        const currentContent = data[position][language] || '';

        const isDeleted = isScriptDeleted(initialContent, currentContent);

        // Checking if the field initially had data and user has deleted it
        if (isDeleted) {
          deleteScriptMessage.push({
            scriptKey: position,
            locale: language
          });
        }

        /**
         * If data[position][language] returns undefined it means that the corresponding input untouched.
         * In this case we don't send that content to backend if it is not the default language content.
         */

        const isUntouchedAndNotDefault =
          typeof data[position][language] !== 'string' &&
          language !== defaultLocale;

        /**
         * We need to send to the backend all the contents of the default language even if it was not inputted
         */
        if (!isUntouchedAndNotDefault) {
          const newTranslation: ScriptTranslationInput = {
            languageCode: language,
            content: currentContent,
            position
          };

          accumulator.push(newTranslation);
        }
      });

      return accumulator;
    },
    []
  );

  return {
    translations,
    deleteScriptMessage
  };
};

export const generateDeleteScriptsMessage = (
  t: TFunction<'codeInjection', undefined>,
  deleteScriptMessages: DeletableScript[]
) => {
  let deletableScripts = '';
  deleteScriptMessages.forEach(({ scriptKey, locale }, idx) => {
    if (!deletableScripts.length) {
      return (deletableScripts += `${t(
        scriptKey,
        FIELDS_LABELS_TRANSLATIONS[scriptKey].defaultValue
      )} (${locale})`);
    }

    if (deletableScripts.length && idx === deleteScriptMessages.length - 1) {
      return (deletableScripts += `, and ${t(
        scriptKey,
        FIELDS_LABELS_TRANSLATIONS[scriptKey].defaultValue
      )} (${locale})`);
    }

    deletableScripts += `, ${t(
      scriptKey,
      FIELDS_LABELS_TRANSLATIONS[scriptKey].defaultValue
    )} (${locale})`;
  });

  return deletableScripts;
};

export const getInitialActions = ({
  localesData,
  localesLoading
}: InitialActionsProps) => {
  return (
    <Row gutter={16} justify="end">
      <Col>
        <LocalesDropdown
          locales={localesData}
          localesLoading={localesLoading}
          key="locale"
        />
      </Col>
    </Row>
  );
};
