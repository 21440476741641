import qs from 'qs';
import { PAGE, SIZE } from 'core/constants/common';

import { useHistory } from './';

const qsParseOptions = { ignoreQueryPrefix: true };

interface InitialParam {
  page: number;
  count: number;
}

const usePagination = (InitialCountAndPage?: InitialParam) => {
  const { push } = useHistory();
  const { page, count } = getParams(InitialCountAndPage);

  function getParams(defaultPropsParam?: InitialParam) {
    const {
      page = defaultPropsParam ? defaultPropsParam.page : PAGE,
      count = defaultPropsParam ? defaultPropsParam.count : SIZE,
      ...others
    } = qs.parse(location.search, qsParseOptions);

    return {
      page,
      count,
      ...others
    };
  }

  function onPaginationChange(page: number, count?: number) {
    push({ ...getParams(), page, count });
  }

  return {
    page: Number(page) || PAGE,
    count: Number(count) || SIZE,
    onPaginationChange
  };
};

export default usePagination;
