import { useEffect } from 'react';
import { IUsersnapApiProps } from 'core/globalTypes';

const { VITE_APP_USERSNAP_GLOBAL_API_KEY = '' } = import.meta.env;

const useUserSnap = () => {
  useEffect(() => {
    if (!window.usersnapApi) {
      window.onUsersnapCXLoad = function (api: IUsersnapApiProps) {
        api.init({});
        window.usersnapApi = api;
      };

      const script = document.createElement('script');
      script.defer = true;
      script.src = `https://widget.usersnap.com/global/load/${VITE_APP_USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
      document.head.appendChild(script);
    }
  }, []);
};

export default useUserSnap;
