import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { GlobalPropsContext } from 'providers/GlobalPropsProvider';

import styles from './styles.module.less';

const ExpiredAlert = () => {
  const { t } = useTranslation('billing');
  const { setIsPlanModalOpened } = useContext(GlobalPropsContext);

  return (
    <Flex className={styles.alert} align="center" justify="center" gap={8}>
      <InfoCircleOutlined />
      {t('subscriptionExpiredAlert')}
      <Button size="small" onClick={() => setIsPlanModalOpened(true)}>
        {t('reactivatePlan')}
      </Button>
    </Flex>
  );
};

export default ExpiredAlert;
