import { FC } from 'react';
import { useFilterQuery, useHistory } from 'hooks';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'core/constants/date-constants';

import { DateRangeProps } from './types';
import { DatePicker } from 'components/basic';
import styles from './styles.module.less';

const DateRange: FC<DateRangeProps> = ({
  fromName,
  toName,
  format = DATE_FORMAT,
  onChange,
  value,
  disabledDate
}) => {
  const { push, resetQuery } = useHistory();
  const { queryObject } = useFilterQuery();

  const handleSetToQueryObject = (date: [string, string]) => {
    const [dateFrom, dateTo] = date;
    const dateFromFormat = dayjs(dateFrom).format(format);
    const dateToFormat = dayjs(dateTo).format(format);

    push({
      [fromName]: dateFromFormat,
      [toName]: dateToFormat
    });
  };

  const handleDateChange = (dates: unknown, dateString: [string, string]) => {
    if (dates) {
      onChange ? onChange(dateString) : handleSetToQueryObject(dateString);
    } else {
      resetQuery(fromName);
      resetQuery(toName);
    }
  };

  const fromValue = queryObject[fromName]
    ? dayjs(queryObject[fromName])
    : value?.[0]
      ? dayjs(value[0])
      : null;

  const toValue = queryObject[toName]
    ? dayjs(queryObject[toName])
    : value?.[1]
      ? dayjs(value[1])
      : null;

  return (
    <DatePicker.RangePicker
      value={[fromValue, toValue]}
      onChange={handleDateChange}
      className={styles.range_picker}
      disabledDate={disabledDate}
    />
  );
};

export default DateRange;
