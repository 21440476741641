import { OperatingSystems } from 'core/globalTypes';

import { ShortcutKeys } from 'components/shared/KeyboardShortcutsGuide/types';
import {
  CTRL,
  MAC_COMMAND,
  PLUS_OPERATOR
} from 'components/shared/KeyboardShortcutsGuide/constants';

export const navigationShortcuts = [
  {
    key: ShortcutKeys.GO_TO_ORDERS,
    label: 'O'
  },
  {
    key: ShortcutKeys.GO_TO_PRODUCTS,
    label: 'P'
  },
  {
    key: ShortcutKeys.GO_TO_ANALYTICS,
    label: 'A'
  },
  {
    key: ShortcutKeys.GO_TO_CUSTOMERS,
    label: 'C'
  },
  {
    key: ShortcutKeys.GO_TO_DISCOUNTS,
    label: 'D'
  },
  {
    key: ShortcutKeys.GO_TO_SETTINGS,
    label: 'S'
  },
  {
    key: ShortcutKeys.GO_TO_ACTIVITY_LOG,
    label: 'L'
  }
];

export const comboShortcutList = (OS: OperatingSystems) => {
  const ctrlLabel = OS === OperatingSystems.MAC ? MAC_COMMAND : CTRL;

  return [
    {
      key: ShortcutKeys.CREATE_NEW_ITEM,
      label: 'N'
    },
    {
      key: ShortcutKeys.SAVE_CHANGES,
      label: [ctrlLabel, 'S'],
      operator: PLUS_OPERATOR
    }
    // Will be implemented later, as backend changes are needed for this feature
    // {
    //   key: ShortcutKeys.NEXT_PREVIOUS_ITEM,
    //   label: [ARROW_LEFT, ARROW_RIGHT],
    //   operator: t('or')
    // },
    // {
    //   key: ShortcutKeys.FULFILL_ORDER,
    //   label: [ctrlLabel, 'F'],
    //   operator: PLUS_OPERATOR
    // }
  ];
};
