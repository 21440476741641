import React, { SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { AttributeFamily } from 'generatedHooks/commerce/generated';

import StepContentHeading from 'components/global/ImportModal/components/StepContentHeading';
import styles from './styles.module.less';
import { ProductTypeChooserList } from 'components/shared';

interface Props {
  productAttributes: AttributeFamily[];
  setSelectedFamily: Dispatch<SetStateAction<string | null>>;
  setSelectedFamilyId: Dispatch<SetStateAction<string>>;
  selectedFamily: string;
  loading: boolean;
}

const ProductFamily: React.FC<Props> = ({
  productAttributes,
  setSelectedFamilyId,
  setSelectedFamily,
  selectedFamily,
  loading
}) => {
  const { t } = useTranslation('importModal');

  return (
    <div className={styles.productFamily__wrapper}>
      <StepContentHeading
        title={t('productFamily')}
        description={t('productFamilyDescription')}
      />
      <ProductTypeChooserList
        dataSource={productAttributes}
        withHeading={false}
        setSelectedFamilyId={setSelectedFamilyId}
        setSelectedType={setSelectedFamily}
        selectedType={selectedFamily}
        loading={loading}
        checkPermissions
        hideArrows
      />
    </div>
  );
};

export default ProductFamily;
