import React, { FC } from 'react';
import { Upload, UploadProps } from 'antd';

import UcDragger from './dragger';

interface BaseUploadProps extends UploadProps {}

const BaseUpload: FC<BaseUploadProps> = props => {
  return <Upload {...props} />;
};

const UcUpload = Object.assign(Upload, BaseUpload, {
  Dragger: UcDragger
});

export default UcUpload;
