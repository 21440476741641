import React, { FC } from 'react';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';
import { PageType } from 'packages/Media/types';

import styles from './styles.module.less';
import { UCCheckboxSingle } from 'components/mobile/UCCheckbox';
import { CarouselThumbItemProps } from '../types';
import useMedia from 'hooks/useMedia';

const CarouselThumbItem: FC<CarouselThumbItemProps> = ({
  thumbnail,
  handleItemSelected,
  id,
  type,
  isSelected
}) => {
  const { thumbnailPath } = useMedia();

  return (
    <div className={styles.carouselThumbItem}>
      <LazyImage
        src={thumbnailPath[type as PageType] + thumbnail}
        alt="media"
        height="100%"
      />
      <div className="overlay" data-id={id}>
        <UCCheckboxSingle
          checked={isSelected}
          className={styles.checkboxStyle}
          onChange={() => handleItemSelected(id)}
        />
      </div>
    </div>
  );
};

export default CarouselThumbItem;
