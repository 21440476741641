import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { linkTypes } from 'apollo/link';
import { useTranslation } from 'react-i18next';
import { SystemMessage } from 'helpers';

import { PageType } from './types';
import useCreateMedia from './useCreateMedia';
import { mediaTypeMapper } from './constants';
import { loadRemoteMediaMutation } from './queries/mediaQuery';

type Props = {
  type: PageType;
  parentId: string;
};

/**
 * Creates a function for load media by url.
 * @param type - Media type.
 * @param parentId - Parent type.
 * @returns onLoadRemoteMedia functions and loading state for load media.
 */
function useLoadRemoteMedia({ type, parentId }: Props) {
  const [loadRemoteMediaLoading, setLoadRemoteMediaLoading] = useState(false);
  const { t } = useTranslation('media');

  const [loadRemoteMedia] = useMutation(loadRemoteMediaMutation, {
    context: { urlType: linkTypes.builder, hideMessages: true }
  });

  const { onCreateMedia } = useCreateMedia({
    type,
    parentId: parentId as string
  });

  /**
   * Loads a media.
   * @param url - Media url.
   * @returns Media object.
   */
  async function onLoadRemoteMedia(url: string) {
    try {
      const variables = {
        input: { type: mediaTypeMapper[type], url }
      };

      setLoadRemoteMediaLoading(true);
      const {
        data: {
          loadRemoteMedia: { name, thumbnail }
        }
      } = await loadRemoteMedia({ variables });

      const data = await onCreateMedia(name, thumbnail);

      setLoadRemoteMediaLoading(false);

      return data;
    } catch (e: any) {
      const { graphQLErrors } = e;

      const msg = graphQLErrors?.[0]?.message;

      if (msg) {
        SystemMessage.error(t(msg));
      }

      setLoadRemoteMediaLoading(false);
    }
  }

  return { onLoadRemoteMedia, loadRemoteMediaLoading };
}

export default useLoadRemoteMedia;
