import { ComponentType, useEffect } from 'react';
import { currentUser } from 'apollo/cache';
import axios from 'axios';
import { useReactiveVar } from '@apollo/client';
import AccountVerification from 'routes/components/AccountVerification';
import i18n from 'i18next';
import { DEFAULT_LANGUAGE } from 'core/constants/common';
import PermissionProtectedRouteWrapper from 'routes/components/PermissionProtectedRouteWrapper';
import { RouteAliases } from 'routes/types';
import { customHeaders } from 'core/globalTypes';

import PageLayout from 'pages/projects/components/Layout';

const { VITE_APP_SSO_URL } = import.meta.env;

const dashboardClientName = location.hostname.split('.')[0].includes('mobile')
  ? 'dashboard-mobile'
  : 'dashboard';

const callbackUrl = `${VITE_APP_SSO_URL}/callback?target=${dashboardClientName}`;

export const protectedRoute = (
  Component: ComponentType<any>,
  routeAlias: RouteAliases = '' as RouteAliases
) => {
  return () => {
    const currUser = useReactiveVar(currentUser);

    useEffect(() => {
      const onLoad = async () => {
        try {
          const user = await axios({
            method: 'GET',
            url: `${VITE_APP_SSO_URL}/api/user`,
            headers: {
              ...customHeaders,
              Authorization: `Bearer ${localStorage.token}`
            }
          });

          currentUser(user.data.data);
          const userLanguage =
            user.data.data.params.language || DEFAULT_LANGUAGE;

          await i18n.changeLanguage(userLanguage);
          document.documentElement.setAttribute('lang', userLanguage);
        } catch (error) {
          location.href = `${callbackUrl}&returnUrl=${location.pathname}`;
        }
      };

      if (!localStorage.token) {
        location.href = `${callbackUrl}&returnUrl=${location.pathname}`;
      }

      if (!currentUser() && localStorage.token) {
        onLoad();
      }
    }, []);

    if (currUser && !currUser.emailVerifiedAt) {
      return <AccountVerification />;
    }

    return (
      <PermissionProtectedRouteWrapper routeAlias={routeAlias}>
        {currUser &&
          (routeAlias === RouteAliases.PROJECTS_PAGE ? (
            <PageLayout user={currUser}>{<Component />}</PageLayout>
          ) : (
            <Component user={currUser} />
          ))}
      </PermissionProtectedRouteWrapper>
    );
  };
};
