import {
  useState,
  ChangeEvent,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';
import { isMobileDevice } from 'apollo/cache';
import {
  PictureFilled,
  CustomerServiceFilled,
  PlayCircleFilled,
  FileFilled
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation, useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { filesize } from 'filesize';
import { linkTypes } from 'apollo/link';
import { SystemMessage } from 'helpers';

import LazyImage from '../UIComponents/LazyImage/LazyImage';
import { uploadMediaMutation } from '../queries/mediaQuery';
import {
  mediaTypeAcceptFormats,
  mediaTypeMapper,
  mediaSizeLimitations
} from '../constants';
import validateFileExtension from '../valiadateFileExtension';
import useCreateMedia from '../useCreateMedia';
import { PageType } from '../types';
import useMedia from 'hooks/useMedia';

export type ListItemType = {
  id: string;
  name: string;
  size: string;
  total: number;
  loaded?: number;
  image?: ReactNode;
  errorMessage?: string;
  onItemClose?: () => void;
};

const icons = {
  images: <PictureFilled />,
  audios: <CustomerServiceFilled />,
  videos: <PlayCircleFilled />,
  docs: <FileFilled />,
  vectors: <PictureFilled />
};

type Props = {
  type: PageType;
  parentId: string | null;
  setUploadIds?: Dispatch<SetStateAction<[] | string[]>>;
};

function useUploadMedia({ type, parentId, setUploadIds }: Props) {
  const { thumbnailPath } = useMedia();

  const [uploadMedia] = useMutation(uploadMediaMutation);
  const isMobile = useReactiveVar(isMobileDevice);

  const [loading, setLoading] = useState<boolean>(false);
  const [uploadList, setUploadList] = useState<Array<ListItemType> | null>(
    null
  );

  const { t } = useTranslation('media');
  const { onCreateMedia } = useCreateMedia({
    type,
    parentId: parentId as string
  });

  async function onUploadMedia(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    setLoading(true);
    const filesQuantity = files?.length || 0;
    const list = files
      ? Array.from(files)
          .map((file, index) => {
            const { size, name: fileName } = file;
            const fileData: ListItemType = {
              name: fileName,
              size: filesize(size),
              total: size,
              image: icons[type],
              id: uuidv4()
            };

            if (
              validateFileExtension(
                fileName,
                mediaTypeAcceptFormats[type] as any
              )
            ) {
              if (size > mediaSizeLimitations[type]) {
                fileData.errorMessage = t('sizeExceedMessage', {
                  size: filesize(mediaSizeLimitations[type])
                });
                setLoading(false);

                if (isMobile) {
                  const maxSize = mediaSizeLimitations[type] / 1048576;

                  SystemMessage.error(
                    t('wrongFileSize', {
                      name: fileData.name,
                      max: maxSize
                    })
                  );
                }
              } else {
                const variables = {
                  input: { type: mediaTypeMapper[type], file }
                };

                uploadMedia({
                  variables,
                  context: {
                    hideMessages: true,
                    urlType: linkTypes.builderFile,
                    fetchOptions: {
                      useUpload: true,
                      onProgress: ({ loaded, total }: ProgressEvent) => {
                        setUploadList(
                          list =>
                            list &&
                            list?.map(item =>
                              item.id === fileData.id
                                ? { ...item, loaded, total }
                                : item
                            )
                        );
                      },
                      onAbortPossible: (abortHandler: () => void) => {
                        fileData.onItemClose = () => {
                          abortHandler();
                          setUploadList(list =>
                            list && list.length > 1
                              ? list.filter(item => item.id !== fileData.id)
                              : null
                          );
                        };
                      }
                    }
                  }
                })
                  .then(({ data }) => {
                    const { name, thumbnail } = data.uploadMedia;

                    if (thumbnail) {
                      fileData.image = (
                        <LazyImage
                          src={`${thumbnailPath[type]}${thumbnail}`}
                          alt="media"
                        />
                      );
                    }

                    onCreateMedia(name, thumbnail)
                      .then(item => {
                        setUploadIds &&
                          setUploadIds(prevState => [...prevState, item.id]);
                      })
                      .finally(() => {
                        if (filesQuantity - index === 1) {
                          setLoading(false);
                        }
                      });
                  })
                  .catch(() => {
                    setUploadList(list => {
                      return (
                        list &&
                        list?.map(item =>
                          item.id === fileData.id
                            ? {
                                ...item,
                                errorMessage: 'Internal Server Error',
                                loaded: 0
                              }
                            : item
                        )
                      );
                    });
                  });
              }
            } else {
              fileData.errorMessage = t('wrongExtensionMessage');
              setLoading(false);

              if (isMobile) {
                SystemMessage.error(
                  t('wrongExtensionName', {
                    name: fileData.name
                  })
                );
              }
            }

            return fileData;
          })
          .concat(uploadList || [])
      : null;

    setUploadList(list);
    e.target.value = '';
  }

  return { onUploadMedia, uploadList, setUploadList, loading };
}

export default useUploadMedia;
