import { FC } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Tooltip, Typography } from 'components/basic';
import { InfoTooltipProps } from './types';
import styles from './styles.module.less';

const { UcBodyText } = Typography;

const InfoTooltip: FC<InfoTooltipProps> = ({ tooltipText }) => {
  return (
    <Tooltip
      trigger={['hover', 'click']}
      title={<UcBodyText>{tooltipText}</UcBodyText>}
    >
      <InfoCircleOutlined className={styles.upload_info_icon} />
    </Tooltip>
  );
};

export default InfoTooltip;
