import { Col, Row, Skeleton } from 'antd';

import styles from 'components/shared/Skeleton/styles.module.less';

const { Input } = Skeleton;

const PageContainer = () => {
  return (
    <Row className={styles.pageContainer}>
      <Col span={24}>
        <Row justify="space-between" className={styles.header}>
          <Col className={styles.col}>
            <Input active className={styles.title} />
          </Col>
          <Col className={styles.col}>
            <div className={styles.buttons}>
              <Input
                active
                style={{
                  width: 130,
                  height: 32
                }}
              />
              <Input
                active
                style={{
                  width: 130,
                  height: 32
                }}
              />
              <Input
                active
                style={{
                  width: 130,
                  height: 32
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col className={styles.main}>
        <Input active className={styles.main_skeleton} />
      </Col>
    </Row>
  );
};

export default PageContainer;
