import { Skeleton as CustomSkeleton, LoadingWrapper } from 'components/shared';
import { Typography } from 'components/basic';

const { UcBodyText } = Typography;

export const localeInLabel = (locale?: string) => (
  <LoadingWrapper
    loading={!locale}
    indicator={<CustomSkeleton.Tag styles={{ width: 30, marginLeft: '5px' }} />}
  >
    <UcBodyText
      size="small"
      style={{ marginLeft: 5 }}
    >{`(${locale})`}</UcBodyText>
  </LoadingWrapper>
);
