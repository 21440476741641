import React, { ChangeEvent, FC } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { KeyValuePair } from 'core/globalTypes';

export interface TranslatableBaseTextAreaProps
  extends Omit<TextAreaProps, 'value' | 'onChange'> {
  locale?: string;
  value?: KeyValuePair<string>;
  onChange?: (v: KeyValuePair<string>) => void;
}

const TranslatableTextArea: FC<TranslatableBaseTextAreaProps> = ({
  locale = '',
  onChange,
  value = {},
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.({ ...value, [locale]: e.target.value });
  };

  return (
    <Input.TextArea onChange={handleChange} value={value[locale]} {...rest} />
  );
};

export default TranslatableTextArea;
