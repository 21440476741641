import { FC } from 'react';
import { linkTypes } from 'apollo/link';
import { Project, useProjectsQuery } from 'generatedHooks/accounts/generated';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';

import { Button, Typography } from 'components/basic';
import { ProjectItem } from './components/ProjectItem';
import { ProjectListSkeleton } from './components/ProjectListSkeleton';
import styles from './styles.module.less';

interface ProjectsListDrawerProps {
  currentProjectId: string | undefined;
  fullHeight?: boolean;
  visible?: boolean;
}

export const ProjectsListDrawer: FC<ProjectsListDrawerProps> = ({
  currentProjectId,
  fullHeight,
  visible
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['projects']);
  const { data: projects, loading: projectsLoading } = useProjectsQuery({
    context: { urlType: linkTypes.accounts },
    variables: { page: 1, first: 20 },
    notifyOnNetworkStatusChange: true
  });

  const currentProject = projects?.projects?.data?.find(project =>
    isEqual(project.id, currentProjectId)
  );

  const data = projects?.projects?.data;

  return (
    <div
      className={`${styles.projects_drawer} ${visible ? styles.visible : ''} ${
        fullHeight ? styles.fullHeight : ''
      }`}
    >
      {projectsLoading ? (
        <ProjectListSkeleton />
      ) : (
        <div className={styles.projects_drawer_list}>
          <div className={styles.current_project}>
            <div className={styles.current_project_title}>
              <Typography.UcBodyText>
                {t('projects:currentProject', 'Current Project')}
              </Typography.UcBodyText>
            </div>
            <ProjectItem project={currentProject as Project} />
          </div>
          {data && data.length > 1 && (
            <>
              <Divider className={styles.divider} />
              <div className={styles.projects_drawer_list_title}>
                <Typography.UcBodyText>
                  {t('projects:allProjects', 'All Projects')}
                </Typography.UcBodyText>
              </div>
              {data
                ?.filter(p => !isEqual(p.id, currentProjectId))
                .map(project => (
                  <div
                    key={project.id}
                    className={styles.projects_drawer_list_item}
                  >
                    <ProjectItem project={project as Project} />
                  </div>
                ))}
            </>
          )}
        </div>
      )}
      <div className={styles.action_buttons_container}>
        <Button onClick={() => navigate('/')}>
          {t('projects:myProjects', 'My Projects')}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            window.location.assign(
              `${
                import.meta.env.VITE_APP_SSO_URL
              }/callback?target=onboarding-start`
            );
          }}
        >
          {t('projects:createProject')}
        </Button>
      </div>
    </div>
  );
};
