import { KeyValuePair } from 'core/globalTypes';
import { CategoryType } from 'generatedHooks/commerce/generated';

import {
  ConditionAttributeType,
  ConditionOperatorKeys,
  ConditionOperatorsByType
} from './types';

export const OPERATORS: ConditionOperatorsByType = {
  [ConditionOperatorKeys.IsEqualTo]: {
    operator: '==',
    key: ConditionOperatorKeys.IsEqualTo,
    label: 'is equal to'
  },
  [ConditionOperatorKeys.NotEqualTo]: {
    operator: '!=',
    key: ConditionOperatorKeys.NotEqualTo,
    label: 'is not equal to'
  },
  [ConditionOperatorKeys.EqualsOrGreater]: {
    operator: '>=',
    key: ConditionOperatorKeys.EqualsOrGreater,
    label: 'equals or greater than'
  },
  [ConditionOperatorKeys.EqualsOrLess]: {
    operator: '<=',
    key: ConditionOperatorKeys.EqualsOrLess,
    label: 'equals or less than'
  },
  [ConditionOperatorKeys.GreaterThan]: {
    operator: '>',
    key: ConditionOperatorKeys.GreaterThan,
    label: 'greater than'
  },
  [ConditionOperatorKeys.LessThan]: {
    operator: '<',
    key: ConditionOperatorKeys.LessThan,
    label: 'less than'
  },
  [ConditionOperatorKeys.Contains]: {
    operator: '{}',
    label: 'contains',
    key: ConditionOperatorKeys.Contains
  },
  [ConditionOperatorKeys.NotContain]: {
    operator: '!{}',
    label: ConditionOperatorKeys.NotContain,
    key: ConditionOperatorKeys.NotContain
  },
  [ConditionOperatorKeys.Every]: {
    operator: '%',
    label: ConditionOperatorKeys.Every,
    key: ConditionOperatorKeys.Every
  }
};

export const conditionOperators = {
  [ConditionAttributeType.Price]: [
    OPERATORS.isEqualTo,
    OPERATORS.notEqualTo,
    OPERATORS.equalsOrGreater,
    OPERATORS.equalsOrLess,
    OPERATORS.greaterThan,
    OPERATORS.lessThan
  ],
  [ConditionAttributeType.Decimal]: [
    OPERATORS.isEqualTo,
    OPERATORS.notEqualTo,
    OPERATORS.equalsOrGreater,
    OPERATORS.equalsOrLess,
    OPERATORS.greaterThan,
    OPERATORS.lessThan
  ],
  [ConditionAttributeType.Integer]: [
    OPERATORS.isEqualTo,
    OPERATORS.notEqualTo,
    OPERATORS.equalsOrGreater,
    OPERATORS.equalsOrLess,
    OPERATORS.greaterThan,
    OPERATORS.lessThan
  ],
  [ConditionAttributeType.Date]: [
    OPERATORS.isEqualTo,
    OPERATORS.notEqualTo,
    OPERATORS.equalsOrGreater,
    OPERATORS.equalsOrLess,
    OPERATORS.greaterThan,
    OPERATORS.lessThan
  ],
  [ConditionAttributeType.Text]: [
    OPERATORS.isEqualTo,
    OPERATORS.notEqualTo,
    OPERATORS.contains,
    OPERATORS.notContain
  ],
  [ConditionAttributeType.Boolean]: [OPERATORS.isEqualTo, OPERATORS.notEqualTo],
  [ConditionAttributeType.DateTime]: [
    OPERATORS.isEqualTo,
    OPERATORS.notEqualTo,
    OPERATORS.equalsOrGreater,
    OPERATORS.equalsOrLess,
    OPERATORS.greaterThan,
    OPERATORS.lessThan
  ],
  [ConditionAttributeType.Select]: [OPERATORS.isEqualTo, OPERATORS.notEqualTo],
  [ConditionAttributeType.Multiselect]: [
    OPERATORS.contains,
    OPERATORS.notContain
  ],
  [ConditionAttributeType.Addon]: [OPERATORS.contains, OPERATORS.notContain],
  [ConditionAttributeType.Checkbox]: [OPERATORS.contains, OPERATORS.notContain]
};

export const BOOLEAN_ATTRIBUTE_OPTIONS: KeyValuePair<
  Record<'falsy' | 'truthy', string>
> = {
  'collection_item|status': {
    falsy: 'common:draft',
    truthy: 'common:active'
  },
  'product|status': {
    falsy: 'common:draft',
    truthy: 'common:active'
  }
};

export const PRODUCT_FAMILY_KEYS_BY_CATEGORY_TYPE = {
  [CategoryType.Product]: 'product|attribute_family_id',
  [CategoryType.Event]: 'eventType',
  [CategoryType.Collection]: 'collection_item|attribute_family_id',
  [CategoryType.Reservation]: 'bookingType',
  [CategoryType.Rental]: 'rentalType'
};
