import { Radio } from 'antd';
import { FC } from 'react';

import { Typography } from 'components/basic';
import styles from './styles.module.less';
import { TimeGroupProps } from 'components/shared/CardWrapperChart/types';

const { UcBodyText } = Typography;

const TimeGroup: FC<TimeGroupProps> = ({ timeOptions, onChange, value }) => {
  const RadioBtn = timeOptions.map((opt, index) => (
    <Radio.Button
      disabled={opt.disabled}
      key={index}
      value={opt.value}
      className={styles.delta_time_btn}
    >
      <div className={styles.delta_time_btn_inner}>
        <UcBodyText
          size="small"
          fontWeight="regular"
          className="delta_time_btn_name"
        >
          {opt.label}
        </UcBodyText>
      </div>
    </Radio.Button>
  ));

  return (
    <div className={styles.delta_time_container}>
      <Radio.Group onChange={onChange} value={value}>
        {RadioBtn}
      </Radio.Group>
    </div>
  );
};

export default TimeGroup;
