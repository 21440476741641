import {
  BulkActionEntity,
  CheckBulkActionProcessStatus,
  useCheckBulkActionProcessStatusQuery
} from 'generatedHooks/commerce/generated';
import { useProjectContext } from 'providers/ProjectProvider';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import BulkActionsProccessController from 'services/bulkActions/BulkActionsProcessController';
import { BulkActionsProcess } from 'services/bulkActions/types';

import { add, remove } from './actions';
import { BULK_PROCESS_CHECK_INTERVAL } from './constants';
import reducer from './reducer';
import { manageProcessesCheckResult } from './utils';

const useBulkActionsProcess = () => {
  const [completedProcessesReport, setCompletedProcessesReport] = useState<
    Omit<CheckBulkActionProcessStatus, '__typename'>[]
  >([]);

  const { id: projectId } = useProjectContext();

  const bulkProcessController = new BulkActionsProccessController(projectId);

  const [bulkActionsProcesses, dispatch] = useReducer(
    reducer,
    bulkProcessController.readProjectProcessesFromStorage()
  );

  const bulkProcessesIds = useMemo(
    () => Object.keys(bulkActionsProcesses),
    [bulkActionsProcesses]
  );

  const hasActiveProcesses = useMemo(
    () => Boolean(bulkProcessesIds.length),
    [bulkProcessesIds]
  );

  const { startPolling, stopPolling } = useCheckBulkActionProcessStatusQuery({
    variables: {
      processIds: bulkProcessesIds
    },
    skip: !hasActiveProcesses,
    notifyOnNetworkStatusChange: true,
    context: {
      hideMessages: true
    },
    onCompleted({ checkBulkActionProcessStatus }) {
      const { completedProcesses } = manageProcessesCheckResult(
        checkBulkActionProcessStatus,
        setCompletedProcessesReport
      );

      completedProcesses.forEach(removeProcess);
    }
  });

  useEffect(() => {
    if (hasActiveProcesses) {
      startPolling(BULK_PROCESS_CHECK_INTERVAL);
    } else {
      stopPolling();
    }
  }, [hasActiveProcesses]);

  const addNewProcess = useCallback((process: BulkActionsProcess) => {
    bulkProcessController.writeProcessToStorage(process);
    dispatch(add(process));
  }, []);

  const removeProcess = useCallback((processId: string) => {
    bulkProcessController.removeProcessFromStorage(processId);
    dispatch(remove(processId));
  }, []);

  const getCompletedProcessesByEntity = useCallback(
    (entity: BulkActionEntity) => {
      return completedProcessesReport.filter(
        process => process.entity === entity
      );
    },
    [completedProcessesReport]
  );

  const excludeEntityFromCompletedProcesses = useCallback(
    (entity: BulkActionEntity) => {
      setCompletedProcessesReport(prevReport =>
        prevReport.filter(process => process.entity !== entity)
      );
    },
    [completedProcessesReport]
  );

  return {
    bulkActionsProcesses,
    addNewProcess,
    getCompletedProcessesByEntity,
    removeProcess,
    completedProcessesReport,
    excludeEntityFromCompletedProcesses
  };
};

export default useBulkActionsProcess;
