import * as React from 'react';
import { Space, Tooltip, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.less';
import Dropdown from './Dropdown';
import ModeSwitcher from './ModeSwitcher';
import Search from '../UIComponents/Search';

// const Container = styled.div<{ selected?: boolean }>`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   margin: 24px 0;
//   background: ${props => (props.selected ? 'var(--ant-gray-2)' : 'transparent')};
//   color: ${props => (props.selected ? 'var(--ant-gray-13)' : '')};
//   padding: ${props => (props.selected ? '24px' : '')};
//   height: 48px;
//   border-radius: var(--ant-border-radius-base);
// `;
//
// const Link = styled.span`
//   font-weight: 600;
//   transition: all 0.2s ease;
//   opacity: 0.8;
//
//   &:hover {
//     text-decoration: underline;
//     cursor: pointer;
//     opacity: 1;
//   }
// `;

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  selectActions?: Array<{
    content: React.ReactNode;
    title: string;
    onClick?: () => void;
  }>;
  selectCount?: number;
  onCancelSelect?: () => void;
};

function ActionBar({
  left,
  right,
  selectActions,
  selectCount,
  onCancelSelect
}: Props) {
  const { t } = useTranslation('common');

  if (selectCount && selectCount > 0) {
    return (
      <div className={styles.ContainerSelected}>
        <Space size={16}>
          <span>
            {selectCount} {t('Items Selected')}
          </span>
          <span
            className={styles.Link}
            data-test="cancel-select"
            onClick={onCancelSelect}
          >
            {t('cancel')}
          </span>
        </Space>
        <Space size={16}>
          {selectActions?.map((action, index) => (
            <Tooltip key={index} placement="bottom" title={action.title}>
              <span className={styles.Link} onClick={action.onClick}>
                {action.content}
              </span>
            </Tooltip>
          ))}
        </Space>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <Space size="middle">{left}</Space>
      <Space size="middle">{right}</Space>
    </div>
  );
}

ActionBar.Search = Search;
ActionBar.Dropdown = Dropdown;
ActionBar.ModeSwitcher = ModeSwitcher;
ActionBar.Button = Button;

export default ActionBar;
