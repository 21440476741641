import client from 'apollo/apolloClient';
import {
  CategoriesForFilterDocument,
  CategoryType,
  ConditionOperator,
  QueryCategoriesConditionColumn,
  QueryCategoriesOrderColumn,
  SortOrder
} from 'generatedHooks/commerce/generated';
import { flatten } from 'helpers';

export const getCategories =
  (categoryType: CategoryType = CategoryType.Product) =>
  async () => {
    try {
      const result = await client.query({
        query: CategoriesForFilterDocument,
        fetchPolicy: 'network-only',
        variables: {
          first: 100,
          input: {
            orderBy: [
              {
                column: QueryCategoriesOrderColumn.CreatedAt,
                order: SortOrder.Desc
              }
            ],
            filters: [
              {
                column: QueryCategoriesConditionColumn.Type,
                operator: ConditionOperator.Eq,
                value: categoryType
              }
            ]
          }
        }
      });

      return {
        data: flatten(result.data.categories.data),
        error: undefined
      };
    } catch (e) {
      return { data: [], error: e };
    }
  };
