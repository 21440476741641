import { useEffect } from 'react';
import {
  useCategoryImportableFieldsLazyQuery,
  useProductImportableFieldsLazyQuery
} from 'generatedHooks/commerce/generated';

import { ImportableEntity } from '../types';

const useImportableFields = (
  entityType: ImportableEntity,
  selectedFamilyId: string
): any => {
  const [
    getCategoryFields,
    { data: categoryFields, loading: categoryFieldsLoading }
  ] = useCategoryImportableFieldsLazyQuery({});

  const [
    getProductFields,
    { data: productFields, loading: productFieldsLoading }
  ] = useProductImportableFieldsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        attributeFamilyId: selectedFamilyId || ''
      }
    }
  });

  useEffect(() => {
    switch (entityType) {
      case 'Category':
        getCategoryFields();
        break;
      case 'Product':
        getProductFields();
        break;
    }
  }, [entityType, selectedFamilyId]);

  return {
    importableFields:
      entityType === 'Product'
        ? productFields?.productImportableFields
        : categoryFields?.categoryImportableFields,
    loading:
      entityType === 'Product' ? productFieldsLoading : categoryFieldsLoading
  };
};

export default useImportableFields;
