import { LockOutlined } from '@ant-design/icons';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useCallback } from 'react';
import { Action } from 'antd-mobile/es/components/action-sheet';

import { LimitationFunctionProps } from './types';
import useQuicklyCheckPermissionAndLimitation from './useQuicklyCheckPermissionAndLimitation';
import Limitation from '../components/Wrappers/Limitation';
import usePermissionAndLimitationContext from './usePermissionAndLimitationContext';

const useLimitationFunction = () => {
  const { checkLimitation } = useQuicklyCheckPermissionAndLimitation();

  const {
    state: {
      limitations: { entities }
    }
  } = usePermissionAndLimitationContext();

  const LimitationFunction = useCallback(
    ({
      children,
      isMobile = false,
      disabledAt,
      entity,
      ...restLimitationProps
    }: LimitationFunctionProps & {
      isMobile?: boolean;
    }) => {
      const disabled = checkLimitation({
        disabledAt,
        entity
      });

      const menuItem = children as MenuItemType & { 'data-test'?: string };

      const mobileMenuItem = children as Action;

      const component = isMobile ? mobileMenuItem.text : menuItem.label;

      if (disabled) {
        return {
          disabled: true,
          icon: <LockOutlined />,
          [isMobile ? 'text' : 'label']: (
            <Limitation entity={entity} {...restLimitationProps}>
              {component}
            </Limitation>
          )
        };
      }

      return children;
    },
    [entities]
  );

  return LimitationFunction;
};

export default useLimitationFunction;
