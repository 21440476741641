import { FC } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { ProgressChartProps } from 'components/shared/Charts/types';
import styles from './styles.module.less';
import { Typography } from 'components/basic';

const { UcBodyText } = Typography;

export const ProgressChartItem: FC<ProgressChartProps> = ({ count, label }) => {
  const { t } = useTranslation('common');
  const countIsNumber = typeof count === 'number';

  return (
    <Row className={styles.progress_chart_item_container}>
      <div className={styles.progress_chart_item_container_information}>
        <UcBodyText fontWeight="regular" size="small" type="secondary-dark">
          {label || t('common:unknown', 'Unknown')}
        </UcBodyText>
        <span className={styles.value_index}>
          <UcBodyText fontWeight="bold" size="small">
            {countIsNumber ? count : count || 'N/A'}
          </UcBodyText>
        </span>
      </div>
    </Row>
  );
};
