import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';
import { commonTranslations } from 'translations';
import { useFilterQuery, useHistory } from 'hooks';

import { ImgIcon, Heading, PopupSheet } from 'components/mobile';
import styles from './styles.module.less';
import { FilterProps } from './types';
import { Typography } from 'components/basic';
import { DEFAULT_SORT_DATA } from 'pages/projects/constants';

const { UcBodyText } = Typography;

const Filter: FC<FilterProps> = ({ visible, onClose }) => {
  const { queryObject } = useFilterQuery();
  const { push } = useHistory();

  const { t } = useTranslation(['projects', 'common']);
  const value =
    queryObject?.sort || t('common:sortBy', commonTranslations.sortBy);

  const handleSelectFilterType = (sort: string) => {
    onClose();
    push({ sort });
  };

  return (
    <PopupSheet
      radius
      outSeparator
      onClose={onClose}
      visible={visible}
      zIndex="5"
    >
      <div className={styles.filter_container}>
        <Heading headingTypes={3} title={t('common:sortBy', 'Sort By')} />

        <div className={styles.filter_list}>
          <List
            itemLayout="horizontal"
            dataSource={DEFAULT_SORT_DATA}
            renderItem={item => (
              <List.Item onClick={() => handleSelectFilterType(item.value)}>
                <List.Item.Meta
                  title={<UcBodyText size="base">{item.name}</UcBodyText>}
                />
                {value === item.value && <ImgIcon icon="checkOutlined" />}
              </List.Item>
            )}
          />
        </div>
      </div>
    </PopupSheet>
  );
};

export default Filter;
