import { FC, ReactNode, useLayoutEffect, useMemo } from 'react';
import { Drawer, DrawerProps, ModalProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import { NiladicVoid } from 'core/globalTypes';

import Header from './Header';
import Footer from './Footer';
import styles from './styles.module.less';

interface Props extends DrawerProps {
  show?: boolean;
  closable?: boolean;
  extra?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
  showFooter?: boolean;
  closeIcon?: ReactNode;
  showCloseIcon?: boolean;
  title?: string | ReactNode;
  align?: 'top' | 'right' | 'bottom' | 'left';
  afterClose?: NiladicVoid;
  okText?: string;
  onOk?: NiladicVoid;
  cancelText?: string;
  customFooter?: ReactNode;
  afterOpenChange?: DrawerProps['afterOpenChange'];
  rootClassName?: DrawerProps['rootClassName'];
  okButtonProps?: ModalProps['okButtonProps'];
  cancelButtonProps?: ModalProps['cancelButtonProps'];
  confirmLoading?: boolean;
  isModal?: boolean;
}

const scrollHeight = window.innerHeight < 800 ? 800 : window.innerHeight;

const BaseDrawer: FC<Props> = ({
  show,
  title,
  align,
  onOk,
  closable = false,
  extra,
  customFooter,
  onClose,
  closeIcon,
  okText,
  cancelText,
  children,
  showCloseIcon = true,
  showFooter = false,
  rootClassName = '',
  afterOpenChange,
  afterClose,
  okButtonProps,
  cancelButtonProps,
  confirmLoading,
  isModal = false,
  ...restProps
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  const closeComponent = useMemo(() => {
    if (showCloseIcon) {
      return closeIcon ? closeIcon : <CloseOutlined />;
    }

    return null;
  }, [showCloseIcon, closeIcon]);

  useLayoutEffect(() => {
    if (isModal) {
      return;
    }

    const scrollLayer = document.querySelector(
      'div.ant-drawer-content-wrapper:not([style*="display: none"])'
    );

    const listener = (e: Event) => {
      const target = e.target as HTMLDivElement;

      if (target) {
        if (target.scrollTop < scrollHeight / 2 - 50) {
          onClose?.();
        }
      }
    };

    if (scrollLayer && (show || restProps.open)) {
      setTimeout(() => (scrollLayer.scrollTop = scrollHeight / 2), 0);

      scrollLayer?.addEventListener('scroll', listener);
    }

    return () => scrollLayer?.removeEventListener('scroll', listener);
  }, [show, restProps.open, isModal]);

  return (
    <Drawer
      // zIndex={1100}
      closable={closable}
      rootClassName={`${styles.ucDrawer} ${
        isMobile ? styles.ucDrawer_mobile : ''
      } ${
        !isModal && isMobile ? styles.ucDrawer_mobile_not_modal : ''
      }  ${rootClassName} ${restProps.open ? styles.open : ''}`}
      extra={
        extra ? (
          extra
        ) : (
          <span className={styles.drawer_icon_wrapper} onClick={onClose}>
            {closeComponent}
          </span>
        )
      }
      title={
        <Header
          title={title}
          onClose={onClose}
          closeIcon={closeIcon}
          showIcon={showCloseIcon}
        />
      }
      open={show}
      onClose={onClose}
      placement={isMobile ? 'bottom' : align}
      afterOpenChange={open => {
        afterOpenChange?.(open);

        if (!open) {
          afterClose?.();
        }
      }}
      footer={
        showFooter && (
          <Footer
            onOk={onOk}
            okText={okText}
            onCancel={onClose}
            cancelText={cancelText}
            customFooter={customFooter}
            cancelButtonProps={cancelButtonProps}
            okButtonProps={okButtonProps}
            confirmLoading={confirmLoading}
          />
        )
      }
      {...restProps}
    >
      {children}
    </Drawer>
  );
};

const UcDrawer = Object.assign(BaseDrawer, Drawer);
export default UcDrawer;
