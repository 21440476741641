import { FC } from 'react';
import { Badge } from 'antd';

import { icons } from './utils';
import styles from './styles.module.less';
import { ImgIconProps } from './types';

const ImgIcon: FC<ImgIconProps> = ({
  icon,
  className = '',
  onClick,
  paddingType = 'middle',
  width,
  height,
  badge,
  disabled = false
}) => {
  const imgProps = {
    src: icons[icon],
    alt: icon,
    width,
    height
  };

  return (
    <span
      onClick={onClick}
      className={`${className} ${styles[paddingType]} ${styles.img_icon_wrapper}`}
      hidden={disabled}
    >
      {badge ? (
        <Badge dot={badge}>
          <img {...imgProps} alt={icon} />
        </Badge>
      ) : (
        <img {...imgProps} alt={icon} />
      )}
    </span>
  );
};

export default ImgIcon;
