import { OrderPaymentStatus } from 'generatedHooks/commerce/generated';

import nonPhysicalOrderLifecycleActions from 'pages/orderDetails/utilities/nonPhysicalOrderLifecycleActions';

const giftCardOrderLifecycleActions = {
  ...nonPhysicalOrderLifecycleActions,
  paymentStatus: {
    ...nonPhysicalOrderLifecycleActions.paymentStatus,
    [OrderPaymentStatus.Paid]: []
  }
};

export default giftCardOrderLifecycleActions;
