import { Row, Col } from 'antd';
import { FC } from 'react';

import styles from './styles.module.less';
import { Typography } from 'components/basic';
import ExtraElementRealTime from './components/ExtraElementRealTime';
import { RealTimeHeaderProps } from 'components/shared/CardWrapperChart/types';

const { UcBodyText, UcHeading } = Typography;

const RealTimeHeader: FC<RealTimeHeaderProps> = ({
  title,
  realTime,
  extraElement = null,
  count
}) => {
  return (
    <Row justify="space-between">
      <Col>
        <div className={styles.real_time_card_title_container}>
          <UcBodyText size="base">
            {realTime && <span className={styles.online_icon} />}
            {title}
          </UcBodyText>
          {count && (
            <UcHeading
              level={3}
              defaultMargin={false}
              className={styles.real_time_card_title_container_count}
            >
              {count}
            </UcHeading>
          )}
        </div>
      </Col>
      {extraElement && <Col>{extraElement}</Col>}
    </Row>
  );
};

export default Object.assign(RealTimeHeader, {
  ExtraElementRealTime
});
