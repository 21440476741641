export function dateDiff(date: Date) {
  const now = Date.now();

  const diffMs = now - new Date(date).valueOf(); // milliseconds between now & Christmas
  const diffDays = Math.floor(diffMs / 86400000); // days
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  if (diffDays > 0) {
    return `${diffDays} ${diffDays > 1 ? 'days' : 'day'} ago`;
  }

  if (diffHrs > 0) {
    return `${diffHrs} ${diffHrs > 1 ? 'hours' : 'hour'} ago`;
  }

  if (diffMins > 0) {
    return `${diffMins} ${diffMins > 1 ? 'minutes' : 'minute'} ago`;
  }

  return 'Just now';
}
