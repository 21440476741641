import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { Button, Space } from 'components/basic';
import { NotificationsDropdownRenderArgs } from 'components/global/Notifications/types';
import { ShowElement } from 'components/shared';

const notificationsDropdownRender = ({
  menu,
  markAllAsRead,
  markAllAsUnread,
  showActions,
  loadings,
  t
}: NotificationsDropdownRenderArgs) => {
  return (
    <div className={styles.notifications_dropdown_wrapper}>
      <ShowElement isShow={showActions}>
        <Space
          direction="horizontal"
          className={styles.dropdown_actions_wrapper}
          wrap
        >
          <Button
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Button,
              prefix: 'mark-read'
            })}
            disabled={loadings.unreadAll}
            loading={loadings.readAll}
            onClick={() => markAllAsRead()}
            type="link"
            size="small"
          >
            {t('messages:markAsRead', 'Mark all as read')}
          </Button>
          <Button
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Button,
              prefix: 'mark-unread'
            })}
            disabled={loadings.readAll}
            loading={loadings.unreadAll}
            onClick={() => markAllAsUnread()}
            type="link"
            size="small"
          >
            {t('messages:markAsUnread', 'Mark all as unread')}
          </Button>
        </Space>
      </ShowElement>
      {menu}
    </div>
  );
};

export default notificationsDropdownRender;
