import React, { FC } from 'react';
import { Affix, AffixProps } from 'antd';

interface Props extends AffixProps {}

const BaseAffix: FC<Props> = props => {
  return <Affix {...props} />;
};

const UcAffix = Object.assign(Affix, BaseAffix);

export default UcAffix;
