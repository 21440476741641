import React, { FC } from 'react';
import { Space, SpaceProps } from 'antd';

interface Props extends SpaceProps {}

const BaseSpace: FC<Props> = props => {
  return <Space {...props} />;
};

const UcSpace = Object.assign(BaseSpace, Space);

export default UcSpace;
