import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import { Typography } from 'components/basic';
import {
  HelpMenuItemType,
  IHelpMenuItemOptions
} from 'components/global/Help/types';

const { UcBodyText } = Typography;

const getHelpMenuItemLabel = (
  { url, type, key, disabled }: IHelpMenuItemOptions,
  t: TFunction
): ReactNode => {
  const label: Record<HelpMenuItemType, ReactNode> = {
    [HelpMenuItemType.LINK]: (
      <a
        key={key}
        href={url || ''}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'block' }}
      >
        <UcBodyText size="small">{t(key)}</UcBodyText>
      </a>
    ),

    [HelpMenuItemType.ACTION]: (
      <UcBodyText type={disabled ? 'secondary' : 'default'} size="small">
        {t(key)}
      </UcBodyText>
    ),
    [HelpMenuItemType.GROUP]: (
      <UcBodyText type="secondary" size="small">
        {t(key)}
      </UcBodyText>
    )
  };

  return label[type];
};

export default getHelpMenuItemLabel;
