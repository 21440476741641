import client from 'apollo/apolloClient';
import { fallbackImg } from 'core/constants/common';
import { useMedia } from 'hooks';
import { mediaImageFragment } from 'packages/Media/queries/mediaQuery';

import { mediaTypeMapping, type MediaTypeMapping } from './constants';

export const getMediaForRichText = (id: string, type: MediaTypeMapping) => {
  return client.readFragment({
    id: client.cache.identify({ id, __typename: mediaTypeMapping[type] }),
    fragment: mediaImageFragment
  });
};

export const getMediaUrl = (thumbnail: string, type: MediaTypeMapping) => {
  const { imagePath, videoPath } = useMedia();

  let path = imagePath;

  if (type === 'videos') {
    path = videoPath;
  }

  return thumbnail ? path + thumbnail : fallbackImg;
};
