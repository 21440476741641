import { FC } from 'react';
import Lottie from 'lottie-react';
import { isMobileDevice } from 'apollo/cache';
import { useReactiveVar } from '@apollo/client';

import styles from './styles.module.less';
import { LoaderProps } from './types';
import { LoaderView } from './constants';

const Loader: FC<LoaderProps> = ({
  webSize = 50,
  mobileSize = 25,
  loaderName = 'preLoader'
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderView[loaderName],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const style = {
    height: isMobile ? mobileSize : webSize,
    width: isMobile ? mobileSize : webSize
  };

  return (
    <div className={styles.loader_wrapper}>
      <Lottie {...defaultOptions} style={style} />
    </div>
  );
};

export default Loader;
