import { SwitchProps } from 'antd';
import { KeyValuePair } from 'core/globalTypes';
import { FC } from 'react';

import { Switch } from '..';

export interface TranslatableBaseSwitchProps
  extends Omit<SwitchProps, 'value' | 'onChange'> {
  locale?: string;
  value?: KeyValuePair<boolean>;
  onChange?: (v: KeyValuePair<boolean>) => void;
}

const TranslatableSwitch: FC<TranslatableBaseSwitchProps> = ({
  locale = '',
  value = {},
  onChange,
  ...restProps
}) => {
  const handleChange = (checked: boolean) => {
    onChange?.({ ...value, [locale]: checked });
  };

  return (
    <Switch onChange={handleChange} checked={value[locale]} {...restProps} />
  );
};

export default TranslatableSwitch;
