import { FC, useEffect, useState } from 'react';
import { NiladicVoid, PureNullable, Undefinable } from 'core/globalTypes';
import {
  MediaFile,
  useUpdateMediaFileDataMutation
} from 'generatedHooks/commerce/generated';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Input, Modal } from 'components/basic';
import Form from 'components/core/form';

const { useForm } = Form;
const { TextArea } = Input;

interface Props {
  open: boolean;
  onCancel: NiladicVoid;
  file: Undefinable<MediaFile>;
  onChange?: (value: PureNullable<MediaFile | MediaFile[]>) => void;
  value?: PureNullable<MediaFile | MediaFile[]>;
}

const MediaInfoModal: FC<Props> = ({
  open,
  onCancel,
  file,
  onChange,
  value
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const [updateMediaFileData, { loading }] = useUpdateMediaFileDataMutation();

  useEffect(() => {
    form.setFieldsValue(file);
  }, [file]);

  const saveMediaFileData = async () => {
    const input = form.getFieldsValue();
    input.mediaFileId = file?.id;

    const data = await updateMediaFileData({
      variables: {
        input
      }
    });

    const newValue = data.data?.updateMediaFileData;

    if (Array.isArray(value)) {
      onChange?.(
        value?.map(mediaFile => {
          if (isEqual(mediaFile.id, file?.id)) {
            return { ...newValue, order: file?.order } as MediaFile;
          }

          return mediaFile;
        })
      );
    } else {
      onChange?.(newValue as MediaFile);
    }

    onCancel();
  };

  const onFieldsChange = () => {
    const values = form.getFieldsValue();
    setIsSaveButtonDisabled(!Object.values(values).some(Boolean));
  };

  return (
    <Modal
      okText={t('common:save')}
      okButtonProps={{
        loading,
        disabled: isSaveButtonDisabled
      }}
      onOk={saveMediaFileData}
      title={t('common:settings')}
      onCancel={onCancel}
      open={open}
      centered
    >
      <Form onFieldsChange={onFieldsChange} layout="vertical" form={form}>
        <Form.Item label={t('common:title')} name="title">
          <Input
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Input,
              prefix: 'title'
            })}
          />
        </Form.Item>
        <Form.Item label={t('common:description')} name="description">
          <TextArea
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.TextArea,
              prefix: 'description'
            })}
          />
        </Form.Item>
        <Form.Item label={t('common:alt')} name="alt">
          <Input
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Input,
              prefix: 'alt'
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MediaInfoModal;
