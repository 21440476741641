import { Select } from 'antd';
import { OptionProps } from 'antd/es/mentions';
import { FC } from 'react';

const { Option: AntOption } = Select;

interface BaseSelectOptionProps extends OptionProps {}

const Option: FC<BaseSelectOptionProps> = props => {
  return <AntOption {...props} />;
};

export default Option;
