import client from 'apollo/apolloClient';
import { gql } from '@apollo/client';

import { OrderingMenu } from 'hooks/useSortParams';
import { MediaType } from './__generated__/types';

const pathNames = [
  'media_image',
  'media_image_thumb',
  'media_original_image',
  'media_audio',
  'media_audio_thumb',
  'media_video',
  'media_video_thumb',
  'media_doc',
  'media_doc_thumb',
  'media_vector',
  'media_vector_path'
];

const configs = pathNames.reduce(
  (configsPaths: { [name: string]: string }, currentPathName: string) => {
    const config = client.readFragment({
      id: `Setting:${currentPathName}`,
      fragment: gql`
        fragment setting on Setting {
          value
        }
      `
    });

    return { ...configsPaths, [currentPathName]: config?.value };
  },
  {}
);

export const imagePath = `${configs.media_image}/`;
export const imageThumbnailPath = `${configs.media_image_thumb}/`;
export const vectorPath = `${configs.media_vector}/`;
export const vectorThumbnailPath = `${configs.media_vector_thumb}/`;
export const videoPath = `${configs.media_video}/`;
export const videoThumbnailPath = `${configs.media_video_thumb}/`;
export const audioPath = `${configs.media_audio}/`;
export const audioThumbnailPath = `${configs.media_audio_thumb}/`;
export const docsPath = `${configs.media_doc}/`;
export const docsThumbnailPath = `${configs.media_doc_thumb}/`;

// export const downloadPath = `${import.meta.env.VITE_APP_SERVER_URL}/media/download/`;
//
// export const ROOT_FOLDER_ID = '__ROOT__';

export const mediaTypeMapper = {
  images: MediaType.IMAGE,
  audios: MediaType.AUDIO,
  videos: MediaType.VIDEO,
  docs: MediaType.DOCUMENT,
  vectors: MediaType.VECTOR
};

export const mediaTypeAcceptFormats = {
  images: '.jpeg,.jpg,.png,.tif,.webp,.gif',
  audios: '.mp3,.ogg,.wav',
  videos: '.mp4,.webm,.ogg',
  docs: '.doc,.docx,.pdf,.txt',
  vectors: '.svg'
};

export const mediaSizeLimitations = {
  images: 5242880,
  audios: 20971520,
  videos: 41943040,
  docs: 10485760,
  vectors: 5242880
};

export const freeStockImageCategories = [
  'nature',
  'car',
  'party',
  'sea',
  'tree',
  'design'
];

export const orderingMenus: OrderingMenu[] = [
  { title: 'common:sortNew', order: 'DESC', column: 'CREATED_AT' },
  { title: 'common:sortOld', order: 'ASC', column: 'CREATED_AT' },
  { title: 'largestFirst', order: 'DESC', column: 'SIZE' },
  { title: 'smallestFirst', order: 'ASC', column: 'SIZE' },
  { title: 'common:sortAToZ', order: 'ASC', column: 'NAME' },
  { title: 'common:sortZToA', order: 'DESC', column: 'NAME' }
];

export const thumbnailPath = {
  images: imageThumbnailPath,
  videos: videoThumbnailPath,
  docs: docsThumbnailPath,
  audios: audioThumbnailPath,
  IMAGE: imageThumbnailPath,
  VIDEO: videoThumbnailPath,
  DOCUMENT: docsThumbnailPath,
  AUDIO: audioThumbnailPath
};
