import { useInsertionEffect, useState } from 'react';
import { SCREEN_SIZES } from 'core/constants/common';

const gteOrEqWindowWidth = (size: number) => window.innerWidth >= size;

const getBreakpoints = () => ({
  xs: window.innerWidth < SCREEN_SIZES.sm,
  sm: gteOrEqWindowWidth(SCREEN_SIZES.sm),
  md: gteOrEqWindowWidth(SCREEN_SIZES.md),
  lg: gteOrEqWindowWidth(SCREEN_SIZES.lg),
  xl: gteOrEqWindowWidth(SCREEN_SIZES.xl)
});

const useScreenBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState(getBreakpoints);

  const onResize = () => setBreakpoints(getBreakpoints());

  useInsertionEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return breakpoints;
};

export default useScreenBreakpoints;
