import { DASHBOARD_ROUTES_PERMISSIONS } from 'core/constants/common';
import { useCheckPermissions } from 'providers/PermissionAndLimitationProvider';
import { useLayoutEffect, useReducer } from 'react';

import { ActionTypes } from './types';
import reducer from './reducer';
import { initialState } from './constants';

const useSidebarAvailableRoutes = () => {
  const dashboardSiderAvailableRoutes = useCheckPermissions(
    DASHBOARD_ROUTES_PERMISSIONS
  );

  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);

  useLayoutEffect(() => {
    const noAnyAccess = Object.values(dashboardSiderAvailableRoutes).every(
      permission => !permission
    );

    dispatch({
      type: ActionTypes.Init,
      payload: {
        dashboardSiderAvailableRoutes,
        noAnyAccess
      }
    });
  }, [dashboardSiderAvailableRoutes]);

  return state;
};

export default useSidebarAvailableRoutes;
