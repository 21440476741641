import { FC } from 'react';
import { Select as AntSelect, SelectProps } from 'antd';

import Option from './components/Option';
import styles from './styles.module.less';

interface Props extends SelectProps<any> {}

const Select: FC<Props> = ({ className = '', ...res }) => {
  return <AntSelect className={`${className} ${styles.select}`} {...res} />;
};

export default Object.assign(Select, AntSelect, {
  Option: Option
});
