import { io, Socket } from 'socket.io-client';

export default class SocketService {
  static instance: SocketService | null = null;
  socket: null | Socket<any, any> = null;
  joinedRooms: string[] = [];

  constructor(url: string, projectName: string) {
    if (SocketService.instance) {
      return SocketService.instance;
    }

    this.socket = io(url, {
      transports: ['websocket'],
      path: '/',
      query: {
        project: projectName
      },
      auth: {
        token: localStorage.token
      }
    });
    SocketService.instance = this;
  }

  emit = (e: any, ...res: Array<any>) => {
    this.socket?.emit(e, ...res);
  };

  on = (ev: any, listener: any) => {
    this.socket?.on(ev, listener);
  };

  join = (roomName: string) => {
    if (!this.joinedRooms.includes(roomName)) {
      this.socket?.emit('join', roomName);
      this.joinedRooms.push(roomName);
    }
  };

  joinAndOn = (roomName: string, ev: any, listener: any) => {
    this.on(ev, listener);
    this.join(roomName);
  };

  leave = (roomName: string) => {
    if (this.joinedRooms.includes(roomName)) {
      this.socket?.emit('leave', roomName);
      this.joinedRooms = this.joinedRooms.filter(rn => rn !== roomName);
    }
  };

  disconnect = () => {
    this.socket?.disconnect();
    this.socket = null;
    this.joinedRooms = [];
    SocketService.instance = null;
  };

  off = (...res: Array<any>) => {
    this.socket?.off(...res);
  };
}
