import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import i18n from 'i18next';
import { ReactComponent as Images } from 'assets/icons/images.svg';
import Icon, {
  DatabaseOutlined,
  FileImageOutlined,
  LinkOutlined
} from '@ant-design/icons';
import FileInputRef from 'packages/Media/UIComponents/FileInputRef';
import { mediaTypeAcceptFormats } from 'packages/Media//constants';

import { MediaWithFoldersData } from 'pages/products/products/Mobile/types';

export type MediaFor =
  | 'categories'
  | 'products'
  | 'settings'
  | 'attributes'
  | 'takePhoto';

export type MediaTypes = { [key: string | MediaFor]: ReactNode };

export type FcProps = {
  mediaFor?: MediaFor;
  setMediaImagesId: (ids: string[] | number[] | null) => void;
  mergeMediaImagesId?: (ids: string[] | number[] | null) => void;
  mediaIds: string[];
  initialDefaultMedialId?: number | null;
  setInitialDefaultMediaId?: Dispatch<SetStateAction<number | null>>;
  forMobile: boolean;
  productUrlPrefix?: string;
};

export const mediaUploadTypes = {
  mediaManager: 'mediaManager',
  uploadPopup: 'uploadPopup',
  cameraRoll: 'cameraRoll',
  imageLink: 'imageLink',
  freeStock: 'freeStock'
};

export enum popupTypes {
  mediaPopup = 'mediaPopup',
  uploadPopup = 'uploadPopup',
  mediaManager = 'mediaManager',
  cameraRoll = 'cameraRoll',
  imageLink = 'imageLink',
  freeStock = 'freeStock'
}

export const linkedTypes = {
  crossSells: 'crossSells',
  upSells: 'upSells',
  relatedProducts: 'relatedProducts'
} as const;

export type linkedArgTypes = keyof typeof linkedTypes;

export enum linkedProductPopupTypes {
  addLinkedProduct = 'addLinkedProduct'
}

export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export interface CarouselData {
  id: string;
  description: string;
  original: string;
  thumbnail: string;
}

export const mediaManagerData = (
  handleClick: (key: string | number) => void,
  iconClassName?: string
) => {
  return [
    {
      key: mediaUploadTypes.cameraRoll,
      text: i18n.t('products:cameraRoll'),
      icon: <FileImageOutlined className={iconClassName} />,
      handleClick
    },
    {
      key: mediaUploadTypes.imageLink,
      text: i18n.t('products:imageLink'),
      icon: <LinkOutlined className={iconClassName} />,
      handleClick
    },
    {
      key: mediaUploadTypes.freeStock,
      text: i18n.t('products:freeStock'),
      icon: <DatabaseOutlined className={iconClassName} />,
      handleClick
    }
  ];
};

export const listData = (
  handleClick: (arg: string | number) => void,
  iconClassName?: string
) => {
  return [
    {
      key: mediaUploadTypes.mediaManager,
      title: i18n.t('products:selectMediaManager'),
      icon: <FileImageOutlined className={iconClassName} />,
      handleClick: () => handleClick(mediaUploadTypes.mediaManager),
      render: function (listTitleClassName?: string) {
        return (
          <div>
            {this.icon}
            <span className={listTitleClassName}>{this.title}</span>
          </div>
        );
      }
    },
    {
      key: mediaUploadTypes.cameraRoll,
      title: i18n.t('products:cameraRoll'),
      icon: <Icon component={Images} className={iconClassName} />,
      handleClick: () => handleClick(mediaUploadTypes.cameraRoll),
      render: function (listTitleClassName?: string, onUploadMedia?: any) {
        return (
          <div>
            {this.icon}
            <label htmlFor="cameraRoll">
              <span className={listTitleClassName}>{this.title}</span>
            </label>
            <FileInputRef
              htmlFor={'cameraRoll'}
              multiple
              accept={mediaTypeAcceptFormats.images}
              hidden
              type={'file'}
              onChange={onUploadMedia}
            />
          </div>
        );
      }
    },
    {
      key: mediaUploadTypes.imageLink,
      title: i18n.t('products:imageLink'),
      icon: <LinkOutlined className={iconClassName} />,
      handleClick: () => handleClick(mediaUploadTypes.imageLink),
      render: function (listTitleClassName?: string) {
        return (
          <div>
            {this.icon}
            <span className={listTitleClassName}>{this.title}</span>
          </div>
        );
      }
    },
    {
      key: mediaUploadTypes.freeStock,
      title: i18n.t('products:freeStock'),
      icon: <DatabaseOutlined className={iconClassName} />,
      handleClick: () => handleClick(mediaUploadTypes.freeStock),
      render: function (listTitleClassName?: string) {
        return (
          <div>
            {this.icon}
            <span className={listTitleClassName}>{this.title}</span>
          </div>
        );
      }
    }
  ];
};

export const convertCarouselData = (arr: Array<MediaWithFoldersData>) =>
  arr.map(item => ({
    ...item,
    id: item.id,
    description: item.name,
    original: item.filename,
    thumbnail: item.thumbnail
  }));
