import { FC, MouseEventHandler } from 'react';

import { ChatIcon } from 'components/shared/SVG';
import { HeaderButton } from 'components/shared';

interface ChatButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

const ChatButton: FC<ChatButtonProps> = ({ onClick, disabled }) => {
  return (
    <HeaderButton
      disabled={disabled}
      onClick={onClick}
      icon={<ChatIcon />}
      dataTestPrefix="chat"
    />
  );
};

export default ChatButton;
