import { ImportableEntity } from '../types';

const getInitialStep = (
  entityType: ImportableEntity,
  isOpenFromNotifications: boolean | undefined
): number => {
  if (!isOpenFromNotifications) {
    return 0;
  }

  if (entityType !== 'Product') {
    return 3;
  }

  return 4;
};

export default getInitialStep;
