import { FC, useMemo } from 'react';
import { Skeleton } from 'antd-mobile';

import { Typography } from 'components/basic';
import { HeadingProps } from './types';
import styles from './styles.module.less';
import { HeadingTypes } from 'components/basic/Typography/types.d';
import { Suspensor } from 'components/shared';

const { UcHeading } = Typography;

const Heading: FC<HeadingProps> = ({
  title,
  className = '',
  headingTypes = HeadingTypes.h4,
  actions,
  loading = false
}) => {
  const titleContent = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <UcHeading defaultMargin={false} level={headingTypes} fontWeight="bold">
          {title}
        </UcHeading>
      );
    }

    return title;
  }, [title]);

  return (
    <div className={`${styles.heading} ${className}`}>
      <Suspensor loading={loading} fallback={<Skeleton.Title animated />}>
        <>
          {titleContent}
          {actions && <div className={styles.action_content}>{actions}</div>}
        </>
      </Suspensor>
    </div>
  );
};

export default Heading;
