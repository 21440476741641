import { useContext } from 'react';
import { PermissionsAndLimitationsContextProps } from 'providers/PermissionAndLimitationProvider/context/types';

import PermissionsAndLimitationsContext from '../context';

const usePermissionAndLimitationContext = () => {
  const context = useContext<PermissionsAndLimitationsContextProps>(
    PermissionsAndLimitationsContext
  );

  if (context === undefined) {
    throw new Error(
      'usePermissionAndLimitationContext must be used within a PermissionAndLimitationProvider'
    );
  }

  return context;
};

export default usePermissionAndLimitationContext;
