import { FC } from 'react';

import Title from './Title';
import Action from './Action';
import SubText from './SubText';
import { ActionBarBaseProps } from './types';
import styles from './style.module.less';

const ActionbarBase: FC<ActionBarBaseProps> = ({ leftBase, rightBase }) => {
  return (
    <div className={styles.actionbarBase}>
      <div className={styles.leftBase}>{leftBase}</div>

      <div className={styles.rightBase}>{rightBase}</div>
    </div>
  );
};

export default Object.assign(ActionbarBase, { Title, SubText, Action });
