export const requestPermissionMutation = `
  mutation requestPermission($input: RequestPermissionInput!) {
    requestPermission(input: $input) {
      id
      note
      createdAt
    }
  }
`;

export const requestPermissionsStoryMutation = `
  query requestedPermissions($input: RequestedPermissionsInput!) {
    requestedPermissions(input: $input) {
      id
      name
      permissionRequest {
        id
        note
        createdAt
      }
    }
  }
`;
