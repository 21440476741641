import kebabCase from 'lodash/kebabCase';
import { ReactNode } from 'react';

import { DataTestAttributeCreatorArgs, DataTestAttributes } from './types';

export const createDataTestAttribute = ({
  dataTestAttribute,
  prefix = '',
  suffix = ''
}: DataTestAttributeCreatorArgs) => {
  return `${prefix ? `${kebabCase(prefix)}-` : ''}${dataTestAttribute}${suffix ? `-${suffix}` : ''}`;
};

export const modalConfirmRenderWithDataTest = (node: ReactNode) => (
  <div
    data-test={createDataTestAttribute({
      dataTestAttribute: DataTestAttributes.Popup,
      prefix: 'confirm'
    })}
  >
    {node}
  </div>
);
