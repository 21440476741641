import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from 'generatedHooks/accounts/generated';
import { mobileMockupSrc, mockImageSrc } from 'core/mocks/projects';
import { useFilterQuery } from 'hooks';
import { StateDispatcher } from 'core/globalTypes';

import PageTitle from 'components/mobile/pageTitle';
import Card from './components/card';
import styles from './styles.module.less';
import { popupTypes } from 'pages/projects/types';
import FilterPopup from './components/filter';
import HeaderContent from 'components/mobile/Header/Content';
import { ShowElement } from 'components/shared';
import { CardVew, PopupModel } from './utils';
import { Heading, ImgIcon, Search } from 'components/mobile';
import Skeleton from './components/Skeleton';
import { Typography } from 'components/basic';
import DeleteProjectPopup from 'pages/projects/Mobile/components/deleteProjectPopup';
import NotificationSettingsModal from '../components/NotificationSettingsModal';

interface Props {
  loading: boolean;
  showDashboard?: true;
  projects: Array<Project>;
  onScroll: (e: any) => void;
  isOpenedDashboard?: boolean;
  searchValue: string | undefined;
  setSearchValue: StateDispatcher<string>;
}

const { UcBodyText } = Typography;

const Mobile: FC<Props> = ({
  projects,
  onScroll,
  loading,
  isOpenedDashboard,
  searchValue,
  setSearchValue
}) => {
  const { queryObject } = useFilterQuery();

  const { t } = useTranslation(['projects', 'common', 'menu']);
  const [gridViewType, setGridViewType] = useState<CardVew>(CardVew.list);
  const projectsCount = projects.length;
  const isShowGridView = gridViewType === CardVew.list;
  const [openedPopup, setOpenPopup] = useState<PopupModel>({
    name: '',
    options: {}
  });

  const [notificationSettingsModalProps, setNotificationSettingsModalProps] =
    useState({
      projectId: '',
      projectClientName: '',
      open: false
    });

  const gridIcon =
    gridViewType === CardVew.grid ? 'appstoreOutlinedIcon' : 'combinedShape';

  useEffect(() => {
    return () => {
      setGridViewType(CardVew.list);
    };
  }, []);

  const handleChangeGridViewType = () => {
    setGridViewType(
      gridViewType === CardVew.grid ? CardVew.list : CardVew.grid
    );
  };

  const handleAddProject = () => {
    window.open(
      `${import.meta.env.VITE_APP_SSO_URL}/callback?target=onboarding-start`
    );
  };

  const handleOpenFilterPopup = () => {
    setOpenPopup({
      name: popupTypes.filters,
      options: {}
    });
  };

  const closePopup = () => {
    setOpenPopup({ name: '', options: {} });
  };

  const deleteProjectHandler = (id: string, name: string) => {
    setOpenPopup({
      name: popupTypes.delete,
      options: {
        id,
        name
      }
    });
  };

  return (
    <>
      <ShowElement isShow={!isOpenedDashboard}>
        <HeaderContent position="center">
          <PageTitle fontWeight="medium" size="small">
            {t('myProject', 'My Projects')}
          </PageTitle>
        </HeaderContent>

        <HeaderContent position="right">
          <ImgIcon
            icon="plusIcon"
            paddingType="big"
            onClick={handleAddProject}
          />
        </HeaderContent>
      </ShowElement>

      <FilterPopup
        onClose={closePopup}
        visible={openedPopup.name === popupTypes.filters}
      />

      <DeleteProjectPopup
        onClose={closePopup}
        options={openedPopup.options}
        visible={openedPopup.name === popupTypes.delete}
      />

      <div className={styles.mayProjectContainer}>
        <Search
          placeholder={t('common:search', 'Search')}
          syncWithQueryParams={!isOpenedDashboard}
          {...(isOpenedDashboard
            ? {
                value: searchValue,
                onChange: value => setSearchValue(value)
              }
            : {})}
        />

        <Heading
          title={
            <UcBodyText size="base" fontWeight="medium">
              {t('projects:projects', 'Projects')} ({projectsCount})
            </UcBodyText>
          }
          actions={[
            <ImgIcon
              key="1"
              paddingType="big"
              icon={gridIcon}
              onClick={handleChangeGridViewType}
            />,
            <ImgIcon
              key="2"
              badge={queryObject.sort}
              icon="filterIcon"
              paddingType="big"
              onClick={handleOpenFilterPopup}
            />
          ]}
        />

        <div onScroll={onScroll} className={styles.myProjectCardList}>
          <Skeleton cardCount={5} loading={loading}>
            {projects.map(project => (
              <Card
                project={project}
                key={project.id}
                cardImgSrc={mockImageSrc}
                showCardImg={isShowGridView}
                mobileMockupSrc={mobileMockupSrc}
                isOpenedDashboard={isOpenedDashboard}
                deleteProjectHandler={deleteProjectHandler}
                openNotificationsSettingsModal={(
                  projectId,
                  projectClientName
                ) =>
                  setNotificationSettingsModalProps({
                    open: true,
                    projectId,
                    projectClientName
                  })
                }
              />
            ))}
          </Skeleton>
        </div>
      </div>
      <NotificationSettingsModal
        open={notificationSettingsModalProps.open}
        projectId={notificationSettingsModalProps.projectId}
        projectClientName={notificationSettingsModalProps.projectClientName}
        onClose={() =>
          setNotificationSettingsModalProps({
            open: false,
            projectId: '',
            projectClientName: ''
          })
        }
      />
    </>
  );
};

export default Mobile;
