const themes = {
  Dark: {
    fromMode: 'light-mode',
    mode: 'dark-mode',
    themeContent: '#222222'
  },
  Light: {
    fromMode: 'dark-mode',
    mode: 'light-mode',
    themeContent: '#fefefe'
  }
};

function setupIcons() {
  const linkIconTags = document.querySelectorAll('link[data-for="theme"]');

  const themeMetaTag = document.querySelector('meta[name="theme-color"]')!;

  const replace = (from: string, to: string) => {
    linkIconTags.forEach(iconTag => {
      const icon = iconTag as HTMLLinkElement;
      icon.href = icon.href.replace(from, to);
    });
  };

  const setTheme = (theme: (typeof themes)[keyof typeof themes]) => {
    replace(theme.fromMode, theme.mode);
    (themeMetaTag as HTMLMetaElement).content = theme.themeContent;
  };

  const matcher = window.matchMedia('(prefers-color-scheme:dark)');

  function onUpdate() {
    setTheme(matcher.matches ? themes.Dark : themes.Light);
  }

  matcher.addListener(onUpdate);
  onUpdate();
}

export default setupIcons;
