//@ts-nocheck
import axios, { AxiosError } from 'axios';

import { KeyValuePair } from '../core/globalTypes';

const {
  VITE_APP_REMOTE_HOST,
  VITE_APP_SSO_URL,
  VITE_APP_CONTENT_GENERATOR_API,
  VITE_APP_UC_X_API_KEY
} = import.meta.env;

type FieldsData = {
  name: string;
  errors: string[];
};

export const getFieldsDataFromServerError = errors => {
  const error = Object.keys(errors).reduce(
    (acc, key) => [...acc, { name: key, errors: errors[key] }],
    []
  );

  return error as FieldsData[];
};

const getHeaders = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem('token')}`
  };
};

export const errorsHandler = (error: AxiosError<any>) => {
  const { response } = error;
  const errorData = response?.data;

  return new Promise((_, reject) => {
    if (errorData) {
      if (errorData.errors) {
        const errors = getFieldsDataFromServerError(errorData.errors);

        return reject({ ...errorData, errors });
      }

      return reject(errorData);
    }

    return reject(error);
  });
};

const axiosApi = axios.create({
  withCredentials: true,
  baseURL: VITE_APP_SSO_URL
});

export const AiApi = axios.create({
  baseURL: VITE_APP_CONTENT_GENERATOR_API
});

AiApi.interceptors.request.use(config => {
  const headers: KeyValuePair<string> = {
    'X-UC-API-KEY': VITE_APP_UC_X_API_KEY
  };

  Object.assign(config.headers, headers);

  return config;
});

AiApi.interceptors.response.use(response => response, errorsHandler);

axiosApi.interceptors.request.use(config => {
  const headers: KeyValuePair<string> = getHeaders();

  const projectName = localStorage.getItem('projectName');

  if (!location.host.includes('localhost')) {
    headers['remote-host'] = `${projectName}.${VITE_APP_REMOTE_HOST}`;
  }

  Object.assign(config.headers, headers);

  return config;
});

axiosApi.interceptors.response.use(response => response, errorsHandler);

export default axiosApi;
