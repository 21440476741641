import { KeyValuePair } from 'core/globalTypes';

export enum ActionTypes {
  Init = 'INIT',
  SetDashboardSidebarAvailableRoutes = 'SET_DASHBOARD_SIDEBAR_AVAILABLE_ROUTES',
  SetNoAnyAccess = 'SET_NO_ANY_ACCESS'
}

export interface State {
  dashboardSiderAvailableRoutes: KeyValuePair<boolean>;
  noAnyAccess: boolean;
}

export type Action =
  | {
      type: ActionTypes.Init;
      payload: State;
    }
  | {
      type: ActionTypes.SetDashboardSidebarAvailableRoutes;
      payload: State['dashboardSiderAvailableRoutes'];
    }
  | {
      type: ActionTypes.SetNoAnyAccess;
      payload: State['noAnyAccess'];
    };
