import { FC } from 'react';

interface Props {
  active: boolean;
}

const LeftRightArrowIcon: FC<Props> = ({ active }) => {
  return (
    <svg
      width="34"
      height="46"
      viewBox="0 0 34 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {active ? (
        <path
          d="M0.646447 22.6464C0.451184 22.8417 0.451184 23.1583 0.646447 23.3536L3.82843 26.5355C4.02369 26.7308 4.34027 26.7308 4.53553 26.5355C4.7308 26.3403 4.7308 26.0237 4.53553 25.8284L1.70711 23L4.53553 20.1716C4.7308 19.9763 4.7308 19.6597 4.53553 19.4645C4.34027 19.2692 4.02369 19.2692 3.82843 19.4645L0.646447 22.6464ZM33.3536 23.3536C33.5488 23.1583 33.5488 22.8417 33.3536 22.6464L30.1716 19.4645C29.9763 19.2692 29.6597 19.2692 29.4645 19.4645C29.2692 19.6597 29.2692 19.9763 29.4645 20.1716L32.2929 23L29.4645 25.8284C29.2692 26.0237 29.2692 26.3403 29.4645 26.5355C29.6597 26.7308 29.9763 26.7308 30.1716 26.5355L33.3536 23.3536ZM1 23.5H33V22.5H1V23.5Z"
          fill="#235DF4"
        />
      ) : (
        <path
          d="M0.646447 22.6466C0.451184 22.8418 0.451184 23.1584 0.646447 23.3537L3.82843 26.5357C4.02369 26.7309 4.34027 26.7309 4.53553 26.5357C4.7308 26.3404 4.7308 26.0238 4.53553 25.8285L1.70711 23.0001L4.53553 20.1717C4.7308 19.9764 4.7308 19.6599 4.53553 19.4646C4.34027 19.2693 4.02369 19.2693 3.82843 19.4646L0.646447 22.6466ZM33.3536 23.3537C33.5488 23.1584 33.5488 22.8418 33.3536 22.6466L30.1716 19.4646C29.9763 19.2693 29.6597 19.2693 29.4645 19.4646C29.2692 19.6599 29.2692 19.9764 29.4645 20.1717L32.2929 23.0001L29.4645 25.8285C29.2692 26.0238 29.2692 26.3404 29.4645 26.5357C29.6597 26.7309 29.9763 26.7309 30.1716 26.5357L33.3536 23.3537ZM1 23.5001H33V22.5001H1V23.5001Z"
          fill="#d9d9d9"
        />
      )}
    </svg>
  );
};

export default LeftRightArrowIcon;
