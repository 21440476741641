import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ThunderboltFilled } from '@ant-design/icons';

import { UpgradePackageProps } from './types';

const { Text } = Typography;

export const UpgradePackage: FC<UpgradePackageProps> = ({
  title,
  upgradeHandler
}) => {
  const { t } = useTranslation('permissions-and-limitations');

  return (
    <>
      <Text style={{ marginBottom: '16px', color: '#595959' }}>{title}</Text>
      <Button
        icon={<ThunderboltFilled style={{ color: '#fadb14' }} />}
        onClick={e => {
          e.stopPropagation();

          upgradeHandler();
        }}
        type={'primary'}
      >
        {t('upgrade', 'Upgrade')}
      </Button>
    </>
  );
};
