import {
  createDataTestAttribute,
  modalConfirmRenderWithDataTest
} from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import { TFunction } from 'react-i18next';
import { NiladicVoid } from 'core/globalTypes';
import { HookAPI } from 'antd/lib/modal/useModal';

const goToVisualEditorConfirmation = ({
  t,
  onOk,
  confirm
}: {
  t: TFunction;
  confirm: HookAPI['confirm'];
  onOk: NiladicVoid;
}) => {
  confirm({
    title: t('channels:enableVisualEditor'),
    content: t('channels:quickReminder'),
    okText: t('common:enable', 'Enable'),
    centered: true,
    onOk,
    cancelButtonProps: {
      'data-test': createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: 'cancel'
      })
    },
    okButtonProps: {
      danger: true,
      'data-test': createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: 'confirm'
      })
    },
    modalRender: modalConfirmRenderWithDataTest
  });
};

export default goToVisualEditorConfirmation;
