import { Space } from 'antd';

import { Skeleton as InputSkeleton } from 'components/shared';
import styles from './styles.module.less';

const CodeInjectionLazySkeleton = () => {
  return (
    <Space
      size={'middle'}
      className={styles.shipping_skeleton}
      direction={'vertical'}
    >
      <InputSkeleton.Input height={60} />
      <InputSkeleton.Input height={60} />
      <InputSkeleton.Input height={60} />
      <InputSkeleton.Input height={60} />
      <InputSkeleton.Input height={60} />
    </Space>
  );
};

export default CodeInjectionLazySkeleton;
