import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import MediaForProduct from 'packages/MediaUpload/MediaForProduct';
import { mediaFileFragment } from 'packages/Media/queries/mediaQuery';
import UploadFromMedia from 'packages/Media/UploadFromMedia';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Typography } from 'components/basic';
import styles from '../styles.module.less';
import { UploadIcon } from 'components/shared/SVG';
import useMedia from 'hooks/useMedia';

export type MediaFor =
  | 'categories'
  | 'products'
  | 'settings'
  | 'attributes'
  | 'variants';

interface Props {
  setIds: (ids: number[] | null) => void;
  mediaFor: MediaFor;
  ids?: number[] | null;
  setDefaultMediaId?: (
    id: number | undefined | null
  ) => void | Dispatch<SetStateAction<number | null>>;
  defaultMediaId?: number | null;
  setInitialMediaIds?: any;
  initialMediaIds?: number[] | null;
  deleteHandler?: () => Promise<void>;
  hasDefault?: boolean;
}

const MediaUpload = ({
  setIds,
  mediaFor,
  initialMediaIds,
  setDefaultMediaId,
  defaultMediaId,
  deleteHandler,
  ids,
  hasDefault = true
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isMediaSelected, setIsMediaSelected] = useState<boolean>(false);
  const [previewMedia, setPreviewMedia] = useState<any[]>([]);
  const { thumbnailPath } = useMedia();

  useEffect(() => {
    if (ids && ids.length) {
      setPreviewMedia(ids);
    } else {
      setPreviewMedia([]);
      setIsMediaSelected(false);
    }
  }, [ids]);

  useEffect(() => {
    if (initialMediaIds && initialMediaIds.length) {
      setIsMediaSelected(true);
    } else {
      setIsMediaSelected(false);
    }
  }, [initialMediaIds]);

  const client = useApolloClient();

  const { t } = useTranslation('media');

  function getMediaById(id: string) {
    return client.readFragment({
      id: client.cache.identify({ id, __typename: 'Image' }),
      fragment: mediaFileFragment
    });
  }

  function findWrapperStyle() {
    switch (mediaFor) {
      case 'categories':
      case 'products':
        return isMediaSelected
          ? styles.categoriesWithImage
          : styles.categoriesWithNoImage;
      case 'settings':
        return isMediaSelected
          ? styles.settingsWithImage
          : styles.settingsWithNoImage;
      case 'attributes':
        return isMediaSelected
          ? styles.attributesWithImage
          : styles.attributesWithNoImage;
      case 'variants':
        return styles.variants;
      default:
        return;
    }
  }

  if (mediaFor === 'products') {
    return (
      <MediaForProduct
        isMediaSelected={isMediaSelected}
        getMediaById={getMediaById}
        previewMedia={previewMedia}
        visible={visible}
        setVisible={setVisible}
        setIsMediaSelected={setIsMediaSelected}
        setPreviewMedia={setPreviewMedia}
        setIds={setIds}
        setDefaultMediaId={setDefaultMediaId}
        defaultMediaId={defaultMediaId}
        hasDefault={hasDefault}
        initialMediaIds={ids}
        // setInitialMediaIds={setInitMediaIds}
      />
    );
  }

  return (
    <div
      className={findWrapperStyle()}
      style={
        isMediaSelected
          ? {
              backgroundImage: `url(${
                thumbnailPath['images'] +
                getMediaById(
                  previewMedia.length ? previewMedia[0] : initialMediaIds?.[0]
                )?.thumbnail
              })`,
              backgroundPosition: 'center',
              backgroundSize: mediaFor === 'variants' ? 'cover' : 'contain'
            }
          : {}
      }
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Icon,
        prefix: 'upload'
      })}
    >
      <div
        onClick={() => {
          if (!visible) {
            setVisible(true);
          }
        }}
        className={styles.filter}
      />
      <div
        className={styles.iconContainer}
        style={
          mediaFor === 'settings'
            ? { flexDirection: 'column', zIndex: isMediaSelected ? 103 : 99 }
            : { zIndex: isMediaSelected ? 103 : 99 }
        }
      >
        {isMediaSelected ? (
          mediaFor !== 'attributes' &&
          mediaFor !== 'variants' && (
            <DeleteOutlined
              className={styles.delete_outlined}
              onClick={async e => {
                e.stopPropagation();

                if (deleteHandler) {
                  await deleteHandler();
                  setIsMediaSelected(false);
                  setIds([]);

                  return;
                }

                setIds([]);
                setIsMediaSelected(false);
              }}
              data-test={createDataTestAttribute({
                dataTestAttribute: DataTestAttributes.Button,
                prefix: 'delete'
              })}
            />
          )
        ) : (
          <div
            className={styles.plusAndUplad}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div>
              <UploadIcon />
            </div>
            <div>
              <Typography.Text>
                {mediaFor === 'settings' ? t('upload', 'Upload') : null}
              </Typography.Text>
            </div>
          </div>
        )}
      </div>
      <UploadFromMedia
        visible={visible}
        types={['images', 'vectors']}
        onCancel={() => setVisible(false)}
        initialIds={ids ? ids.map(String) : []}
        onInsert={ids => {
          setVisible(false);
          setIsMediaSelected(true);
          setPreviewMedia(ids);
          setIds(ids.map(id => Number(id)));
        }}
      />
    </div>
  );
};

export default MediaUpload;
