import { KeyValuePair } from 'core/globalTypes';
import { useMemo } from 'react';

import useQuicklyCheckPermissionAndLimitation from './useQuicklyCheckPermissionAndLimitation';

const useCheckPermissions = (aliases: Array<string>) => {
  const { checkPermission } = useQuicklyCheckPermissionAndLimitation();

  return useMemo(() => {
    return aliases.reduce(
      (accumulator: KeyValuePair<boolean>, currentAlias) => {
        if (currentAlias) {
          return {
            ...accumulator,
            [currentAlias]: checkPermission(currentAlias)
          };
        }

        return accumulator;
      },
      {} as KeyValuePair<boolean>
    );
  }, []);
};

export default useCheckPermissions;
