import React, { FC, ReactNode, useEffect } from 'react';
import { isMobileDevice } from 'apollo/cache';
import { getDeviceType } from 'helpers';
import { deviceTypes } from 'core/constants/common';

import useWindowDimensions from 'hooks/useWindowDimensions';

interface Props {
  children: ReactNode;
}

const DetectDeviceAndWindowWidth: FC<Props> = ({ children }) => {
  // const device = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
  //   ? 'MOBILE'
  //   : 'DESKTOP';
  // const collapsed = device !== 'DESKTOP'
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (getDeviceType() === deviceTypes.desktop) {
      isMobileDevice(false);
    } else if (getDeviceType() !== deviceTypes.desktop) {
      isMobileDevice(true);
    }
  }, [width]);

  return <>{children}</>;
};

export default DetectDeviceAndWindowWidth;
