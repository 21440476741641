import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import {
  Maybe,
  NiladicVoid,
  PureNullable,
  StateDispatcher,
  Undefinable
} from 'core/globalTypes';
import { Spin } from 'antd';
import { MediaFile } from 'generatedHooks/commerce/generated';
import isEqual from 'lodash/isEqual';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { SettingsIcon, StarFilledIcon, StarIcon } from 'components/shared/SVG';
import { ShowElement } from 'components/shared';
import { MediaItemSize } from 'components/shared/UcUpload/types';

interface Props {
  file: Undefinable<MediaFile>;
  removeImage: NiladicVoid;
  isDefault?: boolean;
  setDefaultImage?: (imageId: Maybe<string>) => void;
  loading: boolean;
  onChange?: (value: PureNullable<MediaFile | MediaFile[]>) => void;
  value?: PureNullable<MediaFile | MediaFile[]>;
  setSelectedFile: StateDispatcher<Undefinable<MediaFile>>;
  size: MediaItemSize;
}

const MediaItem: FC<Props> = ({
  file,
  removeImage,
  isDefault,
  setDefaultImage,
  loading,
  setSelectedFile,
  size
}) => {
  return (
    <Spin
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Component,
        prefix: 'uploaded'
      })}
      indicator={<LoadingOutlined spin />}
      spinning={loading}
    >
      <div
        style={{
          backgroundImage: `url(${file?.url})`
        }}
        className={`${styles.media_item_container} ${styles[size]}`}
      >
        <ShowElement
          fallback={
            <ShowElement isShow={Boolean(setDefaultImage)}>
              <StarIcon
                onClick={() => setDefaultImage?.(file?.id)}
                className={styles.star}
              />
            </ShowElement>
          }
          isShow={Boolean(isDefault)}
        >
          <StarFilledIcon
            onClick={() => setDefaultImage?.(null)}
            className={styles.star}
          />
        </ShowElement>
        <ShowElement isShow={isEqual(size, MediaItemSize.Large)}>
          <SettingsIcon
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Icon,
              prefix: 'settings'
            })}
            onClick={event => {
              event.stopPropagation();
              setSelectedFile?.(file);
            }}
            className={styles.info}
          />
        </ShowElement>
        <ShowElement isShow={isEqual(size, MediaItemSize.Large)}>
          <DeleteOutlined
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Icon,
              prefix: 'delete'
            })}
            onClick={event => {
              event.stopPropagation();
              removeImage();
            }}
            className={styles.delete_outlined}
          />
        </ShowElement>
      </div>
    </Spin>
  );
};

export default MediaItem;
