import { Skeleton as AntSkeleton } from 'antd';
import { FC } from 'react';

import { SkeletonProps } from 'components/shared/Activity/types';
import styles from './styles.module.less';

const TimelineSkeleton: FC<SkeletonProps> = ({ count = 3, ...props }) => {
  return (
    <>
      {[...Array(count)].map((_, i) => {
        return (
          <AntSkeleton
            key={i}
            active
            avatar={{ shape: 'circle', size: 10 }}
            title={false}
            paragraph={{ rows: 2 }}
            className={styles.activity_skeleton}
            {...props}
          />
        );
      })}
    </>
  );
};

export default TimelineSkeleton;
