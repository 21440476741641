import { InputNumber, InputNumberProps } from 'antd';
import { FC, ForwardedRef } from 'react';
import { MAX_FLOAT_NUMBER } from 'core/constants/common';

interface Props extends InputNumberProps {
  _ref?: ForwardedRef<HTMLInputElement>;
}

const BaseInputNumber: FC<Props> = ({ _ref, ...props }) => {
  return (
    <InputNumber max={MAX_FLOAT_NUMBER} size="large" ref={_ref} {...props} />
  );
};

const UcInputNumber = Object.assign(BaseInputNumber, InputNumber);

export default UcInputNumber;
