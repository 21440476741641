import React, { FC } from 'react';
import { Dropdown, DropDownProps } from 'antd';

interface Props extends DropDownProps {}

const BaseDropdown: FC<Props> = props => {
  return <Dropdown {...props} />;
};

const UcDropdown = Object.assign(BaseDropdown, Dropdown);

export default UcDropdown;
