import { Col, Row } from 'antd';
import { ImportableField } from 'generatedHooks/commerce/generated';
import { TFunction } from 'react-i18next';
import { Undefinable } from 'core/globalTypes';

import { LeftRightArrowIcon } from 'components/shared/SVG';
import styles from './styles.module.less';
import DataFields from 'components/global/ImportModal/components/DataFields';
import { Tag } from 'components/basic';

const columns = (
  t: TFunction,
  entity: string,
  options: Undefinable<string[]>,
  onSelectHandler: (e: string, rec: ImportableField) => void,
  isSelected: (key: string) => boolean,
  setMatchingValues: (label: string, key: string) => void,
  openNewFieldModal: (key: string, searchValue: string) => void
) => [
  {
    title: t('importModal:mappingTable.columns.entityFields', {
      entity
    }),
    key: 'label',
    render: (rec: ImportableField) => (
      <Row
        justify="space-between"
        align="middle"
        className={styles.entityFieldsContainer}
      >
        <Col className="textEllipsis">
          <Tag
            type={rec.required ? 'danger' : 'default'}
            className={styles.requiredTag}
            text={t(
              `importModal:mappingTable.${
                rec.required ? 'required' : 'optional'
              }`
            )}
          />
          <span className="textExllipsis" title={rec.label}>
            {rec.label}
          </span>
        </Col>
        <Col className={styles.leftRightArrow}>
          <LeftRightArrowIcon active={isSelected(rec.key)} />
        </Col>
      </Row>
    )
  },
  {
    title: t('importModal:mappingTable.columns.dataFields'),
    key: 'value',
    render: (rec: ImportableField) => {
      return (
        <DataFields
          rec={rec}
          onSelectHandler={onSelectHandler}
          options={options}
          setMatchingValues={setMatchingValues}
          onCreateNewField={openNewFieldModal}
        />
      );
    }
  }
];

export default columns;
