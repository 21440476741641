import { NiladicVoid } from 'core/globalTypes';

export interface KeyboardShortcutsGuideProps {
  visible: boolean;
  closeModal: NiladicVoid;
}

export enum ShortcutKeys {
  GO_TO_ORDERS = 'go_to_orders',
  GO_TO_PRODUCTS = 'go_to_products',
  GO_TO_ANALYTICS = 'go_to_analytics',
  GO_TO_CUSTOMERS = 'go_to_customers',
  GO_TO_DISCOUNTS = 'go_to_discounts',
  GO_TO_SETTINGS = 'go_to_settings',
  GO_TO_ACTIVITY_LOG = 'go_to_activity_log',
  CREATE_NEW_ITEM = 'create_new_item',
  SAVE_CHANGES = 'save_changes',
  NEXT_PREVIOUS_ITEM = 'next_previous_item',
  FULFILL_ORDER = 'fulfill_order'
}

export interface ShortcutsListItem {
  key: ShortcutKeys;
  label: string | string[];
  operator?: string;
}
