import { Spin } from 'antd';
import { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from 'apollo/apolloClient';
import DetectDeviceAndWindowWidth from 'hocs/DetectDeviceAndWindowWidth';
import { Router } from 'routes';
import MyI18nextProvider from 'providers/I18nextProvider';
import AntConfigProvider from 'providers/AntdConfigProvider';
import {
  useInitOneSignal,
  useInitRudderstackAnalytics,
  useSetupVH
} from 'hooks';

import { Loader } from 'components/shared';
import './App.module.less';
import HooryProvider from './providers/HooryProvider';

Spin.setDefaultIndicator(
  <Loader loaderName="preLoader" webSize={400} mobileSize={300} />
);

const App = () => {
  useInitOneSignal();
  useInitRudderstackAnalytics();
  useSetupVH();

  return (
    <Suspense fallback={<Spin />}>
      <ApolloProvider client={client}>
        <AntConfigProvider>
          <MyI18nextProvider>
            <DetectDeviceAndWindowWidth>
              <HooryProvider>
                <Router />
              </HooryProvider>
            </DetectDeviceAndWindowWidth>
          </MyI18nextProvider>
        </AntConfigProvider>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
