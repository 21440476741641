import { FC, useMemo } from 'react';
import { Typography } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { globalConfigs } from 'apollo/cache';
import { Trans } from 'react-i18next';
import Api from 'axiosApi';
import { downloadFile } from 'helpers';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { getFileExampleLink } from 'components/global/ImportModal/utils';
import {
  FileExtension,
  ImportableEntity
} from 'components/global/ImportModal/types';
import styles from './styles.module.less';

const { Text, Link } = Typography;

interface Props {
  entityType: ImportableEntity;
  attributeFamilyId: string;
  productFamily: string | undefined;
}

const SampleDownload: FC<Props> = ({
  entityType,
  attributeFamilyId,
  productFamily
}) => {
  const { importBlueprintUrls } = useReactiveVar(globalConfigs);

  const downloadableFileName = useMemo(() => {
    const fileName =
      entityType === 'Product' && productFamily
        ? productFamily.replaceAll(' ', '_')
        : entityType;

    return fileName.toLowerCase();
  }, [entityType, productFamily]);

  const downloadHandler = async (fileExtension: FileExtension) => {
    const response = await Api.get(
      getFileExampleLink(
        importBlueprintUrls[entityType.toLowerCase()],
        fileExtension,
        attributeFamilyId
      ),
      { responseType: 'blob' }
    );

    const url = window.URL.createObjectURL(response.data);

    downloadFile({ fileName: `${downloadableFileName}.${fileExtension}`, url });
  };

  return (
    <div className={styles.downloadSample}>
      <Text>
        <Trans i18nKey="importModal:downloadSample">
          Download a sample
          <Link
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Link,
              prefix: 'csv'
            })}
            onClick={() => downloadHandler('csv')}
          >
            CSV
          </Link>
          or
          <Link
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Link,
              prefix: 'xls'
            })}
            onClick={() => downloadHandler('xlsx')}
          >
            XLS
          </Link>
          file template to see an example of the format required.
        </Trans>
      </Text>
    </div>
  );
};

export default SampleDownload;
