import * as Sentry from '@sentry/react';

const {
  VITE_APP_SENTRY_DSN,
  VITE_APP_SENTRY_ENVIRONMENT,
  VITE_APP_SENTRY_ENABLED,
  VITE_APP_SENTRY_TRACES_SAMPLE_RATE,
  VITE_APP_SENTRY_PROFILES_SAMPLE_RATE,
  VITE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  VITE_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
} = import.meta.env;

const initializeSentryConfigurations = () => {
  if (VITE_APP_SENTRY_DSN) {
    Sentry.init({
      enabled: VITE_APP_SENTRY_ENABLED === 'true',
      dsn: VITE_APP_SENTRY_DSN,
      environment: VITE_APP_SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ],
      // Performance Monitoring
      tracesSampleRate: VITE_APP_SENTRY_TRACES_SAMPLE_RATE, // Capture 100% of the transactions, reduce in production!
      profilesSampleRate: VITE_APP_SENTRY_PROFILES_SAMPLE_RATE,
      // Session Replay
      replaysSessionSampleRate: VITE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE, //0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: VITE_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE //1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
    });
  }
};

export default initializeSentryConfigurations;
