import { useMemo, useState } from 'react';
import { Badge, Dropdown, DropdownProps } from 'antd';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'hooks';

import {
  IImportReportData,
  ImportableEntity
} from 'components/global/ImportModal/types';
import { ImportModal } from 'components/global';
import styles from './styles.module.less';
import {
  NotificationIcon,
  UniversalEmptyStateIcon
} from 'components/shared/SVG';
import notificationItemRenderer from './notificationItemRenderer';
import { Empty, HeaderButton } from 'components/shared';
import notificationsDropdownRender from 'components/global/Notifications/notificationsDropdownRender';
import { INotification } from 'hooks/useNotifications/types';

const Notifications = () => {
  const { t } = useTranslation(['messages', 'component']);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [importReport, setImportReport] = useState<{
    entityType: ImportableEntity;
    importReportFromNotification: IImportReportData;
  } | null>(null);

  const onImportClick = (notificationItem: INotification) => {
    setImportModalVisible(true);
    setImportReport({
      entityType: capitalize(
        notificationItem.notification.meta.scope.split('.')[0]
      ) as ImportableEntity,
      importReportFromNotification: notificationItem.notification.meta.payload
        .report as IImportReportData
    });
  };

  const {
    notifications,
    notificationHandler,
    markOneAsRead,
    notificationConfigs,
    deleteNotification,
    markAsSeen,
    markAllAsRead,
    markAllAsUnread,
    toggleRead,
    loadings
  } = useNotifications({
    onImportClick
  });

  const notificationItems = useMemo(() => {
    return notifications?.length
      ? notifications.map(notificationItem => {
          return {
            ...notificationItemRenderer({
              notificationItem,
              toggleRead,
              deleteNotification,
              t
            }),
            onClick: () => {
              notificationHandler[notificationItem.type](notificationItem);

              if (!notificationItem.read) {
                markOneAsRead(notificationItem._id);
              }
            }
          };
        })
      : [
          {
            key: 'empty',
            label: (
              <Empty
                icon={<UniversalEmptyStateIcon />}
                description={t(
                  'component:globalHeader.notifications.empty',
                  'No Notifications Yet'
                )}
              />
            )
          }
        ];
  }, [notifications]);

  const onOpenChange: DropdownProps['onOpenChange'] = visible => {
    if (visible) {
      markAsSeen(notificationConfigs);
    }
  };

  const hasNewNotification =
    Boolean(notifications?.length) && !notificationConfigs?.seen;

  return (
    <>
      {importReport && (
        <ImportModal
          isOpenFromNotifications
          modalVisible={importModalVisible}
          setModalVisible={setImportModalVisible}
          {...importReport}
        />
      )}
      <Dropdown
        dropdownRender={menu =>
          notificationsDropdownRender({
            menu,
            markAllAsUnread,
            markAllAsRead,
            loadings,
            showActions: Boolean(notifications?.length),
            t
          })
        }
        onOpenChange={onOpenChange}
        menu={{
          items: notificationItems,
          className: `${styles.notifications_list} ${
            !notifications?.length ? styles.notifications_list_empty : ''
          }`
        }}
        placement="bottomRight"
        overlayClassName={styles.notification_list}
        trigger={['click']}
      >
        <HeaderButton
          icon={<NotificationIcon />}
          dataTestPrefix="notification"
          ButtonWrapper={({ children }) => (
            <Badge size="default" dot={hasNewNotification}>
              {children}
            </Badge>
          )}
        />
      </Dropdown>
    </>
  );
};

export default Notifications;
