import { FC, useEffect } from 'react';
import { Popup as AndPopup } from 'antd-mobile';

import Header from './header';
import { ScreenType, PopupProps, popupOpenedStyles } from './types';
import styles from './style.module.less';
import { ArrowLeftIcon } from 'components/shared/SVG';

const root = document.getElementById('root');

const Popup: FC<PopupProps> = ({
  title,
  rightBarValue = '',
  visible,
  onClose,
  onRightBarAction,
  children,
  cancelValue = <ArrowLeftIcon />,
  popupScreen = ScreenType.halfOpen,
  zIndex = '5',
  position = 'bottom',
  header,
  saveDisable,
  padding = '',
  forceRender = false,
  footer = null,
  subtitle,
  titleProps
}) => {
  // demo code do not delete

  useEffect(() => {
    if (visible && root && popupScreen === ScreenType.halfOpen) {
      root.classList.add(popupOpenedStyles.halfOpen);
      document.body.classList.add(popupOpenedStyles.bodyBlackBackground);
    } else if (!visible && root) {
      document.body.classList.remove(popupOpenedStyles.bodyBlackBackground);
      root.classList.remove(popupOpenedStyles.halfOpen);
    }

    if (
      visible &&
      root &&
      popupScreen === ScreenType.fullScreen &&
      position === 'right'
    ) {
      root.classList.add(popupOpenedStyles.fullScreenRight);
    } else if (!visible && root) {
      root.classList.remove(popupOpenedStyles.fullScreenRight);
    }
  }, [visible, root, popupScreen]);

  return (
    <AndPopup
      visible={visible}
      position={position}
      onMaskClick={onClose}
      forceRender={forceRender}
      style={{ '--z-index': zIndex }}
      maskStyle={{ '--z-index': String(Number(zIndex) - 1) }}
      bodyClassName={`${styles[popupScreen]} ${styles[padding]} ${styles.amd_popup_wrapper}`}
    >
      <Header
        title={title}
        subtitle={subtitle}
        onRightBarAction={onRightBarAction}
        header={header}
        onLeftBarAction={onClose}
        rightBarValue={rightBarValue}
        rightBarOptions={{ disabled: saveDisable }}
        leftBarValue={cancelValue}
        titleProps={titleProps}
      />
      <div className={styles.body}>{children}</div>
      {footer}
    </AndPopup>
  );
};

export default Popup;
