import React, { FC } from 'react';
import { RadioGroupProps, Radio } from 'antd';

const { Group } = Radio;

interface BaseRadioGroupProps extends RadioGroupProps {}

const BaseRadioGroup: FC<BaseRadioGroupProps> = props => {
  return <Group {...props} />;
};

const UcRadioGroup = Object.assign(Group, BaseRadioGroup);

export default UcRadioGroup;
