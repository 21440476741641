import { useTranslation } from 'react-i18next';
import { useMemo, memo, FC } from 'react';
import { Limitation } from 'providers/PermissionAndLimitationProvider';
import {
  LIMITATIONS_CONSTANTS,
  PRODUCT_TYPE_CHOOSER
} from 'core/constants/permissions';

import { ProductTypeChooserProps } from 'components/shared/ProductTypeChooserList/types';
import {
  COMING_SOON_PRODUCT_TYPES,
  PRODUCT_TYPE_ICONS
} from 'components/shared/ProductTypeChooserList/constants';
import { TypeChooserRadioButton, ManagePermissions } from 'components/shared';
import styles from './styles.module.less';
import { ProductType } from 'pages/products/productFamily/types';

const ProductTypeChooser: FC<ProductTypeChooserProps> = ({
  icon,
  title,
  familyCode,
  familyId,
  groupType,
  productsCount,
  isSelected,
  checkLimitations,
  checkPermissions,
  hideArrow = false
}) => {
  const { t } = useTranslation('products');

  const ProductTypeIcon = useMemo(() => {
    return (
      PRODUCT_TYPE_ICONS[icon] || PRODUCT_TYPE_ICONS[ProductType.SUBSCRIPTION]
    );
  }, [icon]);

  const productTypeTitle = useMemo(() => {
    const isTranslatable = Object.values(ProductType).includes(
      familyCode as ProductType
    );

    return isTranslatable ? t(`types.${familyCode}`) : title;
  }, [familyCode, title]);

  const isComingSoon = useMemo(() => {
    return COMING_SOON_PRODUCT_TYPES.includes(groupType);
  }, [groupType]);

  const description = useMemo(() => {
    if (typeof productsCount === 'number') {
      return `${productsCount} ${t('products.products')}`;
    }

    return null;
  }, [productsCount]);

  const typeChooser = (
    <TypeChooserRadioButton
      title={productTypeTitle}
      value={{
        id: familyId,
        type: familyCode,
        groupType
      }}
      description={!isComingSoon ? description : ''}
      className={isSelected ? styles.selected : ''}
      icon={<ProductTypeIcon />}
      isComingSoon={isComingSoon}
      hideArrow={hideArrow}
    />
  );

  const managePermissionsChildren = checkLimitations ? (
    <Limitation entity={LIMITATIONS_CONSTANTS[groupType]}>
      {typeChooser}
    </Limitation>
  ) : (
    typeChooser
  );

  return (
    <ManagePermissions
      checkPermissions={checkPermissions}
      permissionList={PRODUCT_TYPE_CHOOSER}
      code={groupType}
    >
      {managePermissionsChildren}
    </ManagePermissions>
  );
};

export default memo(ProductTypeChooser);
