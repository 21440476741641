import { Row, Col } from 'antd';
import { FC } from 'react';

import styles from './styles.module.less';
import { Typography } from 'components/basic';
import { CardWrapperHeaderProps } from 'components/shared/CardWrapperChart/types';
import ShowElement from 'components/shared/ShowElement';

const { UcBodyText, UcHeading } = Typography;

const CardWrapperHeader: FC<CardWrapperHeaderProps> = ({
  title,
  extraElement = null,
  count = ''
}) => {
  return (
    <Row justify="space-between">
      <Col>
        <div className={styles.card_title_container}>
          <UcBodyText size="base">{title}</UcBodyText>
          <ShowElement isShow={Boolean(count)}>
            <UcHeading
              level={3}
              defaultMargin={false}
              className={styles.card_title_container_count}
            >
              {count}
            </UcHeading>
          </ShowElement>
        </div>
      </Col>
      {extraElement && <Col>{extraElement}</Col>}
    </Row>
  );
};

export default CardWrapperHeader;
