import { List } from 'antd';
import { ListItemProps } from 'antd/lib/list';
import { FC } from 'react';

export interface UcListItemProps extends ListItemProps {}

const BaseListItem: FC<UcListItemProps> = ({ children, ...props }) => {
  return <List.Item {...props}>{children}</List.Item>;
};

const UcListItem = Object.assign(List.Item, BaseListItem);

export default UcListItem;
