import { Col, Row } from 'antd';

import { Skeleton as InputSkeleton } from 'components/shared';
import styles from './styles.module.less';

const Skelton = () => {
  return (
    <>
      {Array(4)
        .fill('')
        .map((_, idx) => (
          <Row key={idx} className={styles.skeleton_wrapper}>
            <Col>
              <InputSkeleton.Input height={20} width={100} />
              <InputSkeleton.Input height={200} />
            </Col>
          </Row>
        ))}
    </>
  );
};

export default Skelton;
