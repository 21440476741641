import Plus from 'assets/icons/plusVector.svg';
import Filter from 'assets/icons/union.svg';
import EyeOutlined from 'assets/icons/eyeOutlined.svg';
import AppstoreOutlined from 'assets/icons/vectorProject.svg';
import BellOutline from 'assets/icons/bellOutline.svg';
import CopyOutlined from 'assets/icons/copyOutlined.svg';
import CheckOutlined from 'assets/icons/vectorStroke.svg';
import CombinedShape from 'assets/icons/combinedShape.svg';
import SearchOutlined from 'assets/icons/searchOutlined.svg';
import DeleteOutlined from 'assets/icons/deleteOutlined.svg';
import BellBlackOutline from 'assets/icons/bellBlackOutline.svg';
import CalendarOutlined from 'assets/icons/calendarOutlined.svg';
import CloseIconOutlined from 'assets/icons/closeIconOutlined.svg';
import InfoCircleLite from 'assets/icons/infoCircleLite.svg';
import MenuLinesOutline from 'assets/icons/menuLinesOutline.svg';
import sortByOutline from 'assets/icons/sortByOutline.svg';
import Menu from 'assets/icons/menu.svg';
import Edit from 'assets/icons/edit.svg';
import FilterY from 'assets/icons/filter.svg';
import EyeHide from 'assets/icons/eyeHide.svg';
import Ruler from 'assets/icons/ruler.svg';
import Assignment from 'assets/icons/assignment.svg';
import EllipsisOutlined from 'assets/icons/ellipsis.svg';

export const icons = {
  plusIcon: Plus,
  filterIcon: Filter,
  appstoreOutlinedIcon: AppstoreOutlined,
  combinedShape: CombinedShape,
  checkOutlined: CheckOutlined,
  bellOutline: BellOutline,
  eyeOutlined: EyeOutlined,
  copyOutlined: CopyOutlined,
  deleteOutlined: DeleteOutlined,
  calendarOutlined: CalendarOutlined,
  closeIconOutlined: CloseIconOutlined,
  searchOutlined: SearchOutlined,
  bellBlackOutline: BellBlackOutline,
  infoCircleLite: InfoCircleLite,
  menuLines: MenuLinesOutline,
  filterY: FilterY,
  sortBy: sortByOutline,
  menu: Menu,
  edit: Edit,
  eyeHide: EyeHide,
  ruler: Ruler,
  assignment: Assignment,
  ellipsisOutlined: EllipsisOutlined
};
