export interface AttributeGroup {
  name: string;
  customAttributes: any;
}

interface IProductType {
  title: string;
  key: string;
  icon: string;
  translation: string;
  typeTranslation: string;
}

export interface IProductTypes {
  [key: string]: IProductType;
}

export enum ProductType {
  PHYSICAL = 'physical',
  DIGITAL = 'digital',
  SUBSCRIPTION = 'subscription',
  BOOKING_RENTAL = 'booking_rental',
  BOOKING_APPOINTMENT = 'booking_appointment',
  BOOKING_RESERVATION = 'booking_reservation',
  BOOKING_TABLE = 'booking_table',
  BOOKING_EVENT = 'booking_event',
  GIFT_CARD = 'gift_card'
}
