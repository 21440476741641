import {
  IEnvironment,
  PermissionActionsType
} from 'providers/PermissionAndLimitationProvider/core/globalTypes';
import {
  SET_LIMITATION_ENTITIES,
  INIT_PROJECT_PERMISSION_AND_ENVIRONMENT,
  TRANSFORM_PERMISSIONS
} from 'providers/PermissionAndLimitationProvider/context/actionTypes';

export const initProjectPermissionsAndEnvironments = (
  projectPermissionsAndNotes: any,
  isOwner: boolean,
  environment: IEnvironment,
  redirectHandler: () => void
) => {
  const payload = {
    project: projectPermissionsAndNotes.project,
    permissions: projectPermissionsAndNotes.permissions,
    notes: projectPermissionsAndNotes.notes,
    environment,
    isOwner,
    redirectHandler
  };

  return { type: INIT_PROJECT_PERMISSION_AND_ENVIRONMENT, payload };
};

export const initEntitiesStateForLimitations = (entities: any) => {
  return { type: SET_LIMITATION_ENTITIES, payload: entities };
};

export const transformPermissions = (
  componentPermissions: PermissionActionsType
) => {
  return { type: TRANSFORM_PERMISSIONS, payload: componentPermissions };
};
