import { FC } from 'react';
import { MenuProps, Menu } from 'antd';

import UcMenuItem from './menu-item';
import UcSubMenu from './sub-menu';

interface Props extends MenuProps {}

const BaseMenu: FC<Props> = props => {
  return <Menu {...props} />;
};

const UcMenu = Object.assign(BaseMenu, Menu, {
  Item: UcMenuItem,
  SubMenu: UcSubMenu
});

export default UcMenu;
