import { List, ListProps } from 'antd';

import UcListItem, { UcListItemProps } from './ListItem';

interface UcListOptions extends Array<UcListItemProps> {}

interface UcListProps<T> extends ListProps<T> {
  options?: UcListOptions;
}

const BaseList = <Values extends object>(props: UcListProps<Values>) => {
  const { options, children, ...rest } = props;

  return (
    <List<Values> {...rest}>
      {options?.map((option, index) => {
        return <UcListItem key={index} {...option} />;
      })}
      {children}
    </List>
  );
};

const UcList = Object.assign(BaseList, List, { Item: UcListItem });

export default UcList;
