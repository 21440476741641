import { useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import Api from 'axiosApi';
import { useReactiveVar } from '@apollo/client';
import {
  GlobalMessage,
  queryParamForFetchingToken
} from 'core/constants/common';
import { useTranslation } from 'react-i18next';
import { MessageTypes } from 'core/globalTypes';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { SystemMessage } from 'helpers';

import { currentUser } from '../apollo/cache';
import { HooryContext } from './HooryProvider';

const AuthProvider = () => {
  const [ready, setReady] = useState<boolean>(false);
  const fromNonce = useRef<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectName = useLocation().pathname.split('/')[1];

  const { t } = useTranslation('common');

  const currUser = useReactiveVar(currentUser);
  const {
    hoory: { isChatInitialized, setUserInfo }
  } = useContext(HooryContext);

  localStorage.setItem('projectName', projectName || '');

  const location = useLocation();

  useEffect(() => {
    if (location && window.rudderanalytics) {
      (window.rudderanalytics as RudderAnalytics).page();
    }
  }, [location]);

  useEffect(() => {
    if (window.rudderanalytics && ready && fromNonce.current && currUser) {
      (window.rudderanalytics as RudderAnalytics).identify(currUser.id, {
        id: currUser.id || '',
        firstName: currUser.firstName || '',
        lastName: currUser.lastName || '',
        email: currUser.email || '',
        isTester: currUser.isTester || false,
        country: currUser.location?.country || null
      });
      fromNonce.current = false;
    }
  }, [ready, currUser]);

  useEffect(() => {
    if (currUser && isChatInitialized) {
      setUserInfo?.(currUser?.email, {
        name: (currUser?.firstName || '') + ' ' + (currUser?.lastName || ''),
        email: currUser?.email || '',
        identifier_hash: currUser?.identifierHash
      });
    }
  }, [currUser, isChatInitialized]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has(queryParamForFetchingToken)) {
      const fetchData = async () => {
        try {
          const res = await Api.get('/api/access-token', {
            params: { nonce: params.get(queryParamForFetchingToken) }
          });

          localStorage.setItem('token', res.data.accessToken);
        } catch (err: any) {
          SystemMessage.error(err?.response?.data?.message);
        } finally {
          searchParams.delete(queryParamForFetchingToken);
          setSearchParams(searchParams);
          setReady(true);
          fromNonce.current = true;
        }
      };

      fetchData();
    } else {
      setReady(true);
    }
  }, []);

  const renderGlobalMessage = () => {
    const msg = searchParams.get('msg');
    const type = searchParams.get('type');

    if (msg && type) {
      GlobalMessage(t(msg), type as MessageTypes);

      searchParams.delete('msg');
      searchParams.delete('type');

      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    renderGlobalMessage();
  }, []);

  return <>{ready ? <Outlet /> : <Spin />}</>;
};

export default AuthProvider;
