import { Col, Row, Skeleton } from 'antd';

import styles from 'components/shared/Skeleton/styles.module.less';

const { Input } = Skeleton;

const Dashboard = () => {
  return (
    <Row className={styles.pageContainer}>
      <Col span={24}>
        <Row justify="space-between">
          <Col className={styles.col_dashboard}>
            <Input active className={styles.titleDashboard} />
            <Input active className={styles.titleDashboard} />
          </Col>
          <Col className={styles.col}>
            <div className={styles.buttons}>
              <Input
                active
                style={{
                  width: 130,
                  height: 32
                }}
              />
              <Input
                active
                style={{
                  width: 130,
                  height: 32
                }}
              />
              <Input
                active
                style={{
                  width: 130,
                  height: 32
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col className={styles.main_dashboard}>
        <Row justify="space-between" gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} className={styles.cardCol}>
            <Input active className={styles.card} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className={styles.cardCol}>
            <Input active className={styles.card} />
          </Col>
        </Row>
        <Input active className={styles.table} />
      </Col>
    </Row>
  );
};

export default Dashboard;
