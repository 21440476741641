import { ProductTypeGroup } from 'generatedHooks/commerce/generated';

import { NavigatingPage } from './types';

export const getNavigationPath = (
  page: NavigatingPage,
  id: string,
  groupType: ProductTypeGroup
): string => {
  const type = groupType.replace('_', '-').toLowerCase();

  const PATHS = {
    [NavigatingPage.PRODUCT]: `${id}/${type}-product/add`,
    [NavigatingPage.FAMILY]: `${type}/create`,
    [NavigatingPage.EVENT]: `${id}/event/add`,
    [NavigatingPage.BOOKING]: `${id}/booking/add`,
    [NavigatingPage.RENTAL]: `${id}/rental/add`
  };

  return PATHS[page as NavigatingPage];
};
