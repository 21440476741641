import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ImportableField } from 'generatedHooks/commerce/generated';
import { Undefinable } from 'core/globalTypes';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Empty, Select, Typography } from 'components/basic';
import { UniversalEmptyStateIcon } from 'components/shared/SVG';
import styles from 'components/global/ImportModal/pages/Mapping/styles.module.less';

const { UcLink } = Typography;

const { Option } = Select;

interface Props {
  rec: ImportableField;
  onSelectHandler: (e: string, rec: ImportableField) => void;
  setMatchingValues: (label: string, key: string) => void;
  onCreateNewField: (key: string, searchValue: string) => void;
  options: Undefinable<string[]>;
}

const DataFields: FC<Props> = ({
  rec,
  onSelectHandler,
  options,
  setMatchingValues,
  onCreateNewField
}) => {
  const { t } = useTranslation('importModal');
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => setMatchingValues(rec.label, rec.key), []);

  return (
    <Row align="middle">
      <Col flex={1}>
        <Form.Item
          name={rec.key as string}
          rules={[
            {
              required: rec.required,
              message: t('errors:form.required')
            }
          ]}
          style={{ margin: 0 }}
        >
          <Select
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Dropdown,
              prefix: 'data-fields'
            })}
            placeholder={t('importModal:mappingTable.chooseField')}
            optionFilterProp="children"
            getPopupContainer={trigger => trigger.parentNode}
            onChange={e => onSelectHandler(e as string, rec)}
            showSearch
            notFoundContent={
              !rec.unique ? (
                <UcLink onClick={() => onCreateNewField(rec.key, searchValue)}>
                  {t('createNewField', `Create new field "${searchValue}"`, {
                    field: searchValue
                  })}
                </UcLink>
              ) : (
                <Empty image={<UniversalEmptyStateIcon />} />
              )
            }
            onSearch={value => setSearchValue(value)}
            searchValue={searchValue}
          >
            <Option className={styles.optionNone} value="">
              {t('importModal:mappingTable.none')}
            </Option>
            {options?.map((item: any, index: any) => (
              <Option
                data-test={createDataTestAttribute({
                  dataTestAttribute: DataTestAttributes.Option,
                  prefix: item
                })}
                value={item}
                key={`${item}${index}`}
              >
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col />
    </Row>
  );
};

export default DataFields;
