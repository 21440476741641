import {
  CategoryType,
  ConditionOperator,
  ProductType,
  ProductTypeGroup,
  QueryProductsConditionColumn,
  QueryProductsOrderColumn,
  SortOrder
} from 'generatedHooks/commerce/generated';
import { TFunction } from 'react-i18next';
import { Undefinable, SortTypes } from 'core/globalTypes';
import { Actions } from 'providers/PermissionAndLimitationProvider';
import { getCategories } from 'customGQLrequests';

import { FilterKeys } from 'pages/bookings/bookings/Web/utils';
import {
  productTypes,
  productVisibilityTypes,
  statusList
} from 'components/shared/Filter/utils';
import AttributesFilter from 'components/shared/AttributesFilter';
import { ProductTypeGroupExcluded } from 'pages/products/products/Web/type';
import { FilterElement } from 'components/shared/Filter/types';
import { SortValues } from 'components/shared/SortBy/constants';

const { Create, Update, Delete } = Actions;

const FILTER_INITIAL_DATA = {
  [QueryProductsConditionColumn.Status]: {
    column: QueryProductsConditionColumn.Status,
    operator: ConditionOperator.Eq,
    value: null
  }
};

const SORT_DATA = {
  [SortTypes.Az]: {
    column: QueryProductsOrderColumn.Name,
    order: SortOrder.Asc
  },
  [SortTypes.Za]: {
    column: QueryProductsOrderColumn.Name,
    order: SortOrder.Desc
  },
  [SortTypes.Newest]: {
    column: QueryProductsOrderColumn.Id,
    order: SortOrder.Desc
  },
  [SortTypes.Oldest]: {
    column: QueryProductsOrderColumn.Id,
    order: SortOrder.Asc
  }
};

export { SORT_DATA, FILTER_INITIAL_DATA };

export const PRODUCT_PERMISSIONS_BY_GROUP_TYPE: Record<
  ProductTypeGroupExcluded,
  Record<'create' | 'edit' | 'delete', string>
> = {
  [ProductTypeGroup.Physical]: {
    create: Create.PhysicalProduct,
    edit: Update.PhysicalProduct,
    delete: Delete.PhysicalProduct
  },
  [ProductTypeGroup.Digital]: {
    create: Create.DigitalProduct,
    edit: Update.DigitalProduct,
    delete: Delete.DigitalProduct
  },
  [ProductTypeGroup.BookingAppointment]: {
    create: Create.AppointmentProduct,
    edit: Update.AppointmentProduct,
    delete: Delete.AppointmentProduct
  },
  [ProductTypeGroup.BookingReservation]: {
    create: Create.AppointmentProduct,
    edit: Update.AppointmentProduct,
    delete: Delete.AppointmentProduct
  },
  [ProductTypeGroup.BookingEvent]: {
    create: Create.EventProduct,
    edit: Update.EventProduct,
    delete: Delete.EventProduct
  },
  [ProductTypeGroup.BookingTable]: {
    create: Create.TableProduct,
    edit: Update.TableProduct,
    delete: Delete.TableProduct
  },
  [ProductTypeGroup.BookingRental]: {
    create: Create.RentalProduct,
    edit: Update.RentalProduct,
    delete: Delete.RentalProduct
  },
  [ProductTypeGroup.GiftCard]: {
    create: Create.GiftCardProduct,
    edit: Update.GiftCardProduct,
    delete: Delete.GiftCardProduct
  }
};

export const filters = ({
  t,
  symbol
}: {
  t: TFunction;
  symbol: string;
}): FilterElement[] => [
  {
    type: 'select',
    filterProps: {
      title: t('products:products.status', 'Status'),
      showTopBorder: false,
      showOnlyMobile: true
    },
    data: statusList(t),
    emptyValue: null,
    valueModifier: (value: Undefinable<string>) =>
      value ? Number(value) : null,
    name: QueryProductsConditionColumn.Status,
    placeholder: t('products:products.status', 'Status')
  },
  {
    type: 'select',
    filterProps: {
      title: t('common:sortBy', 'Sort By'),
      showTopBorder: false,
      showOnlyMobile: true
    },
    data: SortValues(t),
    valueKey: 'value',
    emptyValue: SortTypes.Newest,
    name: 'sort',
    placeholder: t('common:sortBy', 'Sort By')
  },
  {
    type: 'buttonGroups',
    filterProps: {
      title: t('products:filters.productVisibility', 'Product Visibility'),
      showTopBorder: false
    },
    name: FilterKeys.Visibility,
    emptyValue: null,
    data: productVisibilityTypes(t, false),
    multiple: false
  },
  {
    type: 'priceRange',
    filterProps: {
      title: t('products:products.price', 'Price'),
      showTopBorder: true
    },
    toKey: FilterKeys.PriceLte,
    fromKey: FilterKeys.PriceGte,
    prefix: symbol,
    onChange: undefined,
    name: 'price',
    emptyValue: ''
  },
  {
    type: 'multiselect',
    filterProps: {
      title: t('products:products.category', 'Category'),
      showTopBorder: true
    },
    name: QueryProductsConditionColumn.CategoryId,
    maxTagCount: 0,
    placeholder: t('products:products.category', 'Category'),
    emptyValue: [],
    loader: getCategories(CategoryType.Product)
  },
  {
    type: 'select',
    filterProps: {
      title: t('products:products.status', 'Status'),
      showTopBorder: true
    },
    data: statusList(t),
    emptyValue: null,
    valueModifier: (value: Undefinable<string>) =>
      value ? Number(value) : null,
    name: QueryProductsConditionColumn.Status,
    placeholder: t('products:products.status', 'Status')
  },
  {
    type: 'select',
    filterProps: {
      title: t('products:filters.productType', 'Product Type'),
      showTopBorder: true
    },
    data: productTypes(t),
    name: QueryProductsConditionColumn.Type,
    emptyValue: null,
    placeholder: t('products:filters.productType', 'Product Type'),
    valueKey: 'id'
  },
  {
    type: 'custom',
    element: (
      <AttributesFilter
        attributesListParams={{
          groupTypes: [
            ProductTypeGroup.Collection,
            ProductTypeGroup.BookingEvent
          ],
          operator: ConditionOperator.NotIn
        }}
      />
    )
  }
];

export const PRODUCTS_DEFAULT_FILTERS = [
  {
    operator: ConditionOperator.Neq,
    column: QueryProductsConditionColumn.Type,
    value: ProductType.BookingEvent
  },
  {
    operator: ConditionOperator.Neq,
    column: QueryProductsConditionColumn.Type,
    value: ProductType.BookingReservation
  },
  {
    operator: ConditionOperator.Neq,
    column: QueryProductsConditionColumn.Type,
    value: ProductType.BookingRental
  }
];

export const PRODUCTS_EXPORT_DEFAULT_FILTERS = [
  {
    operator: ConditionOperator.Eq,
    column: QueryProductsConditionColumn.Type,
    value: ProductType.Simple
  },
  {
    operator: ConditionOperator.Eq,
    column: QueryProductsConditionColumn.Type,
    value: ProductType.Configurable
  },
  {
    operator: ConditionOperator.Eq,
    column: QueryProductsConditionColumn.Type,
    value: ProductType.Downloadable
  }
];

export const IMPORTABLE_PRODUCT_TYPE_GROUPS = [
  ProductTypeGroup.Physical,
  ProductTypeGroup.Digital
];
