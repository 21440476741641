import { countries, Country } from 'countries-list';
import {
  AttributeType,
  ConditionOperator,
  QueryAttributesConditionColumn,
  QueryAttributesOrderColumn,
  SortOrder
} from 'generatedHooks/commerce/generated';
import { commonTranslations } from 'translations';
import { KeyValuePair, SortTypes } from 'core/globalTypes';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';

import { AttributeTypeEnum } from './types';

interface AttributesTranslationsByType {
  name: string;
  dataTest: string;
  defaultName: string;
  value: AttributeType;
}

export const ATTRIBUTE_DEFAULT_COLOR = {
  hex: '#000000',
  hsl: { h: 0, s: 0.029919151346619332, l: 0.032332635, a: 1 },
  rgb: { r: 8, g: 8, b: 8, a: 1 }
};

export const ATTRIBUTE_TEXT_TYPE_VALIDATION_OPTIONS = [
  {
    name: 'common:noValidation',
    dataTest: 'no-validation',
    defaultName: 'No Validation',
    value: ''
  },
  {
    name: 'common:number',
    dataTest: 'number',
    defaultName: 'Number',
    value: 'number'
  },
  {
    name: 'common:email',
    dataTest: 'email',
    defaultName: 'Email',
    value: 'email'
  },
  {
    name: 'common:decimal',
    dataTest: 'decimal',
    defaultName: 'Decimal',
    value: 'decimal'
  },
  {
    name: 'common:url',
    dataTest: 'url',
    defaultName: 'URL',
    value: 'url'
  }
];

export const ATTRIBUTE_TYPE_OPTIONS: AttributesTranslationsByType[] = [
  {
    name: 'common:checkbox',
    dataTest: `${AttributeType.Checkbox.toLowerCase()}`,
    defaultName: commonTranslations.checkbox,
    value: AttributeType.Checkbox
  },
  {
    name: 'common:text',
    dataTest: `${AttributeType.Text.toLowerCase()}`,
    defaultName: commonTranslations.text,
    value: AttributeType.Text
  },
  {
    name: 'common:selection',
    dataTest: `${AttributeType.Select.toLowerCase()}`,
    defaultName: commonTranslations.select,
    value: AttributeType.Select
  },
  {
    name: 'common:multiselect',
    dataTest: `${AttributeType.Multiselect.toLowerCase()}`,
    defaultName: commonTranslations.multiselect,
    value: AttributeType.Multiselect
  },
  {
    name: 'common:boolean',
    dataTest: `${AttributeType.Boolean.toLowerCase()}`,
    defaultName: 'Boolean',
    value: AttributeType.Boolean
  },
  {
    name: 'common:dateAndTime',
    dataTest: `${AttributeType.Datetime.toLowerCase()}`,
    defaultName: commonTranslations.dateAndTime,
    value: AttributeType.Datetime
  },
  {
    name: 'common:date',
    dataTest: `${AttributeType.Date.toLowerCase()}`,
    defaultName: commonTranslations.date,
    value: AttributeType.Date
  },
  {
    name: 'common:file',
    dataTest: `${AttributeType.File.toLowerCase()}`,
    defaultName: commonTranslations.file,
    value: AttributeType.File
  },
  {
    name: 'common:image',
    dataTest: `${AttributeType.Image.toLowerCase()}`,
    defaultName: commonTranslations.image,
    value: AttributeType.Image
  },
  {
    name: 'common:gallery',
    dataTest: `${AttributeType.Gallery.toLowerCase()}`,
    defaultName: commonTranslations.gallery,
    value: AttributeType.Gallery
  },
  {
    name: 'common:richText',
    dataTest: `${AttributeType.Textarea.toLowerCase()}`,
    defaultName: commonTranslations.richText,
    value: AttributeType.Textarea
  },
  {
    name: 'common:price',
    dataTest: `${AttributeType.Price.toLowerCase()}`,
    defaultName: commonTranslations.price,
    value: AttributeType.Price
  },
  {
    name: 'common:addon',
    dataTest: `${AttributeType.Addon.toLowerCase()}`,
    defaultName: commonTranslations.addon,
    value: AttributeType.Addon
  }
];

export const ATTRIBUTES_FILTER_INITIAL_DATA = {
  type: {
    column: QueryAttributesConditionColumn.Type,
    operator: ConditionOperator.Eq,
    value: null
  }
};

export const siteCountries = countries as Record<string, Country>;

export const SORT_DATA = {
  [SortTypes.Az]: {
    column: QueryAttributesOrderColumn.Name,
    order: SortOrder.Asc
  },
  [SortTypes.Za]: {
    column: QueryAttributesOrderColumn.Name,
    order: SortOrder.Desc
  },
  [SortTypes.Newest]: {
    column: QueryAttributesOrderColumn.CreatedAt,
    order: SortOrder.Desc
  },
  [SortTypes.Oldest]: {
    column: QueryAttributesOrderColumn.CreatedAt,
    order: SortOrder.Asc
  }
};

export const nonSelectableAttributesList = [
  AttributeType.Text,
  AttributeType.Boolean,
  AttributeType.Datetime,
  AttributeType.Date,
  AttributeType.File,
  AttributeType.Image,
  AttributeType.Gallery,
  AttributeType.Textarea,
  AttributeType.Price
];

export const selectableAttributesList = [
  AttributeType.Select,
  AttributeType.Checkbox,
  AttributeType.Multiselect,
  AttributeType.Addon
];

export const selectableAttributesWithOnlyTextSwatchType = [
  AttributeType.Checkbox,
  AttributeType.Multiselect
];

export const initialValues = {
  isComparable: false,
  isRequired: false,
  isUnique: false,
  isVisibleOnFront: true,
  valuePerChannel: false,
  valuePerLocale: false,
  isConfigurable: false,
  isPurchaseRequired: false,
  type: AttributeType.Text
};

export const TRANSLATIONS_BY_ATTRIBUTE_TYPE: Record<
  AttributeType,
  KeyValuePair<string>
> = ATTRIBUTE_TYPE_OPTIONS.reduce(
  (acc, curr) => {
    return {
      ...acc,
      [curr.value]: {
        key: curr.name,
        defaultValue: curr.defaultName
      }
    };
  },
  {} as Record<AttributeType, KeyValuePair<string>>
);

export const ATTRIBUTES_WITH_SWATCH_TYPE = [
  AttributeType.Select,
  AttributeType.Addon
];

export const getAttributeOptionsByType = (
  disableType: boolean,
  attributeType: AttributeTypeEnum
) => {
  const fieldsToBeFiltered: AttributeType[] = [];

  if (!disableType) {
    fieldsToBeFiltered.push(AttributeType.Price);
  }

  if (isEqual(attributeType, AttributeTypeEnum.FormField)) {
    fieldsToBeFiltered.push(AttributeType.Addon);
  }

  return ATTRIBUTE_TYPE_OPTIONS.filter(
    option => !includes(fieldsToBeFiltered, option.value)
  );
};

export const DEFAULT_OPTION_PRICE = 0;
export const OPTION_PRESELECTED_DEFAULT_VALUE = true;

export const ATTRIBUTE_TYPES_WITH_CHECKBOX_SWATCH = [AttributeType.Addon];
