import { FC } from 'react';
import { Col, Row, Skeleton } from 'antd';

import { PageContainer } from 'components/wrapper';
import styles from './styles.module.less';

const { Input } = Skeleton;

export const BillingsLazySkeleton: FC = () => {
  return (
    <PageContainer className={styles.billingContainer}>
      <Input active style={{ width: 150, marginBottom: 16 }} />
      <Row gutter={[16, 16]}>
        <Col sm={24} xs={24} lg={15}>
          <Input
            active
            style={{
              width: '100%',
              height: 196
            }}
          />
        </Col>
        <Col sm={24} xs={24} lg={9}>
          <Input
            active
            style={{
              width: '100%',
              height: 196
            }}
          />
        </Col>
      </Row>
      {/*<PaymentFailed subscriptionName={'test'} />*/}
      <Input active style={{ width: 150, margin: '16px 0' }} />
      <Input
        active
        style={{
          width: '100%',
          height: 'calc(100vh - 520px)'
        }}
      />
    </PageContainer>
  );
};
