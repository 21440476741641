import { Popover, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { filesize } from 'filesize';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';

import Player from './UIComponents/Player';
import Modal from './UIComponents/Modal';
import styles from './styles.module.less';
import {
  mediaWithFolders_mediaWithFolders_data,
  mediaWithFolders_mediaWithFolders_data_Image,
  mediaWithFolders_mediaWithFolders_data_Audio
} from './__generated__/types';
import { PageType } from './types';
import useMedia from 'hooks/useMedia';

dayjs.extend(utc);
dayjs.extend(duration);

type DetailsType = Array<{
  key: string;
  value?: string | number;
}>;

type Props = {
  data: mediaWithFolders_mediaWithFolders_data;
  closePreview: () => void;
  type: PageType;
  visible: boolean;
};

// const TitleTag = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   height: 100%;
//   padding-right: 12px;
// `;
//
// const Content = styled.div`
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//
//   video,
//   img {
//     max-width: 100%;
//     max-height: 100%;
//   }
// `;

// const InfoCircleOutlinedIcon = styled(InfoCircleOutlined)`
//   &&& {
//     color: var(--ant-black-45);
//
//     :hover {
//       color: var(--ant-black-65);
//     }
//   }
// `;
//
// const SpaceItem = styled(Space)`
//   line-height: 100%;
// `;

/**
 * Renders Preview media component.
 * @param visible - Detect if modal visible or not.
 * @param closePreview - Function triggers after modal close.
 * @param data - Media data for preview.
 * @param type - Media type.
 * @returns ReactNode.
 */
function Preview({ visible, closePreview, data, type }: Props) {
  const { t } = useTranslation('media');
  const { audioPath, docsPath, imagePath, videoPath, vectorPath } = useMedia();

  /**
   * Renders Media title.
   */
  function getTitle() {
    const { width, height } =
      data as mediaWithFolders_mediaWithFolders_data_Image;

    const { duration } = data as mediaWithFolders_mediaWithFolders_data_Audio;

    const details: DetailsType = [
      { key: 'name', value: `${data?.name}.${data?.extension}` },
      { key: 'size', value: filesize(data?.size || 0) },
      { key: 'uploaded', value: dayjs(data?.createdAt).format('MMM D, YYYY') },
      { key: 'owner', value: 'user' },
      ...(type === 'images'
        ? [{ key: 'dimensions', value: `${width} x ${height}` }]
        : []),
      ...(type === 'audios' || type === 'videos'
        ? [
            {
              key: 'duration',
              value: dayjs
                .utc(dayjs.duration(duration * 1000).asMilliseconds())
                .format('HH:mm:ss')
            }
          ]
        : [])
    ];

    // Renders media information in a pop-up window.
    const content = (
      <div>
        {details.map(({ key, value }) => (
          <div key={key}>
            {t(key)}: <strong>{value}</strong>
          </div>
        ))}
      </div>
    );

    return (
      <div className={styles.TitleTag}>
        <div>
          <strong>{`${data?.name}.${data?.extension}`}</strong>
        </div>
        <Space className={styles.SpaceItem} size={20}>
          <Popover
            placement="bottomRight"
            title={<strong>{t('detailsTitle')}</strong>}
            content={content}
            trigger="click"
          >
            <InfoCircleOutlined className={styles.InfoCircleOutlinedIcon} />
          </Popover>
          <span />
        </Space>
      </div>
    );
  }

  /**
   * Renders media content by type.
   */
  function getPreview() {
    const fileName = data?.name + '.' + data?.extension;
    const { title } = data as mediaWithFolders_mediaWithFolders_data_Image;

    return {
      images: <img src={`${imagePath}${fileName}`} alt={title || ''} />,
      vectors: (
        <img
          style={{ width: 60, height: 60 }}
          src={`${vectorPath}${fileName}`}
          alt={title || ''}
        />
      ),
      audios: <Player audio url={audioPath + fileName} />,
      videos: <Player url={videoPath + fileName} />,
      docs: (
        <iframe
          src={docsPath + fileName}
          frameBorder={0}
          width="100%"
          height="100%"
        />
      )
    };
  }

  return (
    <Modal
      title={visible && getTitle()}
      open={visible}
      onCancel={closePreview}
      footer={null}
      styles={{
        body: type === 'audios' ? undefined : { height: '65vh', padding: 0 }
      }}
      width={type === 'audios' ? undefined : '75%'}
      centered
      destroyOnClose
    >
      <div className={styles.Container} style={{ height: '100%' }}>
        {visible && getPreview()[type]}
      </div>
    </Modal>
  );
}

export default Preview;
