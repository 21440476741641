import { OrderFilterEnum } from 'pages/products/orders/orders/constants';

export const ordersFilterTypes = [
  OrderFilterEnum.Price,
  OrderFilterEnum.OrderDate,
  OrderFilterEnum.TaxApplied,
  OrderFilterEnum.ItemsCount,
  OrderFilterEnum.ShippingMethod,
  OrderFilterEnum.ShippingCountry,
  OrderFilterEnum.ToDate,
  OrderFilterEnum.FromDate,
  OrderFilterEnum.PaymentMethod,
  OrderFilterEnum.ShippingStatus,
  OrderFilterEnum.PaymentStatus,
  OrderFilterEnum.EqItemsCount,
  OrderFilterEnum.GteItemsCount,
  OrderFilterEnum.LteItemsCount
];

export const textAppliedData = (t: Function) => [
  {
    name: t('orders:orders.filters.yes', 'Yes'),
    id: '1'
  },
  {
    name: t('orders:orders.filters.no', 'No'),
    id: '0'
  }
];
