import React, { FC } from 'react';
import { SubMenuProps, Menu } from 'antd';

const { SubMenu } = Menu;

interface Props extends SubMenuProps {}

const BaseSubMenu: FC<Props> = props => {
  return <SubMenu {...props} />;
};

const UcSubMenu = Object.assign(SubMenu, BaseSubMenu);

export default UcSubMenu;
