import { LockOutlined } from '@ant-design/icons';
import { Button, TabPaneProps } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useCallback } from 'react';
import { Action } from 'antd-mobile/es/components/action-sheet';

import Can from '../components/Wrappers/Can';
import { CanFunctionProps } from './types';
import usePermissionAndLimitationContext from './usePermissionAndLimitationContext';

const useCanFunction = (type: 'menu' | 'tab') => {
  const {
    state: {
      permissions: { availablePermissions }
    }
  } = usePermissionAndLimitationContext();

  const CanFunction = useCallback(
    ({
      children,
      I,
      Or,
      RequestAccess,
      isMobile = false,
      ...restCanProps
    }: CanFunctionProps & {
      isMobile?: boolean;
    }) => {
      if (!I || availablePermissions?.includes(I)) {
        return children;
      }

      if (Or && availablePermissions?.includes(Or)) {
        return children;
      }

      if (RequestAccess && !availablePermissions?.includes(RequestAccess)) {
        return null;
      }

      if (type === 'menu') {
        const menuItem = children as MenuItemType & { 'data-test'?: string };

        const mobileMenuItem = children as Action;

        const component = isMobile ? mobileMenuItem.text : menuItem.label;

        return {
          ...children,
          disabled: true,
          icon: <LockOutlined />,
          [isMobile ? 'text' : 'label']: (
            <Can I={I} Or={Or} RequestAccess={RequestAccess} {...restCanProps}>
              <>
                <div style={{ width: '100%' }}>{component}</div>
              </>
            </Can>
          )
        };
      } else if (type === 'tab') {
        const tab = children as TabPaneProps & { 'data-test'?: string };

        return {
          ...children,
          disabled: true,
          tab: (
            <Can I={I} Or={Or} RequestAccess={RequestAccess} {...restCanProps}>
              <Button
                type="text"
                data-perm="perm"
                style={{ height: 'auto', padding: 0 }}
              >
                {tab.tab}
              </Button>
            </Can>
          )
        };
      }
    },
    [availablePermissions]
  );

  return CanFunction;
};

export default useCanFunction;
