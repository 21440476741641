import { FC, ReactNode, useMemo } from 'react';

import BodyText from 'components/basic/Typography/BodyText';

interface Props {
  showIcon?: boolean;
  onClose?: () => void;
  closeIcon?: ReactNode;
  title?: string | ReactNode;
}

const Header: FC<Props> = ({ title }) => {
  const titleComponent = useMemo(() => {
    if (typeof title === 'string') {
      return <BodyText fontWeight="bold" size="large" text={title} />;
    }

    return title;
  }, [title]);

  return <div>{titleComponent}</div>;
};

export default Header;
