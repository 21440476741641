import { FC, ReactNode } from 'react';
import { TFunction, TransProps } from 'react-i18next';
import { KeyValuePair, NiladicVoid } from 'core/globalTypes';

export enum NotificationType {
  Import = 'import',
  Export = 'export',
  OrderPlaced = 'orderPlaced',
  CustomerRegistered = 'customerCreated'
}

export enum NotificationSubType {
  EventOrderPlaced = 'eventOrderPlaced',
  BookingOrderPlaced = 'bookingOrderPlaced'
}

export interface NotificationItemProps {
  icon: FC;
  transComponentProps: {
    i18nkey: string;
    defaultValue: string;
    values?: KeyValuePair;
    components?: TransProps<any>['components'];
  };
}

export interface NotificationsDropdownRenderArgs {
  menu: ReactNode;
  markAllAsRead: NiladicVoid;
  markAllAsUnread: NiladicVoid;
  showActions: boolean;
  loadings: {
    readAll: boolean;
    unreadAll: boolean;
  };
  t: TFunction;
}
