import { FC } from 'react';
import { Skeleton } from 'antd-mobile';

import styles from './styles.module.less';
import { ItemProps } from './types';
import { Typography } from 'components/basic';
import { ShowElement, Suspensor } from 'components/shared';

const { UcBodyText } = Typography;

const Item: FC<ItemProps> = ({
  title,
  suffix,
  description,
  disabled = false,
  prefix,
  subTitle,
  titleProps,
  background = false,
  onClick,
  className = '',
  loading = false
}) => {
  const handleListItemClick = () => {
    onClick?.();
  };

  return (
    <div
      onClick={handleListItemClick}
      className={`${className} ${styles.tappable_list_item_container} ${
        background ? styles.background_style : ''
      } ${disabled ? styles.disabled_tappable_list_item : ''}`}
    >
      <div className={styles.left_bar}>
        <ShowElement isShow={Boolean(prefix)}>
          <div className={styles.left_prefix_container}>{prefix}</div>
        </ShowElement>
        <div className={styles.title_content}>
          <Suspensor loading={loading} fallback={<Skeleton.Title animated />}>
            <UcBodyText size="small" {...titleProps}>
              {title}
            </UcBodyText>
          </Suspensor>

          <div>
            <ShowElement isShow={Boolean(subTitle)}>
              <UcBodyText size="extraSmall" fontWeight="regular">
                {subTitle}
              </UcBodyText>
            </ShowElement>
          </div>

          <div>
            <ShowElement isShow={Boolean(description)}>
              <UcBodyText
                type="secondary-dark"
                size="extraSmall"
                fontWeight="regular"
              >
                {description}
              </UcBodyText>
            </ShowElement>
          </div>
        </div>
      </div>

      <div className={styles.right_bar}>
        <ShowElement isShow={Boolean(suffix)}>{suffix}</ShowElement>
      </div>
    </div>
  );
};

export default Item;
