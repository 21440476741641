import { FC, ReactNode } from 'react';

import styles from './styles.module.less';
import { Typography } from 'components/basic';

const { UcBodyText } = Typography;

interface KeyboardKeyProps {
  label: string | ReactNode;
}

const KeyboardKey: FC<KeyboardKeyProps> = ({ label }) => {
  return (
    <span className={styles.keyboard_key}>
      <UcBodyText size="small" fontWeight="bold">
        {label}
      </UcBodyText>
    </span>
  );
};

export default KeyboardKey;
