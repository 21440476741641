import { memo, useLayoutEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { coreConfigs } from 'apollo/cache';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const DayjsTimezone = () => {
  const { timezone } = useReactiveVar(coreConfigs);
  useLayoutEffect(() => {
    dayjs.tz.setDefault(timezone);

    return () => {
      dayjs.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);
    };
  }, [timezone]);

  return <></>;
};

export default memo(DayjsTimezone);
