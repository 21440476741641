import { Tabs, App } from 'antd';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { usePermissionAndLimitationContext } from 'providers/PermissionAndLimitationProvider';
import { useCallback, useMemo } from 'react';
import {
  Channel,
  useChannelLazyQuery,
  useChannelsQuery,
  useVisualEditorVisitedMutation
} from 'generatedHooks/commerce/generated';
import { useProjectContext } from 'providers/ProjectProvider';
import { useTranslation } from 'react-i18next';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import goToVisualEditorConfirmation from 'helpers/commonHelpers/goToVisualEditorConfirmation';

import styles from './styles.module.less';
import { ACTIVE_KEY } from './constants';

const { VITE_APP_SSO_URL } = import.meta.env;
const { useApp } = App;

const Channels = () => {
  const { modal } = useApp();
  const { t } = useTranslation(['channels', 'common']);

  const CanFunction = useCanFunction('tab');

  const { data } = useChannelsQuery();
  const channel = useMemo(() => data?.channels?.data[0], [data]);
  const [getChannel] = useChannelLazyQuery();

  const [markVisualEditorVisited] = useVisualEditorVisitedMutation({
    variables: {
      id: channel?.id as string
    },
    update(cache) {
      cache.modify({
        fields: {
          channels: (existing = { data: [] }, { readField }) => {
            return existing.data.map((exChannel: Channel) => {
              if (readField('id', exChannel) === channel?.id) {
                return {
                  ...exChannel,
                  visualEditorVisited: true
                };
              }

              return exChannel;
            });
          }
        }
      });
    }
  });

  const {
    state: {
      permissions: {
        actions: { Access }
      }
    }
  } = usePermissionAndLimitationContext();

  const { id } = useProjectContext();

  const REDIRECT_URLS = {
    CMS: `${VITE_APP_SSO_URL}/callback?projectId=${id}&returnUrl=/admin`,
    VISUAL_EDITOR: `${VITE_APP_SSO_URL}/callback?projectId=${id}&returnUrl=/admin/~/visual-editor`
  };

  const openVisualEditor = () =>
    window.open(REDIRECT_URLS.VISUAL_EDITOR, '_blank');

  const confirmVisualEditorNavigation = useCallback(
    () =>
      goToVisualEditorConfirmation({
        t,
        confirm: modal.confirm,
        onOk() {
          try {
            markVisualEditorVisited();
          } catch (e) {
            console.error(e);
          } finally {
            openVisualEditor();
          }
        }
      }),
    []
  );

  const goToVisualEditor = useCallback(async () => {
    // If channel.visualEditorVisited was initially true, no need to revalidate this value
    if (channel?.visualEditorVisited) {
      openVisualEditor();

      return;
    }

    try {
      // Revalidating channel data, to make sure the of visualEditorVisited is fresh
      const { data } = await getChannel({
        fetchPolicy: 'network-only'
      });

      data?.channel?.visualEditorVisited
        ? openVisualEditor()
        : confirmVisualEditorNavigation();
    } catch (e) {
      confirmVisualEditorNavigation();
    }
  }, [channel?.visualEditorVisited]);

  const tabHandlersMapping = {
    [ACTIVE_KEY]: () => {},
    CMS: () => window.open(REDIRECT_URLS.CMS, '_blank'),
    VISUAL_EDITOR: goToVisualEditor
  };

  const items = useMemo(
    () =>
      [
        {
          key: ACTIVE_KEY,
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Tab,
            prefix: 'project'
          }),
          tab: t('common:project'),
          disabled: false
        },
        CanFunction({
          I: Access.CMS,
          children: {
            key: 'CMS',
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Tab,
              prefix: 'content'
            }),
            tab: t('channels:content')
          }
        }),
        CanFunction({
          I: Access.VisualEditor,
          children: {
            key: 'VISUAL_EDITOR',
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Tab,
              prefix: 'design'
            }),
            tab: t('channels:design')
          }
        })
      ].map(({ tab, disabled, key, ...restArgs }: any) => ({
        key: key,
        label: <div data-test={restArgs['data-test']}>{tab}</div>,
        style: {
          fontWeight: 400,
          paddingLeft: 0
        },
        disabled
      })),
    [CanFunction]
  );

  return (
    <Tabs
      type="line"
      className={styles.channels}
      tabPosition="bottom"
      onChange={activeKey => {
        return tabHandlersMapping[
          activeKey as keyof typeof tabHandlersMapping
        ]();
      }}
      activeKey={ACTIVE_KEY}
      items={items}
    />
  );
};

export default Channels;
