import React, { FC } from 'react';
import { Button } from 'antd';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as Ellipses } from 'assets/icons/ellipsis.svg';

import { ArrowLeftIcon } from 'components/shared/SVG';
import styles from './styles.module.less';

export type ButtonTypes = 'back' | 'add' | 'close' | 'default';

interface ButtonProps {
  type?: ButtonTypes;
  children?: React.ReactNode;
  handleClick?: (param?: any) => void;
  className?: string;
  closeClassName?: string;
  disabled?: boolean;
  underline?: boolean;
}

interface IEllipses {
  className?: string;
  handleClick?: (param?: any) => void;
}

interface ILink {
  type: 'default' | 'primary';
  children?: React.ReactNode;
  handleClick?: (param?: any) => void;
  danger?: boolean;
  extraClasses?: string;
}

const IconButton: FC<ButtonProps> = ({
  type = 'back',
  children,
  handleClick,
  className,
  closeClassName,
  disabled,
  underline
}) => {
  const ButtonContent = {
    back: (
      <>
        <ArrowLeftIcon /> {children}
      </>
    ),
    add: (
      <>
        <PlusOutlined style={{ color: 'var(--adm-color-primary)' }} />
        &nbsp;
        {children}
      </>
    ),
    close: (
      <div className={styles.closeBlock}>
        {children}{' '}
        <Icon
          component={CloseIcon}
          className={`${styles.closeIcon} ${closeClassName}`}
        />
      </div>
    ),
    default: <div className={styles.defaultBtn}>{children}</div>
  };

  return (
    <Button
      disabled={disabled}
      type="text"
      onClick={handleClick && handleClick}
      className={`${styles.iconBtnMain}  ${
        underline ? styles.underline : ''
      } ${className}`}
    >
      {ButtonContent[type]}
    </Button>
  );
};

const EllipseButton: FC<IEllipses> = ({ className, handleClick, ...rest }) => {
  return (
    <Button
      onClick={handleClick && handleClick}
      className={`${styles.ellipsesBtn} ${className}`}
      {...rest}
    >
      <Icon component={Ellipses} className={styles.ellipsisIcon} />
    </Button>
  );
};

const LinkButton: FC<ILink> = ({
  type,
  children,
  handleClick,
  danger,
  extraClasses = ''
}) => {
  const className = type === 'default' ? styles.default : styles.primary;

  return (
    <Button
      type="link"
      className={`${className} ${extraClasses}`}
      onClick={handleClick && handleClick}
      danger={danger}
    >
      {children}
    </Button>
  );
};

export { IconButton, EllipseButton, LinkButton };
