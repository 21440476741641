import { BulkActionEntity } from 'generatedHooks/commerce/generated';

import { BULK_ACTIONS_META_DATA } from './constants';

export const getEntityTranslationKey = (
  entity: BulkActionEntity,
  count = 0
) => {
  const key = BULK_ACTIONS_META_DATA[entity].entityTranslationKey;

  return count === 1 ? `bulkActions:${key}` : `bulkActions:${key}Plural`;
};
