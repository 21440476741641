import { TFunction } from 'react-i18next';

export const columns = (t: TFunction) => {
  return [
    {
      title: t('line'),
      dataIndex: 'line',
      key: 'line',
      width: 80
    },
    {
      title: t('error'),
      dataIndex: 'error',
      key: 'message'
    }
  ];
};
