import { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from './UIComponents/Modal';

type Props = {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (url: string) => void;
  loading: boolean;
};

/**
 * Renders modal with and design input to upload images from url.
 * @param loading - Gets loading state after click save Button.
 * @param visible - Detect if modal visible or not.
 * @param onCancel - Function triggers after modal close.
 * @param onConfirm - Function triggers after click save Button.
 * @returns ReactNode.
 */
function ImportFromUrlModal({ loading, visible, onCancel, onConfirm }: Props) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['media', 'common', 'errors']);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setDisabled(true);
    }
  }, [visible]);

  const onKeyUpEnter = (e: { keyCode: number }) => {
    return e.keyCode === 13 && form.submit();
  };

  return (
    <Modal
      open={visible}
      centered
      title={t('importFromUrl')}
      okText={t('common:upload')}
      cancelText={t('common:cancel')}
      onCancel={onCancel}
      onOk={form.submit}
      okButtonProps={{ loading, disabled }}
    >
      <Form
        form={form}
        onKeyUp={onKeyUpEnter}
        onFinish={({ url }) => {
          onConfirm(url);
        }}
        onFieldsChange={() => {
          setDisabled(form.getFieldError('url')?.length > 0);
        }}
      >
        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: t('errors:required', {
                field: t('common:url')
              })
            },
            {
              type: 'url',
              message: t('errors:invalidField', {
                field: t('common:url')
              })
            }
          ]}
        >
          <Input
            autoComplete="off"
            autoFocus
            type="text"
            placeholder={t('urlPlaceholder')}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ImportFromUrlModal;
