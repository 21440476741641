import { BulkActionsProcess } from 'services/bulkActions/types';

export enum ActionTypes {
  ADD_NEW_PROCESS = 'ADD_NEW_PROCESS',
  REMOVE_PROCESS = 'REMOVE_PROCESS'
}

export type Action =
  | {
      type: ActionTypes.ADD_NEW_PROCESS;
      payload: BulkActionsProcess;
    }
  | {
      type: ActionTypes.REMOVE_PROCESS;
      payload: string;
    };

export enum CompletedProcessTypes {
  ALL_SUCCEEDED = 'ALL_SUCCEEDED',
  PARTIALLY_SUCCEEDED = 'PARTIALLY_SUCCEEDED',
  ALL_FAILED = 'ALL_FAILED',
  EMPTY_REPORT = 'EMPTY_REPORT'
}
