import { FC } from 'react';
import { PureNullable } from 'core/globalTypes';

import styles from './styles.module.less';
import { DateProps } from './types';
import DatePickerFormatter from 'components/shared/DatePickerFormatter';

const Date: FC<DateProps> = ({ placeholder, onChange, value, ...rest }) => {
  const handleDateChange = (date: PureNullable<string>) => {
    onChange?.(date);
  };

  return (
    <DatePickerFormatter
      value={value}
      placeholder={placeholder}
      onChange={handleDateChange}
      className={styles.date_filter}
      {...rest}
    />
  );
};

export default Date;
