import { FC, useState, useEffect, ChangeEvent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory, useFilterQuery } from 'hooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { Input } from 'components/basic';
import styles from './styles.module.less';
import { SearchProps } from './types';
import useDebounce from 'hooks/useDebounce';
import { SEARCH_KEY } from './constants';

const Search: FC<SearchProps> = ({
  value,
  allowClear = true,
  placeholder,
  defaultValue,
  maxFilterCount = 1,
  onChange,
  fullWidthInput = false,
  syncWithQueryParams = true,
  ...restProps
}) => {
  const { queryObject } = useFilterQuery();
  const [inputValue, setInputValue] = useState(
    syncWithQueryParams ? queryObject.search || '' : ''
  );

  const location = useLocation();
  const { push, resetQuery } = useHistory();
  const debouncedSearchValue = useDebounce(inputValue, 600);

  const isMobile = useReactiveVar(isMobileDevice);

  useEffect(() => {
    const handleChangeQueryString = () => {
      if (debouncedSearchValue?.trim('').length >= maxFilterCount) {
        const { page } = qs.parse(location.search, {
          ignoreQueryPrefix: true
        });

        return push({
          search: debouncedSearchValue,
          ...(page && { page: 1 })
        });
      }
    };

    if (syncWithQueryParams) {
      handleChangeQueryString();
    }

    if (debouncedSearchValue === null) {
      resetQuery(SEARCH_KEY);
    }
  }, [debouncedSearchValue, syncWithQueryParams]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      e.stopPropagation();
    }

    const { value } = e.target;

    if (
      queryObject?.search?.length === maxFilterCount &&
      value.length < maxFilterCount
    ) {
      resetQuery(SEARCH_KEY);
    }

    if (onChange) {
      onChange(value);
    } else {
      setInputValue(value ? value : null);
    }
  };

  return (
    <div className={styles.ucSearchWrapper}>
      <Input
        value={value ? value : inputValue}
        onChange={handleSearch}
        allowClear={allowClear}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`${styles.ucSearch}${
          fullWidthInput ? styles.search_input_full_width : ''
        } ${restProps.className ? restProps.className : ''} ${
          isMobile ? styles.mobile : ''
        }`}
        prefix={<SearchOutlined className={styles.searchIconOutlined} />}
        {...restProps}
      />
    </div>
  );
};

export default Search;
