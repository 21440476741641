import { Radio } from 'antd';
import { FC } from 'react';

import { CategoryTagChooserProps } from 'components/shared/SectionWrapperChart/types';
import { Typography } from 'components/basic';
import styles from './styles.module.less';
import ShowElement from 'components/shared/ShowElement';

const { UcBodyText } = Typography;

const CategoryTagChooser: FC<CategoryTagChooserProps> = ({
  dataSource,
  onChange,
  value
}) => {
  const RadioBtn = dataSource.map(option => (
    <Radio.Button
      key={option.name}
      value={option.name}
      className={styles.info_tag_btn}
    >
      <div className={styles.info_tag_btn_inner}>
        <UcBodyText
          size="small"
          fontWeight="regular"
          className="info_tag_btn_name"
        >
          {option.label}
        </UcBodyText>
        <UcBodyText
          size="base"
          fontWeight="bold"
          className="info_tag_btn_count"
        >
          {option.value}
        </UcBodyText>
      </div>
    </Radio.Button>
  ));

  return (
    <div className={styles.info_tag_container}>
      <ShowElement isShow={Boolean(dataSource.length)}>
        <Radio.Group onChange={onChange} value={value}>
          {RadioBtn}
        </Radio.Group>
      </ShowElement>
    </div>
  );
};

export default CategoryTagChooser;
