import { useEffect } from 'react';
import OneSignal from 'react-onesignal';

const { VITE_APP_ONE_SIGNAL_APP_ID, VITE_APP_ONE_SIGNAL_APP_KEY } = import.meta
  .env;

const useInitOneSignal = () => {
  useEffect(() => {
    if (VITE_APP_ONE_SIGNAL_APP_ID) {
      OneSignal.init({
        appId: VITE_APP_ONE_SIGNAL_APP_ID,
        apiKey: VITE_APP_ONE_SIGNAL_APP_KEY,
        allowLocalhostAsSecureOrigin: true
      }).then(() => {
        console.log('Init OneSignal!');
      });
    }
  }, []);
};

export default useInitOneSignal;
