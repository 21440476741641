import { Col, Row } from 'antd';
import React, { FC, ReactNode, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { ArrowLeftIcon } from 'components/shared/SVG';
import { BackButton } from 'components/shared';
import { Space, Typography } from 'components/basic';
import styles from './styles.module.less';
import HeaderContent from 'components/mobile/Header/Content';
import PageTitle from 'components/mobile/pageTitle';

const { UcHeading } = Typography;

interface Props {
  title: ReactNode | string;
  backUrl?: string;
  extra?: ReactNode;
  titleExtra?: ReactNode;
  onBack: () => void;
}

const NestedPageHeader: FC<Props> = ({ title, extra, titleExtra, onBack }) => {
  const isMobile = useReactiveVar(isMobileDevice);

  const titleContent = useMemo(() => {
    return typeof title === 'string' ? (
      <UcHeading ellipsis level={3} defaultMargin={false}>
        {title}
      </UcHeading>
    ) : (
      title
    );
  }, [title]);

  const titleContentMobile = useMemo(() => {
    return typeof title === 'string' ? (
      <PageTitle size="base" fontWeight="bold" text={title || ''} />
    ) : (
      title
    );
  }, [title]);

  if (isMobile) {
    return (
      <>
        <HeaderContent position="left">
          <ArrowLeftIcon onClick={onBack} />
        </HeaderContent>
        <HeaderContent position="center">{titleContentMobile}</HeaderContent>
        <HeaderContent position="right">{extra || null}</HeaderContent>
      </>
    );
  }

  return (
    <Row
      wrap={false}
      className={styles.header}
      justify="space-between"
      align="middle"
    >
      <Col span={12} className={styles.invite_members_header_title}>
        <Row align="middle">
          <Space align="center">
            <Col span={2}>
              <BackButton onClick={onBack} />
            </Col>
            <Col xs={16} sm={24} span={24}>
              {titleContent}
            </Col>
            <Col span={4}>{titleExtra}</Col>
          </Space>
        </Row>
      </Col>
      {extra ? <Col>{extra}</Col> : null}
    </Row>
  );
};

export default NestedPageHeader;
