import React, { useState } from 'react';
import { Skeleton } from 'antd';

import styles from './styles.module.less';

type Props = {
  src?: string;
  alt?: string;
  /**
   * Width of the placeholder SVG in 'px'
   */
  width?: string | number;
  /**
   * Height of the placeholder SVG in 'px'
   */
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
};

function LazyImage({
  src,
  alt,
  width,
  height,
  minWidth,
  minHeight,
  onError,
  ...props
}: Props) {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        <Skeleton.Avatar
          className={styles.skeleton}
          active
          shape="square"
          style={{
            minWidth: !minWidth ? '300px' : minWidth,
            minHeight: !minHeight ? '150px' : minHeight,
            width: !width ? '100%' : width,
            height: !height ? '100%' : height
          }}
        />
      )}
      <img
        {...props}
        src={src}
        alt={alt}
        style={{
          width,
          height,
          borderRadius: 0
        }}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={onError}
      />
    </>
  );
}

export default LazyImage;
