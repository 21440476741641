import { Skeleton, Space } from 'antd';
import { FC } from 'react';

import { ButtonSkeletonProps } from 'components/shared/Skeleton/types';

const Button: FC<ButtonSkeletonProps> = ({ count }: { count: number }) => {
  const countObButtons = Array(count).fill(1);

  return (
    <Space size={16}>
      {countObButtons.map((_: number, idx: number) => (
        <Skeleton.Input
          key={idx}
          active
          style={{ width: 95, height: 40, borderRadius: 12 }}
        />
      ))}
    </Space>
  );
};

export default Button;
