import React, { FC, useMemo, useState } from 'react';
import { Popover } from 'antd';
import { CheckOutline } from 'antd-mobile-icons';

import { Props } from './interface';
import styles from './styles.module.less';

const MobilePopover: FC<Props> = ({
  value,
  width = 'auto',
  onChange,
  placement,
  menuItems,
  valueKey = 'key',
  labelKey = 'text',
  style,
  showCheckIcon,
  closePopupAfterSelect = true,
  className,
  styleContent,
  children
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleVisibleChange = () => setVisible(!visible);

  const content = useMemo(() => {
    return menuItems.map(
      ({ icon, color, key, disabled, handleClick, onClick, ...restData }) => {
        return (
          <div
            key={restData[valueKey]}
            style={{ color }}
            className={`${styles.popover_dropdown_item} ${
              disabled ? styles.disabled_item : ''
            }`}
            onClick={e => {
              e.stopPropagation();
              handleClick && handleClick(key);

              if (!disabled) {
                onClick && onClick();
                closePopupAfterSelect && setVisible(false);
                onChange && onChange(restData[valueKey]);
              }
            }}
          >
            <div
              className={`${styles.row_content} ${
                showCheckIcon ? styles.popover_option_content : ''
              }`}
            >
              {icon && (
                <span style={{ color }} className={styles.icon_container}>
                  {icon}
                </span>
              )}
              {restData[labelKey]}

              {showCheckIcon && value === restData[valueKey] && (
                <span className={styles.show_icon_container}>
                  <CheckOutline />
                </span>
              )}
            </div>
          </div>
        );
      }
    );
  }, [menuItems, value]);

  return (
    <Popover
      trigger="click"
      placement={placement}
      open={visible}
      content={content}
      arrowPointAtCenter
      onOpenChange={handleVisibleChange}
      className={`${className}`}
      overlayInnerStyle={{
        borderRadius: '8px',
        width,
        ...style
      }}
      overlayClassName={`${styles.mobile_popover_wrapper}  ${styleContent}`}
    >
      {children}
    </Popover>
  );
};

export default MobilePopover;
