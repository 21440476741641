import { Collapse, CollapseProps } from 'antd';
import { FC } from 'react';

import UcPanel, { UcPanelProps } from './panel';

interface UcCollapseOptions extends Array<UcPanelProps> {}

interface Props extends CollapseProps {
  options?: UcCollapseOptions;
}

const BaseCollapse: FC<Props> = props => {
  const { options, children, ...rest } = props;

  return (
    <Collapse {...rest}>
      {options?.map(option => {
        return <UcPanel {...option} key={option.key} />;
      })}
      {children}
    </Collapse>
  );
};

const UcCollapse = Object.assign(BaseCollapse, Collapse, { Panel: UcPanel });

export default UcCollapse;
