import { FC } from 'react';

import { Card } from 'components/basic';
import styles from './styles.module.less';
import { CardWrapperChartProps } from 'components/shared/CardWrapperChart/types';
import { CardWrapperHeader, RealTimeHeader, TimeGroup } from './components';

const CardWrapperChart: FC<CardWrapperChartProps> = ({
  children,
  cardTitle,
  className
}) => {
  return (
    <Card
      title={cardTitle}
      headStyle={{ padding: '0 16px' }}
      bodyStyle={{ padding: '0 16px 16px 16px' }}
      className={`${className} ${styles.card_wrapper_container}`}
    >
      {children}
    </Card>
  );
};

export default Object.assign(CardWrapperChart, {
  CardWrapperHeader,
  RealTimeHeader,
  TimeGroup
});
