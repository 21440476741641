import { KeyboardKey } from 'components/shared/index';
import { Typography } from 'components/basic';

const { UcBodyText } = Typography;

const renderKeyboardKey = (label: string | string[], operator: string) => {
  const extra = {
    string: <KeyboardKey label={label} />,
    object: (
      <div style={{ display: 'flex', gap: 10, alignItems: 'baseline' }}>
        {Array.isArray(label) &&
          label.map((item, index, labels) => {
            const isLastItem = index === labels.length - 1;

            return (
              <>
                <KeyboardKey label={item} />
                {!isLastItem && (
                  <UcBodyText size="small" type="secondary" fontWeight="bold">
                    {operator}
                  </UcBodyText>
                )}
              </>
            );
          })}
      </div>
    )
  };

  return extra[typeof label as 'string' | 'object'];
};

export default renderKeyboardKey;
