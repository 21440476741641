import React, { FC } from 'react';
import { Avatar as AntAvatar } from 'antd';
import { RelatedUser } from 'generatedHooks/accounts/generated';
import { nameToAbbreviation } from 'helpers';

interface Props {
  data: Array<RelatedUser>;
  avatarMaxShowCount: number;
}

const Avatar: FC<Props> = ({ data, avatarMaxShowCount }) => {
  return (
    <AntAvatar.Group maxCount={avatarMaxShowCount}>
      {data.map(({ id, avatarUrl, firstName }) => (
        <AntAvatar
          key={id}
          src={avatarUrl}
          style={{ color: '#595959', backgroundColor: '#F5F5F5' }}
        >
          {nameToAbbreviation(firstName || '')}
        </AntAvatar>
      ))}
    </AntAvatar.Group>
  );
};

export default Avatar;
