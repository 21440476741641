import React, { FC, ReactNode } from 'react';
import { Image } from 'antd-mobile';

import { Typography } from 'components/basic';
import { ShowElement } from 'components/shared';
import styles from './styles.module.less';

export interface ItemProps {
  imgUrl?: string;
  name?: string;
  description?: string | ReactNode;
  prefix?: ReactNode;
  price?: string | ReactNode;
  extra?: ReactNode;
}

const { UcBodyText } = Typography;

const Item: FC<ItemProps> = ({
  imgUrl,
  name,
  extra,
  description,
  prefix,
  price
}) => {
  return (
    <div className={styles.product_list_item_container}>
      <div className={styles.product_list_main_content}>
        <div className={styles.left_bar}>
          <ShowElement isShow={Boolean(prefix)}>{prefix}</ShowElement>
          <Image className={styles.product_img} src={imgUrl} />
        </div>

        <div className={styles.right_bar}>
          <div className={styles.product_info_content}>
            <UcBodyText display="block" size="small" fontWeight="medium">
              {name}
            </UcBodyText>

            <UcBodyText
              display="block"
              type="secondary"
              size="small"
              fontWeight="regular"
            >
              {description}
            </UcBodyText>

            <UcBodyText fontWeight="medium" size="small">
              {price}
            </UcBodyText>
          </div>
        </div>
      </div>

      <div className={styles.extra_container}>{extra}</div>
    </div>
  );
};

export default Item;
