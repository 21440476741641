import { Maybe } from 'core/globalTypes';

/**
 * Creates a space separated string with provided values with only truthy values
 * @param items array of unlimited number of arguments of type string;
 */

const trimmer = (...items: Maybe<string>[]) => {
  return items.filter(Boolean).join(' ').trim();
};

export default trimmer;
