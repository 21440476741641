import React from 'react';
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons';

import styles from './styles.module.less';
import SoundOffIcon from './SoundOff';
import SoundOnIcon from './SoundOn';
import Slider from './Slider';
import { ControlsType } from './types';

function AudioControls({
  muted,
  togglePlay,
  toggleMuted,
  playing,
  duration,
  volume,
  progress,
  changePlayed,
  changeVolume
}: ControlsType) {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.PlayPauseIcons} onClick={togglePlay}>
        {playing ? <PauseCircleFilled /> : <PlayCircleFilled />}
      </div>
      <div style={{ flexGrow: 1 }}>
        <Slider
          value={progress.played}
          onChange={changePlayed}
          trailColor="var(--ant-black-15)"
          strokeColor="var(--ant-primary-color)"
        >
          <Slider.Progress
            percent={progress.loaded}
            color="var(--ant-black-15)"
          />
        </Slider>
      </div>
      <div className={styles.Time}>
        {progress.playedSeconds} / {duration}
      </div>
      <div className={styles.VolumeIcon} onClick={toggleMuted}>
        {muted ? <SoundOffIcon /> : <SoundOnIcon />}
      </div>
      <div className={styles.Volume}>
        <Slider
          value={volume}
          trailColor="var(--ant-black-15)"
          strokeColor="var(--ant-primary-color)"
          onChange={changeVolume}
        />
      </div>
    </div>
  );
}

export default AudioControls;
