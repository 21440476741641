import React, { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  children?: ReactNode;
}

const Details: FC<Props> = ({ children }) => {
  return <div className={styles.details}>{children}</div>;
};

export default Details;
