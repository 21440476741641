import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { linkTypes } from 'apollo/link';
import { App, Col, Row } from 'antd';
import { useSettingsDispatchContext } from 'providers/SettingsProvider';
import { isEqual } from 'lodash';
import {
  ExclamationCircleOutlined,
  StopOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useNavigationContext } from 'providers/NavigationProvider';
import { Locale, useLocalesQuery } from 'generatedHooks/commerce/generated';
import { useReactiveVar } from '@apollo/client';
import { globalConfigs, isMobileDevice } from 'apollo/cache';
import {
  ScriptPosition,
  ScriptTranslationInput,
  useScriptQuery,
  useSetScriptsMutation
} from 'generatedHooks/builder/generated';
import { useWatch } from 'antd/lib/form/Form';
import { CLEAR_LAYOUT_CACHE_KEY } from 'core/constants/common';
import { localeInLabel } from 'helpers/commonHelpers/localeInLabel';
import {
  createDataTestAttribute,
  modalConfirmRenderWithDataTest
} from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Locale as LocalesDropdown } from 'components/shared';
import { PageContainer } from 'components/wrapper';
import { SettingsContentContainer } from 'pages/settings/components/SettingsContentContainer';
import UcForm from 'components/core/form';
import styles from './styles.module.less';
import { Button } from 'components/basic';
import {
  generateDeleteScriptsMessage,
  getInitialActions,
  initialFormDataAdapter,
  scriptsMutationInputAdapter
} from './utils';
import Skeleton from './components/Skeleton';
import { CodeInjectionFormData } from './types';
import CodeEditor from './components/CodeEditor';
import { FIELDS_LABELS_TRANSLATIONS } from './constants';

const { useApp } = App;

const localesDataEmpty: Locale[] = [];

const CodeInjection = () => {
  const [form] = UcForm.useForm();
  const { modal } = useApp();
  const { setSettingsPageContent } = useSettingsDispatchContext();
  const { setShouldShowConfirm } = useNavigationContext();
  const isMobile = useReactiveVar(isMobileDevice);

  const isScriptSaved = useRef(false);

  const { defaultInternationalCode } = useReactiveVar(globalConfigs);
  const selectedLocale = useWatch('locale', form);

  const { t } = useTranslation('codeInjection');

  const [setScripts, { loading: setScriptsLoading }] = useSetScriptsMutation({
    context: {
      urlType: linkTypes.builder
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          script: existing => {
            console.log(existing, 'existing');

            if (data?.setScripts) {
              return data?.setScripts;
            }

            return existing;
          }
        }
      });
    }
  });

  const { data: locales, loading: localesLoading } = useLocalesQuery({
    variables: { input: {} }
  });

  const { data, loading: scriptsLoading } = useScriptQuery({
    context: {
      urlType: linkTypes.builder,
      [CLEAR_LAYOUT_CACHE_KEY]: true
    }
  });

  const loading = scriptsLoading || localesLoading;

  const localesData = (locales?.locales?.data || localesDataEmpty) as Locale[];

  const initialFormDataCollection = useMemo(() => {
    const scriptsFormData: CodeInjectionFormData = initialFormDataAdapter(
      data?.script,
      locales?.locales?.data as Locale[]
    );

    const localeFormData = { locale: defaultInternationalCode as string };

    return { scriptsFormData, localeFormData };
  }, [data, defaultInternationalCode, locales?.locales?.data]);

  useEffect(() => {
    form.setFieldsValue(initialFormDataCollection.scriptsFormData);
  }, [initialFormDataCollection.scriptsFormData]);

  const renderInitialActions = () => {
    setShouldShowConfirm(false);

    setSettingsPageContent(
      <UcForm
        form={form}
        initialValues={initialFormDataCollection.localeFormData}
      >
        {getInitialActions({ localesData, localesLoading })}
      </UcForm>
    );
  };

  const handleDiscard = () => {
    const scriptsValues = initialFormDataCollection.scriptsFormData;

    for (const key in scriptsValues) {
      form.setFieldValue(key, scriptsValues[key as ScriptPosition]);
    }

    form.setFieldsValue(scriptsValues);

    renderInitialActions();
  };

  const saveScripts = async (translations: ScriptTranslationInput[]) => {
    await setScripts({
      variables: {
        input: {
          translations
        }
      },
      onCompleted() {
        isScriptSaved.current = true;

        renderInitialActions();
      }
    });
  };

  const handleSave = () => {
    form
      .validateFields()
      .then(async data => {
        try {
          const { translations, deleteScriptMessage } =
            scriptsMutationInputAdapter(
              data,
              initialFormDataCollection.scriptsFormData,
              defaultInternationalCode
            );

          if (deleteScriptMessage.length) {
            const deletableScripts = generateDeleteScriptsMessage(
              t,
              deleteScriptMessage
            );

            modal.confirm({
              title: t('confirmTitle'),
              icon: <ExclamationCircleOutlined />,
              centered: true,
              //TODO: find solution for using HTML 4.0 special entities in i18n
              content: (
                <Trans
                  shouldUnescape
                  t={t}
                  i18nKey="codeInjection:deletableScripts"
                  components={{ strongText: <strong /> }}
                  values={{ deletableScripts }}
                  defaults="You have deleted the <strongText>{{deletableScripts}}<strongText> codes which will result in changes on your website. Are you sure you want to continue?"
                />
              ),
              okText: t('common:continue'),
              cancelText: t('common:cancel'),
              onOk: async () => {
                await saveScripts(translations);
              },
              cancelButtonProps: {
                'data-test': createDataTestAttribute({
                  dataTestAttribute: DataTestAttributes.Button,
                  prefix: 'cancel'
                })
              },
              okButtonProps: {
                'data-test': createDataTestAttribute({
                  dataTestAttribute: DataTestAttributes.Button,
                  prefix: 'confirm'
                })
              },
              modalRender: modalConfirmRenderWithDataTest
            });
          } else {
            await saveScripts(translations);
          }
        } catch (e) {
          console.error(e);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleShowButtons = () => {
    const scriptValues = form.getFieldsValue(Object.values(ScriptPosition));

    if (
      !isEqual(scriptValues, initialFormDataCollection.scriptsFormData) &&
      !isScriptSaved.current
    ) {
      setShouldShowConfirm(true);

      setSettingsPageContent(
        <UcForm
          form={form}
          initialValues={initialFormDataCollection.localeFormData}
        >
          <Row gutter={16} justify="end">
            <Col>
              <LocalesDropdown
                locales={localesData}
                localesLoading={localesLoading}
                key="locale"
              />
            </Col>
            <Col>
              <Button
                icon={isMobile ? <StopOutlined /> : undefined}
                onClick={handleDiscard}
              >
                {t('discard')}
              </Button>
            </Col>
            <Col>
              <Button
                icon={isMobile ? <CheckOutlined /> : undefined}
                onClick={handleSave}
                loading={setScriptsLoading}
                type="primary"
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </UcForm>
      );
    } else {
      renderInitialActions();
    }
  };

  useEffect(() => {
    handleShowButtons();
  }, [setScriptsLoading]);

  useEffect(() => {
    renderInitialActions();

    return () => setSettingsPageContent(null);
  }, [localesData, localesLoading]);

  return (
    <PageContainer>
      <SettingsContentContainer title={t('codeInjection')} subtitle={t('desc')}>
        {loading ? (
          <Skeleton />
        ) : (
          <UcForm
            onChange={() => {
              isScriptSaved.current = false;

              handleShowButtons();
            }}
            form={form}
            layout="vertical"
            className={styles.code_injection_wrapper}
          >
            {Object.values(ScriptPosition).map(position => {
              return (
                <UcForm.Item
                  key={position}
                  name={position}
                  label={
                    <label>
                      {t(
                        position,
                        FIELDS_LABELS_TRANSLATIONS[position].defaultValue
                      )}
                      {localeInLabel(selectedLocale)}
                    </label>
                  }
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.locale !== currentValues.locale
                  }
                >
                  <CodeEditor
                    dataTest={position}
                    selectedLocale={selectedLocale}
                  />
                </UcForm.Item>
              );
            })}
          </UcForm>
        )}
      </SettingsContentContainer>
    </PageContainer>
  );
};

export default CodeInjection;
