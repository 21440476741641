import React from 'react';

import Options, { OptionsItemType } from '../Options';
import { VisiblePosition } from './OverlayContext';
import useOverlay from './useOverlay';

type Props = {
  items: OptionsItemType[];
  position?: VisiblePosition;
  size?: number;
};

function OverlayOptions({ position = 'right', ...optionsProps }: Props) {
  const overlayContext = useOverlay();

  return (
    <Options
      {...optionsProps}
      onToggle={visible => {
        overlayContext.setVisiblePosition(visible ? position : 'all');
      }}
      color="var(--ant-gray-13)"
    />
  );
}

export default OverlayOptions;
