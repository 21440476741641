import { Dispatch, SetStateAction, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Image, Typography } from 'antd';
import UploadFromMedia from 'packages/Media/UploadFromMedia';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { StarFilledIcon, StarIcon } from 'components/shared/SVG';
import styles from '../styles.module.less';
import useMedia from 'hooks/useMedia';

interface Props {
  isMediaSelected: boolean;
  getMediaById: (id: string) => any;
  previewMedia: any[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setIsMediaSelected: Dispatch<SetStateAction<boolean>>;
  setPreviewMedia: Dispatch<SetStateAction<any[]>>;
  setIds: (ids: number[] | null) => void;
  setDefaultMediaId?: (
    id: number | undefined | null
  ) => void | Dispatch<SetStateAction<number | null>>;
  defaultMediaId?: number | null;
  setInitialMediaIds?: (
    id: number[] | undefined | null
  ) => void | Dispatch<SetStateAction<number[] | null>>;
  initialMediaIds?: number[] | null;
  index?: number;
  hasDefault?: boolean;
}

const MediaForProduct = ({
  // isMediaSelected,
  getMediaById,
  // previewMedia,
  visible,
  setVisible,
  setIsMediaSelected,
  setPreviewMedia,
  setIds,
  setDefaultMediaId,
  defaultMediaId,
  initialMediaIds = [],
  hasDefault
}: // index
Props) => {
  // const [currentIds, setCurrentIds] = useState<number[] | null>(
  //   initialMediaIds
  // );

  // useEffect(() => {
  //   setCurrentIds(initialMediaIds);
  // }, [initialMediaIds]);

  // useEffect(() => {
  //   return () => {
  //     setCurrentIds([]);
  //   };
  // }, []);

  const [preview, setPreview] = useState('');
  const { imagePath, thumbnailPath } = useMedia();

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Icon,
          prefix: 'media'
        })}
        className={styles.categoriesWithNoImage}
        onClick={e => {
          e.stopPropagation();

          if (!visible) {
            setVisible(true);
          }
        }}
      >
        <PlusOutlined style={{ color: '#8C8C8C' }} />
      </div>
      <Image
        style={{
          display: 'none'
        }}
        preview={{
          visible: !!preview,
          onVisibleChange: vis => {
            if (!vis) {
              setPreview('');
            }
          }
        }}
        src={preview}
      />
      {initialMediaIds?.map(id => (
        <div key={id} className={styles.categoriesWithImage}>
          <div
            className={styles.imageContainer}
            style={{
              backgroundImage: `url(${
                thumbnailPath['images'] + getMediaById(String(id))?.thumbnail
              })`
            }}
          >
            {/* {isMediaSelected && ( */}
            <div className={styles.productMediaStyle}>
              <div>
                {hasDefault &&
                  (defaultMediaId === id ? (
                    <StarFilledIcon
                      className="starIcon"
                      onClick={() => {
                        setDefaultMediaId ? setDefaultMediaId(null) : null;
                      }}
                    />
                  ) : (
                    <StarIcon
                      className="starIcon icon"
                      onClick={() => {
                        setDefaultMediaId ? setDefaultMediaId(id) : null;
                      }}
                    />
                  ))}
                <Button
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                  }}
                  className="preview"
                  type="primary"
                  size="small"
                  onClick={() => {
                    setPreview(
                      `${imagePath + getMediaById(String(id))?.thumbnail}`
                    );
                  }}
                >
                  Preview
                </Button>
                <DeleteOutlined
                  style={{ color: '#fff', zIndex: 111 }}
                  className="removeIcon icon"
                  onClick={e => {
                    e.stopPropagation();
                    // setIsMediaSelected(false);
                    // setCurrentIds(((prev: number[]) =>
                    //   prev?.filter(
                    //     (cId: number) => cId !== id
                    //   )) as SetStateAction<number[] | null>);

                    const res = initialMediaIds.filter(cid => cid !== id);

                    setIds(res);

                    // setIds(((prev: number[]) => {
                    //   prev?.filter((cId: number) => cId !== id);
                    // }) as SetStateAction<number[] | null>);
                  }}
                />
              </div>
            </div>
            {/* // )} */}
          </div>
          <div className="categoriesWithImage-footer">
            <Typography.Text>
              {getMediaById(String(id))?.filename}
            </Typography.Text>
          </div>
        </div>
      ))}

      <UploadFromMedia
        visible={visible}
        initialIds={(initialMediaIds ? initialMediaIds : []).map(String)}
        types={['images', 'vectors']}
        multiSelect={true}
        onCancel={() => setVisible(false)}
        onInsert={ids => {
          setVisible(false);
          setIsMediaSelected(true);
          setPreviewMedia(ids);
          setIds(ids.map(Number));
          // setCurrentIds(ids.map(Number));
        }}
      />
    </div>
  );
};

export default MediaForProduct;
