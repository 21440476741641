import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { FC } from 'react';

import { CalendarIcon } from 'components/shared/SVG/CalendarIcon';
import { CloseIcon } from 'components/shared/SVG';
import styles from './styles.module.less';

const { RangePicker } = DatePicker;

const BaseRangeDatePicker: FC<RangePickerProps> = ({
  suffixIcon = <CalendarIcon />,
  ...props
}) => {
  return (
    <RangePicker
      suffixIcon={suffixIcon}
      clearIcon={<CloseIcon onClick={e => e.stopPropagation()} />}
      {...props}
      size="large"
      className={`${styles.range_picker} ${props.className}`}
    />
  );
};

const UcRangeDatePicker = Object.assign(BaseRangeDatePicker, RangePicker);

export default UcRangeDatePicker;
