import { CardElement } from '@stripe/react-stripe-js';
import { Skeleton } from 'antd';
import stripeImage from 'assets/images/stripe.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/basic';
import styles from './styles.module.less';

const options = {
  hidePostalCode: true,
  classes: {
    invalid: styles.invalidNumber,
    focus: styles.focused
    // empty: styles.withIcons
  },
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#8C8C8C'
      }
    },
    invalid: {
      // color: '#9e2146',
      // borderColor: 'red'
      iconColor: 'red'
    }
  }
};

const { Text } = Typography;

const StripeConnect = ({
  error,
  setError,
  setComplete
}: {
  error: string;
  setComplete: Dispatch<SetStateAction<boolean>>;
  setError: Function;
}) => {
  const { t } = useTranslation('billing');
  const [fakeLoad, setFakeLoad] = useState(true);
  const [stripeNumberError, setStripeNumberError] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setFakeLoad(false);
    }, 500);
  }, []);

  return (
    <div className={styles.stripeConnect}>
      <div className={styles.details}>
        <Typography>{t('billingInfo', 'Billing Information')}</Typography>
        <a href="https://stripe.com" target="_blank" rel="noreferrer">
          <img src={stripeImage} alt="powered by stripe" />
        </a>
      </div>
      {!fakeLoad ? (
        <CardElement
          className={`${styles.input}  ${
            error ? styles.errorNumber : ''
          } ant-input`}
          onChange={event => {
            setError('');
            setComplete(event.complete);

            if (event.error) {
              setStripeNumberError(event.error?.message);
            } else if (stripeNumberError) {
              setStripeNumberError('');
            }
          }}
          options={options}
          onEscape={() => {}}
        />
      ) : (
        <Skeleton.Input
          active
          style={{
            height: 40,
            width: '100%'
          }}
        />
      )}
      {(error || stripeNumberError) && (
        <Text type="danger">{error || stripeNumberError}</Text>
      )}
    </div>
  );
};

export default StripeConnect;
