import { Col, Row, Skeleton } from 'antd';

import { PageContainer } from 'components/wrapper';
import NestedPageHeader from 'pages/settings/components/NestedPageHeader';
import NestedPageWrapper from 'pages/settings/components/NestedPageWrapper';
import { Divider } from 'components/basic';
import styles from './styles.module.less';

const CreateSelfPickupSkeleton = () => {
  return (
    <PageContainer>
      <NestedPageHeader
        onBack={() => null}
        title={
          <Skeleton.Input
            className={styles.skeleton_title}
            style={{ width: 250 }}
          />
        }
      />
      <NestedPageWrapper>
        <div className={styles.block}>
          <Skeleton.Input
            style={{ height: 28, width: 250, marginBottom: 24 }}
          />

          <Skeleton.Input style={{ height: 16, width: 200, marginBottom: 5 }} />
          <Skeleton.Input size="large" style={{ width: 300 }} />

          <Skeleton.Input
            style={{ height: 16, width: 200, marginTop: 24, marginBottom: 16 }}
          />
          <Skeleton.Input size="large" style={{ height: 200, width: 750 }} />
          <Divider />
          <Row gutter={24}>
            <Col>
              <Skeleton.Input style={{ height: 20, width: 200 }} />
            </Col>
            <Col>
              <Skeleton.Input style={{ height: 20, width: 200 }} />
            </Col>
          </Row>
          <Skeleton.Input
            style={{ height: 16, width: 200, marginTop: 24, marginBottom: 5 }}
          />
          <Skeleton.Input size="large" style={{ width: 750 }} />
          <Divider />
          <Skeleton.Input
            style={{ height: 28, width: 250, marginBottom: 24 }}
          />
          <Skeleton.Input style={{ height: 16, width: 200, marginBottom: 5 }} />
          <Skeleton.Input size="large" style={{ width: 200 }} />
        </div>
      </NestedPageWrapper>
    </PageContainer>
  );
};

export default CreateSelfPickupSkeleton;
