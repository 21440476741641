import { FC, MouseEventHandler } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { LinkProps } from 'antd/lib/typography/Link.d';
import { Typography } from 'antd';
import { BaseType } from 'antd/es/typography/Base';

import styles from './styles.module.less';
import { customGenericClassNames } from '../helpers';
import { FontWightTypes } from 'components/basic/Typography/types.d';
import { LinkTypes } from 'components/basic/Typography/Link/index.d';

const { Link: TypographyLink } = Typography;

interface Props extends Omit<LinkProps, 'to' | 'type'> {
  to?: string;
  text?: string;
  className?: string;
  ellipsis?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  size?: LinkTypes | keyof typeof LinkTypes;
  fontWeight?: FontWightTypes | keyof typeof FontWightTypes;
  type?: BaseType | 'primary' | 'dark' | 'default';
}

const Link: FC<Props> = ({
  to,
  size = LinkTypes.small,
  className = '',
  ellipsis = true,
  fontWeight = FontWightTypes.regular,
  text,
  onClick,
  children,
  type,
  ...restProps
}) => {
  const hrefClassName = `${className} ${customGenericClassNames(
    type,
    ellipsis
  )} ${styles.linkWrapper} ${styles[size]} ${styles[fontWeight]} ${
    styles.link
  }`;

  if (to) {
    return (
      <RouteLink to={to} className={hrefClassName} {...restProps}>
        {text || children}
      </RouteLink>
    );
  }

  // this component use only for style
  return (
    <TypographyLink onClick={onClick} className={hrefClassName} {...restProps}>
      {text || children}
    </TypographyLink>
  );
};

export default Link;
