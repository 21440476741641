import { FC, ReactNode, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { portalPositions } from './utils';

type Keys = keyof typeof portalPositions;

interface Props {
  position: Keys;
  children?: ReactNode;
  key?: string;
}

const HeaderContent: FC<Props> = ({ children, position }) => {
  const [container, setContainer] = useState<null | HTMLElement>(null);

  useLayoutEffect(() => {
    const container = document.getElementById(portalPositions[position]);
    setContainer(container);
  }, []);

  if (container) {
    return ReactDOM.createPortal(children, container);
  }

  return null;
};

export default HeaderContent;
