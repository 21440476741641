import OneSignal from 'react-onesignal';

import { userIdTagName } from './constants';

export const setOneSignalTags = async (userId: string) => {
  try {
    if (window.median) {
      await window.median.onesignal.tags.setTags({
        tags: {
          userId
        }
      });
    } else {
      await OneSignal.sendTag(userIdTagName, userId);
    }
  } catch (e) {
    console.error(e);
  }
};

export const deleteOneSignalTags = async () => {
  try {
    if (window.median) {
      await window.median.onesignal.tags.deleteTags({
        tags: [userIdTagName]
      });
    } else {
      await OneSignal.deleteTag(userIdTagName);
    }
  } catch (e) {
    console.error(e);
  }
};
