import React, { FC } from 'react';
import { Upload } from 'antd';
import { DraggerProps } from 'antd/es/upload';

interface BaseDraggerProps extends DraggerProps {}

const { Dragger } = Upload;

const BaseDragger: FC<BaseDraggerProps> = props => {
  return <Dragger {...props} />;
};

const UcDragger = Object.assign(Dragger, BaseDragger);

export default UcDragger;
