import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { SystemMessage } from 'helpers';

import { VerificationIcon } from 'components/shared/SVG';
import {
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Typography
} from 'components/basic';
import styles from './styles.module.less';
import useInterval from 'hooks/useInterval';

const { VITE_APP_SSO_URL } = import.meta.env;

const verificationUrl = `${VITE_APP_SSO_URL}/email/verification-notification`;

const AccountVerification = () => {
  const [resendLoading, setResendLoading] = useState(false);

  const { t } = useTranslation('accountVerification');

  const { startTimer, timeout, isPlaying } = useInterval();

  const resendInvitation = async () => {
    try {
      setResendLoading(true);
      const res = await axios({
        method: 'POST',
        url: verificationUrl,
        withCredentials: true
      });

      SystemMessage.success(res.data.message);
      startTimer();
    } catch (error) {
      console.error(error);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Modal
      className={styles.verification_modal}
      width={457}
      centered
      open={true}
      footer={null}
      closable={false}
    >
      <VerificationIcon />
      <Space direction="vertical" align="center">
        <Typography.UcHeading level={3}>
          {t('accountVerification')}
        </Typography.UcHeading>
        <Typography.UcBodyText size="small">
          {t('verificationDescription')}
        </Typography.UcBodyText>
      </Space>
      <Button
        disabled={isPlaying}
        onClick={resendInvitation}
        loading={resendLoading}
        type="primary"
      >
        {t('resend')}
      </Button>
      {isPlaying && (
        <>
          <Divider
            style={{
              margin: 0
            }}
          />
          <Form.Item
            style={{
              marginBottom: 0
            }}
            label="Resend in:"
          >
            <Input disabled value={timeout} />
          </Form.Item>
        </>
      )}
    </Modal>
  );
};

export default AccountVerification;
