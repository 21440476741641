import { Row, Col, Skeleton, Card } from 'antd';
import { useScreenBreakpoints } from 'hooks';

import { ShowElement } from 'components/shared';
import styles from 'components/shared/Skeleton/styles.module.less';

const ProductSkeleton = () => {
  const screens = useScreenBreakpoints();

  return (
    <Row gutter={24} className={styles.discount_form_container}>
      <Col flex={1} className={styles.discount_form_container_section}>
        <Row gutter={24}>
          <Col flex={1}>
            <Card>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Card>
          </Col>
          <ShowElement isShow={Boolean(screens.lg)}>
            <Col span={8}>
              <Card>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Card>
            </Col>
          </ShowElement>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductSkeleton;
