import dayjs from 'dayjs';
import { PureNullable } from 'core/globalTypes';
import { getFullDateFormat } from 'helpers/dateHelpers';

const stringTodayjs = (value: PureNullable<string>, format?: string) => {
  const defaultFormat = getFullDateFormat();
  const formattedValue = dayjs(value, format || defaultFormat).format(
    defaultFormat
  );

  return dayjs(formattedValue, defaultFormat).isValid()
    ? dayjs(formattedValue, defaultFormat)
    : null;
};

export default stringTodayjs;
