import { FC } from 'react';
import { Skeleton } from 'antd';

import { InputSkeletonProps } from 'components/shared/Skeleton/types';

const Input: FC<InputSkeletonProps> = ({ width, height }) => {
  return (
    <Skeleton.Input
      active
      style={{ width, height: height || 40, borderRadius: 12 }}
    />
  );
};

export default Input;
