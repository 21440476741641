import React, { FC, ReactNode, useMemo } from 'react';
import { Skeleton as AmdSkeleton } from 'antd-mobile';

import styles from './styles.module.less';

interface Props {
  loading: boolean;
  cardCount?: number;
  children?: ReactNode;
}

const Skeleton: FC<Props> = ({ loading, children, cardCount }) => {
  const skeletonItems = useMemo(() => {
    return new Array(cardCount).fill(
      <AmdSkeleton animated className={styles.my_project_skeleton} />
    );
  }, [cardCount]);

  if (loading) {
    return <div className={styles.skeleton_height}>{skeletonItems}</div>;
  }

  return <>{children}</>;
};

export default Skeleton;
