import React, { FC } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Button, Drawer } from 'components/basic';
import { CloseIcon } from 'components/shared/SVG';
import styles from './styles.module.less';

interface Props extends ModalProps {
  fullSize?: boolean;
  changeToMobileView?: boolean;
  showFooter?: boolean;
}

const BaseModal: FC<Props> = ({
  okButtonProps,
  cancelButtonProps,
  className,
  fullSize = false,
  changeToMobileView = true,
  ...props
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  if (isMobile && changeToMobileView) {
    const {
      footer,
      open,
      afterClose,
      cancelText,
      okText,
      onOk,
      onCancel,
      title,
      children,
      destroyOnClose,
      confirmLoading,
      closeIcon,
      showFooter = true
    } = props;

    const showCloseIcon = 'closeIcon' in props ? Boolean(closeIcon) : true;

    return (
      <Drawer
        //@ts-ignore
        onClose={e => onCancel?.(e as any)}
        //@ts-ignore
        cancelText={cancelText}
        title={title}
        afterClose={afterClose}
        show={open}
        showFooter={showFooter}
        className={className}
        closeIcon={closeIcon}
        showCloseIcon={showCloseIcon}
        customFooter={footer}
        onOk={onOk}
        okText={okText}
        destroyOnClose={destroyOnClose}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        confirmLoading={confirmLoading}
        rootClassName={props.wrapClassName}
        zIndex={props.zIndex}
        isModal
      >
        {children}
      </Drawer>
    );
  }

  return (
    <Modal
      {...props}
      modalRender={node => (
        <div
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Modal,
            prefix: 'basic'
          })}
        >
          {node}
        </div>
      )}
      closeIcon={
        <Button
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Icon,
            prefix: 'close'
          })}
          className={styles.modal_close_icon}
          icon={<CloseIcon />}
        />
      }
      cancelButtonProps={{
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'cancel'
        }),
        ...cancelButtonProps,
        size: 'large'
      }}
      okButtonProps={{
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'save'
        }),
        ...okButtonProps,
        size: 'large',
        style: { marginLeft: 16 }
      }}
      className={`${className} ${fullSize ? styles.full_size : ''}`}
    />
  );
};

const UcModal = Object.assign(BaseModal, Modal);

export default UcModal;
