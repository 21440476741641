import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';

const { Group } = Checkbox;

const BaseCheckboxGroup = (props: CheckboxGroupProps) => {
  return <Group {...props} />;
};

const UcCheckboxGroup = Object.assign(Group, BaseCheckboxGroup);

export default UcCheckboxGroup;
