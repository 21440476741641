import { Skeleton } from 'antd';

import styles from './styles.module.less';

type Props = {
  colCount?: number;
  rowCount?: number;
};

function TableSkeleton({ colCount = 5, rowCount = 6 }: Props) {
  return (
    <table className={styles.Table}>
      <thead>
        <tr>
          {[...Array(colCount)].map((_, i) => (
            <td key={i}>
              <Skeleton.Input active style={{ width: 120, height: 16 }} />
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(rowCount)].map((_, i) => (
          <tr key={i}>
            {[...Array(colCount)].map((_, i) => (
              <td key={i}>
                <Skeleton.Input active style={{ width: 180, height: 16 }} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableSkeleton;
