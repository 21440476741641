import { PopoverProps } from 'antd';

export const PERMISSION_CONSTANTS = {
  Create: {
    Page: 'can-create-page',
    PhysicalProduct: 'can-create-physical-product',
    DigitalProduct: 'can-create-digital-product',
    AppointmentProduct: 'can-create-appointment-product',
    BookingProduct: 'can-create-reservation-product',
    EventProduct: 'can-create-event-product',
    TableProduct: 'can-create-table-product',
    RentalProduct: 'can-create-rental-product',
    GiftCardProduct: 'can-create-gift-card-product',
    Variants: 'can-create-product-variants',
    Customer: 'can-create-customer',
    InternationalShippingProfile: 'can-create-international-shipping-profile',
    LocalShippingProfile: 'can-create-local-shipping-profile',
    Role: 'can-create-role',
    CollectionItem: 'can-create-collection-item',
    InventorySource: 'can-create-inventory-source',
    ManualDiscount: 'can-create-manual-discount',
    AutomaticDiscount: 'can-create-automatic-discount',
    TaxRate: 'can-create-tax-rate'
  },
  Update: {
    Page: 'can-update-page',
    PhysicalProduct: 'can-update-physical-product',
    DigitalProduct: 'can-update-digital-product',
    AppointmentProduct: 'can-update-appointment-product',
    BookingProduct: 'can-update-reservation-product',
    EventProduct: 'can-update-event-product',
    TableProduct: 'can-update-table-product',
    RentalProduct: 'can-update-rental-product',
    GiftCardProduct: 'can-update-gift-card-product',
    Role: 'can-update-role',
    Customer: 'can-update-customer',
    InternationalShippingProfile: 'can-update-international-shipping-profile',
    LocalShippingProfile: 'can-update-local-shipping-profile',
    CollectionItem: 'can-update-collection-item',
    InventorySource: 'can-update-inventory-source',
    NotificationTemplate: 'can-update-notification-template',
    ManualDiscount: 'can-update-manual-discount',
    AutomaticDiscount: 'can-update-automatic-discount',
    TaxRate: 'can-update-tax-rate',
    Plan: 'can-change-plan',
    Order: 'can-edit-order'
  },
  Move: { Page: 'can-move-page' },
  Delete: {
    Page: 'can-delete-page',
    Customer: 'can-delete-customer',
    Member: 'can-delete-member',
    InternationalShippingProfile: 'can-delete-international-shipping-profile',
    LocalShippingProfile: 'can-delete-local-shipping-profile',
    Role: 'can-delete-role',
    CollectionItem: 'can-delete-collection-item',
    InventorySource: 'can-delete-inventory-source',
    ManualDiscount: 'can-delete-manual-discount',
    AutomaticDiscount: 'can-delete-automatic-discount',
    TaxRate: 'can-delete-tax-rate',
    PhysicalProduct: 'can-delete-physical-product',
    DigitalProduct: 'can-delete-digital-product',
    AppointmentProduct: 'can-delete-appointment-product',
    BookingProduct: 'can-delete-reservation-product',
    EventProduct: 'can-delete-event-product',
    TableProduct: 'can-delete-table-product',
    RentalProduct: 'can-delete-rental-product',
    GiftCardProduct: 'can-delete-gift-card-product'
  },
  Set: {
    PasswordOnPage: 'can-set-password-on-page',
    CashOnDelivery: 'can-use-payment-cash-on-delivery',
    PosOnDelivery: 'can-use-payment-pos-on-delivery',
    MoneyTransfer: 'can-use-payment-money-transfer',
    PayPal: 'can-use-payment-paypal',
    AmeriavPos: 'can-use-payment-ameria',
    Ardshinbank: 'can-use-payment-ardshin',
    Evocabank: 'can-use-payment-evoca',
    Acbabank: 'can-use-payment-acba',
    Braintree: 'can-use-payment-braintree',
    UcraftPay: 'can-use-payment-ucraft-pay',
    CashU: 'can-use-payment-cashu',
    Mollie: 'can-use-payment-mollie',
    Revolut: 'can-use-payment-revolut',
    Square: 'can-use-payment-square',
    Paytabs: 'can-use-payment-paytabs',
    Idram: 'can-use-payment-idram',
    FastShift: 'can-use-payment-fastshift',
    Telr: 'can-use-payment-telr',
    FastPay: 'can-use-payment-fastpay',
    EasyPay: 'can-use-payment-easy-pay',
    FulgurPay: 'can-use-payment-'
  },
  Access: { VisualEditor: 'can-access-visual-editor', CMS: 'can-access-cms' },
  Upsell: { Product: 'can-up-sell-products' },
  CrossSell: { Product: 'can-cross-sell-products' },
  Invite: { Member: 'can-invite-member' },
  Do: {
    BulkActions: 'can-do-bulk-actions'
  },
  See: {
    BookingCategories: 'can-see-reservation-categories',
    RentalCategories: 'can-see-rental-categories',
    Reservations: 'can-see-reservations',
    RentalOrders: 'can-see-rental-orders',
    EventCategories: 'can-see-event-categories',
    BookingTypes: 'can-see-reservation-types',
    EventTypes: 'can-see-event-types',
    CustomCheckout: 'can-see-custom-checkout',
    Dashboard: 'can-see-dashboard',
    Orders: 'can-see-orders',
    Products: 'can-see-products',
    Events: 'can-see-events',
    Attendees: 'can-see-attendees',
    Customers: 'can-see-customers',
    Analytics: 'can-see-analytics',
    Discounts: 'can-see-discounts',
    ActivityLog: 'can-see-activity-log',
    Apps: 'can-see-apps',
    Settings: 'can-see-settings',
    Pages: 'can-see-pages',
    ProductCategories: 'can-see-product-categories',
    Attributes: 'can-see-attributes',
    ProductFamilies: 'can-see-product-families',
    PhysicalProduct: 'can-see-physical-products',
    DigitalProduct: 'can-see-digital-products',
    AppointmentProduct: 'can-see-appointment-products',
    BookingProduct: 'can-see-reservation-products',
    Bookings: 'can-see-bookings',
    Rentals: 'can-see-rentals',
    EventProduct: 'can-see-event-products',
    TableProduct: 'can-see-table-products',
    RentalProduct: 'can-see-rental-products',
    GiftCardProduct: 'can-see-gift-card-products',
    AbandonedCheckouts: 'can-see-abandoned-checkouts',
    OwnOrders: 'can-see-own-orders',
    Collections: 'can-see-collections',
    CollectionCategories: 'can-see-collection-categories',
    CollectionItems: 'can-see-collection-items',
    Fields: 'can-see-fields',
    CustomerGroups: 'can-see-groups',
    CustomerReviews: 'can-see-reviews',
    FormSubmissions: 'can-see-form-submissions',
    SalesChannels: 'can-see-sales-channels',
    EventsAbandonedCheckouts: 'can-see-events-abandoned-checkouts',
    BookingAbandonedCheckouts: 'can-see-reservations-abandoned-checkouts',
    RentalsAbandonedCheckouts: 'can-see-rentals-abandoned-checkouts',
    RentalTypes: 'can-see-rental-types'
  },
  Buy: { Domain: 'can-buy-domain' },
  Connect: { Domain: 'can-connect-domain' },
  Disconnect: { Domain: 'can-disconnect-domain' },
  Has: { HooryHumanSupport: 'has-human-support', Watermark: 'has-watermark' },
  RequestAccess: {
    Reservations: 'can-request-reservations-access',
    RentalOrders: 'can-request-rental-orders-access',
    Rentals: 'can-request-rentals-access',
    BookingCategories: 'can-request-reservation-categories-access',
    RentalCategories: 'can-request-rental-categories-access',
    EventCategories: 'can-request-event-categories-access',
    BookingTypes: 'can-request-reservation-types-access',
    EventTypes: 'can-request-event-types-access',
    Dashboard: 'can-request-dashboard-access',
    Orders: 'can-request-orders-access',
    Products: 'can-request-products-access',
    Events: 'can-request-events-access',
    Bookings: 'can-request-bookings-access',
    Attendees: 'can-request-attendees-access',
    Collections: 'can-request-collections-access',
    Customers: 'can-request-customers-access',
    Analytics: 'can-request-analytics-access',
    Discounts: 'can-request-discounts-access',
    ActivityLog: 'can-request-activity_log-access',
    Apps: 'can-request-apps-access',
    Settings: 'can-request-settings-access',
    ProductCategories: 'can-request-product-categories-access',
    Attributes: 'can-request-attributes-access',
    ProductFamilies: 'can-request-product-families-access',
    AbandonedCheckouts: 'can-request-abandoned-checkouts-access',
    CollectionCategories: 'can-request-collection-categories-access',
    CollectionItems: 'can-request-collection-items-access',
    Fields: 'can-request-fields-access',
    CustomerGroups: 'can-request-groups-access',
    CustomerReviews: 'can-request-reviews-access',
    FormSubmissions: 'can-request-form-submissions-access',
    SalesChannels: 'can-request-sales_channels-access',
    EventsAbandonedCheckouts: 'can-request-events-abandoned-checkouts-access',
    BookingAbandonedCheckouts:
      'can-request-reservations-abandoned-checkouts-access',
    RentalsAbandonedCheckouts: 'can-request-rentals-abandoned-checkouts-access',
    RentalTypes: 'can-request-rental-types-access'
  },
  Manage: {
    Orders: 'can-manage-order',
    OwnProducts: 'can-manage-own-products',
    OwnCollectionItems: 'can-manage-own-collection-items',
    Languages: 'can-manage-settings-locale',
    Currencies: 'can-manage-settings-currency'
  }
};

export const antPopoverConfigs: PopoverProps = {
  autoAdjustOverflow: true,
  trigger: 'hover',
  overlayStyle: {
    zIndex: 1100
  },
  overlayInnerStyle: {
    width: 250
  },
  arrowPointAtCenter: true,
  mouseEnterDelay: 0.5
};

export { PERMISSION_CONSTANTS as Actions };
