import { createContext } from 'react';
import { Actions } from 'providers/PermissionAndLimitationProvider/core/constants';

import { PermissionsAndLimitationsContextProps, State } from './types';
import { EnvironmentEnum } from '../core/globalTypes';

export const initialState: State = {
  isOwner: false,
  project: {},
  redirectHandler: () => {},
  permissions: {
    notes: {},
    availablePermissions: [],
    actions: Actions
  },
  environment: {
    [EnvironmentEnum.COMMERCE_URL]: '',
    [EnvironmentEnum.SSO_URL]: '',
    [EnvironmentEnum.REMOTE_HOST]: '',
    [EnvironmentEnum.BUILDER_URL]: ''
  },
  limitations: {
    entities: {}
  }
};

const PermissionsAndLimitationsContext =
  createContext<PermissionsAndLimitationsContextProps>({
    state: initialState,
    dispatch: () => {},
    fetchPermissions: () => {},
    fetchLimitations: () => {}
  });

export default PermissionsAndLimitationsContext;
