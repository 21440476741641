import { FC } from 'react';

import {
  SectionWrapperChartHeader,
  CategoryTagChooser,
  LinkNavigation
} from './components';
import styles from './styles.module.less';
import { SectionWrapperChartProps } from './types';

const SectionWrapperChart: FC<SectionWrapperChartProps> = ({
  title,
  navigationElement,
  infoTagElement = null,
  children
}) => {
  return (
    <div className={styles.container_section}>
      <SectionWrapperChartHeader
        title={title}
        navigationElement={navigationElement}
      />
      {infoTagElement && infoTagElement}
      {children}
    </div>
  );
};

export default Object.assign(SectionWrapperChart, {
  SectionWrapperChartHeader,
  CategoryTagChooser,
  LinkNavigation
});
