import { SelectProps } from 'antd';
import { KeyValuePair } from 'core/globalTypes';
import { ChangeEvent, FC } from 'react';

import Select from '../Select';

export interface TranslatableBaseSelectProps
  extends Omit<SelectProps, 'value' | 'onChange'> {
  locale?: string;
  value?: KeyValuePair<string>;
  onChange?: (v: KeyValuePair<string>) => void;
  onSelectChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const TranslatableSelect: FC<TranslatableBaseSelectProps> = ({
  locale = '',
  onChange,
  value = {},
  onSelectChange,
  ...rest
}) => {
  const handleChange = (e: any) => {
    onChange?.({ ...value, [locale]: e });
    onSelectChange?.(e);
  };

  return <Select onChange={handleChange} value={value[locale]} {...rest} />;
};

export default TranslatableSelect;
